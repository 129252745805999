import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Input } from 'reactstrap';

const SubmissionFilter = ({ setFilter, originalDateFrom, originalDateTo, originalForename, originalLastname }) => {

    const [dateFrom, setDateFrom] = useState(originalDateFrom);
    const [dateTo, setDateTo] = useState(originalDateTo);
    const [forename, setForename] = useState(originalForename);
    const [lastname, setLastname] = useState(originalLastname);

    const filterClickHandler = () => {
        setFilter(dateFrom, dateTo, forename, lastname);
    }

    const dateFromChangeHandler = (datePicked) => {
        if (datePicked < dateTo || dateTo === null) {
            setDateFrom(datePicked);
        } else {
            setDateFrom(dateTo);
            setDateTo(datePicked);
        }
    };

    const dateToChangeHandler = (datePicked) => {
        if (datePicked > dateFrom) {
            setDateTo(datePicked);
        } else {
            setDateFrom(datePicked);
            setDateTo(dateFrom);
        }
    };

    const forenameChangeHandler = (e) => {
        setForename(e.target.value);
    };

    const lastnameChangeHandler = (e) => {
        setLastname(e.target.value);
    };

    return <Row className="submission-filter-bar">
        <Col xs="2">From:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={dateFrom} onChange={dateFromChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="2">To:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={dateTo} onChange={dateToChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="3">Forename: <Input className="hospital-number-inputbox" name="forename" value={forename} onChange={forenameChangeHandler} /></Col>
        <Col xs="4">Last name: <Input className="hospital-number-inputbox" name="lastname" value={lastname} onChange={lastnameChangeHandler} /></Col>
        <Col xs="1" className="filter-button-container"><Button color="primary" onClick={filterClickHandler}>Filter</Button></Col>
    </Row>;
};

export default SubmissionFilter;