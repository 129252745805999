import React, { useState, useEffect, useCallback} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Input, Table, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import tokenService from '../../../services/tokenService';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}
startOfWeek.setDate(startOfWeek.getDate() - 7);
let tomorrow = new Date(new Date().toDateString());
tomorrow.setDate(tomorrow.getDate() + 1);

const SiteManagement = () => {

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [editedSite, setEditedSite] = useState(null);
    const [addMode, setAddMode] = useState(false);
    const [yesNoList, setYesNoList] = useState([]);

    useEffect(() => {
        setYesNoList([{ value: true, name: "Yes" }, { value: false, name: "No" }])
    }, []);

    useEffect(() => {
        getSites();
    }, [])

    const getSites = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleSite', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSiteList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const saveSite = async () => {
        setSaved(false);
        setUpdating(true);

        try {
            const token = tokenService.getToken();
            const data = { ...editedSite };
            const request = fetch('/ScheduleSite', {
                method: 'PUT',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify(data)
            });

            const response = await request;

            if (response.ok) {
                setSaved(true);
                setEditedSite(null);
                getSites();
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            setErrorRetrieving(true);
            console.log(err);
        }
        finally {
            setUpdating(false);
        }
    }

    const editSite = (site) => {
        setSaved(false);
        if (!site) {
            setAddMode(false);
            setEditedSite(null);
        } else {
            setEditedSite({ ...site });
        }
    }

    const addNewSite = () => {
        setAddMode(true);
        editSite({ siteName: '', friendlyName: '', campusName: '', availableForPatientBooking: false });
    }

    const textChangeHandler = (e) => {
        if (editedSite) {
            setEditedSite({ ...editedSite, [e.target.name]: e.target.value });
        }
    }

    const checkedChangeHandler = (e) => {
        if (editedSite) {
            setEditedSite({ ...editedSite, [e.target.name]: e.target.checked });
        }
    }

    const saveIsEnabled = () => {
        if (!editedSite.siteName) {
            return false;
        }
        if (!editedSite.campusName) {
            return false;
        }
        if (addMode) {
            if (siteList.find(ll => ll.siteName.toLowerCase() === editedSite.siteName.toLowerCase())) {
                return false;
            }
        }
        if (updating) {
            return false;
        }
        return true;
    }

    const renderSitesTable = () => {
        if (!siteList.length) {
            return <span>There are no sites configured. Please add one.</span>
        }

        return <Table>
            <thead>
                <tr>
                    <th>Site Name</th>
                    <th>Campus Name</th>
                    <th>Available for Patient Booking (PFP)</th>
                    <th>&nbsp;</th>
                 </tr>
            </thead>
            <tbody>
                {siteList.map((s, idx) => <tr key={ idx }>
                    <td>{s.friendlyName || s.siteName}</td>
                    <td>{s.campusName}</td>
                    <td>{s.availableForPatientBooking ? "Yes" : "No"}</td>
                    <td>
                        <Link to={"/scheduling/location/" + encodeURIComponent(s.siteName)}><Button color="success">View Locations</Button></Link>&nbsp;
                        <Button color="warning" onClick={() => { setAddMode(false); editSite(s) }}>Edit</Button>
                    </td>
                </tr>)}
            </tbody>
        </Table>
    }

    const renderEditSite = () => {
        return <div>
            <Row>
                <Col xs="3">Site Name:</Col>
                <Col xs="3">
                    {addMode && <div className="questionnaire-error"><strong>IMPORTANT: Once set this cannot be changed!</strong></div>}
                    <Input type="text" className="site-management-textbox" name="siteName" autoFocus={addMode} value={editedSite.siteName} onChange={(e) => textChangeHandler(e)} disabled={!addMode} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Display Name:</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="friendlyName" autoFocus={!addMode} value={editedSite.friendlyName} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Campus Name:</Col>
                <Col xs="3"><Label check>
                    <Input type="text" className="site-management-textbox" name="campusName" value={editedSite.campusName} onChange={(e) => textChangeHandler(e)} />
                </Label></Col>
            </Row>
            <Row>
                <Col xs="3">Available for Patient Booking:</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="availableForPatientBooking" checked={editedSite.availableForPatientBooking} onChange={(e) => checkedChangeHandler(e)} />
                </Col>
            </Row>
            <br/>&nbsp;<br/>
            <Button color="danger" onClick={(e) => saveSite()} disabled={!saveIsEnabled()}>Save</Button>&nbsp;<Button color="warning" onClick={(e) => editSite(null)} disabled={updating}>Cancel</Button>
        </div>
    }

    return <div className="questionnaire-body">
        <h3>Site Management for COVID-19 Vaccination</h3>
        {!editedSite && <div><Link to="/scheduling/management">Back to Schedules</Link></div>}
        {(loading || updating) && <Spinner />}
        {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
        {errorRetrieving && <div className="questionnaire-error">Unexpected error detected. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Vaccination Admin service.</div>}
        {!loading && !editedSite && renderSitesTable()}
        {!loading && editedSite && renderEditSite()}
        <br />
        {!editedSite && !loading && <Button color="success" onClick={() => addNewSite()}>Add New Site</Button>}
        {saved && <span className="schedule-utilisation-success">Site saved!</span>}        
    </div>;
};

export default SiteManagement;