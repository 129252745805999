import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Input } from 'reactstrap';

const SubmissionFilter = ({ setFilter, originalSite, originalLocation, originalAppointmentDate, originalForename, originalLastname, originalNhsNumber }) => {

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [site, setSite] = useState(originalSite);
    const [location, setLocation] = useState(originalLocation);
    const [appointmentDate, setAppointmentDate] = useState(originalAppointmentDate);
    const [forename, setForename] = useState(originalForename);
    const [lastname, setLastname] = useState(originalLastname);
    const [nhsNumber, setNhsNumber] = useState(originalNhsNumber);

    useEffect(() => {
        const getSites = async () => {
            setLoading(true);
            try {
                const response = await fetch('/ScheduleSite', {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setSiteList(data.map(d => d.siteName));
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                } else {
                    setErrorRetrieving(true);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }

        getSites();
    }, [])

    useEffect(() => {
        const getLocations = async () => {
            setLoading(true);
            try {
                const response = await fetch('/ScheduleLocation?siteName=' + encodeURIComponent(site), {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setLocationList(data.map(d => d.locationName));
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                } else {
                    setErrorRetrieving(true);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }

        if (site) {
            getLocations();
        }
    }, [site])

    const filterClickHandler = () => {
        setFilter(site, location, appointmentDate, forename, lastname, nhsNumber);
    }

    const siteChangeHandler = (e) => {
        setSite(e.target.value);
    }

    const locationChangeHandler = (e) => {
        setLocation(e.target.value);
    }

    const appointmentDateChangeHandler = (datePicked) => {
        setAppointmentDate(datePicked);
    };

    const forenameChangeHandler = (e) => {
        setForename(e.target.value);
    };

    const lastnameChangeHandler = (e) => {
        setLastname(e.target.value);
    };

    const nhsNumberChangeHandler = (e) => {
        setNhsNumber(e.target.value);
    };

    return <>
        <Row className="submission-filter-bar">
            <Col xs="3">Site:&nbsp;<Input type="select" name="site" onChange={(e) => siteChangeHandler(e)} value={site} width="100px">
                <option value="">Please select</option>
                {siteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
            </Input></Col>
            <Col xs="3">Location:&nbsp;<Input type="select" name="location" onChange={(e) => locationChangeHandler(e)} value={location} width="100px" disabled={locationList.length === 0}>
                <option value="">Please select</option>
                {locationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
            </Input></Col>
        </Row>
        <Row className="submission-filter-bar">
        <Col xs="4">Appointment Date:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={appointmentDate} onChange={appointmentDateChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="2">Forename: <Input className="hospital-number-inputbox" name="forename" value={forename} onChange={forenameChangeHandler} maxLength={30} /></Col>
        <Col xs="3">Last name: <Input className="hospital-number-inputbox" name="lastname" value={lastname} onChange={lastnameChangeHandler} maxLength={30} /></Col>
        <Col xs="2">NHS #: <Input className="hospital-number-inputbox" name="nhsnumber" value={nhsNumber} onChange={nhsNumberChangeHandler} maxLength={10} /></Col>
        <Col xs="1" className="filter-button-container"><Button color="primary" onClick={filterClickHandler}>Filter</Button></Col>
    </Row></>;
};

export default SubmissionFilter;