const questionManager = {
    getQuestions(hadJabElsewhereAnswer, intendFluJabAnswer, declineOtherAnswer) {
        let newQuestions = [];
        let hadJabElsewhere = false;
        if (hadJabElsewhereAnswer === 'Yes') {
            hadJabElsewhere = true;
        }
        let intendFluJab = false;
        if (intendFluJabAnswer === 'Yes') {
            intendFluJab = true;
        }
        let declineOther = false;
        if (declineOtherAnswer === 'Yes') {
            declineOther = true;
        }
        if (hadJabElsewhere) {
            newQuestions = [
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: true },
                { key: "assignmentnumber", mandatory: true },
                { key: "jobtitle", mandatory: true },
                { key: "jabelsewhere", mandatory: true },
                { key: "jablocation", mandatory: true },
                { key: "dateofjab", mandatory: true },
                { key: "intendflujab", mandatory: false },
                { key: "decline_needles", mandatory: false },
                { key: "decline_wontgetflu", mandatory: false },
                { key: "decline_dontbelieveevidence", mandatory: false },
                { key: "decline_dontthinkeffective", mandatory: false },
                { key: "decline_concernsideeffects", mandatory: false },
                { key: "decline_difficultplace", mandatory: false },
                { key: "decline_difficulttime", mandatory: false },
                { key: "decline_other", mandatory: false },
                { key: "decline_reason", mandatory: false },
                { key: "confirm", mandatory: true }];
        } else {
            if (!intendFluJab && !declineOther) {
                newQuestions = [
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "assignmentnumber", mandatory: true },
                    { key: "jobtitle", mandatory: true },
                    { key: "jabelsewhere", mandatory: true },
                    { key: "jablocation", mandatory: false },
                    { key: "dateofjab", mandatory: false },
                    { key: "intendflujab", mandatory: true },
                    { key: "decline_needles", mandatory: false },
                    { key: "decline_wontgetflu", mandatory: false },
                    { key: "decline_dontbelieveevidence", mandatory: false },
                    { key: "decline_dontthinkeffective", mandatory: false },
                    { key: "decline_concernsideeffects", mandatory: false },
                    { key: "decline_difficultplace", mandatory: false },
                    { key: "decline_difficulttime", mandatory: false },
                    { key: "decline_other", mandatory: false },
                    { key: "decline_reason", mandatory: false },
                    { key: "confirm", mandatory: true }];
            } else if (!intendFluJab && declineOther) {
                newQuestions = [
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "assignmentnumber", mandatory: true },
                    { key: "jobtitle", mandatory: true },
                    { key: "jabelsewhere", mandatory: true },
                    { key: "jablocation", mandatory: false },
                    { key: "dateofjab", mandatory: false },
                    { key: "intendflujab", mandatory: true },
                    { key: "decline_needles", mandatory: false },
                    { key: "decline_wontgetflu", mandatory: false },
                    { key: "decline_dontbelieveevidence", mandatory: false },
                    { key: "decline_dontthinkeffective", mandatory: false },
                    { key: "decline_concernsideeffects", mandatory: false },
                    { key: "decline_difficultplace", mandatory: false },
                    { key: "decline_difficulttime", mandatory: false },
                    { key: "decline_other", mandatory: false },
                    { key: "decline_reason", mandatory: true },
                    { key: "confirm", mandatory: true }];
            } else {
                newQuestions = [
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "assignmentnumber", mandatory: true },
                    { key: "jobtitle", mandatory: true },
                    { key: "jabelsewhere", mandatory: true },
                    { key: "jablocation", mandatory: false },
                    { key: "dateofjab", mandatory: false },
                    { key: "intendflujab", mandatory: true },
                    { key: "decline_needles", mandatory: false },
                    { key: "decline_wontgetflu", mandatory: false },
                    { key: "decline_dontbelieveevidence", mandatory: false },
                    { key: "decline_dontthinkeffective", mandatory: false },
                    { key: "decline_concernsideeffects", mandatory: false },
                    { key: "decline_difficultplace", mandatory: false },
                    { key: "decline_difficulttime", mandatory: false },
                    { key: "decline_other", mandatory: false },
                    { key: "decline_reason", mandatory: false },
                    { key: "confirm", mandatory: true }];
            }
        }
        return newQuestions;
    },
    getDirectorates() {
        return ["Cancer Services - Haematology",
            "Cancer Services - Oncology",
            "Cancer Services - Surgical Oncology",
            "Cardiovascular Services",
            "Chief Executive's Office",
            "Chief Nurse's Office",
            "Chief Operating Officer",
            "Clinical Imaging & Medical Physics",
            "Commercial",
            "Communications",
            "Data, Technology & Information",
            "Dental Services",
            "Essentia",
            "Evelina London - Community",
            "Evelina London - Medicine & Neonatology",
            "Evelina London - Surgery & Intensive Care",
            "Evelina London - Women's Services",
            "Finance",
            "Gastrointestinal Medicine & Surgery",
            "GSTT Contractors",
            "Integrated Care - Acute & General Medicine",
            "Integrated Care - Integrated Local Services",
            "Integrated Care - Therapies & Rehabilitation",
            "KCL",
            "Medical Director's Office",
            "Medical Specialties",
            "Other Corporate Departments",
            "Other Directorate not otherwise listed",
            "PACCS - Pulmonary, Adult Critical Care & Sleep",
            "Pharmacy & Medicines Optimisation",
            "R&D / BRC",
            "Retail Partners",
            "Specialist Ambulatory Services",
            "Strategy",
            "Surgery",
            "Theatres, Anaesthesia & Perioperative",
            "Transplant, Renal & Urology",
            "Viapath",
            "Workforce"];
    },
    getJobRoles() {
        return ["Additional Clinical Services",
            "Admin and Clerical Band 2",
            "Admin and Clerical Band 3",
            "Admin and Clerical Band 4",
            "Admin and Clerical Band 5",
            "Admin and Clerical Band 6",
            "Admin and Clerical Band 7",
            "Admin and Clerical Band 8",
            "Admin and Clerical Band 9",
            "Allied Health Professional ",
            "Allied Health Professional Band 4",
            "Allied Health Professional Band 5",
            "Allied Health Professional Band 6",
            "Allied Health Professional Band 7",
            "Allied Health Professional Band 8",
            "Allied Health Professional Band 9",
            "Clinical Scientist / Additional Professional Scientific & Technical",
            "Director/VSM",
            "Estates & Ancillary - Essentia Accommodation Services",
            "Estates & Ancillary - Essentia Catering Services",
            "Estates & Ancillary - Essentia Housekeeping Services",
            "Estates & Ancillary - Essentia Maintenance",
            "Estates & Ancillary - Essentia Other",
            "Estates & Ancillary - Essentia Portering Services",
            "Estates & Ancillary - Essentia Rapid Response Team",
            "Estates & Ancillary - Essentia Transport Services",
            "Estates & Ancillary - GSTT Engineering",
            "Estates & Ancillary - Other",
            "Estates & Ancillary - Security & Reception",
            "Executive Director",
            "KCL Other (if clinical, please chose from other categories if possible)",
            "Medical & Dental - Clinical Fellow/SAS Grade",
            "Medical & Dental - Consultant",
            "Medical & Dental - Foundation Doctor FY1/FY2/FY3",
            "Medical & Dental - Specialty Registrar (ST1/2)",
            "Medical & Dental - Specialty Registrar (ST3+)",
            "Non-Executive Director",
            "Nurse Practitioner",
            "Nursing & Midwifery Band 2",
            "Nursing & Midwifery Band 3",
            "Nursing & Midwifery Band 4",
            "Nursing & Midwifery Band 5",
            "Nursing & Midwifery Band 6",
            "Nursing & Midwifery Band 7",
            "Nursing & Midwifery Band 8",
            "Nursing & Midwifery Band 9",
            "Other",
            "Retail ",
            "Student - Medical & Dental",
            "Student - Nursing & Midwifery",
            "Student - Other ",
            "Viapath"];
    }
};

export default questionManager;