import React, { Component } from 'react';
import { Row } from 'reactstrap';
import RequiredSymb from './RequiredSymb';

class Question extends Component {

    render() {
        const { questionNumber, questionText, isMandatory, unanswered, children } = this.props;
        return <div>
            <Row>
                <div className={(isMandatory && unanswered) ? "col-12 question-text question-text-mandatory" : "col-12 question-text"}>
                    {questionNumber}. {questionText} {isMandatory && <RequiredSymb />}
                </div>
            </Row>
            <Row>
                <div className="col-12">
                    {React.Children.map(children, child => React.cloneElement(child))}
                </div>
            </Row>
        </div> 
    }
};
        
export default Question;