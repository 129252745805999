import React, { Component } from 'react';
import Banner from '../Banner';
import QuestionnaireResults from './QuestionnaireResults';
import { Card, CardHeader, CardBody } from 'reactstrap';

export class Covid19QuestionnaireResults extends Component {

    render() {

        return (<>
            <Card>
                <CardHeader style={{ backgroundColor: '#004B8B' }} >
                    <Banner />
                </CardHeader>
                <CardBody>
                    <QuestionnaireResults />
                </CardBody>
            </Card>
            <footer>
                &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust
            </footer>
        </>
        );
    }
}
