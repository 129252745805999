export const IDENTITY_CONFIG = {
    authority: process.env.REACT_APP_IDP_AUTHORITY, //(string): The URL of the OIDC provider.
    client_id: process.env.REACT_APP_IDP_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: window.location.origin + process.env.REACT_APP_CALLBACK_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
    login: process.env.REACT_APP_IDP_AUTHORITY + "/login",
    automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: window.location.origin + process.env.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: window.location.origin + process.env.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
    audience: window.location.origin + process.env.REACT_APP_AUDIENCE_URL, //is there a way to specific the audience when making the jwt
    response_type: "token id_token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    scope: "openid roles profile", //(string, default: 'openid'): The scope being requested from the OIDC provider.
    acr_values: 'idp:Windows' //(string): The ACR values to use when authenticating against the OIDC provider.
};

export const METADATA_OIDC = {
    issuer: process.env.REACT_APP_IDP_AUTHORITY,
    jwks_uri:
        process.env.REACT_APP_IDP_AUTHORITY +
        "/discovery/keys",
    authorization_endpoint:
        process.env.REACT_APP_IDP_AUTHORITY + "/oauth2/authorize",
    token_endpoint: process.env.REACT_APP_IDP_AUTHORITY + "/oauth2/token",
    userinfo_endpoint: process.env.REACT_APP_IDP_AUTHORITY + "/userinfo",
    end_session_endpoint:
        process.env.REACT_APP_IDP_AUTHORITY + "/oauth2/logout",
    check_session_iframe:
        process.env.REACT_APP_IDP_AUTHORITY + "/oauth2/checksession",
    revocation_endpoint:
        process.env.REACT_APP_IDP_AUTHORITY + "/oauth2/revocation",
    introspection_endpoint:
        process.env.REACT_APP_IDP_AUTHORITY + "/oauth2/introspect",
    claim_types_supported: ["normal","distributed"],
    claims_supported: ["group","role","sub","iss","auth_time","acr",
        "name","given_name","family_name","nickname",
        "profile","picture","website",
        "email","email_verified","locale","zoneinfo"]
};
