import React, { Component } from 'react';
import Question from '../common/Question';
import QuestionnaireBlurb from './QuestionnaireBlurb';
import { Input, FormGroup, Label, Button } from 'reactstrap';

class Questionnaire extends Component {

    constructor(props) {
        super(props);

        const { hospitalnumber } = props.match.params;
        const uppercasedHospitalNumber = hospitalnumber ? hospitalnumber.toUpperCase() : '';
        const answers = [];
        if (uppercasedHospitalNumber) {
            answers.push({ key: 'hospnum', answer: uppercasedHospitalNumber });
        }

        this.state = {
            questionnaire: 'COVID-19 CC',
            answers: answers,
            allMandatoryAnswered: false,
            hospitalNumber: uppercasedHospitalNumber,
            isInvalid: false,
            completed: false,
            errored: false,
            submitting: false,
            questions: [
                { key: "hospnum", mandatory: true },
                { key: "covidcontact", mandatory: false },
                { key: "newcough", mandatory: false },
                { key: "newbreathlessness", mandatory: false },
                { key: "runnynose", mandatory: false },
                { key: "flusymptoms", mandatory: false },
                { key: "diarrhoea", mandatory: false },
                { key: "covidpositive", mandatory: false },
                { key: "beswabbed", mandatory: false },
                { key: "confirm", mandatory: true }
            ],
            validationErrors: []
        };

        this.storeAnswer = this.storeAnswer.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.submitAnswers = this.submitAnswers.bind(this);
    }

    async submitAnswers() {

        this.setState({
            submitting: true
        });

        let mandatoryQuestionsUnanswered = 0;
        const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
        for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
            const mandatoryQuestion = mandatoryQuestions[mandatory];
            const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
            if (userAnswer.length === 0 || !userAnswer[0].answer) {
                mandatoryQuestionsUnanswered++;
            }
        }

        if (mandatoryQuestionsUnanswered > 0) {
            this.setState({
                isInvalid: true,
                submitting: false
            })
        }
        else {
            const data = {
                questionnaireName: this.state.questionnaire,
                questionAnswers: []
            };
            this.state.answers.map(a => data.questionAnswers.push({ questionKey: a.key, answers: [a.answer] }));

            const request = fetch('/QuestionnaireAnswers', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const response = await request;

            if (response.ok) {
                this.setState({
                    completed: true,
                    submitting: false
                });
            } else {
                let validationErrors = [];
                if (response.status === 400) {
                    try {
                        validationErrors = await response.json();
                    }
                    catch (err) {
                        console.log(err);
                    }  
                }
                this.setState({
                    errored: true,
                    submitting: false,
                    validationErrors: validationErrors
                });
            }
        }        
    }

    storeAnswer(questionKey, answer) {
        const existingAnswer = this.state.answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...this.state.answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = this.state.answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        this.setState({
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    textChangeHandler(e, q) {
        this.storeAnswer(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    radioChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
        }
    }

    render() {
        if (this.state.completed) {
            return <div className="questionnaire-body">
                <strong>Thank you! Your responses have been recorded.</strong>
            </div>
        }
        return <div className="questionnaire-body">
            <QuestionnaireBlurb />
            <Question questionNumber={1} questionText={"Please provide your hospital number"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="hospitalNumber" autoFocus value={this.state.hospitalNumber} onChange={(e) => this.textChangeHandler(e, "hospnum")} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={2} questionText={"Have you had recent contact with people who have been diagnosed with COVID-19?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="covidcontact" value="Yes" onChange={(e) => this.radioChangeHandler(e, "covidcontact")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="covidcontact" value="No" onChange={(e) => this.radioChangeHandler(e, "covidcontact")} />No
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="covidcontact" value="Not sure" onChange={(e) => this.radioChangeHandler(e, "covidcontact")} />Not sure
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={3} questionText={"In the past 2 weeks, have you had a new cough?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="newcough" value="Yes" onChange={(e) => this.radioChangeHandler(e, "newcough")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="newcough" value="No" onChange={(e) => this.radioChangeHandler(e, "newcough")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={4} questionText={"In the past 2 weeks, have you experienced new breathlessness?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="newbreathlessness" value="Yes" onChange={(e) => this.radioChangeHandler(e, "newbreathlessness")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="newbreathlessness" value="No" onChange={(e) => this.radioChangeHandler(e, "newbreathlessness")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={5} questionText={"In the past 2 weeks, have you had a streaming/runny nose?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="runnynose" value="Yes" onChange={(e) => this.radioChangeHandler(e, "runnynose")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="runnynose" value="No" onChange={(e) => this.radioChangeHandler(e, "runnynose")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={6} questionText={"In the past 2 weeks, have you had flu like symptoms (headaches, muscle aches)?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="flusymptoms" value="Yes" onChange={(e) => this.radioChangeHandler(e, "flusymptoms")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="flusymptoms" value="No" onChange={(e) => this.radioChangeHandler(e, "flusymptoms")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={7} questionText={"In the past 2 weeks, have you had diarrhoea?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="diarrhoea" value="Yes" onChange={(e) => this.radioChangeHandler(e, "diarrhoea")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="diarrhoea" value="No" onChange={(e) => this.radioChangeHandler(e, "diarrhoea")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={8} questionText={"Have you recently tested positive for COVID-19?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="covidpositive" value="Yes" onChange={(e) => this.radioChangeHandler(e, "covidpositive")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="covidpositive" value="No" onChange={(e) => this.radioChangeHandler(e, "covidpositive")} />No
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="covidpositive" value="Awaiting results" onChange={(e) => this.radioChangeHandler(e, "covidpositive")} />Awaiting results
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={9} questionText={"We are swabbing patients so we can keep the Cancer Centre safe. Are you happy to be swabbed today?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="beswabbed" value="Yes" onChange={(e) => this.radioChangeHandler(e, "beswabbed")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="beswabbed" value="No" onChange={(e) => this.radioChangeHandler(e, "beswabbed")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={10} questionText={"The purpose of this questionnaire is to keep our patients and the Cancer Centre safe. I agree for my answers and swab result to be used for this research purpose."} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="confirm" value="Yes" onChange={(e) => this.radioChangeHandler(e, "confirm")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="confirm" value="No" onChange={(e) => this.radioChangeHandler(e, "confirm")} />No
                    </Label>
                </FormGroup>
            </Question>
            <Button color="primary" onClick={() => this.submitAnswers()} disabled={this.state.submitting}>Submit</Button>
            {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review your answers and re-submit.</strong></div>}
        </div>;
    }
};

export default Questionnaire;