import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Input } from 'reactstrap';

const SubmissionFilter = ({ setFilter, originalDateFrom, originalDateTo, originalSurname }) => {

    const [dateFrom, setDateFrom] = useState(originalDateFrom);
    const [dateTo, setDateTo] = useState(originalDateTo);
    const [surname, setSurname] = useState(originalSurname);

    const filterClickHandler = () => {
        setFilter(dateFrom, dateTo, surname);
    }

    const dateFromChangeHandler = (datePicked) => {
        if (datePicked < dateTo || dateTo === null) {
            setDateFrom(datePicked);
        } else {
            setDateFrom(dateTo);
            setDateTo(datePicked);
        }
    };

    const dateToChangeHandler = (datePicked) => {
        if (datePicked > dateFrom) {
            setDateTo(datePicked);
        } else {
            setDateFrom(datePicked);
            setDateTo(dateFrom);
        }
    };

    const surnameChangeHandler = (e) => {
        setSurname(e.target.value);
    };

    return <Row className="submission-filter-bar">
        <Col xs="3">From: <DatePicker className="form-control date-selection-inputbox" selected={dateFrom} onChange={dateFromChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="3">To: <DatePicker className="form-control date-selection-inputbox" selected={dateTo} onChange={dateToChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="4">Surname: <Input className="hospital-number-inputbox" name="hospitalNumber" value={surname} onChange={surnameChangeHandler} /></Col>
        <Col xs="1" className="filter-button-container"><Button color="primary" onClick={filterClickHandler}>Filter</Button></Col>
    </Row>;
};

export default SubmissionFilter;