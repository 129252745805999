import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import Question from '../../common/Question';
import Moment from 'react-moment';

const ViewQuestionnaireSubmission = ({ submission, returnCallback }) => {

    const renderAnswer = (questions, questionKey) => {
        const filteredQuestion = questions.filter(q => q.questionKey.toLowerCase() === questionKey.toLowerCase());
        const question = filteredQuestion.length > 0 ? filteredQuestion[0] : null;
        if (question === null || question.answers.length === 0) {
            return "Not Answered";
        } else {
            if (question.answers.length === 1) {
                return <strong>{question.answers[0]}</strong>;
            } else {
                return <ol>{question.answers.map((a, idx) => <li key={idx}><strong>{a}</strong></li>)}</ol>;
            }
        }
    }

    return <div className="questionnaire-body">
        <div><Button color="secondary" onClick={() => returnCallback()}>Return to list</Button></div>
        <div className="questionnaire-submission-summary">This submission was recorded on <strong><Moment format="DD MMM YYYY HH:mm:ss">{submission.submissionDate}</Moment></strong>. Submission identifier: <strong>{submission.submissionIdentifier}</strong>.</div>
        <Question questionNumber="1" questionText={"Mobile"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "mobile")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="2" questionText={"Assignment Number:"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "assignmentnumber")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="3.1" questionText={"First name"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "forename")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="3.2" questionText={"Last name"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "lastname")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="4" questionText={"Date of birth"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "dateofbirth")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="5" questionText={"Gender"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "gender")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="6" questionText={"Ethnic Group"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "ethnicity")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="7" questionText={"Postcode of residence"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "postcode")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="8" questionText={"Directorate:"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "directorate")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="9" questionText={"Job Role:"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "jobrole")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={10} questionText={"Date of test"} isMandatory>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "dateoftest")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="11" questionText={"Identifier code of test strip (i.e. C12345678)"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "lotnumberoftest1")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="12.1a" questionText={"What is the result of your test?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "resultoftest1")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="12.1b" questionText={"Were you able to perform a repeat test after the first invalid test?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "confirmrepeattest")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="12.1c" questionText={"Identifier code of repeat test strip (i.e. C87654321)"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "lotnumberoftest2")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="12.1d" questionText={"What is the result of your repeat test?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "resultoftest2")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="12.2" questionText={"Have you been in to work within the past 48 hours?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "beentowork")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="13" questionText={"Have you reported this result on GOV.UK?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "dualreported")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber="14" questionText={"Please tell us of your experience whilst performing the test, including any issues or problems."}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "comments")}</Label>
            </FormGroup>
        </Question>
        <div><Button color="secondary" onClick={() => returnCallback()}>Return to list</Button></div>
    </div>;

};

export default ViewQuestionnaireSubmission;