import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import Banner from '../Banner';
import HubInfoConfig from './HubInfoConfig';
import { Card, CardHeader, CardBody } from 'reactstrap';
import isInRole from '../../../utils/isInRole';

export class HubInfoConfigContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkingAccess: true,
            hasRequiredRoles: false
        };
    }

    async componentDidMount() {
        const { requiredRoles } = this.props;
        if (isInRole(requiredRoles)) {
                this.setState({
                    checkingAccess: false,
                    hasRequiredRoles: true
                });
            } else {
                this.setState({
                    checkingAccess: false
                });
            }
        }

        userHasRequiredRoles = (usersRoles, requiredRoles) => {
            const matchingRoles = usersRoles.filter(ur => requiredRoles.filter(rr => ur.toLowerCase() === rr.toLowerCase()).length > 0);
            return matchingRoles.length > 0;
        };

        render() {

            if (this.state.checkingAccess) {
                return null;
            }

            if (!this.state.hasRequiredRoles) {
                return (<>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#004B8B' }} >
                            <Banner showLogin />
                        </CardHeader>
                        <CardBody>
                            <div>You are not authorized to view this page.</div>
                        </CardBody>
                    </Card>
                    <footer>
                        &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust
                    </footer>
                </>
                );
            }

            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} >
                        <Banner />
                    </CardHeader>
                    <CardBody>
                        <CookiesProvider>
                            <HubInfoConfig {...this.props} />
                        </CookiesProvider>
                    </CardBody>
                </Card>
                <footer>
                    &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust - <a href="https://www.guysandstthomas.nhs.uk/patients-and-visitors/patients/your-care/health-records.aspx" target="_blank" rel="noopener noreferrer" title="Privacy Policy">Privacy</a>
                </footer>
            </>
            );
        }
    }
