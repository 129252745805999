import React, { Component } from 'react';
import Question from '../common/Question';
import QuestionnaireBlurb from './QuestionnaireBlurb';
import RequiredSymb from '../common/RequiredSymb';
import questionManager from './questionManager';
import { Input, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.gb';

const currentDate = new Date(new Date().toDateString());

class Questionnaire extends Component {

    constructor(props) {
        super(props);

        const { hospitalnumber } = props.match.params;
        const uppercasedHospitalNumber = hospitalnumber ? hospitalnumber.toUpperCase() : '';
        const answers = [];
        if (uppercasedHospitalNumber) {
            answers.push({ key: 'hospnum', answer: uppercasedHospitalNumber });
        }
        // default all answers for "availability" to be checked
        const availability = [];
        const availabilityStartDate = new Date();
        availabilityStartDate.setDate(availabilityStartDate.getDate() + 1);
        let availabilityDate = new Date(availabilityStartDate)
        for (let day = 1; day <= 14; day++) {
            if (this.availableDate(availabilityDate)) {
                availability.push({ day: this.constuctDayAvailabilityKey(new Date(availabilityDate), true), checked: true })
                availability.push({ day: this.constuctDayAvailabilityKey(new Date(availabilityDate), false), checked: true })
                if (this.dateMatch(availabilityDate, new Date(2020, 6, 16)) || this.dateMatch(availabilityDate, new Date(2020, 6, 17))) {
                    availability.push({ day: this.constuctDayAvailabilityKey(new Date(availabilityDate), false, true), checked: true })
                }
            }
            availabilityDate.setDate(availabilityDate.getDate() + 1);
        }
        const availableDays = availability.filter(ad => ad.checked === true).sort((f, s) => {
            if (f.day < s.day) {
                return -1
            }
            else if (f.day > s.day) {
                return 1
            }
            return 0;
        }).reduce((acc, cur, idx) => idx === 0 ? cur.day : acc + ", " + cur.day, '');
        answers.push({ key: 'availability', answer: availableDays });

        this.state = {
            canAccess: false,
            accessDeniedMessage: '',
            checkingAccess: true,
            questionnaire: 'COVID-19 Staff Antibody',
            answers: answers,
            allMandatoryAnswered: false,
            hospitalNumber: uppercasedHospitalNumber,
            surname: '',
            firstname: '',
            dateOfBirth: '',
            mobile: '',
            confirmedMobile: '',
            directorate: '',
            directorateList: [],
            jobRole: '',
            jobRoleList: [],
            availabilityDays: availability,
            isInvalid: false,
            completed: false,
            errored: false,
            submitting: false,
            questions: [
                { key: "previouslyhadtest", mandatory: true },
                { key: "regpatient", mandatory: true },
                { key: "testingsite", mandatory: false },
                { key: "hospnum", mandatory: false },
                { key: "surname", mandatory: true },
                { key: "firstname", mandatory: true },
                { key: "dateofbirth", mandatory: true },
                { key: "gender", mandatory: true },
                { key: "address", mandatory: true },
                { key: "postcode", mandatory: true },
                { key: "gpname", mandatory: false },
                { key: "gpaddress", mandatory: false },
                { key: "nhsnumber", mandatory: false },
                { key: "mobile", mandatory: true },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "wanttesting", mandatory: true },
                { key: "availability", mandatory: true },
                { key: "notestreason", mandatory: true },
                { key: "confirm", mandatory: true }
            ],
            validationErrors: []
        };

        this.storeAnswer = this.storeAnswer.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.submitAnswers = this.submitAnswers.bind(this);
    }

    async componentDidMount() {
        const availableDirectorates = questionManager.getDirectorates();
        const availableJobRoles = questionManager.getJobRoles();
        this.setState({
            checkingAccess: true,
            directorateList: availableDirectorates,
            jobRoleList: availableJobRoles
        });

        const request = fetch('/Connection?questionnaireKey=' + encodeURIComponent(this.state.questionnaire), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const response = await request;

        if (response.ok) {
            var connection = await response.json();
            this.setState({
                checkingAccess: false,
                canAccess: connection.canAccess,
                accessDeniedMessage: connection.message
            });
        } else {
            this.setState({
                checkingAccess: false,
                canAccess: false,
                accessDeniedMessage: "There was a problem contacting the registration system. Please try again later."
            });
        }
    }

    async submitAnswers() {

        this.setState({
            submitting: true
        });

        // determine which questions are mandatory based upon answers
        let previouslyTested = '';
        let registeredPatient = '';
        let wantTesting = '';
        let dateOfBirth = '';
        let dateOfBirthIsoFormat = '';
        var newQuestions = [];
        const previouslyTestedAnswer = this.state.answers.filter(a => a.key === "previouslyhadtest");
        const registeredPatientAnswer = this.state.answers.filter(a => a.key === "regpatient");
        const wantTestingAnswer = this.state.answers.filter(a => a.key === 'wanttesting');
        const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
        if (previouslyTestedAnswer.length > 0) {
            previouslyTested = previouslyTestedAnswer[0].answer;
        }
        if (registeredPatientAnswer.length > 0) {
            registeredPatient = registeredPatientAnswer[0].answer;
        }
        if (wantTestingAnswer.length > 0) {
            wantTesting = wantTestingAnswer[0].answer;
        }
        if (dateOfBirthAnswer.length > 0) {
            dateOfBirth = dateOfBirthAnswer[0].answer;
            if (dateOfBirth.length === 10) {
                dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
            }
        }
        newQuestions = questionManager.getQuestions(previouslyTested, registeredPatient, wantTesting);

        this.setState({
            questions: newQuestions
        }, async () => {
            let mandatoryQuestionsUnanswered = 0;
            const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
            for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                const mandatoryQuestion = mandatoryQuestions[mandatory];
                const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                if (userAnswer.length === 0 || !userAnswer[0].answer) {
                    mandatoryQuestionsUnanswered++;
                }
            }

            if (mandatoryQuestionsUnanswered > 0) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: []
                })
            }
            else if (this.state.mobile !== this.state.confirmedMobile) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Mobile numbers do not match!"]
                });
            }
            else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth cannot be in the future!"]
                });
            }
            else if (new Date() >= new Date(2020, 8, 10, 17, 0, 0)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["The staff antibody test registration is no longer available as of 5:00PM on Thursday 10th September 2020."]
                });
            }
            else {
                const data = {
                    questionnaireName: this.state.questionnaire,
                    questionAnswers: []
                };
                this.state.answers.map(a => data.questionAnswers.push({ questionKey: a.key, answers: [a.answer] }));

                const request = fetch('/QuestionnaireAnswers', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const response = await request;

                if (response.ok) {
                    this.setState({
                        completed: true,
                        submitting: false
                    });
                } else {
                    let validationErrors = [];
                    if (response.status === 400) {
                        try {
                            validationErrors = await response.json();
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    this.setState({
                        errored: true,
                        isInvalid: false,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
            }
        });
    }

    dateMatch(firstDate, secondDate) {
        if (firstDate.getDate() !== secondDate.getDate()) {
            return false;
        }
        if (firstDate.getMonth() !== secondDate.getMonth()) {
            return false;
        }
        if (firstDate.getFullYear() !== secondDate.getFullYear()) {
            return false;
        }
        return true;
    }

    availableDate(theDate) {
        let invalidDate = false;
        if (this.dateMatch(theDate, new Date(2020, 6, 18))
            || this.dateMatch(theDate, new Date(2020, 6, 19))
            || this.dateMatch(theDate, new Date(2020, 6, 25))
            || this.dateMatch(theDate, new Date(2020, 6, 26))
            || this.dateMatch(theDate, new Date(2020, 7, 29))
            || this.dateMatch(theDate, new Date(2020, 7, 30))
            || this.dateMatch(theDate, new Date(2020, 7, 31))
            || this.dateMatch(theDate, new Date(2020, 8, 5))
            || this.dateMatch(theDate, new Date(2020, 8, 6))) {
            invalidDate = true;
        }
        if (theDate >= new Date(2020, 8, 12)) {
            invalidDate = true;
        }
        return !invalidDate;
    }

    stThomasSiteAvailable() {
        return new Date() < new Date(2020, 6, 24);
    }

    storeAnswer(questionKey, answer, callback) {
        const existingAnswer = this.state.answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...this.state.answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = this.state.answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        this.setState({
            answers: updatedAnswers,
            isInvalid: false
        }, () => {
                if (callback) {
                    callback();
                }
        });
    }

    resetAvailability() {
        const availableDays = this.state.availabilityDays.filter(ad => ad.checked === true).sort((f, s) => {
            if (f.day < s.day) {
                return -1
            }
            else if (f.day > s.day) {
                return 1
            }
            return 0;
        }).reduce((acc, cur, idx) => idx === 0 ? cur.day : acc + ", " + cur.day, '');
        this.storeAnswer('availability', availableDays);
    }

    textChangeHandler(e, q) {
        this.storeAnswer(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeNoStoreHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeRawHandler(e, q) {
        this.storeAnswer(q, e.target.rawValue);
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    textChangeNoStoreRawHandler(e) {
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    radioChangeHandler(e, q, callback) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value, callback);
        }
    }

    getAvailabilityValue(day) {
        const availabilityValue = this.state.availabilityDays.filter(a => a.day === day);
        return availabilityValue[0].checked;
    }

    availabilityChanged(e, day) {
        const filteredAvailabilityDays = this.state.availabilityDays.filter(a => a.day !== day);
        this.setState({
            availabilityDays: [...filteredAvailabilityDays, { day: day, checked: e.target.checked }]
        }, () => {
                const availableDays = this.state.availabilityDays.filter(ad => ad.checked === true).sort((f, s) => {
                    if (f.day < s.day) {
                        return -1
                    }
                    else if (f.day > s.day) {
                        return 1
                    }
                    return 0;
                }).reduce((acc, cur, idx) => idx === 0 ? cur.day : acc + ", " + cur.day, '');
                this.storeAnswer("availability", availableDays);
        });
        
    }

    constuctDayAvailabilityKey(date, isMorning, isEvening) {
        return date.getFullYear() + '-' + ("00" + (date.getMonth() + 1)).slice(-2) + '-' + ("00" + date.getDate()).slice(-2) + ' ' + (isMorning ? "AM" : (isEvening ? "EVE" : "PM"));
    }

    renderAvailabilityInput() {
        const output = [];
        const startDate = new Date();
        startDate.setDate(startDate.getDate() + 1);
        let curDate = new Date(startDate);
        for (let i = 0; i < 14; i++) {
            const row = [];
            const renderDate = new Date(curDate);
            if (this.availableDate(renderDate)) {
                row.push(<Row key={i}>
                    <Col xs="4" className="text-nowrap"><Moment format="dddd DD MMM YYYY">{renderDate}</Moment>: </Col>
                    <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getAvailabilityValue(this.constuctDayAvailabilityKey(renderDate, true))} onChange={(e) => this.availabilityChanged(e, this.constuctDayAvailabilityKey(renderDate, true))} /> AM</Col>
                    <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getAvailabilityValue(this.constuctDayAvailabilityKey(renderDate, false))} onChange={(e) => this.availabilityChanged(e, this.constuctDayAvailabilityKey(renderDate, false))} />PM</Col>
                    {(this.dateMatch(renderDate, new Date(2020, 6, 16)) || this.dateMatch(renderDate, new Date(2020, 6, 17))) && <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getAvailabilityValue(this.constuctDayAvailabilityKey(renderDate, false, true))} onChange={(e) => this.availabilityChanged(e, this.constuctDayAvailabilityKey(renderDate, false, true))} />Evening</Col>}
                </Row>);
                output.push(row);
            }
            curDate.setDate(curDate.getDate() + 1);
        }
        return output;
    }

    showIf(previouslyHadTestAnswered, previouslyHadTest, wantsTestAnswered, wantsTest, registeredAnswered, registered) {
        let show = true;
        let currentPreviouslyHadTestAnswer = '';
        let currentWantsTestAnswer = '';
        let currentRegisteredAnswer = '';
        const previouslyTestedAnswer = this.state.answers.filter(a => a.key === "previouslyhadtest");
        const registeredPatientAnswer = this.state.answers.filter(a => a.key === "regpatient");
        const wantTestingAnswer = this.state.answers.filter(a => a.key === 'wanttesting');
        if (previouslyTestedAnswer.length > 0) {
            currentPreviouslyHadTestAnswer = previouslyTestedAnswer[0].answer;
        }
        if (wantTestingAnswer.length > 0) {
            currentWantsTestAnswer = wantTestingAnswer[0].answer;
        }
        if (registeredPatientAnswer.length > 0) {
            currentRegisteredAnswer = registeredPatientAnswer[0].answer;
        }
        if (previouslyHadTestAnswered) {
            if (previouslyHadTest !== '') {
                if (currentPreviouslyHadTestAnswer !== previouslyHadTest) {
                    show = false;
                }
            } else {
                if (currentPreviouslyHadTestAnswer === '') {
                    show = false;
                }
            }
        }
        if (wantsTestAnswered) {
            if (wantsTest !== '') {
                if (currentWantsTestAnswer !== wantsTest) {
                    show = false;
                }
            } else {
                if (currentWantsTestAnswer === '') {
                    show = false;
                }
            }
        }
        if (registeredAnswered) {
            if (registered !== '') {
                if (currentRegisteredAnswer !== registered) {
                    show = false;
                }
            } else {
                if (currentRegisteredAnswer === '') {
                    show = false;
                }
            }
        }
        return show;
    }

    renderHospitalSites() {
        let output = [];
        if (this.stThomasSiteAvailable()) {
            output.push(<FormGroup className="questionnaire-answers">
                <Label check>
                    <Input type="radio" name="testingsite" value="STH" onChange={(e) => this.radioChangeHandler(e, "testingsite")} />St. Thomas' Hospital
                    </Label>
            </FormGroup>);
        }
        output.push(<FormGroup className="questionnaire-answers">
            <Label check>
                <Input type="radio" name="testingsite" value="Guys" onChange={(e) => this.radioChangeHandler(e, "testingsite")} />Guy's Hospital
                    </Label>
        </FormGroup>);
        return output;
    }

    render() {
        if (this.state.checkingAccess) {
            return <div className="questionnaire-body">
                Loading...
            </div>;
        }
        if (!this.state.canAccess) {
            return <div className="questionnaire-body">
                {this.state.accessDeniedMessage}
            </div>;
        }
        if (this.state.completed) {
            return <div className="questionnaire-body">
                <p><strong>Thank you! Your responses have been recorded. If you have opted in for the test, then you will receive an appointment confirmation via the mobile number you provided.</strong></p>
                <p><strong>We are currently not undertaking repeat tests for those who have previously had an antibody screen taken at GSTT. Staff who are participating in COVID vaccine trials do not need to book an additional test as this is already incorporated into the vaccines testing programme.</strong></p>
            </div>
        }
        if (new Date() >= new Date(2020, 8, 10, 17, 0, 0)) {
            return <div className="questionnaire-body">
                <p className="questionnaire-blurb-important"><strong>The staff antibody testing programme supported by NHSE has now closed.</strong></p>
                <p><strong>Any existing appointments, where you have received a confirmation text, will still go ahead.</strong></p>
            </div>
        }
        return <div className="questionnaire-body">
            <QuestionnaireBlurb />
            <Question questionNumber="1.1" questionText={"Have you previously had the antibody test at Guy's & St. Thomas' NHS Foundation Trust?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="previouslyhadtest" value="Yes" onChange={(e) => this.radioChangeHandler(e, "previouslyhadtest", () => this.storeAnswer('wanttesting', '', () => this.storeAnswer('availability', '')))} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="previouslyhadtest" value="No" onChange={(e) => this.radioChangeHandler(e, "previouslyhadtest")} />No
                    </Label>
                </FormGroup>
            </Question>
            {this.showIf(true, 'No', false, '', false, '') && <Question questionNumber="1.2" questionText={"Do you want to be tested with the antibody test?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="wanttesting" value="Yes" onChange={(e) => this.radioChangeHandler(e, "wanttesting", () => this.resetAvailability())} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="wanttesting" value="No" onChange={(e) => this.radioChangeHandler(e, "wanttesting", () => this.storeAnswer('regpatient', '', () => this.storeAnswer('availability', ''))) } />No
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, 'No', true, 'Yes', false, '') && <Question questionNumber="1.3" questionText={"A 15 minute slot for the test will be allocated in one of your available mornings or afternoons. Please provide your earliest ½ day availability below:"}>
                <FormGroup className="questionnaire-answers">
                    {this.renderAvailabilityInput()}
                </FormGroup>
            </Question>}
            {this.showIf(true, 'No', true, 'No', false, '') && <Question questionNumber="1.3" questionText={"I don't want to be tested because: (please provide reason)"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="notestreason" className="answer-reason" value={this.state.notestreason} onChange={(e) => this.textChangeHandler(e, "notestreason")} maxLength={255} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, 'No', true, 'Yes', false, '') && <Question questionNumber="1.4" questionText={"Are you registered as a patient at Guy's & St. Thomas' NHS Foundation Trust?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="regpatient" value="Yes" onChange={(e) => this.radioChangeHandler(e, "regpatient")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="regpatient" value="No" onChange={(e) => this.radioChangeHandler(e, "regpatient", () => this.storeAnswer('hospnum', ''))} />No
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="1.5" questionText={"Please indicate which hospital site you would prefer to be tested at (Note that after 24th July tests will only be available at Guy's):"} isMandatory>
                {this.renderHospitalSites()}
            </Question>}
            {this.showIf(true, 'No', true, 'Yes', true, 'Yes') && <Question questionNumber="1.6" questionText={"Please provide your hospital number (leave blank if you do not know this)"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="hospitalNumber" value={this.state.hospitalNumber} onChange={(e) => this.textChangeHandler(e, "hospnum")} maxLength={10} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, '', true, '') || this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'Yes', false, '', false, '')) && <Question questionNumber="2.1" questionText={"Please provide your surname"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="surname" value={this.state.surname} onChange={(e) => this.textChangeHandler(e, "surname")} maxLength={30} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, '', true, '') || this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'Yes', false, '', false, '')) && <Question questionNumber="2.2" questionText={"Please provide your first name"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="firstName" value={this.state.firstName} onChange={(e) => this.textChangeHandler(e, "firstname")} maxLength={30} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'Yes', false, '', false, '')) && <Question questionNumber="2.3" questionText={"Please provide your date of birth"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" onChange={(e) => this.textChangeHandler(e, "dateofbirth")} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.4" questionText={"Please provide your gender"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="gender" value={this.state.gender} onChange={(e) => this.textChangeHandler(e, "gender")} maxLength={15} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.5" questionText={"Please provide your address (excluding postcode)"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="address" className="answer-address" value={this.state.address} onChange={(e) => this.textChangeHandler(e, "address")} maxLength={100} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.6" questionText={"Please provide your postcode"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="postcode" value={this.state.postcode} onChange={(e) => this.textChangeHandler(e, "postcode")} maxLength={10} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.7" questionText={"Please provide your GP's name"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="gpName" value={this.state.gpName} onChange={(e) => this.textChangeHandler(e, "gpname")} maxLength={65} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.8" questionText={"Please provide your GP's address"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="gpAddress" className="answer-address" value={this.state.gpAddress} onChange={(e) => this.textChangeHandler(e, "gpaddress")} maxLength={100} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.9" questionText={"Please provide your NHS number (leave blank if you do not know this)"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="nhsNumber" value={this.state.nhsNumber} onChange={(e) => this.textChangeHandler(e, "nhsnumber")} maxLength={20} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'No', true, 'No', true, '')) && <Question questionNumber="2.10" questionText={"Please provide your mobile phone number (for receiving results via text)"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave name="mobile" placeholder="07911 123456" options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeRawHandler(e, "mobile")} maxLength={15} />
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave name="confirmedMobile" placeholder="07911 123456" options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeNoStoreRawHandler(e)} maxLength={15} />
                    </Label>
                    &nbsp;<i>(confirm mobile number by entering again)</i>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, '', true, '') || this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'Yes', false, '', false, '')) && <Question questionNumber="3.1" questionText={"Please enter your Directorate:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="directorate" onChange={(e) => this.textChangeHandler(e, "directorate")}>
                            <option value="">Please select</option>
                            {this.state.directorateList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, '', true, '') || this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'Yes', false, '', false, '')) && <Question questionNumber="3.2" questionText={"Please enter your Job Role:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="jobRole" onChange={(e) => this.textChangeHandler(e, "jobrole")}>
                            <option value="">Please select</option>
                            {this.state.jobRoleList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'Yes', false, '', false, '')) && <div>
                <Row>
                    <div className="col-12 question-text">
                        4. Please confirm that the information you have provided is correct and that you have understood the <a href="https://www.guysandstthomas.nhs.uk/resources/coronavirus/occupational-health/staff-antibody-testing-info-pack.pdf" target="_blank" rel="noopener noreferrer">guidance information</a>. <RequiredSymb />
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="checkbox" name="confirm" value="Yes" onChange={(e) => this.radioChangeHandler(e, "confirm")} />Yes
                            </Label>
                        </FormGroup>
                    </div>
                </Row>
            </div>}
            {(this.showIf(true, 'No', true, 'No', false, '') || this.showIf(true, 'No', true, 'Yes', true, '') || this.showIf(true, 'Yes', false, '', false, '')) && <Button color="primary" onClick={() => this.submitAnswers()} disabled={this.state.submitting}>Submit</Button>}
            {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review your answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
        </div>;
    }
};

export default Questionnaire;