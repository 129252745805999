import moment from "moment";

export const PatientAppointmentItem = (item = {}) => {
    const staff = item.organisation === "Guy's and St Thomas' NHS Foundation Trust" || item.organisation === "Kings College Hospital NHS Foundation Trust";
    const dnaThreshold = 120;
    const hcw = item.healthcareWorker === "Yes" || item.healthcareWorker === true || item.carehomeWorker === "Yes" || item.carehomeWorker === true;

    const getMinutesPastAppointment = (timeSlot) => {
        if (timeSlot && !timeSlot.toLowerCase().startsWith('w')) {
            const timeSlotHours = timeSlot.substring(6, 8);
            const timeSlotMinutes = timeSlot.substring(9, 11);
            const timeSlotTime = new moment();
            timeSlotTime.set({hour: timeSlotHours, minute:timeSlotMinutes});
            return moment().diff(timeSlotTime,"minute");
        }

        return 0;
    }
    return {
        id: item.id,
        submissionId: item.submissionIdentifier,
        isUnregistered: false,
        patientName: item.forename + ' ' + item.lastName,
        comments: item.comments || "",
        dob: new Date(item.dateOfBirth),
        nhsNumber: item.nhsNumber,
        isPatient: !hcw && !staff,
        isHcw: hcw,
        isStaff: staff,
        isPriority: item.isPatient || false,
        firstAppointmentSite: item.firstAppointmentSite,
        firstAppointmentLocation: item.firstAppointmentLocation,
        firstAppointmentDate: item.firstAppointmentDate ? item.firstAppointmentDate : (item.firstAppointmentDateTime ? item.firstAppointmentDateTime.substring(8, 10) + "/" + item.firstAppointmentDateTime.substring(5, 7) + "/" + item.firstAppointmentDateTime.substring(0, 4) : ''),
        firstAppointmentDateTime: item.firstAppointmentDateTime,
        firstAppointmentTimeSlot: item.firstAppointmentTimeSlot,
        secondAppointmentSite: item.secondAppointmentSite,
        secondAppointmentLocation: item.secondAppointmentLocation,
        secondAppointmentDate: item.secondAppointmentDate ? item.secondAppointmentDate : (item.secondAppointmentDateTime ? item.secondAppointmentDateTime.substring(8, 10) + "/" + item.secondAppointmentDateTime.substring(5, 7) + "/" + item.secondAppointmentDateTime.substring(0, 4) : ''),
        secondAppointmentDateTime: item.secondAppointmentDateTime,
        secondAppointmentTimeSlot: item.secondAppointmentTimeSlot,
        thirdAppointmentSite: item.thirdAppointmentSite,
        thirdAppointmentLocation: item.thirdAppointmentLocation,
        thirdAppointmentDate: item.thirdAppointmentDate ? item.thirdAppointmentDate : (item.thirdAppointmentDateTime ? item.thirdAppointmentDateTime.substring(8, 10) + "/" + item.thirdAppointmentDateTime.substring(5, 7) + "/" + item.thirdAppointmentDateTime.substring(0, 4) : ''),
        thirdAppointmentDateTime: item.thirdAppointmentDateTime,
        thirdAppointmentTimeSlot: item.thirdAppointmentTimeSlot,
        boost1AppointmentSite: item.boost1AppointmentSite,
        boost1AppointmentLocation: item.boost1AppointmentLocation,
        boost1AppointmentDate: item.boost1AppointmentDate ? item.boost1AppointmentDate : (item.boost1AppointmentDateTime ? item.boost1AppointmentDateTime.substring(8, 10) + "/" + item.boost1AppointmentDateTime.substring(5, 7) + "/" + item.boost1AppointmentDateTime.substring(0, 4) : ''),
        boost1AppointmentDateTime: item.boost1AppointmentDateTime,
        boost1AppointmentTimeSlot: item.boost1AppointmentTimeSlot,
        flu1AppointmentSite: item.flu1AppointmentSite,
        flu1AppointmentLocation: item.flu1AppointmentLocation,
        flu1AppointmentDate: item.flu1AppointmentDate ? item.flu1AppointmentDate : (item.flu1AppointmentDateTime ? item.flu1AppointmentDateTime.substring(8, 10) + "/" + item.flu1AppointmentDateTime.substring(5, 7) + "/" + item.flu1AppointmentDateTime.substring(0, 4) : ''),
        flu1AppointmentDateTime: item.flu1AppointmentDateTime,
        flu1AppointmentTimeSlot: item.flu1AppointmentTimeSlot,
        appointmentTime: item.currentTimeSlot,
        hasArrived: item.hasArrived || false,
        arrivalTime: item.arrivalTime,
        minutesPastAppointment: getMinutesPastAppointment(item.currentTimeSlot),
        possibleDna: getMinutesPastAppointment(item.currentTimeSlot) > dnaThreshold,
        bookingSource: item.bookingSource || ""
    }
}