import React, { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Row, Col, Button } from 'reactstrap';
import Spinner from '../../common/Spinner';
import tokenService from '../../../services/tokenService';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}
startOfWeek.setDate(startOfWeek.getDate() - 7);
let tomorrow = new Date(new Date().toDateString());
tomorrow.setDate(tomorrow.getDate() + 1);

const ScheduleSelection = () => {

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [dateFrom, setDateFrom] = useState(currentDate);
    const [dateTo, setDateTo] = useState(null);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const downloadLink = useRef();

    const buildFilterUrl = useCallback(() => {
        let url = "startDate=";
        
        if (dateFrom) {
            const newValue = `${dateFrom.getFullYear()}-${pad(dateFrom.getMonth() + 1, 2)}-${pad(dateFrom.getDate(), 2)}`;
            url = url + encodeURIComponent(newValue);
        }
        url = url + "&endDate=";
        if (dateTo) {
            const newValue = `${dateTo.getFullYear()}-${pad(dateTo.getMonth() + 1, 2)}-${pad(dateTo.getDate(), 2)}`;
            url = url + encodeURIComponent(newValue);
        }
        return url;
    }, [dateFrom, dateTo]);

    const downloadCsv = async () => {
        setLoadingCsv(true);
        setErrorRetrieving(false);
        try {
            const token = tokenService.getToken();
            const url = '/ScheduleUtilisation/Download?' + buildFilterUrl();
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.blob();
                const filename = "Schedule Utilisation - " + new Date().toISOString().substring(0, 10) + ".csv";

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(data, filename);
                } else {
                    let objectUrl = window.URL.createObjectURL(data);

                    downloadLink.current.href = objectUrl;
                    downloadLink.current.download = filename;
                    downloadLink.current.click();

                    window.URL.revokeObjectURL(objectUrl);
                }
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
            setErrorRetrieving(true);
        }
        finally {
            setLoadingCsv(false);
        }
    }

    const dateFromChangeHandler = (datePicked) => {
        setDateFrom(datePicked);
    };

    const dateToChangeHandler = (datePicked) => {
        setDateTo(datePicked);
    };

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    return <div className="questionnaire-body">
        <h3>Schedule Utilisation for COVID-19 Vaccination</h3>
        <Row className="submission-filter-bar">
            <Col xs="2">From:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={dateFrom} onChange={dateFromChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
            <Col xs="2">To:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={dateTo} onChange={dateToChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        </Row>
        {loadingCsv && <Spinner />}
        {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
        {errorRetrieving && <div className="questionnaire-error">Unexpected error detected whilst retrieving results. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Home Testing service.</div>}
        {!loadingCsv && <Button color="primary" onClick={() => downloadCsv()}>Download</Button>}
        <br/>&nbsp;<br/>
        <a ref={downloadLink} href="/">&nbsp;</a>
    </div>;
};

export default ScheduleSelection;