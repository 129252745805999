import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Col, Container, FormGroup, Input, Label } from 'reactstrap';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import LoginView from '../../common/LoginView';
import isInRole from '../../../utils/isInRole';

const brandingOptions = ['GSTT', 'BHNC', 'KCH'];

export const QueueLandingPage = ({ getUser, requiredRoles }) => {

    const [siteTree, setSiteTree] = useState([]);
    const [selectedSite, setSelectedSite] = useState("");
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [assessorDesk, setAssessorDesk] = useState("")
    const [assessors, setAssessors] = useState(0);
    const [loading, setLoading] = useState(false);
    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);
    const [branding, setBranding] = useState("GSTT");

    useEffect(() => {
        const checkAccess = async () => {
            setCheckingAccess(true);

            if (isInRole(requiredRoles)) {
                setHasRequiredRoles(true);
                setCheckingAccess(false);
            } else {
                setHasRequiredRoles(false);
                setCheckingAccess(false);
            }
        }
        if (getUser) {
            checkAccess();
        }
    }, [getUser])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const response = await fetch('/Sites', {
                method: 'Get',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            var data = await response.json()
            console.log(data);

            setSiteTree(data);
            setLoading(false);
        }
        fetchData();
    }, []);
    const selectSite = (site) => {
        setSelectedSite(site);
        if (site) {
            const locations = siteTree.find(x => x.siteName == site).locations;
            setLocations(locations);
        } else {
            setLocations([]);
            setSelectedLocation("");
        }
    }

    const selectLocation = (location) => {
        setSelectedLocation(location);
        if (location) {
            const currentLocation = siteTree.find(x => x.siteName == selectedSite).locations.find(x => x.locationName == location);
            setAssessors(new Array(currentLocation.numberOfAssessors).fill(null));
        } else {

        }
    }

    const getLink = (role) => {
        let url = `/covid19vaccinationQueue/${role}/${selectedSite}/${selectedLocation}`;
        if (assessorDesk) {
            url += `/${assessorDesk}`;
        }
        url += `?branding=${branding}`;
        return url;
    }

    if (checkingAccess) {
        return null;
    }
    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    return (<Container>
        <LoginView nonBanner />
        <Card>
            <CardBody>
                <form>
                    <FormGroup row>
                        <Label sm={2}>Site:</Label>
                        <Col sm={10}>
                            <Input type="select" onChange={(e) => { selectSite(e.target.value) }} disabled={loading}>
                                <option value="">---select site ---</option>
                                {siteTree.map((site, i) => <option key={i}>
                                    {site.siteName}
                                </option>)}
                            </Input>
                        </Col>
                    </FormGroup>
                    {selectedSite &&

                        <FormGroup row>
                            <Label sm={2}>Location:</Label>
                            <Col sm={10}>
                                <Input type="select" onChange={(e) => { selectLocation(e.target.value) }}>
                                    <option value="">---select location ---</option>
                                    {locations.map((location, i) => <option key={i}>
                                        {location.locationName}
                                    </option>)}
                                </Input>
                            </Col>
                        </FormGroup>
                    }
                    {selectedLocation && <>
                        <FormGroup row>
                            <Label sm={2}>Signage Branding:</Label>
                            <Col sm={10}>
                                <Input type="select" onChange={(e) => { setBranding(e.target.value) }}>
                                    {brandingOptions.map((option, i) => <option key={i}>
                                        {option}
                                    </option>)}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Assessor Desk:</Label>
                            <Col sm={10}>
                                <Input type="select" onChange={(e) => { setAssessorDesk(e.target.value) }}>
                                    <option value="">---select location ---</option>
                                    {
                                        assessors.map((j, i) => <option key={i + 1}>Desk: {i + 1}</option>)
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        {!assessorDesk && <>
                            <Link to={getLink('greeter')}>
                                <Button>Greeter</Button>
                            </Link>
                            <Link to={getLink('Prescriber')}>
                                <Button>Prescriber</Button>
                            </Link>
                            <Link to={getLink('signage')}>
                                <Button>Signage</Button>
                            </Link>
                        </>
                        }
                        {assessorDesk &&
                            <Link to={getLink('assessor')}>
                                <Button>Assessor</Button>
                            </Link>
                        }
                    </>
                    }
                </form>
            </CardBody>
        </Card>
    </Container>
    )
}
