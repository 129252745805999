import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Col, Button, Card, CardHeader, CardBody } from 'reactstrap';
import Spinner from '../../common/Spinner';
import tokenService from '../../../services/tokenService';

const currentDate = new Date();

const HubInfoConfig = () => {

    const [errorRetrievingConfig, setErrorRetrievingConfig] = useState(false);
    const [errorRetrievingLocations, setErrorRetrievingLocations] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [savingConfig, setSavingConfig] = useState(false);
    const [savedConfig, setSavedConfig] = useState(false);
    const [validConfig, setValidConfig] = useState(true);
    const [hubConfig, setHubConfig] = useState(null);
    const [savingLocations, setSavingLocations] = useState(false);
    const [savedLocations, setSavedLocations] = useState(false);
    const [validLocations, setValidLocations] = useState(true);
    const [locationList, setLocationList] = useState([]);

    useEffect(() => {

        const getConfig = async () => {
            setErrorRetrievingConfig(false);
            const token = tokenService.getToken();

            const response = await fetch(`/CovidHubConfig`, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setHubConfig(data);
                setLoading(false);
            } else if (response.status === 404) {
                setHubConfig(null);
                setLoading(false);
            }
            else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrievingConfig(true);
            }
        }

        const getLocations = async () => {
            setLoading(true);
            setErrorRetrievingLocations(false);
            try {
                const response = await fetch('/ScheduleLocation', {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    data.sort((a, b) => {
                        if (a.scheduleSite.siteName === b.scheduleSite.siteName) {
                            if (a.locationName < b.locationName) {
                                return -1;
                            } else if (a.locationName > b.locationName) {
                                return 1;
                            }
                            return 0;
                        }
                        else {
                            if (a.scheduleSite.siteName < b.scheduleSite.siteName) {
                                return -1;
                            } else if (a.scheduleSite.siteName > b.scheduleSite.siteName) {
                                return 1;
                            }
                            return 0;
                        }
                    });
                    setLocationList(data);
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                } else {
                    setErrorRetrievingLocations(true);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }

        getConfig();
        getLocations();
        
    }, [])

    const updateConfigValue = (name, value, number) => {
        let updatedConfig = { ...hubConfig };
        if (number) {
            try {
                let valueAsNum = parseFloat(value);
                updatedConfig[name] = valueAsNum;
            }
            catch { }
        } else {
            updatedConfig[name] = value;
        }
        
        setHubConfig(updatedConfig);
        setSavedConfig(false);
        setValidConfig(true);
    }

    const updateLocationAssessors = (site, location, property, number) => {
        let existingLocation = locationList.find(l => l.scheduleSite.siteName === site && l.locationName === location);
        if (existingLocation) {
            existingLocation[property] = number;
            let updatedLocations = locationList.filter(l => l.scheduleSite.siteName !== site || l.locationName !== location);
            updatedLocations.push(existingLocation);
            updatedLocations.sort((a, b) => {
                if (a.scheduleSite.siteName === b.scheduleSite.siteName) {
                    if (a.locationName < b.locationName) {
                        return -1;
                    } else if (a.locationName > b.locationName) {
                        return 1;
                    }
                    return 0;
                }
                else {
                    if (a.scheduleSite.siteName < b.scheduleSite.siteName) {
                        return -1;
                    } else if (a.scheduleSite.siteName > b.scheduleSite.siteName) {
                        return 1;
                    }
                    return 0;
                }
            });
            setLocationList(updatedLocations);
        }
    }

    const saveConfigValues = async () => {
        setSavingConfig(true);
        setSavedConfig(false);
        setErrorRetrievingConfig(false);
        setValidConfig(true);

        if (!hubConfig) {
            setValidConfig(false);
            return;
        }
        if (!hubConfig.waitingRagAmberThreshold ||
            !hubConfig.waitingRagRedThreshold ||
            !hubConfig.waitingRagBlackThreshold ||
            !hubConfig.currentWaitRagAmberThreshold ||
            !hubConfig.currentWaitRagRedThreshold ||
            !hubConfig.currentWaitRagBlackThreshold ||
            !hubConfig.desksOpenRagAmberThreshold ||
            !hubConfig.desksOpenRagRedThreshold ||
            !hubConfig.desksOpenRagBlackThreshold ||
            !hubConfig.averageVaccinationTimeRagAmberThreshold ||
            !hubConfig.averageVaccinationTimeRagRedThreshold ||
            !hubConfig.averageVaccinationTimeRagBlackThreshold ||
            !hubConfig.percentageCompleteRagAmberThreshold ||
            !hubConfig.percentageCompleteRagRedThreshold ||
            !hubConfig.percentageCompleteRagBlackThreshold) {
            setValidConfig(false);
        }

        try {
            const token = tokenService.getToken();
            const request = fetch('/CovidHubConfig', {
                method: 'PUT',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify(hubConfig)
            });

            const response = await request;

            if (response.ok) {
                setSavedConfig(true);
            } else {
                console.error(response.text);
                setErrorRetrievingConfig(true);
            }
        }
        catch (err) {
            setErrorRetrievingConfig(true);
            console.error(err);
        }
        finally {
            setSavingConfig(false);
        }
    }

    const saveLocationValues = async () => {
        if (locationList.length > 0) {
            setSavingLocations(true);
            setSavedLocations(false);
            setErrorRetrievingLocations(false);
            setValidLocations(true);

            try {
                const token = tokenService.getToken();

                for (let i = 0; i < locationList.length; i++) {
                    if (isNaN(locationList[i].numberOfAssessors) || locationList[i].numberOfAssessors < 0) {
                        setValidLocations(false);
                        break;
                    }
                    const request = fetch(`/ScheduleLocation/${encodeURIComponent(locationList[i].scheduleSite.siteName)}/${encodeURIComponent(locationList[i].locationName)}/${locationList[i].numberOfAssessors}/${locationList[i].availableDesks}/${locationList[i].waitingCapacity}`, {
                        method: 'PUT',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + token
                        }
                    });

                    const response = await request;

                    if (!response.ok) {
                        console.error(response.text);
                        setErrorRetrievingLocations(true);
                        break;
                    }
                }
                if (validLocations && !errorRetrievingLocations) {
                    setSavedLocations(true);
                }
            }
            catch (err) {
                setErrorRetrievingLocations(true);
                console.error(err);
            }
            finally {
                setSavingLocations(false);
            }
        }
    }

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    if (loading) {
        return <div><Spinner /></div>;
    }
    
    return <div>
        <Link to="/covid19vaccinationhubs">Go to Dashboard</Link>
        <br />&nbsp;<br />
        <Row>
            {locationList.map(l => <Col md="3"><Card><CardHeader><strong>{l.scheduleSite.siteName} - {l.locationName}</strong></CardHeader><CardBody>Assessors: <Input className="hub-config-location-assessor" type="number" value={l.numberOfAssessors} onChange={(e) => updateLocationAssessors(l.scheduleSite.siteName, l.locationName, 'numberOfAssessors', e.target.value)} />Available Desks: <Input className="hub-config-location-assessor" type="number" value={l.availableDesks} onChange={(e) => updateLocationAssessors(l.scheduleSite.siteName, l.locationName, 'availableDesks', e.target.value)} />Waiting Capacity: <Input className="hub-config-location-assessor" type="number" value={l.waitingCapacity} onChange={(e) => updateLocationAssessors(l.scheduleSite.siteName, l.locationName, 'waitingCapacity', e.target.value)} /></CardBody></Card></Col>)}
        </Row>
        <Button color="primary" onClick={(e) => saveLocationValues()} disabled={savingLocations}>Update Locations</Button>
        {errorRetrievingLocations && <div className="hub-config-error">Problem retrieving/saving locations!</div>}
        {savedLocations && <div className="hub-config-success">Saved!</div>}
        {!validLocations && <div className="hub-config-error">Invalid. Please check all values have been supplied!</div>}
        <br />&nbsp;<br />
        <div>
            <h3>Waiting RAG Thresholds</h3>
            This is the total number of patients who have been arrived but who have not yet been called forward - if used as a percentage it will be against the <strong>waiting capacity</strong> configured for the location.
        </div>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Waiting RAG - Amber Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="waitingRagAmberThreshold" value={(hubConfig && hubConfig.waitingRagAmberThreshold) || ''} onChange={(e) => updateConfigValue('waitingRagAmberThreshold', e.target.value, true)} maxLength={4} required />
                            {hubConfig && hubConfig.waitingRagAsPercent && <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>}
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Waiting RAG - Red Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="waitingRagRedThreshold" value={(hubConfig && hubConfig.waitingRagRedThreshold) || ''} onChange={(e) => updateConfigValue('waitingRagRedThreshold', e.target.value, true)} maxLength={4} required />
                            {hubConfig && hubConfig.waitingRagAsPercent && <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>}
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Waiting RAG - Black Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="waitingRagBlackThreshold" value={(hubConfig && hubConfig.waitingRagBlackThreshold) || ''} onChange={(e) => updateConfigValue('waitingRagBlackThreshold', e.target.value, true)} maxLength={4} required />
                            {hubConfig && hubConfig.waitingRagAsPercent && <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>}
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <Input type="checkbox" name="waitingRagAsPercent" checked={(hubConfig && hubConfig.waitingRagAsPercent) || false} onChange={(e) => updateConfigValue('waitingRagAsPercent', e.target.checked)} /><strong>Waiting RAG - As Percentage?</strong>
                    </Label>
                </FormGroup>
            </Col>
        </Row>
        <div>
            <h3>Current Wait RAG Thresholds</h3>
            This is the current waiting time for patients who have been arrived but not yet called forward. <strong>Values are in minutes</strong>.
        </div>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Current Wait RAG - Amber Threshold:</strong>
                        <Input className="hub-config-rag-input" type="number" name="currentWaitRagAmberThreshold" value={(hubConfig && hubConfig.currentWaitRagAmberThreshold) || ''} onChange={(e) => updateConfigValue('currentWaitRagAmberThreshold', e.target.value, true)} maxLength={4} />
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Current Wait RAG - Red Threshold:</strong>
                        <Input className="hub-config-rag-input" type="number" name="currentWaitRagRedThreshold" value={(hubConfig && hubConfig.currentWaitRagRedThreshold) || ''} onChange={(e) => updateConfigValue('currentWaitRagRedThreshold', e.target.value, true)} maxLength={4} />
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Current Wait RAG - Black Threshold:</strong>
                        <Input className="hub-config-rag-input" type="number" name="currentWaitRagBlackThreshold" value={(hubConfig && hubConfig.currentWaitRagBlackThreshold) || ''} onChange={(e) => updateConfigValue('currentWaitRagBlackThreshold', e.target.value, true)} maxLength={4} />
                    </Label>
                </FormGroup>
            </Col>
        </Row>
        <div>
            <h3>Desks Open RAG Thresholds</h3>
            This is the total number of active desks - if used as a percentage it will be against the total number of desks configured for the location.
        </div>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Desks Open RAG - Amber Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="desksOpenRagAmberThreshold" value={(hubConfig && hubConfig.desksOpenRagAmberThreshold) || ''} onChange={(e) => updateConfigValue('desksOpenRagAmberThreshold', e.target.value, true)} maxLength={4} />
                            {hubConfig && hubConfig.desksOpenRagAsPercent && <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>}
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Desks Open RAG - Red Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="desksOpenRagRedThreshold" value={(hubConfig && hubConfig.desksOpenRagRedThreshold) || ''} onChange={(e) => updateConfigValue('desksOpenRagRedThreshold', e.target.value, true)} maxLength={4} />
                            {hubConfig && hubConfig.desksOpenRagAsPercent && <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>}
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Desks Open RAG - Black Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="desksOpenRagBlackThreshold" value={(hubConfig && hubConfig.desksOpenRagBlackThreshold) || ''} onChange={(e) => updateConfigValue('desksOpenRagBlackThreshold', e.target.value, true)} maxLength={4} />
                            {hubConfig && hubConfig.desksOpenRagAsPercent && <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>}
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <Input type="checkbox" name="desksOpenRagAsPercent" checked={(hubConfig && hubConfig.desksOpenRagAsPercent) || false} onChange={(e) => updateConfigValue('desksOpenRagAsPercent', e.target.checked)} /><strong>Desks Open RAG - As Percentage?</strong>
                    </Label>
                </FormGroup>
            </Col>
        </Row>
        <div>
            <h3>Average Time Per Vaccination RAG Thresholds</h3>
            This is the average vaccination time for patients who have left and received an outcome, from the time they arrived at a desk. <strong>Values are in minutes</strong>.
        </div>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Average Time Per Vaccination RAG - Amber Threshold:</strong>
                        <Input className="hub-config-rag-input" type="number" name="averageVaccinationTimeRagAmberThreshold" value={(hubConfig && hubConfig.averageVaccinationTimeRagAmberThreshold) || ''} onChange={(e) => updateConfigValue('averageVaccinationTimeRagAmberThreshold', e.target.value, true)} maxLength={4} />
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Average Time Per Vaccination RAG - Red Threshold:</strong>
                        <Input className="hub-config-rag-input" type="number" name="averageVaccinationTimeRagRedThreshold" value={(hubConfig && hubConfig.averageVaccinationTimeRagRedThreshold) || ''} onChange={(e) => updateConfigValue('averageVaccinationTimeRagRedThreshold', e.target.value, true)} maxLength={4} />
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Average Time Per Vaccination RAG - Black Threshold:</strong>
                        <Input className="hub-config-rag-input" type="number" name="averageVaccinationTimeRagBlackThreshold" value={(hubConfig && hubConfig.averageVaccinationTimeRagBlackThreshold) || ''} onChange={(e) => updateConfigValue('averageVaccinationTimeRagBlackThreshold', e.target.value, true)} maxLength={4} />
                    </Label>
                </FormGroup>
            </Col>
        </Row>
        <div>
            <h3>Percentage Complete RAG Thresholds</h3>
            This is the percentage of patients who have been outcomed, excluding any DNAs. This threshold compares the current percentage against the target percentage, which is calculated based on the number of scheduled patients that should have been outcomed at the <strong>current</strong> time of day.
        </div>
        <Row>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Percentage Complete RAG - Amber Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="percentageCompleteRagAmberThreshold" value={(hubConfig && hubConfig.percentageCompleteRagAmberThreshold) || ''} onChange={(e) => updateConfigValue('percentageCompleteRagAmberThreshold', e.target.value, true)} maxLength={4} />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Percentage Complete RAG - Red Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="percentageCompleteRagRedThreshold" value={(hubConfig && hubConfig.percentageCompleteRagRedThreshold) || ''} onChange={(e) => updateConfigValue('percentageCompleteRagRedThreshold', e.target.value, true)} maxLength={4} />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
            <Col md="3">
                <FormGroup>
                    <Label check>
                        <strong>Percentage Complete RAG - Black Threshold:</strong>
                        <InputGroup>
                            <Input className="hub-config-rag-input" type="number" name="percentageCompleteRagBlackThreshold" value={(hubConfig && hubConfig.percentageCompleteRagBlackThreshold) || ''} onChange={(e) => updateConfigValue('percentageCompleteRagBlackThreshold', e.target.value, true)} maxLength={4} />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Label>
                </FormGroup>
            </Col>
        </Row>
        <Button color="primary" onClick={(e) => saveConfigValues()} disabled={savingConfig}>Update RAG Thresholds</Button>
        {errorRetrievingConfig && <div className="hub-config-error">Problem retrieving/saving config!</div>}
        {savedConfig && <div className="hub-config-success">Saved!</div>}
        {!validConfig && <div className="hub-config-error">Invalid. Please check all values have been supplied!</div>}
    </div>;
};

export default HubInfoConfig;
