import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import Question from '../../common/Question';
import Moment from 'react-moment';

const ViewQuestionnaireSubmission = ({ submission, returnCallback }) => {

    const renderAnswer = (questions, questionKey) => {
        const filteredQuestion = questions.filter(q => q.questionKey.toLowerCase() === questionKey.toLowerCase());
        const question = filteredQuestion.length > 0 ? filteredQuestion[0] : null;
        if (question === null || question.answers.length === 0) {
            return "Not Answered";
        } else {
            if (question.answers.length === 1) {
                return <strong>{question.answers[0]}</strong>;
            } else {
                return <ol>{question.answers.map((a, idx) => <li key={idx}><strong>{a}</strong></li>)}</ol>;
            }
        }
    }

    return <div className="questionnaire-body">
        <div><Button color="secondary" onClick={() => returnCallback()}>Return to list</Button></div>
        <div className="questionnaire-submission-summary">This submission was recorded on <strong><Moment format="DD MMM YYYY HH:mm:ss">{submission.submissionDate}</Moment></strong>. Submission identifier: <strong>{submission.submissionIdentifier}</strong>.</div>
        <Question questionNumber={1} questionText={"Please provide your hospital number"} isMandatory>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "hospnum")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={2} questionText={"Have you had recent contact with people who have been diagnosed with COVID-19?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "covidcontact")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={3} questionText={"In the past 2 weeks, have you had a new cough?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "newcough")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={4} questionText={"In the past 2 weeks, have you experienced new breathlessness?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "newbreathlessness")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={5} questionText={"In the past 2 weeks, have you had a streaming/runny nose?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "runnynose")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={6} questionText={"In the past 2 weeks, have you had flu like symptoms (headaches, muscle aches)?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "flusymptoms")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={7} questionText={"In the past 2 weeks, have you had diarrhoea?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "diarrhoea")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={8} questionText={"Have you recently tested positive for COVID-19?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "covidpositive")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={9} questionText={"We are swabbing patients so we can keep the Cancer Centre safe. Are you happy to be swabbed today?"}>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "beswabbed")}</Label>
            </FormGroup>
        </Question>
        <Question questionNumber={10} questionText={"The purpose of this questionnaire is to keep our patients and the Cancer Centre safe. I agree for my answers and swab result to be used for this research purpose."} isMandatory>
            <FormGroup className="questionnaire-answers">
                <Label>{renderAnswer(submission.questionAnswers, "confirm")}</Label>
            </FormGroup>
        </Question>
        <div><Button color="secondary" onClick={() => returnCallback()}>Return to list</Button></div>
    </div>;

};

export default ViewQuestionnaireSubmission;