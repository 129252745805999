import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button } from 'reactstrap';
import Spinner from '../../common/Spinner';
import Moment from 'react-moment';
import dateUtil from '../../../utils/dateUtil';
import tokenService from '../../../services/tokenService';
import ViewQuestionnaireSubmission from './ViewQuestionnaireSubmission';
import SubmissionFilter from './SubmissionFilter';

const currentDate = new Date(new Date().toDateString());

const QuestionnaireResults = () => {

    const questionnaireKey = 'COVID-19 CC';
    const [questionnaireResults, setQuestionnaireResults] = useState([]);
    const [submissionIdentifier, setSubmissionIdentifier] = useState(null);
    const [submissionResults, setSubmissionResults] = useState(null);
    const [dateFrom, setDateFrom] = useState(currentDate);
    const [dateTo, setDateTo] = useState(currentDate);
    const [hospitalNumber, setHospitalNumber] = useState('');
    const [showSubmissionResult, setShowSubmissionResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const downloadLink = useRef();

    const buildFilterUrl = useCallback(() => {
        let url = "dateFrom=";
        if (dateFrom) {
            url = url + encodeURIComponent(dateFrom.toISOString());
        }
        url = url + "&dateTo=";
        if (dateTo) {
            let dateToEndOfDay = new Date(dateTo.getTime());
            dateToEndOfDay.setDate(dateToEndOfDay.getDate() + 1);
            url = url + encodeURIComponent(dateToEndOfDay.toISOString());
        }
        if (hospitalNumber) {
            url = url + "&questionFilterKey=hospnum&questionFilterValue=" + encodeURIComponent(hospitalNumber);
        }
        return url;
    }, [dateFrom, dateTo, hospitalNumber]);

    const getQuestionnaireSubmission = useCallback(async () => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const response = await fetch('/QuestionnaireAnswers/' + questionnaireKey + '/' + submissionIdentifier, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSubmissionResults(data);
                setShowSubmissionResult(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }, [submissionIdentifier]);

    const getQuestionnaireAnswers = useCallback(async () => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers?questionnaireKey=' + questionnaireKey + "&" + buildFilterUrl();
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setQuestionnaireResults(data);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }, [buildFilterUrl]);

    useEffect(() => {
        getQuestionnaireAnswers();
    }, [questionnaireKey, getQuestionnaireAnswers, dateFrom, dateTo, hospitalNumber])

    useEffect(() => {
        if (submissionIdentifier !== null) {
            getQuestionnaireSubmission();
        }
    }, [submissionIdentifier, getQuestionnaireSubmission])    

    const backToListHandler = () => {
        setSubmissionIdentifier(null);
        setShowSubmissionResult(false)
    }

    const getHospitalNumber = (questionAnswers) => {
        const hospitalNumberQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'hospnum');
        if (hospitalNumberQuestion.length > 0) {
            const answers = hospitalNumberQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0].toUpperCase();
            }
        }
        return "n/a";
    }

    const setFilter = (dateFrom, dateTo, hospitalNumber) => {
        setDateFrom(dateFrom);
        setDateTo(dateTo);
        setHospitalNumber(hospitalNumber);
    };

    const downloadCsv = async () => {
        setLoadingCsv(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers/' + questionnaireKey + '/Download?' + buildFilterUrl();
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.blob();
                let objectUrl = window.URL.createObjectURL(data);

                downloadLink.current.href = objectUrl;
                const filename = questionnaireKey + " - Results - " + new Date().toISOString().substring(0, 10) + ".csv";
                downloadLink.current.download = filename;
                downloadLink.current.click();

                window.URL.revokeObjectURL(objectUrl);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingCsv(false);
        }
    }

    if (showSubmissionResult) {
        return <ViewQuestionnaireSubmission submission={submissionResults} returnCallback={() => backToListHandler()} />
    } else {
        return <div className="questionnaire-body">
            <h3>Results for Cancer Centre COVID-19 Questionnaire</h3>
            <SubmissionFilter setFilter={setFilter} originalDateFrom={dateFrom} originalDateTo={dateTo} originalHospitalNumber={hospitalNumber} />
            {loading && <Spinner />}
            {questionnaireResults.length === 0 && !loading && <div>No Submissions Found.</div>}
            {questionnaireResults.length > 0 && <Table className="questionnaire-results-list" striped>
                <thead>
                    <tr>
                        <th>Submission Date</th>
                        <th>Hospital Number</th>
                        <th>Submission Id</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {questionnaireResults.map(qr => <tr key={qr.submissionIdentifier}>
                        <td><Moment format="DD MMM YYYY HH:mm">{dateUtil.ConvertDateFromJSON(qr.submissionDate)}</Moment></td>
                        <td>{getHospitalNumber(qr.questionAnswers)}</td>
                        <td>{qr.submissionIdentifier}</td>
                        <td><Button onClick={() => setSubmissionIdentifier(qr.submissionIdentifier)}>View Results</Button></td>
                    </tr>)}
                </tbody>
            </Table>}
            {loadingCsv && <Spinner />}
            {questionnaireResults.length > 0 && !loadingCsv && <Button color="link" onClick={() => downloadCsv()}>Download as CSV</Button>}
            <a ref={downloadLink} href="/">&nbsp;</a>
        </div>;
    }
};

export default QuestionnaireResults;