import React, { Component } from 'react';
import Question from '../common/Question';
import QuestionnaireBlurb from './QuestionnaireBlurb';
import questionManager from './questionManager';
import { Input, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import Cleave from 'cleave.js/react';

const currentDate = new Date(new Date().toDateString());

class Questionnaire extends Component {

    constructor(props) {
        super(props);
        
        const answers = [];
        
        const availableTestDays = [];
        availableTestDays.push({ day: 'Monday', sort: 1, checked: false });
        availableTestDays.push({ day: 'Tuesday', sort: 2, checked: false });
        availableTestDays.push({ day: 'Wednesday', sort: 3, checked: false });
        availableTestDays.push({ day: 'Thursday', sort: 4, checked: false });
        availableTestDays.push({ day: 'Friday', sort: 5, checked: false });
        availableTestDays.push({ day: 'Saturday', sort: 6, checked: false });
        availableTestDays.push({ day: 'Sunday', sort: 7, checked: false });

        this.state = {
            questionnaire: 'COVID Testing Kit Registration',
            answers: answers,
            willingtopartake: '',
            positivetest: '',
            forename: '',
            lastname: '',
            dateofbirth: '',
            gender: '',
            postcode: '',
            mobile: '',
            confirmedMobile: '',
            email: '',
            confirmedEmail: '',
            directorate: '',
            jobrole: '',
            departmentward: '',
            hadantibodytestbefore: '',
            address: '',
            gpaddress: '',
            testdays: '',
            seenvideoinstructions: '',
            pledgetousetestsforself: '',
            helpadministering: '',
            reason: '',
            allMandatoryAnswered: false,
            jobRoleList: [],
            directorateList: [],
            genderList: [],
            testDaysAvailable: availableTestDays,
            isInvalid: false,
            completed: false,
            errored: false,
            submitting: false,
            questions: [
                { key: "willingtopartake", mandatory: true },
                { key: "positivetest", mandatory: false },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: false },
                { key: "gender", mandatory: false },
                { key: "postcode", mandatory: false },
                { key: "mobile", mandatory: false },
                { key: "email", mandatory: false },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "departmentward", mandatory: true },
                { key: "hadantibodytestbefore", mandatory: false },
                { key: "address", mandatory: false },
                { key: "gpaddress", mandatory: false },
                { key: "testdays", mandatory: false },
                { key: "seenvideoinstructions", mandatory: false },
                { key: "pledgetousetestsforself", mandatory: false },
                { key: "helpadministering", mandatory: false },
                { key: "reason", mandatory: false },
                { key: "confirm", mandatory: true }
            ],
            validationErrors: [],
            blacklistChars: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '¬', '!', '"', '£', '$', '%', '^', '&', '*', '(', ')', '_', '=', '+', '[', '{', ']', '}', ';', ':', '@', '#', '~', '\\', '|', ',', '<', '>', '?', '/']
        };

        this.validateEmail = this.validateEmail.bind(this);
        this.isBlacklisted = this.isBlacklisted.bind(this);
        this.storeAnswer = this.storeAnswer.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.textChangeWithBlacklistHandler = this.textChangeWithBlacklistHandler.bind(this);
        this.textChangeNoStoreHandler = this.textChangeNoStoreHandler.bind(this);
        this.textChangeRawHandler = this.textChangeRawHandler.bind(this);
        this.textChangeNoStoreRawHandler = this.textChangeNoStoreRawHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.checkedChangeHandler = this.checkedChangeHandler.bind(this);
        this.getDayOfWeekValue = this.getDayOfWeekValue.bind(this);
        this.dayOfWeekChanged = this.dayOfWeekChanged.bind(this);
        this.showIf = this.showIf.bind(this);
        this.pad = this.pad.bind(this);
        this.submitAnswers = this.submitAnswers.bind(this);
    }

    async componentDidMount() {
        const availableDirectorates = questionManager.getDirectorates();
        const availableJobRoles = questionManager.getJobRoles();
        const availableGenders = questionManager.getGenders();
        this.setState({
            directorateList: availableDirectorates,
            jobRoleList: availableJobRoles,
            genderList: availableGenders
        });
    }

    async submitAnswers() {

        this.setState({
            submitting: true
        });

        let dateOfBirth = '';
        let dateOfBirthIsoFormat = '';
        let dateOfBirthInvalid = false;
        let email = ''
        const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
        const emailAnswer = this.state.answers.filter(a => a.key === 'email');
        if (dateOfBirthAnswer.length > 0) {
            dateOfBirth = dateOfBirthAnswer[0].answer;
            if (dateOfBirth.length === 10) {
                dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
                try {
                    let dateWhen16 = new Date(dateOfBirthIsoFormat);
                    dateWhen16.setDate(dateWhen16.getDate() + ((365 * 16) + Math.floor(16 / 4)));
                    let dateWhen90 = new Date(dateOfBirthIsoFormat);
                    dateWhen90.setDate(dateWhen16.getDate() + ((365 * 90) + Math.floor(90 / 4)));
                    if (dateWhen16 > currentDate | dateWhen90 < currentDate) {
                        dateOfBirthInvalid = true;
                    }
                } catch {
                    dateOfBirthInvalid = true;
                }
            }
        }
        if (emailAnswer.length > 0) {
            email = emailAnswer[0].answer;
        }
        const selectedTestDays = this.state.testDaysAvailable.filter(d => d.checked === true);

        // determine which questions are mandatory based upon answers
        let willingToPartake = '';
        let positiveTest = '';
        let hadAntibodyTest = '';
        var newQuestions = [];
        const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
        const positiveTestAnswer = this.state.answers.filter(a => a.key === "positivetest");
        const hadAntibodyTestAnswer = this.state.answers.filter(a => a.key === "hadantibodytestbefore");
        if (willingToPartakeAnswer.length > 0) {
            willingToPartake = willingToPartakeAnswer[0].answer;
        }
        if (positiveTestAnswer.length > 0) {
            positiveTest = positiveTestAnswer[0].answer;
        }
        if (hadAntibodyTestAnswer.length > 0) {
            hadAntibodyTest = hadAntibodyTestAnswer[0].answer;
        }
        newQuestions = questionManager.getQuestions(willingToPartake, positiveTest, hadAntibodyTest);

        this.setState({
            questions: newQuestions
        }, async () => {
            let mandatoryQuestionsUnanswered = 0;
            const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
            for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                const mandatoryQuestion = mandatoryQuestions[mandatory];
                const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                if (userAnswer.length === 0 || !userAnswer[0].answer) {
                    mandatoryQuestionsUnanswered++;
                }
            }

            if (mandatoryQuestionsUnanswered > 0) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: []
                });
            }
            else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth cannot be in the future!"]
                });
            }
            else if (dateOfBirthInvalid) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["You must be between 16 and 90 years of age!"]
                });
            }
            else if (this.state.mobile !== this.state.confirmedMobile) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Mobile numbers do not match!"]
                });
            }
            else if (email && !this.validateEmail(email)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Email address is not in a valid format!"]
                });
            }
            else if (this.state.email !== this.state.confirmedEmail) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Email addresses do not match!"]
                });
            }
            else if (willingToPartake === 'Yes' && positiveTest === 'No' && (selectedTestDays.length > 0 && selectedTestDays.length !== 2)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["You must specify which two days you have been asked to take the tests on."]
                });
            }
            else {
                const data = {
                    questionnaireName: this.state.questionnaire,
                    questionAnswers: []
                };
                this.state.answers.map(a => data.questionAnswers.push({ questionKey: a.key, answers: [a.answer] }));

                const request = fetch('/QuestionnaireAnswers', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const response = await request;

                if (response.ok) {
                    this.setState({
                        completed: true,
                        submitting: false
                    });
                } else {
                    let validationErrors = [];
                    if (response.status === 400) {
                        try {
                            validationErrors = await response.json();
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    this.setState({
                        errored: true,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
            }
        });       
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isBlacklisted(char) {
        if (this.state.blacklistChars.indexOf(char) > -1) {
            return true;
        }
        return false;
    }

    storeAnswer(questionKey, answer) {
        const existingAnswer = this.state.answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...this.state.answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = this.state.answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        this.setState({
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    textChangeHandler(e, q) {
        this.storeAnswer(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeWithBlacklistHandler(e, q) {
        let newValue = '';
        if (e.target.value) {
            const newValueChars = e.target.value.split('');
            for (var i = 0; i < newValueChars.length; i++) {
                if (!this.isBlacklisted(newValueChars[i])) {
                    newValue += newValueChars[i];
                }
            }
        }
        
        this.storeAnswer(q, newValue);
        this.setState({
            [e.target.name]: newValue
        });
              
    }

    textChangeNoStoreHandler(e, q) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeRawHandler(e, q) {
        this.storeAnswer(q, e.target.rawValue);
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    textChangeNoStoreRawHandler(e) {
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    radioChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
        }
    }

    checkedChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
        } else {
            this.storeAnswer(q, '');
        }
    }

    getDayOfWeekValue(day) {
        const availabilityValue = this.state.testDaysAvailable.filter(a => a.day === day);
        return availabilityValue[0].checked;
    }

    dayOfWeekChanged(e, day) {
        if (e.target.checked) {
            const checkedDays = this.state.testDaysAvailable.filter(a => a.checked === true);
            if (checkedDays.length > 1) {
                return;
            }
        }
        const filteredTestDays = this.state.testDaysAvailable.filter(a => a.day !== day);
        this.setState({
            testDaysAvailable: [...filteredTestDays, { day: day, checked: e.target.checked }]
        }, () => {
            const testDays = this.state.testDaysAvailable.filter(ad => ad.checked === true).sort((f, s) => {
                return f.sort - s.sort;
            }).reduce((acc, cur, idx) => idx === 0 ? cur.day : acc + ", " + cur.day, '');
            this.storeAnswer("testdays", testDays);
        });

    }

    showIf(willingToPartakeAnswered, willingToPartake, positiveCovidTestAnswered, positiveCovidTest, hadAntibodyTestAnswered, hadAntibodyTest) {
        let show = true;
        let currentWillingToPartakeAnswer = '';
        let currentPositiveCovidTestAnswer = '';
        let currentHadAntibodyTestAnswer = '';
        const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
        const positiveCovidTestAnswer = this.state.answers.filter(a => a.key === "positivetest");
        const hadAntibodyTestAnswer = this.state.answers.filter(a => a.key === "hadantibodytestbefore");

        if (willingToPartakeAnswer.length > 0) {
            currentWillingToPartakeAnswer = willingToPartakeAnswer[0].answer;
        }
        if (positiveCovidTestAnswer.length > 0) {
            currentPositiveCovidTestAnswer = positiveCovidTestAnswer[0].answer;
        }
        if (hadAntibodyTestAnswer.length > 0) {
            currentHadAntibodyTestAnswer = hadAntibodyTestAnswer[0].answer;
        }

        if (willingToPartakeAnswered) {
            if (willingToPartake !== '') {
                if (currentWillingToPartakeAnswer !== willingToPartake) {
                    show = false;
                }
            } else {
                if (currentWillingToPartakeAnswer === '') {
                    show = false;
                }
            }
        }
        if (positiveCovidTestAnswered) {
            if (positiveCovidTest !== '') {
                if (currentPositiveCovidTestAnswer !== positiveCovidTest) {
                    show = false;
                }
            } else {
                if (currentPositiveCovidTestAnswer === '') {
                    show = false;
                }
            }
        }
        if (hadAntibodyTestAnswered) {
            if (hadAntibodyTest !== '') {
                if (currentHadAntibodyTestAnswer !== hadAntibodyTest) {
                    show = false;
                }
            } else {
                if (currentHadAntibodyTestAnswer === '') {
                    show = false;
                }
            }
        }

        return show;
    }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    render() {
        if (this.state.completed) {
            let willingToPartake = '';
            let positiveTest = ''
            const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
            const positiveTestAnswer = this.state.answers.filter(a => a.key === "positivetest");
            if (willingToPartakeAnswer.length > 0) {
                willingToPartake = willingToPartakeAnswer[0].answer;
            }
            if (positiveTestAnswer.length > 0) {
                positiveTest = positiveTestAnswer[0].answer;
            }
            if (willingToPartake === 'Yes' && positiveTest === 'No') {
                return <div className="questionnaire-body">
                    <p><strong><h3>Thank you for registering to take part in COVID-19 asymptomatic testing.</h3></strong></p>
                    <p><strong><h5>What you should do now</h5></strong></p>
                    <p>Please <a href="https://www.guysandstthomas.nhs.uk/resources/coronavirus/occupational-health/asymptomatic-testing-directorate-leads.pdf" target="_blank" rel="noopener noreferrer">contact your local directorate testing lead</a> to arrange collection of a testing kit.</p>
                    <p><strong><h5>When you collect your kit</h5></strong></p>
                    <p>You'll be given a pack with instructions on how to take your tests and report your results.</p>
                    <p>Please perform the test at home, as early as possible on the days you have been allocated by your manager. The result of every test you take must be recorded on the online portal.</p>
                    <p>You can read full information about the tests on our <a href="https://www.guysandstthomas.nhs.uk/coronavirus/testing-no-symptoms.aspx" target="_blank" rel="noopener noreferrer">staff information web page</a>.</p>
                </div>;
            } else if (willingToPartake === 'Yes' && positiveTest === 'Yes') {
                return <div className="questionnaire-body">
                    <div><strong>Thank you! Your responses have been recorded.</strong></div>
                    <div className="guidance-problem-warning"><strong>Based on your responses you cannot partake in the programme at this time, please speak to your line manager for further guidance. You will be eligible to register again 90 days after you received your positive COVID-19 PCR test result.</strong></div>
                </div>;
            } else {
                return <div className="questionnaire-body">
                    <strong>Thank you! Your responses have been recorded.</strong>
                </div>;
            }
        }
        return <div className="questionnaire-body">
            <QuestionnaireBlurb />
            <Question questionNumber="1.1a" questionText={"Are you willing to take part in the staff COVID-19 home testing programme?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="willingtopartake" value="Yes" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="willingtopartake" value="No" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />No
                    </Label>
                </FormGroup>
            </Question>
            {this.showIf(true, "Yes") && <Question questionNumber="1.1b" questionText={"Have you had a positive COVID-19 PCR test (nose and throat swab taken in the staff testing centre or a national testing centre) in the last 90 days?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="positivetest" value="Yes" onChange={(e) => this.radioChangeHandler(e, "positivetest")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="positivetest" value="No" onChange={(e) => this.radioChangeHandler(e, "positivetest")} />No
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) && <Question questionNumber="1.2a" questionText={"Forename"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="forename" value={this.state.forename} onChange={(e) => this.textChangeWithBlacklistHandler(e, "forename", true)} maxLength={30} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) && <Question questionNumber="1.2b" questionText={"Last name"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="lastname" value={this.state.lastname} onChange={(e) => this.textChangeWithBlacklistHandler(e, "lastname", true)} maxLength={30} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No") && <Question questionNumber="1.2c" questionText={"Date of birth"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={this.state.dateofbirth} onChange={(e) => this.textChangeHandler(e, "dateofbirth", true)} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No") && <Question questionNumber="1.2d" questionText={"Gender"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="gender" onChange={(e) => this.textChangeHandler(e, "gender")} value={this.state.gender}>
                            <option value="">Please select</option>
                            {this.state.genderList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No") && <Question questionNumber="1.2e" questionText={"Postcode of residence"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="postcode" value={this.state.postcode} onChange={(e) => this.textChangeHandler(e, "postcode", true)} maxLength={10} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No") && <Question questionNumber="1.2f" questionText={"Mobile number"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave name="mobile" placeholder="07911 123456" options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeRawHandler(e, "mobile")} maxLength={15} />
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave name="confirmedMobile" placeholder="07911 123456" options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeNoStoreRawHandler(e)} maxLength={15} />
                    </Label>
                    &nbsp;<i>(confirm mobile number by entering again)</i>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No") && <Question questionNumber="1.2g" questionText={"Email address"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="email" name="email" value={this.state.email} onChange={(e) => this.textChangeHandler(e, "email", true)} maxLength={320} />
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="email" name="confirmedEmail" value={this.state.confirmedEmail} onChange={(e) => this.textChangeNoStoreHandler(e, "confirmedEmail", true)} maxLength={320} />
                    </Label>
                    &nbsp;<i>(confirm email address by entering again)</i>
                </FormGroup>
            </Question>}
            {(this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) && <Question questionNumber="1.3a" questionText={"Please select your Directorate:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="directorate" onChange={(e) => this.textChangeHandler(e, "directorate", true)} value={this.state.directorate}>
                            <option value="">Please select</option>
                            {this.state.directorateList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) && <Question questionNumber="1.3b" questionText={"Please select your Job Role:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="jobrole" onChange={(e) => this.textChangeHandler(e, "jobrole", true)} value={this.state.jobrole} >
                            <option value="">Please select</option>
                            {this.state.jobRoleList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) && <Question questionNumber="1.3c" questionText={"Please enter your Department or Ward:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="departmentward" value={this.state.departmentward} onChange={(e) => this.textChangeHandler(e, "departmentward", true)} maxLength={50} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No") && <Question questionNumber="1.4a" questionText={"Have you had an antibody test at LGT before?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="hadantibodytestbefore" value="Yes" onChange={(e) => this.radioChangeHandler(e, "hadantibodytestbefore")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="hadantibodytestbefore" value="No" onChange={(e) => this.radioChangeHandler(e, "hadantibodytestbefore")} />No
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No", true, "No") && <Question questionNumber="1.4b" questionText={"Address"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="textarea" name="address" value={this.state.address} onChange={(e) => this.textChangeHandler(e, "address", true)} maxLength={100} cols={40} rows={4} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No", true, "No") && <Question questionNumber="1.4c" questionText={"GP Name and Address"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="textarea" name="gpaddress" value={this.state.gpaddress} onChange={(e) => this.textChangeHandler(e, "gpaddress", true)} maxLength={120} cols={40} rows={5} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No", true, "") && <Question questionNumber="1.5" questionText={"If your line manager has asked you to take your test on specific days each week, then please indicate below"}>
                <FormGroup className="questionnaire-answers">
                    <Row>
                        <Col xs="4" className="text-nowrap">Monday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Monday")} onChange={(e) => this.dayOfWeekChanged(e, "Monday")} /></Col>
                    </Row>
                    <Row>
                        <Col xs="4" className="text-nowrap">Tuesday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Tuesday")} onChange={(e) => this.dayOfWeekChanged(e, "Tuesday")} /></Col>
                    </Row>
                    <Row>
                        <Col xs="4" className="text-nowrap">Wednesday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Wednesday")} onChange={(e) => this.dayOfWeekChanged(e, "Wednesday")} /></Col>
                    </Row>
                    <Row>
                        <Col xs="4" className="text-nowrap">Thursday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Thursday")} onChange={(e) => this.dayOfWeekChanged(e, "Thursday")} /></Col>
                    </Row>
                    <Row>
                        <Col xs="4" className="text-nowrap">Friday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Friday")} onChange={(e) => this.dayOfWeekChanged(e, "Friday")} /></Col>
                    </Row>
                    <Row>
                        <Col xs="4" className="text-nowrap">Saturday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Saturday")} onChange={(e) => this.dayOfWeekChanged(e, "Saturday")} /></Col>
                    </Row>
                    <Row>
                        <Col xs="4" className="text-nowrap">Sunday: </Col>
                        <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Sunday")} onChange={(e) => this.dayOfWeekChanged(e, "Sunday")} /></Col>
                    </Row>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No", true, "") && <Question questionNumber={1.6} questionText={"In preparation for undertaking self-administered testing, I can confirm that I have seen the training video, have read the full instructions 'A guide for healthcare staff self-testing for coronavirus using a Lateral Flow Device (LFD)' and have read the occupational health FAQ guide."} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="seenvideoinstructions" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "seenvideoinstructions")} />Yes
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No", true, "") && <Question questionNumber={1.7} questionText={"I pledge to use the tests only for myself."} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="pledgetousetestsforself" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "pledgetousetestsforself")} />Yes
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "No", true, "") && <Question questionNumber="1.8" questionText={"Do you need any additional assistance to administer the test to yourself?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="helpadministering" value="Yes" onChange={(e) => this.radioChangeHandler(e, "helpadministering")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="helpadministering" value="No" onChange={(e) => this.radioChangeHandler(e, "helpadministering")} />No
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "No") && <Question questionNumber="1.3d" questionText={"Please let us know the reason why you are not willing to take part in this programme."} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="textarea" name="reason" value={this.state.reason} onChange={(e) => this.textChangeHandler(e, "reason", true)} maxLength={255} cols={80} rows={2} />
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf(true, "Yes", true, "Yes") || this.showIf(true, "Yes", true, "No", true, "") || this.showIf(true, "No")) && <Question questionNumber={2} questionText={"I confirm that the above information is accurate to the best of my knowledge."} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="confirm" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "confirm")} />Yes
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, "Yes", true, "Yes") && <div className="guidance-problem-informational">As you have responded that you have tested positive within the last 90 days you cannot partake in the programme at this time, please continue by submitting the form and speak to your line manager for further guidance. You will be eligible to register again 90 days after you received your positive COVID-19 PCR test result.<br />&nbsp;</div>}
            {(this.showIf(true, "Yes", true, "Yes") || this.showIf(true, "Yes", true, "No", true, "") || this.showIf(true, "No")) && <Button color="primary" onClick={() => this.submitAnswers()} disabled={this.state.submitting}>Submit</Button>}
            {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review your answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
        </div>;
    }
};

export default Questionnaire;