import React from 'react';
import { Row } from 'reactstrap';
import LoginView from '../common/LoginView';

const Banner = ({ showLogin }) => (
    <div className="questionnaire-banner">
        <Row>
            <div className={showLogin ? "questionnaire-bannertext-med col-9" : "questionnaire-bannertext-med col-12"}>COVID-19 NHS Staff Vaccination</div>
            {showLogin && <LoginView />}
        </Row>
    </div>
);

export default Banner;