import React from 'react';
import RequiredSymb from '../common/RequiredSymb';

const QuestionnaireBlurb = () => (
    <div>
        <div>
            <div><h2>Welcome to the Guy's and St. Thomas' COVID-19 vaccination portal</h2></div>
            <p>The COVID-19 vaccine is our best defence against the virus, alongside effective social distancing, wearing a mask and washing your hands.</p>
            <p>Priority groups for vaccine include staff who are classified as Clinically Extremely Vulnerable or staff who work with older people (80 years and over).</p>
            <div><h4>The system will allow you to:</h4></div>
            <div>
                <ul>
                    <li>Register for your vaccine</li>
                    <li>Read important information about the vaccine</li>
                    <li>Provide your consent to receive the vaccine</li>
                    <li>Book your first appointment at the vaccine centre</li>
                    <li>Book your second appointment (the vaccine is delivered in two doses)</li>
                </ul>
            </div>
            <div><h4>You will need to provide:</h4></div>
            <div>
                <ul>
                    <li>Your NHS number</li>
                    <li>Information about yourself</li>
                </ul>
                <p>If you do not know your NHS number, there are several ways to find this:</p>
                <ul>
                    <li>Check letters from your hospital or GP practice</li>
                    <li>Download the <a href="https://www.nhsapp.service.nhs.uk" target="_blank" rel="noopener noreferrer">NHS app</a>. The verification of your identity may take a while so it's best to download as soon as possible</li>
                    <li>If you're unable to do either of the above, you can call your GP and ask</li>
                </ul>
                <p>Details of how your information will be used is available in the privacy notice.</p>
            </div>
            <div><h4>Important information about the vaccine</h4></div>
            <div>
                Please ensure you have read the important information about the vaccine:
                <ul>
                    <li>Patient information leaflet</li>
                    <li>Frequently asked questions</li>
                </ul>
            </div>
            <div><h4>Privacy notice</h4></div>
            <p>As part of registration for the COVID-19 vaccination, you will be asked to submit some information about yourself and your health.</p>
            <p>The information you submit will be accessed by Guy’s and St Thomas’ occupational health, health Informatics, pharmacy and the COVID-19 vaccination teams. The information will be also shared with Public Health England in order to support further vaccination planning.</p>
            <p>Your organisation will only be notified that you have registered to receive the COVID-19 vaccine. None of the other information you provide here will be shared with your organisation’s management team.</p>
            <p>By submitting your data, you are agreeing to the data to be shared with the National Immunisation Vaccine System, NHS Digital and your GP. This is a national requirement to ensure that your GP records are updated with details of the vaccination you have received.</p>
            <p>For more information about your rights under the General Data Protection Regulation and Data Protection Act 2018 please see <a href="https://www.gsttcommercialservices.co.uk/Resources/GDPR-Privacy-notice-Occupational-Health-Final.pdf" target="_blank" rel="noopener noreferrer">here</a> for staff, otherwise <a href="https://www.gov.uk/data-protection" target="_blank" rel="noopener noreferrer">here</a>.</p>
        </div>
        <div>
            <RequiredSymb /> Required;
        </div>
    </div>);

export default QuestionnaireBlurb;