import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from "../providers/authProvider";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import LoginView from './common/LoginView';
import Footer from './common/Footer';
import * as consts from '../constants/dataConst';
import isInRole from '../utils/isInRole';

const adminRoles = ["gstt\\IT Paas", "kingsch\\COVIDVaccinationScheduling", "kingsch\\COVIDVaccinationAdmin"]

const Home = () => {

    const authContext = useContext(AuthContext);
    const [userIsAdmin, setUserIsAdmin] = useState(false)
    const isAuthenticated = authContext.isAuthenticated();

    useEffect(() => {
        const getUser = async () => {
            if (isAuthenticated) {
                const userHasAdminRoles = isInRole(adminRoles);
                setUserIsAdmin(userHasAdminRoles);
            } else {
                setUserIsAdmin(false);
            }
        }

        getUser();
    }, [])


    return <>
        <Card>
            <CardHeader style={{ backgroundColor: '#004B8B' }} >
                <div className="questionnaire-banner">
                    <Row>
                        <Col xs="9"><h2>{consts.PAGE_HEADER}</h2></Col>
                        <LoginView />
                    </Row>
                </div>
            </CardHeader>
            <CardBody>
                <div>
                    <ul>
                        {consts.SHOW_COVID19CC && <li><Link to="/covid19cc">Cancer Centre - COVID-19 Questionnaire</Link>&nbsp;{isAuthenticated && <span>(<Link to="/results/covid19cc">Results</Link>)</span>}</li>}
                        {consts.SHOW_COVID19SA && <li><Link to="/covid19sa">COVID-19 Staff Antibody Test Registration</Link>&nbsp;{isAuthenticated && <span>(<Link to="/results/covid19sa">Results</Link>)</span>}</li>}
                        {consts.SHOW_FLUVACCINATION && <li><Link to="/fluvaccination">Flu Vaccination Uptake Questionnaire</Link>&nbsp;{isAuthenticated && <span>(<Link to="/results/fluvaccination">Results</Link>)</span>}</li>}
                        {consts.SHOW_COVID19HOMETESTREPORTING && <li><Link to="/covid19hometestreporting">COVID-19 Staff Home Test Reporting</Link>&nbsp;{isAuthenticated && <span>(<Link to="/results/covid19hometestreporting">Results</Link>)</span>}</li>}
                        {consts.SHOW_COVID19HOMETESTINGREGISTRATION && <li><Link to="/covid19hometestingregistration">COVID-19 Staff Home Testing Registration</Link>&nbsp;{isAuthenticated && <span>(<Link to="/results/covid19hometestingregistration">Results</Link>)</span>}</li>}
                        {consts.SHOW_COVID19VACCINATION && <li><Link to="/covid19vaccination">COVID-19 Vaccination</Link>&nbsp;{isAuthenticated && <span>(<Link to="/results/covid19vaccination">Results</Link> / <Link to="/covid19vaccinationQueue">QMS</Link>)</span>}</li>}
                        {consts.SHOW_COVID19VACCINATION && userIsAdmin && <li>
                            <span>COVID-19 Vaccination Administration</span> (<Link to="/scheduling/management">Schedule Management</Link> / <Link to="/scheduling/download">Schedule Download</Link> / <Link to="/appointmentUpload">NBS Upload</Link> / <Link to="/upload">SMS Upload</Link> / <Link to="/bulkmove">Bulk Appointment Move</Link>)
                            </li>}
                    </ul>
                </div>
            </CardBody>
        </Card>
        <Footer />
    </>;
};

export default Home;