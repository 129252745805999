import React, { useEffect, useState } from 'react';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import { Container, Row, Col } from 'reactstrap';
import tokenService from '../../services/tokenService';
import { signalRUtil } from '../../utils/signalRUtil';
import Card from 'reactstrap/lib/Card';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Table from 'reactstrap/lib/Table';
import LoginView from '../common/LoginView';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavbarText from 'reactstrap/lib/NavbarText';
import isInRole from '../../utils/isInRole';

export const AppointmentUpload = ({ getUser, requiredRoles }) => {

    const [file, setFile] = useState();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [hubConnection, setHubConnection] = useState(false);
    const [progress, setProgress] = useState({});
    const [sites, setSites] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedSite, setSelectedSite] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");

    const [controller, setAbortController] = useState();
    const [problemRecords, setProblemRecords] = useState([]);

    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);


    useEffect(() => {
        const checkAccess = async () => {
            setCheckingAccess(true);
            
            if (isInRole(requiredRoles)) {
                setHasRequiredRoles(true);
                setCheckingAccess(false);
            } else {
                setHasRequiredRoles(false);
                setCheckingAccess(false);
            }
        }
        if (getUser) {
            checkAccess();
        }
    }, [getUser])


    useEffect(() => {
        const createHub = async () => {
            const hub = await signalRUtil.getHubConnectionUpload();
            hub.on("progress", progress => {
                setProgress(progress);
            });

            hub.on("problemRecord", record => {

                setProblemRecords(records => {
                    var next = [record, ...records];
                    return next;
                });
            });

            setHubConnection(hub);
        }

        const loadSites = async () => {
            const response = await fetch('/Sites', {
                method: 'Get',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            var data = await response.json()
            console.log(data);

            setSites(data);
        }

        createHub();
        loadSites();

        setAbortController(new AbortController());
    }, []);

    const submit = async () => {
        setLoading(true);
        const token = tokenService.getToken();
        var data = new FormData()
        data.append('location', selectedLocation);
        data.append('site', selectedSite);
        data.append('file', file[0])

        try {
            var response = await fetch('/api/AppointmentUpload', { // Your POST endpoint
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token
                },
                body: data,
                signal: controller.signal
            })

            if (!response.ok) {
                setError("Something went wrong")
            } else {
                setDone(true);
                setLoading(false);
            }
        } catch (ex) {
            setLoading(false);
        }
    }

    const abort = () => {
        controller.abort();
        setAbortController(new AbortController());
    }

    const siteSelected = (e) => {
        setSelectedSite(e.target.value);
        setSelectedLocation("");
        if (e.target.value) {
            var locations = sites.find(x => x.siteName === e.target.value).locations;
            if (locations.length === 1) {
                setSelectedLocation(locations[0].locationName);
            }
            setLocations(locations);
        } else {
            setLocations([]);
        }
    }

    const locationSelected = (e) => {
        setSelectedLocation(e.target.value);
    }

    if (checkingAccess) {
        return (<></>)
    }

    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    return <div style={{ 'backgroundColor': 'rgb(0, 75, 139)', minHeight: "100vh" }}>
        <Navbar>
            <NavbarBrand><h3 className="align-middle" style={{ color: "white" }}>Patient Appointment Upload</h3></NavbarBrand>
            <NavbarText><img src="/images/GSTT-logo-2017-white.png" alt="GSTT" className="float-right p-2" height="75" /></NavbarText>
        </Navbar>
        <Container>
            <Row>
                <Col>
                    <Card style={{ marginBottom: "25px", marginTop: "25px" }}>
                        <CardHeader>Upload</CardHeader>
                        <CardBody>
                            <form>
                                <FormGroup>
                                    <Label>Site</Label>
                                    <Input type="select" onChange={siteSelected}>
                                        <option>--Select Site--</option>
                                        {sites.map((s, i) => <option key={i}>{s.siteName}</option>)}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Location</Label>
                                    <Input type="select" onChange={locationSelected} value={selectedLocation}>
                                        <option>--Select Location--</option>
                                        {locations.map((l, i) => <option key={i}>{l.locationName}</option>)}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="file" name="file" id="exampleFile" onChange={(e) => setFile(e.target.files)} />
                                </FormGroup>
                                <ButtonGroup>
                                    {!loading && <Button disabled={loading || selectedSite == "" || selectedLocation == ""} color="dark" className="mt-3" onClick={() => submit()}>Upload</Button>}
                                    {loading && <Button onClick={abort} className="mt-3">Abort Upload</Button>}
                                </ButtonGroup>
                                <br />
                                {progress.total && <>Processing: {progress.progress} of {progress.total}</>}
                                <div>
                                    {error && <>{error} </>}
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    {problemRecords.length !== 0 && <Card>
                        <CardHeader>Problems</CardHeader>
                        <CardBody>
                            <Table responsive={true}>
                                <tbody>
                                    {problemRecords.map((r, i) => <tr key={i}>
                                        <td>{r.reason}</td>
                                        <td>{JSON.stringify(r.item)}</td>
                                    </tr>
                                    )}


                                </tbody>
                            </Table>

                        </CardBody>
                    </Card>
                    }
                </Col>
            </Row>
        </Container>
    </div>

}
