import React from 'react';
import * as consts from '../../constants/dataConst';

const Footer = () => {
    return(
    <footer>
        &copy; {consts.FOOTER_TEXT} - <a href={consts.PRIVACY_LINK} target="_blank" rel="noopener noreferrer" title="Privacy Policy">Privacy</a>
    </footer>
    )
}

export default Footer;