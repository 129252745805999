import React, { Component } from 'react';
import Banner from './Banner';
import Questionnaire from './Questionnaire';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Footer from '../common/Footer';

export class Covid19CCQuestionnaire extends Component {

    render() {
        return (<>
            <Card>
                <CardHeader style={{ backgroundColor:'#004B8B' }} >
                    <Banner />
                </CardHeader>
                <CardBody>
                    <Questionnaire {...this.props} />
                </CardBody>
            </Card>
            <Footer />
            </>
        );
    }
}
