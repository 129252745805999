import React, { Component } from 'react';
import Banner from '../Banner';
import QuestionnaireResults from './QuestionnaireResults';
import { Card, CardHeader, CardBody } from 'reactstrap';
import isInRole from '../../../utils/isInRole';

export class CovidVaccinationQuestionnaireResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkingAccess: true,
            hasReportRole: false,
            hasDateOverrideRole: false,
            hasRequiredRoles: false
        };
    }

    async componentDidMount() {
        const { requiredRoles } = this.props;

        if (isInRole(requiredRoles)) {
            let hasReportRole = false;
            let hasDateOverrideRole = false;
            let hasVipAccessRole = false;
            if (isInRole(["kingsch\\COVIDVaccinationReportAdmin"])) {
                hasReportRole = true;
            }
            if (isInRole(["kingsch\\COVIDVaccinationScheduling"])) {
                hasDateOverrideRole = true;
            }
            if (isInRole(["kingsch\\COVIDVaccinationBookingLead"])) {
                hasVipAccessRole = true;
            }
            this.setState({
                checkingAccess: false,
                hasReportRole: hasReportRole,
                hasDateOverrideRole: hasDateOverrideRole,
                hasVipAccessRole: hasVipAccessRole,
                hasRequiredRoles: true
            });
        } else {
            this.setState({
                checkingAccess: false
            });
        }
    }

    render() {

        if (this.state.checkingAccess) {
            return null;
        }

        if (this.state.hasRequiredRoles) {
            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} id="bannerHeader" >
                        <Banner showLogin />
                    </CardHeader>
                    <CardBody>
                        <QuestionnaireResults hasReportRole={this.state.hasReportRole} hasDateOverrideRole={this.state.hasDateOverrideRole} hasVipAccessRole={this.state.hasVipAccessRole} />
                    </CardBody>
                </Card>
                <footer>
                    <span className="copyRightText">&copy; 2020 - Guy's and St Thomas' NHS Foundation Trust</span>
                </footer>
            </>
            );
        } else {
            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} >
                        <Banner showLogin />
                    </CardHeader>
                    <CardBody>
                        <div>You are not authorized to view this page.</div>
                    </CardBody>
                </Card>
                <footer>
                    <span className="copyRightText">&copy; 2020 - Guy's and St Thomas' NHS Foundation Trust</span>
                </footer>
            </>)
        }
    }
}
