import React, { Component } from 'react';
import Banner from './Banner';
import { Card, CardHeader, CardBody } from 'reactstrap';

export class FluVaccinationNotice extends Component {

    render() {
        return (<>
            <Card>
                <CardHeader style={{ backgroundColor:'#004B8B' }} >
                    <Banner />
                </CardHeader>
                <CardBody>
                    <p>How will the data from the Uptake Questionnaire be used?</p>
                    <p>Your response to the uptake questionnaire will be collected by the occupational health team to inform the Trust of your directorate's <strong>percentage uptake</strong> of the vaccination. Your personal data, such as your name or the information that you provide in the questionnaire will NOT be shared with anyone outside of occupational health unless you work in a Trust designated high-risk area and there is a <strong>flu outbreak</strong>.</p>
                    <p>Flu vaccination uptake in frontline workers is reportable to the Department of Health (DH). Your response to this questionnaire will be used to inform the DH of the Trust's percentage uptake of the vaccination and the reasons why some staff have decided not to have the vaccine. Your personal data, such as your name, will NOT be shared with the DH.</p>
                    <p>Only names of staff who DO NOT complete the questionnaire and have not attended a flu clinic will be given to Directorate Management Teams.</p>
                    <p><strong>In the event that the Trust declares a flu outbreak</strong> names of staff from designated high-risk areas who have been vaccinated will be provided to the senior site nurse practitioner (SNP) and incident response team. This is so that they can enable a response ensuring both staff and patients are protected and to reduce spread of infection as much as possible.</p>
                    <p>For further information on information governance within the occupational health service please see our <a href="https://www.gsttcommercialservices.co.uk/Resources/GDPR-Privacy-notice-Occupational-Health-Final.pdf" >privacy notice</a>.</p>
                </CardBody>
            </Card>
            <footer>
                &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust - <a href="https://www.guysandstthomas.nhs.uk/patients-and-visitors/patients/your-care/health-records.aspx" target="_blank" rel="noopener noreferrer" title="Privacy Policy">Privacy</a>
            </footer>
            </>
        );
    }
}
