import React, { useState, useEffect} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Input, Table, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import tokenService from '../../../services/tokenService';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}
startOfWeek.setDate(startOfWeek.getDate() - 7);
let tomorrow = new Date(new Date().toDateString());
tomorrow.setDate(tomorrow.getDate() + 1);

const LocationManagement = ({ site }) => {

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [editedLocation, setEditedLocation] = useState(null);
    const [addMode, setAddMode] = useState(false);
    const [vaccine, setVaccine] = useState('');
    const [availableVaccinesList, setAvailableVaccinesList] = useState([]);
    const [yesNoList, setYesNoList] = useState([]);

    useEffect(() => {
        setYesNoList([{ value: true, name: "Yes" }, { value: false, name: "No" }]);
    }, []);

    useEffect(() => {
        if (site) {
            getLocations();
            getVaccines();
        }
    }, [site])

    const getLocations = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleLocation?siteName=' + encodeURIComponent(site), {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLocationList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getVaccines = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleVaccine', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setAvailableVaccinesList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const saveLocation = async () => {
        setSaved(false);
        setUpdating(true);

        try {
            const token = tokenService.getToken();
            const data = { ...editedLocation, vaccine: vaccine };
            const request = fetch('/ScheduleLocation?site=' + encodeURIComponent(site), {
                method: 'PUT',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify(data)
            });

            const response = await request;

            if (response.ok) {
                setSaved(true);
                setEditedLocation(null);
                getLocations();
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            setErrorRetrieving(true);
            console.log(err);
        }
        finally {
            setUpdating(false);
        }
    }

    const editLocation = (location) => {
        setSaved(false);
        if (!location) {
            setAddMode(false);
            setEditedLocation(null);
        } else {
            setEditedLocation({ ...location });
        }
    }

    const addNewLocation = () => {
        setAddMode(true);
        editLocation({ locationName: '', friendlyName: '', locationPriority: 1, numberOfAssessors: 40, locationIsAccessible: true, allowMultiCallForward: false, locationCompositeKey: '', mapUri: '', whatThreeWords: '', availableDesks: 0, waitingCapacity: 0, paediatricAppointments: false, fiveToElevenAppointments: false, newSmsTemplateId: '', updatedSmsTemplateId: '', reminderSmsTemplateId: '' });
    }

    const vaccineChangeHandler = (e) => {
        setVaccine(e.target.value);
    }

    const textChangeHandler = (e, asNumber) => {
        const saveAsNumber = asNumber || false;
        if (editedLocation) {
            setEditedLocation({ ...editedLocation, [e.target.name]: saveAsNumber ? parseInt(e.target.value) : e.target.value });
        }
    }

    const checkedChangeHandler = (e) => {
        if (editedLocation) {
            setEditedLocation({ ...editedLocation, [e.target.name]: e.target.checked });
        }
    }

    const renderLocationsTable = () => {
        if (!locationList.length) {
            return <span>There are no locations configured. Please add one.</span>
        }

        return <Table>
            <thead>
                <tr>
                    <th>Location Name</th>
                    <th>Location Priority</th>
                    <th># of Assessors</th>
                    <th>Is Accessible?</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {locationList.map((s, idx) => <tr key={idx}>
                    <td>{s.friendlyName || s.locationName}{s.friendlyName ? <div className="site-management-smalltext">({s.locationName})</div> : null}</td>
                    <td>{s.locationPriority}</td>
                    <td>{s.numberOfAssessors}</td>
                    <td>{s.isAccessible ? "Yes" : "No"}</td>
                    <td>
                        <Button color="warning" onClick={() => { setAddMode(false); editLocation(s) }}>Edit</Button>
                    </td>
                </tr>)}
            </tbody>
        </Table>
    }

    const saveIsEnabled = () => {
        if (!editedLocation.locationName) {
            return false;
        }
        if (addMode && !vaccine) {
            return false;
        }
        if (addMode) {
            if (locationList.find(ll => ll.locationName.toLowerCase() === editedLocation.locationName.toLowerCase())) {
                return false;
            }
        }
        if (updating) {
            return false;
        }
        return true;
    }

    const renderEditLocation = () => {
        return <div>
            <Row>
                <Col xs="3">Location Name:</Col>
                <Col xs="3">
                    {addMode && <div className="questionnaire-error"><strong>IMPORTANT: Once set this cannot be changed!</strong></div>}
                    <Input type="text" className="site-management-textbox" name="locationName" autoFocus={addMode} value={editedLocation.locationName} onChange={(e) => textChangeHandler(e)} disabled={!addMode} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Display Name:</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="friendlyName" autoFocus={!addMode} value={editedLocation.friendlyName || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Location Priority:</Col>
                <Col xs="3"><Label check>
                    <Input type="number" className="site-management-textbox" name="locationPriority" value={editedLocation.locationPriority} onChange={(e) => textChangeHandler(e, true)} />
                </Label></Col>
            </Row>
            <Row>
                <Col xs="3">Number of Assessors:</Col>
                <Col xs="3"><Label check>
                    <Input type="number" className="site-management-textbox" name="numberOfAssessors" value={editedLocation.numberOfAssessors} onChange={(e) => textChangeHandler(e, true)} />
                </Label></Col>
            </Row>
            <Row>
                <Col xs="3">Location is Accessible:</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="isAccessible" checked={editedLocation.isAccessible} onChange={(e) => checkedChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Paediatric Appointments Only:</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="paediatricAppointments" checked={editedLocation.paediatricAppointments} onChange={(e) => checkedChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">5-11 Appointments?:</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="fiveToElevenAppointments" checked={editedLocation.fiveToElevenAppointments} onChange={(e) => checkedChangeHandler(e)} disabled={!editedLocation.paediatricAppointments} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Allow Multi Call Forward:</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="allowMultiCallForward" checked={editedLocation.allowMultiCallForward} onChange={(e) => checkedChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Location Composite Key:</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="locationCompositeKey" value={editedLocation.locationCompositeKey || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">GSTT Map URI:</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="mapUri" value={editedLocation.mapUri || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Precise Map URI:</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="whatThreeWords" value={editedLocation.whatThreeWords || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">New Appointment Sms Template Id (default Gov Notify template will be used if not specified):</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="newSmsTemplateId" value={editedLocation.newSmsTemplateId || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Updated Appointment Sms Template Id (default Gov Notify template will be used if not specified):</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="updatedSmsTemplateId" value={editedLocation.updatedSmsTemplateId || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Appointment Reminder Sms Template Id (default Gov Notify template will be used if not specified):</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="reminderSmsTemplateId" value={editedLocation.reminderSmsTemplateId || ''} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            {addMode && <Row>
                <Col xs="3">Vaccine Type:</Col>
                <Col xs="3">
                    <Input className="schedule-utilisation-dropdown" type="select" name="vaccinetype" onChange={(e) => vaccineChangeHandler(e)} value={vaccine}><option value="">Please Select...</option>{availableVaccinesList.map((v, idx) => <option key={idx} value={v.vaccineCode}>{v.vaccineName}</option>)}</Input>
                </Col>
            </Row>}
            <br />&nbsp;<br />
            {addMode && <div><strong>NOTE: After saving please wait while the schedule is generated, which make take a few moments. This will eventually appear on the schedule management UI when complete.</strong></div>}
            <Button color="danger" onClick={(e) => saveLocation()} disabled={!saveIsEnabled()}>Save</Button>&nbsp;<Button color="warning" onClick={(e) => editLocation(null)} disabled={updating}>Cancel</Button>
        </div>
    }

    return <div className="questionnaire-body">
        <h3>Location Management for COVID-19 Vaccination</h3>
        {!editedLocation && <div><Link to="/scheduling/site">Back to Sites</Link></div>}
        {(loading || updating) && <Spinner />}
        {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
        {errorRetrieving && <div className="questionnaire-error">Unexpected error detected. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Vaccination Admin service.</div>}
        {!loading && !editedLocation && renderLocationsTable()}
        {!loading && editedLocation && renderEditLocation()}
        <br />
        {!editedLocation && !loading && <Button color="success" onClick={() => addNewLocation()}>Add New Location</Button>}
        {saved && <span className="schedule-utilisation-success">Location saved!</span>}        
    </div>;
};

export default LocationManagement;