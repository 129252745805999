import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

String.prototype.firstLastWord = function () {
    if(this.length===0) {
        return this;
    }
    const txt =  this.trim().split(' ');

    if(txt.length === 1) {
        return this;
    }

    const first = txt[0];
    const last = txt[txt.length-1];
    return `${first} ${last}`;
};


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
  </BrowserRouter>,
  rootElement);

