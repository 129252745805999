import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Button } from 'reactstrap';
import { AuthContext } from "../../providers/authProvider";
import { METADATA_OIDC } from '../../constants/authConst';
import Spinner from './Spinner';

const LoginView = ({ nonBanner }) => {

    const [signingIn, setSigningIn] = useState(false);
    const [signinError, setSigninError] = useState(false);
    const [idpAccessible, setIdpAccessible] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const authContext = useContext(AuthContext);
    const isAuthenticated = authContext.isAuthenticated();

    useEffect(() => {
        fetch(METADATA_OIDC.jwks_uri).then(r => {
            if (r.ok) {
                setIdpAccessible(true);
            } else {
                setIdpAccessible(false);
            }
        }).catch(err => {
            console.log(err);
            setIdpAccessible(false);
        }); 
    }, [authContext])

    useEffect(() => {
        const getUserLoginDetails = async () => {
            try {
                const user = await authContext.getUser();
                console.log(user);
                setUserDetails(user.profile.unique_name);
            } catch (err) {
                console.log(err);
            }            
        }

        if (isAuthenticated) {
            getUserLoginDetails();
        }        
    }, [isAuthenticated, authContext])

    const signin = () => {
        setSigningIn(true);
        setSigninError(false);
        fetch(METADATA_OIDC.jwks_uri).then(r => {
            if (r.ok) {
                authContext.signinRedirect();
            } else {
                setSigningIn(false)
                setSigninError(true);
            }            
        }).catch(err => {
            console.log(err);
            setSigningIn(false)
            setSigninError(true);
        });        
    };

    if (idpAccessible) {
        return <Col xs="3" className={nonBanner ? "login-independent-view" : ""}><div className="float-right">
            {signingIn && <Spinner />}
            {isAuthenticated && !signingIn && <span><strong>Logged in as:</strong> {userDetails} <Link className="login-link" to={'/Logout'}>(log out)</Link></span>}
            {!isAuthenticated && !signingIn && <Button className="login-link" color="link" onClick={() => signin()}>Login</Button>}
            {signinError && <i className="fa fa-exclamation-circle logon-error" title="There was a problem accessing the logon server. Please check VPN if not on-site."></i>}
        </div></Col>;
    }

    return null;
}

export default LoginView;