import React from 'react';
import { Row } from 'reactstrap';

const Banner = () => (
    <div className="questionnaire-banner">
        <Row>
            <div className="questionnaire-bannertext-med col-12">Flu Vaccination Uptake Questionnaire</div>
        </Row>
    </div>
);

export default Banner;