import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button } from 'reactstrap';
import Spinner from '../../common/Spinner';
import Moment from 'react-moment';
import dateUtil from '../../../utils/dateUtil';
import tokenService from '../../../services/tokenService';
import SubmissionFilter from './SubmissionFilter';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}

const QuestionnaireResults = () => {

    const questionnaireKey = 'Flu Vaccination Uptake';
    const [questionnaireResults, setQuestionnaireResults] = useState([]);
    const [dateFrom, setDateFrom] = useState(startOfWeek);
    const [dateTo, setDateTo] = useState(currentDate);
    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const downloadLink = useRef();

    const buildFilterUrl = useCallback(() => {
        let url = "dateFrom=";
        if (dateFrom) {
            url = url + encodeURIComponent(dateFrom.toISOString());
        }
        url = url + "&dateTo=";
        if (dateTo) {
            let dateToEndOfDay = new Date(dateTo.getTime());
            dateToEndOfDay.setDate(dateToEndOfDay.getDate() + 1);
            url = url + encodeURIComponent(dateToEndOfDay.toISOString());
        }
        if (assignmentNumber) {
            url = url + "&questionFilterKey=assignmentnumber&questionFilterValue=" + encodeURIComponent(assignmentNumber);
        }
        return url;
    }, [dateFrom, dateTo, assignmentNumber]);

    const getQuestionnaireAnswers = useCallback(async () => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers?questionnaireKey=' + questionnaireKey + "&" + buildFilterUrl();
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setQuestionnaireResults(data);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }, [buildFilterUrl]);

    useEffect(() => {
        getQuestionnaireAnswers();
    }, [questionnaireKey, getQuestionnaireAnswers, dateFrom, dateTo, assignmentNumber])

    const getAssignmentNumber = (questionAnswers) => {
        const assignmentNumberQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'assignmentnumber');
        if (assignmentNumberQuestion.length > 0) {
            const answers = assignmentNumberQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0].toLowerCase();
            }
        }
        return "n/a";
    }

    const setFilter = (dateFrom, dateTo, assignmentNumber) => {
        setDateFrom(dateFrom);
        setDateTo(dateTo);
        setAssignmentNumber(assignmentNumber);
    };

    const downloadCsv = async () => {
        setLoadingCsv(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers/' + questionnaireKey + '/Download?' + buildFilterUrl();
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.blob();
                const filename = questionnaireKey + " - Results - " + new Date().toISOString().substring(0, 10) + ".csv";

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(data, filename);
                } else {
                    let objectUrl = window.URL.createObjectURL(data);

                    downloadLink.current.href = objectUrl;
                    downloadLink.current.download = filename;
                    downloadLink.current.click();

                    window.URL.revokeObjectURL(objectUrl);
                }
                
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingCsv(false);
        }
    }

    return <div className="questionnaire-body">
        <h3>Results for Flu Vaccination Uptake Questionnaire</h3>
        <SubmissionFilter setFilter={setFilter} originalDateFrom={dateFrom} originalDateTo={dateTo} assignmentNumber={assignmentNumber} />
        {loading && <Spinner />}
        {questionnaireResults.length === 0 && !loading && <div>No Submissions Found.</div>}
        {questionnaireResults.length > 0 && <Table className="questionnaire-results-list" striped>
            <thead>
                <tr>
                    <th>Submission Date</th>
                    <th>Assignment Number</th>
                    <th>Submission Id</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {questionnaireResults.map(qr => <tr key={qr.submissionIdentifier}>
                    <td><Moment format="DD MMM YYYY HH:mm">{dateUtil.ConvertDateFromJSON(qr.submissionDate)}</Moment></td>
                    <td>{getAssignmentNumber(qr.questionAnswers)}</td>
                    <td>{qr.submissionIdentifier}</td>
                    <td>&nbsp;</td>
                </tr>)}
            </tbody>
        </Table>}
        {loadingCsv && <Spinner />}
        {questionnaireResults.length > 0 && !loadingCsv && <Button color="link" onClick={() => downloadCsv()}>Download as CSV</Button>}
        <a ref={downloadLink} href="/">&nbsp;</a>
    </div>;
};

export default QuestionnaireResults;