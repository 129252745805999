import { faExclamation, faComment, faPhone, faLink, faSpinner, faEye, faSyringe } from '@fortawesome/free-solid-svg-icons';
import { faComment as farComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Card, CardBody, CardFooter, CardHeader, Col, Button, Row, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Input, Badge, ListGroup, ListGroupItem } from 'reactstrap';
import Spinner from '../../common/Spinner';
import { signalRUtil } from '../../../utils/signalRUtil';
import tokenService from '../../../services/tokenService';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import { PatientAppointmentItem } from '../../common/PatientAppointmentItem';
import ViewQuestionnaireSubmission from '../results/ViewQuestionnaireSubmission';
import { queueStatus } from './QueueStatus';
import LoginView from '../../common/LoginView';

import Questionnaire from '../Questionnaire';
import { Timer } from '../../common/Timer';
import isInRole from '../../../utils/isInRole';

const anonymousPatientName = "Patient";

export const QueueAssessor = ({ match, getUser, requiredRoles }) => {

    const questionnaireKey = 'COVID Vaccination';

    const { site, location, desk } = match.params;
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [locationConfig, setLocationConfig] = useState(null);
    const [locationScheduleDetail, setLocationScheduleDetail] = useState(null);
    const [disableMultiPatientCalling, setDisableMultiPatientCalling] = useState(true);
    const [calledPatients, setCalledPatients] = useState([]);
    const [callHoldingPatients, setCallHoldingPatients] = useState([]);
    const [waitingPatients, setWaitingPatients] = useState([]);
    const [basicRegistrationSubmissionId, setBasicRegistrationSubmissionId] = useState("");
    const [submission, setSubmission] = useState(null);
    const [currentPsdDose, setCurrentPsdDose] = useState('');
    const [hubConnection, setHubConnection] = useState();
    const [loading, setLoading] = useState(true);
    const [submissionSaved, setSubmissionSaved] = useState(false);
    const [outcomeComplete, setOutcomeComplete] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalComments, setModalComments] = useState("");
    const [selectedPatient, setSelectedPatient] = useState("");
    const [avgWaitingTime, setAvgWaitingTime] = useState();
    const [showNewRegistration, setShowNewRegistration] = useState(false);
    const [savedSuccess, setSavedSuccess] = useState(false);
    const [showFinishedWarning, setShowFinishedWarning] = useState(false);
    const [patientHasBeenVaccinated, setPatientHasBeenVaccinated] = useState(false);
    const [finishedWithPatientsAvailable, setFinishedWithPatientsAvailable] = useState(true);
    const [showPatientSelection, setShowPatientSelection] = useState(false);
    const [prescriberCommentsAcknowledged, setPrescriberCommentsAcknowledged] = useState(false);
    const [acknowledgeDisabled, setAcknowledgeDisabled] = useState(true);

    const [disconnected, setDisconnected] = useState(false);
    const [reconnecting, setReconnecting] = useState(false);
    const [reconnected, setReconnected] = useState(false);

    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);
    const [psdRole, setPsdRole] = useState("");
    const [lastKnownCovidDose, setLastKnownCovidDose] = useState('');

    useEffect(() => {
        if (!prescriberCommentsAcknowledged && !submission && acknowledgeDisabled) {
            setTimeout(() => {
                setAcknowledgeDisabled(false);
            }, 5000)
        }
    }, [prescriberCommentsAcknowledged, submission, calledPatients])

    useEffect(() => {
        const checkAccess = async () => {
            setCheckingAccess(true);

            if (isInRole(requiredRoles)) {
                setHasRequiredRoles(true);
                setCheckingAccess(false);
            } else {
                setHasRequiredRoles(false);
                setCheckingAccess(false);
            }
        }
        if (getUser) {
            checkAccess();
        }
    }, [getUser])

    useEffect(() => {
        if (savedSuccess) {
            setTimeout(() => { setSavedSuccess(false); }, 3000);
        }

    }, [savedSuccess]);

    useEffect(() => {
        getLocations();
    }, [site])

    useEffect(() => {
        if (locationList.length > 0) {
            const locatedLocation = locationList.find(ll => ll.locationName === location);
            if (locatedLocation) {
                setLocationConfig(locatedLocation);
                return;
            }
        }
        setLocationConfig(null);
    }, [locationList])

    useEffect(() => {
        if (locationConfig) {
            getLocationScheduleDetail();
            if (locationConfig.allowMultiCallForward) {
                setDisableMultiPatientCalling(false);
                return;
            }
        }
        setDisableMultiPatientCalling(true);
    }, [locationConfig]);

    const getLocationScheduleDetail = async () => {
        try {
            ///Schedule/Lookup/{questionnaireKey}/{site}/{location}/{scheduleDate}
            const currentDate = new Date();
            const currentDateUriFormat = `${currentDate.getFullYear()}-${pad(currentDate.getMonth() + 1, 2)}-${pad(currentDate.getDate(), 2)}`;
            const response = await fetch(`/Schedule/Lookup/${encodeURIComponent(questionnaireKey)}/${encodeURIComponent(site)}/${encodeURIComponent(location)}/${encodeURIComponent(currentDateUriFormat)}`, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLocationScheduleDetail(data);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const getLocations = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleLocation?siteName=' + encodeURIComponent(site), {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLocationList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const userHasRequiredRoles = (usersRoles, requiredRoles) => {
        const matchingRoles = usersRoles.filter(ur => requiredRoles.filter(rr => ur.toLowerCase() === rr.toLowerCase()).length > 0);
        return matchingRoles.length > 0;
    };

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    const openSubmission = async (submission, role) => {

        if (!submission) {
            setSubmission(null);
            return;
        }

        setPsdRole(role);
        setShowNewRegistration(false);
        setSubmissionSaved(false);
        setErrorRetrieving(false);
        setErrorSaving(false);
        setSubmission(null);
        setShowPatientSelection(false);

        try {
            const token = tokenService.getToken();
            const response = await fetch('/QuestionnaireAnswers/' + questionnaireKey + '/' + submission.submissionId, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSubmission(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
                setSubmission(null);
            } else {
                setErrorRetrieving(true);
                setSubmission(null);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            //  setLoading(false);
        }
    }

    const copyIfPresent = (changes, sourceFields, destinationField) => {
        for (let i = 0; i < sourceFields.length; i++) {
            if (changes && changes[sourceFields[i]]) {
                changes[destinationField] = changes[sourceFields[i]];
            };
        }
    }

    const answerCopyAllowed = (answerKey) => {
        switch (answerKey) {
            case "bleedingdisorder":
                if (locationScheduleDetail.scheduleLocation.vaccine === "Pf" || locationScheduleDetail.scheduleLocation.vaccine === "Mo") {
                    return true;
                }
                break;
            case "pregnant":
                if (locationScheduleDetail.scheduleLocation.vaccine === "Pf" || locationScheduleDetail.scheduleLocation.vaccine === "Mo") {
                    return true;
                }
                break;
        }
        return false;
    }

    const saveChangesHandler = async (changes, afterSaveCallback) => {
        setErrorSaving(false);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers';

            const data = {
                questionnaireName: questionnaireKey,
                submissionIdentifier: submission.submissionIdentifier,
                questionAnswers: []
            };
            const method = changes.deleted === true ? 'DELETE' : 'PUT';
            delete changes.deleted;

            const currentDate = new Date();
            const currentDateUkFormat = pad(currentDate.getDate(), 2) + '/' + pad((currentDate.getMonth() + 1), 2) + '/' + currentDate.getFullYear();
            const currentPatient = calledPatients.find(cp => cp.submissionId === submission.submissionIdentifier);

            if (psdRole === "screening") {
                if (changes.confirmfluvaxcheck === "Yes" || changes.confirmfluvaxcheck2 === "Yes" || changes.confirmfluvaxcheck3 === "Yes" || changes.confirmfluvaxcheckboost1 === "Yes" || lastKnownCovidDose === "Flu") {
                    setLastKnownCovidDose("Flu");
                    changes.flu1appointmentdate = currentDateUkFormat;
                    changes.flu1appointmentsite = site;
                    changes.flu1appointmentlocation = location;
                    changes.flu1appointmenttimeslot = 'Add-on';

                    if (locationScheduleDetail) {
                        copyIfPresent(changes, ['assessorcomments', 'assessorcomments2', 'assessorcomments3', 'assessorcommentsboost1'], 'assessorcommentsflu1');
                        copyIfPresent(changes, ['infectionorfeverish', 'infectionorfeverish2', 'infectionorfeverish3', 'infectionorfeverishboost1'], 'infectionorfeverishflu1');
                        copyIfPresent(changes, ['covidsymptoms', 'covidsymptoms2', 'covidsymptoms3', 'covidsymptomsboost1'], 'covidsymptomsflu1');
                        if (answerCopyAllowed('bleedingdisorder')) {
                            copyIfPresent(changes, ['bleedingdisorder', 'bleedingdisorder2', 'bleedingdisorder3', 'bleedingdisorderboost1'], 'bleedingdisorderflu1');
                        }
                        if (answerCopyAllowed('pregnant')) {
                            copyIfPresent(changes, ['pregnant', 'pregnant2', 'pregnant3', 'pregnantboost1'], 'pregnantflu1');
                        }
                        copyIfPresent(changes, ['breastfeeding', 'breastfeeding2', 'breastfeeding3', 'breastfeedingboost1'], 'breastfeedingflu1');
                        copyIfPresent(changes, ['bcgpast3months', 'bcgpast3months2', 'bcgpast3months3', 'bcgpast3monthsboost1'], 'bcgpast3monthsflu1');
                        copyIfPresent(changes, ['covidtrial', 'covidtrial2', 'covidtrial3', 'covidtrialboost1'], 'covidtrialflu1');
                    }
                } else if (changes.confirmcovidvaxcheckflu1 === "Yes" || lastKnownCovidDose) {
                    if (changes.requiredcoviddoseflu1 === "1st" || lastKnownCovidDose === "1st") {
                        setLastKnownCovidDose("1st");
                        changes.firstappointmentdate = currentDateUkFormat;
                        changes.firstappointmentsite = site;
                        changes.firstappointmentlocation = location;
                        changes.firstappointmenttimeslot = 'Add-on';

                        if (locationScheduleDetail) {
                            copyIfPresent(changes, ['assessorcommentsflu1'], 'assessorcomments');
                            copyIfPresent(changes, ['infectionorfeverishflu1'], 'infectionorfeverish');
                            copyIfPresent(changes, ['covidsymptomsflu1'], 'covidsymptoms');
                            if (answerCopyAllowed('bleedingdisorder')) {
                                copyIfPresent(changes, ['bleedingdisorderflu1'], 'bleedingdisorder');
                            }
                            if (answerCopyAllowed('pregnant')) {
                                copyIfPresent(changes, ['pregnantflu1'], 'pregnant');
                            }
                            copyIfPresent(changes, ['breastfeedingflu1'], 'breastfeeding');
                            copyIfPresent(changes, ['bcgpast3monthsflu1'], 'bcgpast3months');
                            copyIfPresent(changes, ['covidtrialflu1'], 'covidtrial');
                        }
                    }
                    if (changes.requiredcoviddoseflu1 === "2nd" || lastKnownCovidDose === "2nd") {
                        setLastKnownCovidDose("2nd");
                        changes.secondappointmentdate = currentDateUkFormat;
                        changes.secondappointmentsite = site;
                        changes.secondappointmentlocation = location;
                        changes.secondappointmenttimeslot = 'Add-on';

                        if (locationScheduleDetail) {
                            copyIfPresent(changes, ['assessorcommentsflu1'], 'assessorcomments2');
                            copyIfPresent(changes, ['infectionorfeverishflu1'], 'infectionorfeverish2');
                            copyIfPresent(changes, ['covidsymptomsflu1'], 'covidsymptoms2');
                            if (answerCopyAllowed('bleedingdisorder')) {
                                copyIfPresent(changes, ['bleedingdisorderflu1'], 'bleedingdisorder2');
                            }
                            if (answerCopyAllowed('pregnant')) {
                                copyIfPresent(changes, ['pregnantflu1'], 'pregnant2');
                            }
                            copyIfPresent(changes, ['breastfeedingflu1'], 'breastfeeding2');
                            copyIfPresent(changes, ['bcgpast3monthsflu1'], 'bcgpast3months2');
                            copyIfPresent(changes, ['covidtrialflu1'], 'covidtrial2');
                        }
                    }
                    if (changes.requiredcoviddoseflu1 === "3rd" || lastKnownCovidDose === "3rd") {
                        setLastKnownCovidDose("3rd");
                        changes.thirdappointmentdate = currentDateUkFormat;
                        changes.thirdappointmentsite = site;
                        changes.thirdappointmentlocation = location;
                        changes.thirdappointmenttimeslot = 'Add-on';

                        if (locationScheduleDetail) {
                            copyIfPresent(changes, ['assessorcommentsflu1'], 'assessorcomments3');
                            copyIfPresent(changes, ['infectionorfeverishflu1'], 'infectionorfeverish3');
                            copyIfPresent(changes, ['covidsymptomsflu1'], 'covidsymptoms3');
                            if (answerCopyAllowed('bleedingdisorder')) {
                                copyIfPresent(changes, ['bleedingdisorderflu1'], 'bleedingdisorder3');
                            }
                            if (answerCopyAllowed('pregnant')) {
                                copyIfPresent(changes, ['pregnantflu1'], 'pregnant3');
                            }
                            copyIfPresent(changes, ['breastfeedingflu1'], 'breastfeeding3');
                            copyIfPresent(changes, ['bcgpast3monthsflu1'], 'bcgpast3months3');
                            copyIfPresent(changes, ['covidtrialflu1'], 'covidtrial3');
                        }
                    }
                    if (changes.requiredcoviddoseflu1 === "Booster" || lastKnownCovidDose === "Booster") {
                        setLastKnownCovidDose("Booster");
                        changes.boost1appointmentdate = currentDateUkFormat;
                        changes.boost1appointmentsite = site;
                        changes.boost1appointmentlocation = location;
                        changes.boost1appointmenttimeslot = 'Add-on';

                        if (locationScheduleDetail) {
                            copyIfPresent(changes, ['assessorcommentsflu1'], 'assessorcommentsboost1');
                            copyIfPresent(changes, ['infectionorfeverishflu1'], 'infectionorfeverishboost1');
                            copyIfPresent(changes, ['covidsymptomsflu1'], 'covidsymptomsboost1');
                            if (answerCopyAllowed('bleedingdisorder')) {
                                copyIfPresent(changes, ['bleedingdisorderflu1'], 'bleedingdisorderboost1');
                            }
                            if (answerCopyAllowed('pregnant')) {
                                copyIfPresent(changes, ['pregnantflu1'], 'pregnantboost1');
                            }
                            copyIfPresent(changes, ['breastfeedingflu1'], 'breastfeedingboost1');
                            copyIfPresent(changes, ['bcgpast3monthsflu1'], 'bcgpast3monthsboost1');
                            copyIfPresent(changes, ['covidtrialflu1'], 'covidtrialboost1');
                        }
                    }
                } else if (currentPatient.originalPsd && currentPatient.originalPsd === currentPatient.activePsd) {
                    if (currentPatient.previousPsd && currentPatient.previousPsd !== currentPatient.activePsd) {
                        changes[currentPatient.previousPsd + "appointmentdate"] = '';
                        changes[currentPatient.previousPsd + "appointmentsite"] = '';
                        changes[currentPatient.previousPsd + "appointmentlocation"] = '';
                        changes[currentPatient.previousPsd + "appointmenttimeslot"] = '';
                    }
                } else {
                    setLastKnownCovidDose('');
                }
            }

            const changeKeys = Object.keys(changes);

            if (changeKeys.length > 0) {
                changeKeys.map(a => data.questionAnswers.push({ questionKey: a, answers: [changes[a]] }));

                const response = await fetch(url, {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {
                    setSubmissionSaved(true);
                    setSavedSuccess(true);
                    setErrorSaving(false);
                    if (afterSaveCallback) {
                        afterSaveCallback(true);
                    }
                } else {
                    setErrorSaving(true);
                    if (afterSaveCallback) {
                        afterSaveCallback(false);
                    }
                }
            } else {
                setSubmissionSaved(true);
                setErrorSaving(false);
                if (afterSaveCallback) {
                    afterSaveCallback(true);
                }
            }
        }
        catch (err) {
            console.log(err);
            setErrorSaving(true);
            if (afterSaveCallback) {
                afterSaveCallback(false);
            }
        }
        finally {
            setLoading(false);
        }
    }

    const callPatient = (patient) => {
        setErrorSaving(false);
        setShowNewRegistration(false);
        setShowPatientSelection(false);
        setPrescriberCommentsAcknowledged(false);
        setAcknowledgeDisabled(true);
        var patients = [patient];
        hubConnection.invoke('callPatient', patient, desk, site, location);
        if (patient.linkedSubmissions.length) {
            patient.linkedSubmissions.forEach(x => {
                const linkedPatient = waitingPatients.find(y => y.submissionId === x);
                if (linkedPatient) {
                    patients.push(linkedPatient);
                    hubConnection.invoke('callPatient', linkedPatient, desk, site, location);
                }
            })
        }
        setShowFinishedWarning(false);
        setPatientHasBeenVaccinated(false);
        setSubmission(null);
        if (calledPatients.length > 0) {
            setCallHoldingPatients([...callHoldingPatients, patient]);
        } else {
            setCalledPatients(patients);
        }
    }

    const cancelCall = (patient) => {
        setErrorSaving(false);
        setShowNewRegistration(false);
        setShowPatientSelection(false);
        setPrescriberCommentsAcknowledged(false);
        setAcknowledgeDisabled(true);
        if (!patient) {
            calledPatients.forEach(p => {
                hubConnection.invoke('cancelPatientCall', p, site, location);
            })
            setSubmission(null);
            setCalledPatients([]);
        } else {
            let updatedCallHoldingPatients = callHoldingPatients.filter(chp => chp.submissionId !== patient.submissionId);
            hubConnection.invoke('cancelPatientCall', patient, site, location);
            if (patient.linkedSubmissions.length) {
                patient.linkedSubmissions.forEach(x => {
                    const linkedPatient = waitingPatients.find(y => y.submissionId === x);
                    if (linkedPatient) {
                        updatedCallHoldingPatients = updatedCallHoldingPatients.filter(uchp => uchp.submissionId !== linkedPatient.submissionId);
                        hubConnection.invoke('cancelPatientCall', linkedPatient, site, location);
                    }
                })
            }
            setCallHoldingPatients(updatedCallHoldingPatients);
        }
    }

    const markPatientWithAssessor = () => {
        setPrescriberCommentsAcknowledged(false);
        setAcknowledgeDisabled(true);
        setShowNewRegistration(false);
        setShowPatientSelection(false);
        calledPatients.forEach(p => {
            hubConnection.invoke('patientWithAssessor', p, site, location);
        })

    }

    const sendPatientToVaccinator = () => {
        calledPatients.forEach(p => {
            hubConnection.invoke('patientPassedToVaccination', p, site, location);
        });
        setSubmission(null);
        setCalledPatients([]);
    }

    const openModalComments = (patient) => {
        setSelectedPatient(patient);
        setModalComments(patient.comments);
        setModalOpen(true);
    }

    const saveModalComments = () => {
        const nextPatient = { ...selectedPatient };
        nextPatient.comments = modalComments;
        hubConnection.invoke('updatePatient', nextPatient, site, location);
        setModalComments('');
        setSelectedPatient(null);
        setModalOpen(false);
    }

    useEffect(() => {
        const createHub = async () => {
            const hub = await signalRUtil.getHubConnection(site, location, "Assessor", desk);

            hub.onclose(err => {
                console.error("Hub connection closed. " + err);
                setDisconnected(true);
                setReconnecting(false);
                setReconnected(false);
            });
            hub.onreconnecting(err => {
                console.error("Hub connection re-connecting... " + err);
                setReconnected(false);
                setReconnecting(true);
            });
            hub.onreconnected(() => {
                setReconnected(true);
                setReconnecting(false);
                setDisconnected(false);
            });

            hub.on("patientArrived", patient => {
                if (!patient) return;

                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients, new PatientQueueItem(patient)]
                    return next.sort(sortByDate);
                });
            });

            hub.on("patientArrivalCancelled", patient => {
                if (!patient) return;

                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients]
                    var i = next.findIndex(x => x.submissionId === patient.submissionId);
                    next.splice(i, 1);
                    return next.sort(sortByDate);
                })
            });

            hub.on("PatientUpdated", patient => {
                if (!patient) return;
                setPrescriberCommentsAcknowledged(false);
                setAcknowledgeDisabled(true);

                const mappedPatient = new PatientQueueItem(patient);
                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients]
                    var i = next.findIndex(x => x.submissionId === patient.submissionId);
                    next.splice(i, 1, mappedPatient);
                    return next.sort(sortByDate);
                });

                setCalledPatients(calledPatients => {

                    if (calledPatients && calledPatients.findIndex(x => x.submissionId === mappedPatient.submissionId && !mappedPatient.callHolding) !== -1) {
                        const next = [...calledPatients];
                        if (mappedPatient.desk !== desk) {
                            next.splice(next.findIndex(x => x.submissionId === mappedPatient.submissionId), 1);
                        } else {
                            next.splice(next.findIndex(x => x.submissionId === mappedPatient.submissionId), 1, mappedPatient);
                        }

                        return next.filter(x => x.waitingStatus === queueStatus.Waiting || x.waitingStatus === queueStatus.Called || x.waitingStatus === queueStatus.WithAssessor || x.waitingStatus === queueStatus.PatientVaccinated);
                    }

                    if (mappedPatient.desk === desk && patient.waitingStatus === queueStatus.WithAssessor && !mappedPatient.callHolding) {
                        if (mappedPatient) {
                            setFinishedWithPatientsAvailable(true);
                        }

                        return [...calledPatients, mappedPatient]
                    }

                    return [...calledPatients];
                });

                setCallHoldingPatients(callHoldingPatients => {
                    if (callHoldingPatients && callHoldingPatients.findIndex(x => x.submissionId === mappedPatient.submissionId && x.callHolding) !== -1) {
                        const next = [...callHoldingPatients];
                        if (mappedPatient.desk !== desk) {
                            next.splice(next.findIndex(x => x.submissionId === mappedPatient.submissionId), 1);
                        } else {
                            next.splice(next.findIndex(x => x.submissionId === mappedPatient.submissionId), 1, mappedPatient);
                        }

                        return next.filter(x => x.waitingStatus === queueStatus.Waiting || x.waitingStatus === queueStatus.Called || x.waitingStatus === queueStatus.WithAssessor || x.waitingStatus === queueStatus.PatientVaccinated);
                    }

                    if (callHoldingPatients && callHoldingPatients.findIndex(x => x.submissionId === mappedPatient.submissionId && !x.callHolding && !mappedPatient.callHolding) !== -1) {
                        const next = [...callHoldingPatients];
                        next.splice(next.findIndex(x => x.submissionId === mappedPatient.submissionId), 1);
                        return next.filter(x => x.waitingStatus === queueStatus.Waiting || x.waitingStatus === queueStatus.Called || x.waitingStatus === queueStatus.WithAssessor || x.waitingStatus === queueStatus.PatientVaccinated);
                    }

                    //let updatedCallHoldingPatients = callHoldingPatients.filter(chp => !calledPatients.find(cp => cp.submissionId == chp.submissionId));

                    return [...callHoldingPatients];
                })

            });

            hub.on("connected", patients => {
                const mappedPatients = patients.map(x => new PatientQueueItem(x))
                    .filter(x => x.waitingStatus === queueStatus.Waiting || x.waitingStatus === queueStatus.Called || x.waitingStatus === queueStatus.WithAssessor || x.waitingStatus === queueStatus.PatientVaccinated)
                    .sort(sortByDate);
                setWaitingPatients(mappedPatients);
                const calledPatients = mappedPatients.filter(x => x.desk === desk && !x.callHolding);
                setCalledPatients(calledPatients);
                const callHoldingPatients = mappedPatients.filter(x => x.desk === desk && x.callHolding);
                setCallHoldingPatients(callHoldingPatients);
                setLoading(false);
            });

            setHubConnection(hub);

            return hub;
        };

        const hub = createHub();

        return async () => {
            (await hub).stop();
        }

    }, [])

    const newRegistrationHandler = async (submissionIdentifier) => {

        setLoading(true);
        try {
            const token = tokenService.getToken();
            const url = "/CovidVaccinationAttendees/SubmissionLookup/" + encodeURIComponent(submissionIdentifier);
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                const patient = new PatientAppointmentItem(data);
                const linkedPatient = calledPatients.find(y => y.submissionId === submissionIdentifier);
                if (linkedPatient) {
                    patient.id = linkedPatient.id;
                    patient.arrivalTime = linkedPatient.arrivalTime;
                    patient.comments = linkedPatient.comments;
                    patient.isPriority = linkedPatient.isPriority;
                    hubConnection.invoke('updatePatient', patient, site, location);
                }

                setBasicRegistrationSubmissionId('');
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const sortByDate = (a, b) => {
        if (moment(a.arrivalTime).diff(moment(b.arrivalTime), "milliseconds") > 0) {
            return 1
        }
        return -1;
    }

    const getDoseKeyFromName = (name) => {
        let appointmentKey = '';
        switch (name) {
            case "1st":
                appointmentKey = "first";
                break;
            case "2nd":
                appointmentKey = "second";
                break;
            case "3rd":
                appointmentKey = "third";
                break;
            case "Booster":
                appointmentKey = "boost1";
                break;
            case "Flu":
                appointmentKey = "flu1";
                break;
        }
        return appointmentKey;
    }

    const sendRequestToPrescriber = (submission, stream, currentPsd, additionalPsd) => {
        if (additionalPsd) {
            const nextPatient = calledPatients.find(cp => cp.submissionId === submission.submissionIdentifier);
            const doseName = getDoseKeyFromName(additionalPsd);
            nextPatient.previousPsd = currentPsd;
            nextPatient.originalPsd = currentPsd;
            nextPatient.activePsd = doseName;
            nextPatient.stream = stream;
            hubConnection.invoke('updatePatient', nextPatient, site, location);
            setCurrentPsdDose(doseName);
            openSubmission(nextPatient, "screening");
            setPrescriberCommentsAcknowledged(false);
            setAcknowledgeDisabled(true);
        }
        else {
            if (psdRole === "screening") {
                var patient = calledPatients.find(x => x.submissionId == submission.submissionIdentifier);
                if (patient) {
                    patient.activePsd = currentPsd;
                    if (!patient.originalPsd) {
                        patient.originalPsd = currentPsd;
                    }
                    // If there are no additional psds specified and the active psd is flu and original psd is flu
                    // then any previous psd that might have been originally stored is no longer required, so clear this
                    if (patient.originalPsd === "flu1" && patient.activePsd === "flu1") {
                        patient.previousPsd = "";
                    }
                    if (patient.stream !== 'Red') {
                        patient.stream = stream;
                    }
                    setFinishedWithPatientsAvailable(false);
                    hubConnection.invoke('SendPatientToPrescriber', patient, site, location);
                }
            }
            setShowPatientSelection(false);
            setSubmission(null);
            setPrescriberCommentsAcknowledged(false);
            setAcknowledgeDisabled(true);
        }
    }

    const cancelRequestToPrescriber = (submission) => {
        hubConnection.invoke('FinishedPrescribing', submission, site, location, false, false);
        setPrescriberCommentsAcknowledged(false);
        setAcknowledgeDisabled(true);
    }

    const finishedVaccination = (submission, vaccinated, currentPsd, additionalPsd) => {
        if (additionalPsd) {
            const nextPatient = calledPatients.find(cp => cp.submissionId === submission.submissionIdentifier);
            const doseName = getDoseKeyFromName(additionalPsd);
            nextPatient.previousPsd = currentPsd;
            nextPatient.originalPsd = currentPsd;
            nextPatient.activePsd = doseName;
            if (psdRole === "screening" && !vaccinated) {
                if (currentPsd === "flu1") {
                    nextPatient.prescriberOutcomeFlu = "Not Eligible";
                } else {
                    nextPatient.prescriberOutcome = "Not Eligible";
                }
            }
            if (psdRole === "administering") {
                if (currentPsd === "flu1") {
                    nextPatient.hasBeenVaccinatedFlu = vaccinated;
                } else {
                    nextPatient.hasBeenVaccinated = vaccinated;
                }
            }
            hubConnection.invoke('updatePatient', nextPatient, site, location);
            setCurrentPsdDose(doseName);
            if (psdRole === "screening") {
                openSubmission(nextPatient, "screening");
            } else {
                openSubmission(nextPatient, "administering");
            }
        }
        else {
            const patient = calledPatients.find(p => p.submissionId == submission.submissionIdentifier)

            if (patient) {
                if (vaccinated) {
                    hubConnection.invoke('patientVaccinated', patient, site, location);
                } else {
                    hubConnection.invoke('ClearPatientVaccinated', patient, site, location);
                }

                setShowPatientSelection(false);
                setSubmission(null);
                hubConnection.invoke('PatientLeft', patient, site, location);
            }
        }

    }

    const cancelScreening = (submission) => {
        const patient = calledPatients.find(p => p.submissionId == submission.submissionIdentifier)

        if (patient) {
            const currentActivePsd = patient.activePsd;
            patient.activePsd = patient.previousPsd;
            patient.previousPsd = currentActivePsd;
            hubConnection.invoke('updatePatient', patient, site, location);
            setCurrentPsdDose(patient.activePsd);
            openSubmission(patient, "screening");
            setPrescriberCommentsAcknowledged(false);
            setAcknowledgeDisabled(true);
        }
    }

    const cancelAdministering = (submission) => {
        const patient = calledPatients.find(p => p.submissionId == submission.submissionIdentifier)

        if (patient) {
            const currentActivePsd = patient.activePsd;
            patient.activePsd = patient.previousPsd;
            patient.previousPsd = currentActivePsd;
            hubConnection.invoke('updatePatient', patient, site, location);
            setCurrentPsdDose(patient.activePsd);
            openSubmission(patient, "administering");
            setPrescriberCommentsAcknowledged(false);
            setAcknowledgeDisabled(true);
        }
    }

    const finishedWithPatient = (patient) => {
        hubConnection.invoke('PatientLeft', patient, site, location);
    }

    const getPatientMarkUp = (patient, i) => {
        return <li key={i} className="list-group-item">
            <Button style={{ 'marginRight': "20px" }} onClick={() => callPatient(patient)} color="success" title="Call" disabled={(disconnected || reconnecting)}><FontAwesomeIcon icon={faPhone} />&nbsp;Call</Button>
            <span>({<Timer startTime={patient.arrivalTime} />})&nbsp;</span>
            <span>{patient.patientName}&nbsp;</span>
            <span style={{ 'marginRight': "20px" }}>
                {patient.isPriority && <FontAwesomeIcon icon={faExclamation} size="1x" color="red" title="Priority Patient"></FontAwesomeIcon>}
            </span>
            <FontAwesomeIcon className="pointer blueIcon" icon={patient.comments.length > 0 ? faComment : farComment} onClick={() => openModalComments(patient)} title="Comment" />
            {patient.isLinkParent && patient.linkedSubmissions.length && <span style={{ marginLeft: "10px" }}>
                <FontAwesomeIcon icon={faLink} className="blueIcon"></FontAwesomeIcon>
                <Badge>{patient.linkedSubmissions.length}</Badge>
            </span>}
        </li>
    }

    const getPatientCallHoldingMarkUp = (patient, i) => {
        return <li key={i} className="list-group-item">
            <Button style={{ 'marginRight': "20px" }} onClick={() => callPatient(patient)} color="success" title="Call" disabled={calledPatients.length > 0 || (disconnected || reconnecting)}><FontAwesomeIcon icon={faPhone} />&nbsp;Call Up</Button>
            <span>({<Timer startTime={patient.arrivalTime} />})&nbsp;</span>
            <span>{patient.patientName}&nbsp;</span>
            <span style={{ 'marginRight': "20px" }}>
                {patient.isPriority && <FontAwesomeIcon icon={faExclamation} size="1x" color="red" title="Priority Patient"></FontAwesomeIcon>}
            </span>
            <FontAwesomeIcon className="pointer blueIcon" icon={patient.comments.length > 0 ? faComment : farComment} onClick={() => openModalComments(patient)} title="Comment" />
            {patient.isLinkParent && patient.linkedSubmissions.length && <span style={{ marginLeft: "10px" }}>
                <FontAwesomeIcon icon={faLink} className="blueIcon"></FontAwesomeIcon>
                <Badge>{patient.linkedSubmissions.length}</Badge>
            </span>}
            <Button onClick={() => cancelCall(patient)} color="danger" block disabled={disconnected}>Cancel Call (send to waiting)</Button>
        </li>
    }

    const anyPatientsWithAssessor = useCallback(() => {
        return calledPatients.filter(x => { return x.waitingStatus === queueStatus.WithAssessor }).length !== 0
        }, [calledPatients]
    );

    if (loading || checkingAccess) {
        return (<></>)
    }

    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    const currentPatient = calledPatients.find(x => x.submissionId == (submission ? submission.submissionIdentifier : ''));
    let showingAsAdditionalPsd = false;
    let hasAdditionalPsd = false;
    if (currentPatient) {
        if (currentPatient.originalPsd && currentPatient.activePsd && currentPatient.previousPsd) {
            if (currentPatient.activePsd !== currentPatient.originalPsd) {
                showingAsAdditionalPsd = true;
                hasAdditionalPsd = true;
            }
            if (psdRole === "administering" && currentPatient.activePsd === "flu1" && currentPatient.previousPsd !== "flu1") {
                showingAsAdditionalPsd = true;
            }
            if (psdRole === "administering" && currentPatient.activePsd !== "flu1") {
                showingAsAdditionalPsd = false;
            }
        }
    }

    return (
        <div className="assessorContainer">
            {disconnected && <div className="disconnect-warning">Your client has disconnected. Please refresh the browser as soon as possible to avoid queue management issues.</div>}
            {reconnecting && <div className="disconnect-warning">Your client has disconnected and is trying to reconnect... <Spinner /></div>}
            <Modal isOpen={modalOpen}>
                <ModalHeader>Set Comments:</ModalHeader>
                <ModalBody>
                    <Input type="textarea" value={modalComments} onChange={(e) => setModalComments(e.target.value)}></Input>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { saveModalComments() }}>Save</Button>
                    <Button onClick={() => { setModalOpen(false); setModalComments("") }} >Close</Button>
                </ModalFooter>
            </Modal>

            <Row className="assessorMainRow">
                {calledPatients.length === 0 && callHoldingPatients.length === 0 && <>
                    <Col xs="12" style={{ padding: '10px', 'border': 'solid 4px #41B6E6', background: '#231f20', height: '100vh' }}>
                        <Row>
                            <Col xs="4">
                                <Card className="cardHeight">
                                    <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Next Patient</CardHeader>
                                    <CardBody style={{ overflowY: 'scroll' }}>
                                        <ul className="list-unstyled">
                                            {waitingPatients
                                                .filter(x => x.waitingStatus === queueStatus.Waiting && x.isPatient && (x.isLinkParent || x.linkedSubmissions.length === 0)).map((patient, i) => getPatientMarkUp(patient, i))}
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="4">
                                <Card className="cardHeight">
                                    <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Next HCW</CardHeader>
                                    <CardBody style={{ overflowY: 'scroll' }}>
                                        <ul className="list-unstyled">
                                            {waitingPatients.filter(x => x.waitingStatus === queueStatus.Waiting && x.isHcw && !x.isStaff && (x.isLinkParent || x.linkedSubmissions.length === 0)).map((patient, i) => getPatientMarkUp(patient, i))}
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="4">
                                <Card className="cardHeight">
                                    <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Next Staff</CardHeader>
                                    <CardBody style={{ overflowY: 'scroll' }}>
                                        <ul className="list-unstyled">
                                            {waitingPatients.filter(x => x.waitingStatus === queueStatus.Waiting && x.isStaff && (x.isLinkParent || x.linkedSubmissions.length === 0)).map((patient, i) => getPatientMarkUp(patient, i))}
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </>
                }
                {(calledPatients.length !== 0 || callHoldingPatients.length !== 0) && <>{<>
                    <Col id="assessorNav" xs="3" style={{ background: 'rgb(232, 237, 238)', 'marginTop': '20px' }}>
                        <div>Prescriber Queue: {waitingPatients.filter(x => x.withPrescriber).length}</div>
                        {calledPatients.length !== 0 && <Card>
                            <CardHeader>My Patients</CardHeader>
                            <CardBody>
                                {calledPatients.filter(x => { return x.waitingStatus === queueStatus.Called }).length !== 0 && <Button style={{ marginBottom: "20px" }} onClick={() => markPatientWithAssessor()} color="success" block disabled={(disconnected || reconnecting)}>Arrive Patient{calledPatients.length > 1 && "s"}</Button>}
                                <ListGroup>
                                    {calledPatients.map((cp, i) => <ListGroupItem key={i}>
                                        <div>Name: <strong>{cp.patientName}</strong></div>
                                        <div>{cp.nhsNumber ? <span>NHS #: <strong>{cp.nhsNumber}</strong></span> : 'No NHS #'}</div>
                                        <div>1st Appt: <strong>{cp.firstAppointmentDate ? (cp.firstAppointmentDate + " @ " + cp.firstAppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>2nd Appt: <strong>{cp.secondAppointmentDate ? (cp.secondAppointmentDate + " @ " + cp.secondAppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>3rd Appt: <strong>{cp.thirdAppointmentDate ? (cp.thirdAppointmentDate + " @ " + cp.thirdAppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>Booster Appt: <strong>{cp.boost1AppointmentDate ? (cp.boost1AppointmentDate + " @ " + cp.boost1AppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>Flu Appt: <strong>{cp.flu1AppointmentDate ? (cp.flu1AppointmentDate + " @ " + cp.flu1AppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div><br />
                                        </div>


                                        {!cp.withPrescriber && (cp.waitingStatus == "WithAssessor" || cp.waitingStatus === queueStatus.PatientVaccinated) && <>
                                            {(!cp.prescriberOutcome && !cp.prescriberOutcomeFlu) && <Button onClick={() => openSubmission(cp, "screening")} color="primary" block disabled={(disconnected || reconnecting)}>Open Screening PSD</Button>}
                                            {cp.prescriberOutcome && cp.prescriberOutcome === "Approved" && <>
                                                <Alert color="success">COVID-19: Approved {cp.prescriberComments ? <FontAwesomeIcon icon={faComment} title="Prescriber has made comments."></FontAwesomeIcon> : null} {cp.hasAlternateDose ? <FontAwesomeIcon icon={faSyringe} title="Vaccine dose needs review."></FontAwesomeIcon> : null}</Alert>
                                            </>}
                                            {cp.prescriberOutcome && cp.prescriberOutcome === "Deferred" && <>
                                                <Alert color="danger">COVID-19: Deferred {cp.prescriberComments ? <FontAwesomeIcon icon={faComment} title="Prescriber has made comments."></FontAwesomeIcon> : null}</Alert>
                                            </>}
                                            {cp.prescriberOutcome && cp.prescriberOutcome === "Not Eligible" && <>
                                                <Alert color="danger">COVID-19: Not Eligible</Alert>
                                            </>}
                                            {cp.prescriberOutcome && cp.prescriberOutcome === "DiscussionNeeded" && <>
                                                <Alert color="warning">COVID-19: Clinical Review Required</Alert>
                                            </>}
                                            

                                            {cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "Approved" && <>
                                                <Alert color="success">Influenza: Approved {cp.prescriberCommentsFlu ? <FontAwesomeIcon icon={faComment} title="Prescriber has made comments."></FontAwesomeIcon> : null} {cp.hasAlternateDoseFlu ? <FontAwesomeIcon icon={faSyringe} title="Vaccine dose needs review."></FontAwesomeIcon> : null}</Alert>
                                            </>}
                                            {cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "Deferred" && <>
                                                <Alert color="danger">Influenza: Deferred {cp.prescriberCommentsFlu ? <FontAwesomeIcon icon={faComment} title="Prescriber has made comments."></FontAwesomeIcon> : null}</Alert>
                                            </>}
                                            {cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "Not Eligible" && <>
                                                <Alert color="danger">Influenza: Not Eligible</Alert>
                                            </>}
                                            {cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "DiscussionNeeded" && <>
                                                <Alert color="warning">Influenza: Clinical Review Required</Alert>
                                            </>}

                                            {(cp.prescriberOutcome && cp.prescriberOutcome === "Deferred") && <>
                                                <Button onClick={() => openSubmission(cp, "screening")} color="primary" block disabled={(disconnected || reconnecting)}>Re-Open Screening PSD</Button>
                                            </>}
                                            {(cp.prescriberOutcome && cp.prescriberOutcome === "DiscussionNeeded") && <>
                                                <Button onClick={() => openSubmission(cp, "screening")} color="primary" block disabled={(disconnected || reconnecting)}>Re-Open Screening PSD</Button>
                                            </>}
                                            {(cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "Deferred") && !(cp.prescriberOutcome && cp.prescriberOutcome === "Deferred") && <>
                                                <Button onClick={() => openSubmission(cp, "screening")} color="primary" block disabled={(disconnected || reconnecting)}>Re-Open Screening PSD</Button>
                                            </>}
                                            {(cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "DiscussionNeeded") && <>
                                                <Button onClick={() => openSubmission(cp, "screening")} color="primary" block disabled={(disconnected || reconnecting)}>Re-Open Screening PSD</Button>
                                            </>}

                                            {((cp.prescriberOutcome && cp.prescriberOutcome === "Approved") || (cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "Approved")) && <>
                                                <Button onClick={() => openSubmission(cp, "administering")} color="primary" block disabled={(disconnected || reconnecting)}>Open administering PSD</Button>
                                            </>}
                                            <Modal isOpen={!prescriberCommentsAcknowledged && !submission && (cp.prescriberOutcome === "Approved" || cp.prescriberOutcomeFlu === "Approved") && ((cp.prescriberComments || cp.prescriberCommentsFlu) || (cp.hasAlternateDose || cp.hasAlternateDoseFlu))}>
                                                <ModalHeader>Attention!</ModalHeader>
                                                <ModalBody>
                                                    <p>Concerning patient: <strong>{cp.patientName}</strong></p>
                                                    {(cp.hasAlternateDose || cp.hasAlternateDoseFlu) && <p className="questionnaire-error-attention"><h2 className="questionnaire-error">DOSE ALERT!</h2>Please review the dose for the COVID-19 vaccine before administering.</p>}
                                                    {(cp.prescriberComments || cp.prescriberCommentsFlu) && <p className="questionnaire-warning-attention">Please review prescriber comments before administering the vaccine.</p>}
                                                    <p>Please acknowledge by clicking below, which will open the PSD.</p>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="success" disabled={ acknowledgeDisabled } onClick={() => { setPrescriberCommentsAcknowledged(true); openSubmission(cp, "administering"); }}>Acknowledge</Button>
                                                </ModalFooter>
                                            </Modal>
                                        </>}

                                        {cp.withPrescriber && <>
                                            {cp.prescriberOutcome && cp.prescriberOutcome === "DiscussionNeeded" && <>
                                                <Alert color="warning">COVID-19: Clinical Review Required</Alert>
                                            </>}
                                            {cp.prescriberOutcomeFlu && cp.prescriberOutcomeFlu === "DiscussionNeeded" && <>
                                                <Alert color="warning">Influenza: Clinical Review Required</Alert>
                                            </>}
                                            <Button onClick={() => cancelRequestToPrescriber(cp)} color="danger" block disabled={ disconnected }>
                                                <FontAwesomeIcon icon={faSpinner} pulse />&nbsp;
                                                Cancel Request to Prescriber&nbsp;&nbsp;{cp.startedPrescribingName ? <FontAwesomeIcon title={"Being reviewed by " + cp.startedPrescribingName} icon={faEye} /> : ""}
                                                </Button>
                                        </>}

                                        {((cp.prescriberOutcome !== "Approved" || cp.prescriberOutcome === "Deferred") || (cp.prescriberOutcomeFlu !== "Approved" || cp.prescriberOutcomeFlu === "Deferred")) && <Button color={(cp.hasBeenVaccinated || cp.hasBeenVaccinatedFlu) ? "success" : "warning"} onClick={() => finishedWithPatient(cp)} block disabled={ (disconnected || reconnecting) }>Finished With Patient ({(cp.hasBeenVaccinated || cp.hasBeenVaccinatedFlu) ? 'Vaccinated' : 'Not Vaccinated'})</Button>}
                                    </ListGroupItem>
                                    )}
                                </ListGroup>
                            </CardBody>
                            <CardFooter>
                                {errorSaving && <Alert color="danger">Problem saving record. Please try again.</Alert>}
                                <Button onClick={() => cancelCall()} color="danger" block disabled={ (disconnected || reconnecting) }>Cancel Call (send to waiting)</Button>
                                {savedSuccess && <Alert style={{ marginTop: '20px' }} color="success">Saved.</Alert>}
                            </CardFooter>
                        </Card>}
                        {callHoldingPatients.length !== 0 && <Card>
                            <CardHeader>Call Holding Queue</CardHeader>
                            <CardBody>
                                {callHoldingPatients.filter(x => x.isLinkParent || x.linkedSubmissions.length === 0).map((patient, i) => getPatientCallHoldingMarkUp(patient, i))}
                            </CardBody>
                        </Card>}
                    </Col>
                    {
                        <Col style={{ background: '#E8EDEE', maxHeight: '100%' }}>
                            {!showNewRegistration && submission &&
                                <div>
                                <ViewQuestionnaireSubmission submission={submission} saveChanges={saveChangesHandler} viewAsPsd={true} psdRole={psdRole} additionalPsd={showingAsAdditionalPsd} multiplePsds={hasAdditionalPsd} currentDose={currentPatient ? currentPatient.activePsd : ''} originalDose={currentPatient ? currentPatient.originalPsd : ''} returnCallback={(success, stream, currentPsd, additionalPsd) => { if (success) { sendRequestToPrescriber(submission, stream, currentPsd, additionalPsd); } }} role={psdRole} finishedVaccinationCallback={(vaccinated, currentPsd, additionalPsd) => finishedVaccination(submission, vaccinated, currentPsd, additionalPsd)} cancelScreeningCallback={() => cancelScreening(submission)} cancelAdministeringCallback={() => cancelAdministering(submission)} />
                                </div>
                            }
                            {errorAuthenticating && <Card>
                                <CardBody>
                                    <div>There was a problem loading the screening questions <Button onClick={() => window.location.reload()}>Click Here to Reload</Button></div>
                                </CardBody>
                            </Card>
                            }
                            {showNewRegistration && <div><Questionnaire submissionIdentifier={basicRegistrationSubmissionId} saveCallback={(si) => newRegistrationHandler(si)} /></div>}
                            {!showNewRegistration && !submission && !errorAuthenticating && !showPatientSelection && !disableMultiPatientCalling && <Row><Col xs="12"><div>&nbsp;</div><Button color="primary" onClick={(e) => setShowPatientSelection(true)}>Call Additional Patients...</Button></Col></Row>}
                            {!showNewRegistration && !submission && !errorAuthenticating && showPatientSelection && !disableMultiPatientCalling && <Col xs="12" style={{ padding: '10px', 'border': 'solid 4px #41B6E6', background: '#231f20', height: '100vh' }}>
                                <Row>
                                    <Col xs="4">
                                        <Card className="cardHeight">
                                            <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Next Patient</CardHeader>
                                            <CardBody style={{ overflowY: 'scroll' }}>
                                                <ul className="list-unstyled">
                                                    {waitingPatients
                                                        .filter(x => x.waitingStatus === queueStatus.Waiting && x.isPatient && (x.isLinkParent || x.linkedSubmissions.length === 0)).map((patient, i) => getPatientMarkUp(patient, i))}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="4">
                                        <Card className="cardHeight">
                                            <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Next HCW</CardHeader>
                                            <CardBody style={{ overflowY: 'scroll' }}>
                                                <ul className="list-unstyled">
                                                    {waitingPatients.filter(x => x.waitingStatus === queueStatus.Waiting && x.isHcw && !x.isStaff && (x.isLinkParent || x.linkedSubmissions.length === 0)).map((patient, i) => getPatientMarkUp(patient, i))}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="4">
                                        <Card className="cardHeight">
                                            <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Next Staff</CardHeader>
                                            <CardBody style={{ overflowY: 'scroll' }}>
                                                <ul className="list-unstyled">
                                                    {waitingPatients.filter(x => x.waitingStatus === queueStatus.Waiting && x.isStaff && (x.isLinkParent || x.linkedSubmissions.length === 0)).map((patient, i) => getPatientMarkUp(patient, i))}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>}
                        </Col>
                    }
                </>
                }</>
                }
                <LoginView nonBanner />
            </Row>
        </div >)
}
