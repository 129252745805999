import React, { useState, useEffect } from 'react';
import { useInterval } from '../../hooks/useInterval';
import { API_VERSION, API_VERSION_REFRESH_INTERVAL } from '../../constants/appConst';

export const ApiVersionChecker = () => {

    const [apiVersion, setApiVersion] = useState(false);
    const [isMismatch, setIsMismatch] = useState(false);

    const fetchVersion = async () => {
        const apiResponse = await fetch('/Version');
        if (apiResponse.ok) {
            const data = await apiResponse.text();
            if (data && data.length < 100) {
                setApiVersion(data);
            }            
        }
    }

    useInterval(async () => {
        await fetchVersion();
    }, API_VERSION_REFRESH_INTERVAL)

    useEffect(() => {
        if (apiVersion) {
            if (apiVersion !== API_VERSION) {
                setIsMismatch(true);
            } else {
                setIsMismatch(false);
            }
        }
        
    }, [apiVersion])

    useEffect(() => {
        fetchVersion()
    }, [])

    if (isMismatch) {
        return <div className="version-mismatch-warning">You are using an out-dated version ({API_VERSION}) of the portal. New version is {apiVersion}. Please refresh your browser at your earliest convenience to avoid any issues.</div>
    }
    return null;
}