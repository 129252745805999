import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Button, Row, Alert, Jumbotron, Modal, ModalHeader, ModalBody, ModalFooter, Input, Badge, ListGroup, ListGroupItem } from 'reactstrap';
import Spinner from '../../common/Spinner';
import { signalRUtil } from '../../../utils/signalRUtil';
import tokenService from '../../../services/tokenService';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import ViewQuestionnaireSubmission from '../results/ViewQuestionnaireSubmission';
import LoginView from '../../common/LoginView';
import isInRole from '../../../utils/isInRole';

import Questionnaire from '../Questionnaire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { HubConnection } from '@microsoft/signalr';

export const QueuePrescriber = ({ match, getUser, requiredRoles }) => {

    const questionnaireKey = 'COVID Vaccination';

    const { site, location, desk } = match.params;
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const [userName, setUserName] = useState('');
    const [prescriberConflict, setPrescriberConflict] = useState(false);
    const [patientQueue, setPatientQueue] = useState([]);
    const [submission, setSubmission] = useState(null);
    const [timer, setTimer] = useState(new Date());
    const [hubConnection, setHubConnection] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(true);
    const [submissionSaved, setSubmissionSaved] = useState(false);
    const [submissionPrinted, setSubmissionPrinted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalComments, setModalComments] = useState("");
    const [selectedPatient, setSelectedPatient] = useState("");
    const [avgWaitingTime, setAvgWaitingTime] = useState();
    const [showNewRegistration, setShowNewRegistration] = useState(false);

    const [disconnected, setDisconnected] = useState(false);
    const [reconnecting, setReconnecting] = useState(false);
    const [reconnected, setReconnected] = useState(false);

    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);

    useEffect(() => {
        const checkAccess = async () => {
            setCheckingAccess(true);
            
            if (isInRole(requiredRoles)) {
                setHasRequiredRoles(true);
                setCheckingAccess(false);
            } else {
                setHasRequiredRoles(false);
                setCheckingAccess(false);
            }
        }
        if (getUser) {
            checkAccess();
        }
    }, [getUser])

    const openSubmission = async (submission) => {

        if (!submission) {
            setSubmission(null);
            return;
        }

        setShowNewRegistration(false);
        setSubmissionSaved(false);
        setErrorSaving(false);
        setSubmissionPrinted(false);
        setSubmission(null);

        try {
            const token = tokenService.getToken();
            const response = await fetch('/QuestionnaireAnswers/' + questionnaireKey + '/' + submission.submissionId, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSubmission(data);
                var patient = patientQueue.find(x => x.submissionId == data.submissionIdentifier);
                hubConnection.invoke('StartedPrescribing', patient, site, location);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
                setSubmission(null);
            } else {
                setErrorRetrieving(true);
                setSubmission(null);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            //  setLoading(false);
        }
    }


    const saveChangesHandler = async (changes, afterSaveCallback) => {
        setErrorSaving(false);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers';

            const data = {
                questionnaireName: questionnaireKey,
                submissionIdentifier: submission.submissionIdentifier,
                questionAnswers: []
            };
            const method = changes.deleted === true ? 'DELETE' : 'PUT';
            delete changes.deleted;
            const changeKeys = Object.keys(changes);

            if (changeKeys.length > 0) {
                changeKeys.map(a => data.questionAnswers.push({ questionKey: a, answers: [changes[a]] }));

                const response = await fetch(url, {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {
                    setSubmissionSaved(true);
                    setErrorSaving(false);
                    if (afterSaveCallback) {
                        afterSaveCallback(true);
                    }
                } else {
                    setErrorSaving(true);
                    if (afterSaveCallback) {
                        afterSaveCallback(false);
                    }
                }
            } else {
                setSubmissionSaved(true);
                setErrorSaving(false);
                if (afterSaveCallback) {
                    afterSaveCallback(true);
                }
            }
        }
        catch (err) {
            console.log(err);
            setErrorSaving(true);
            if (afterSaveCallback) {
                afterSaveCallback(false);
            }
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        window.onafterprint = function () {
            setSubmissionPrinted(true);
        }

        const createHub = async () => {
            const hub = await signalRUtil.getHubConnection(site, location, "Prescriber", "");

            hub.onclose(err => {
                console.error("Hub connection closed. " + err);
                setDisconnected(true);
                setReconnecting(false);
                setReconnected(false);
            });
            hub.onreconnecting(err => {
                console.error("Hub connection re-connecting... " + err);
                setReconnected(false);
                setReconnecting(true);
            });
            hub.onreconnected(() => {
                setReconnected(true);
                setReconnecting(false);
                setDisconnected(false);
            });

            hub.on("PatientUpdated", patient => {
                if (!patient) return;

                const mappedPatient = new PatientQueueItem(patient);

                if (mappedPatient.withPrescriber) {
                    // add or update
                    setPatientQueue(patientQueue => {
                        const next = [...patientQueue]
                        var i = next.findIndex(x => x.submissionId === patient.submissionId);
                        if (i !== -1) {
                            next.splice(i, 1, mappedPatient)
                            return next;
                        } else {
                            return [...next, mappedPatient];
                        }
                    })
                    const checkPrescriberConflict = async () => {
                        let submissionId = '';
                        setSubmission(submission => {
                            submissionId = submission && submission.submissionIdentifier;
                            return submission;
                        });

                        const loggedInUser = await getUser();
                        if (mappedPatient.submissionId === submissionId && mappedPatient.startedPrescribingName && mappedPatient.startedPrescribingName !== loggedInUser.profile.upn) {
                            setPrescriberConflict(true);
                        } else {
                            setPrescriberConflict(false);
                        }
                    }
                    checkPrescriberConflict();
                } else {
                    setPatientQueue(patientQueue => {
                        const next = [...patientQueue]
                        var i = next.findIndex(x => x.submissionId === patient.submissionId);

                        if (i !== -1) {
                            next.splice(i, 1)
                            return next;
                        } else {
                            return next;
                        }

                    })
                    setSubmission(submission => {
                        if (submission && submission.submissionIdentifier === patient.submissionId) {
                           return null;
                        }

                        return submission;
                    })

                }
            });

            hub.on("connected", patients => {
                const mappedPatients = patients.map(x => new PatientQueueItem(x))
                    .filter(x => x.withPrescriber)
                    .sort(sortByDate);
                setPatientQueue(mappedPatients);
                setLoading(false);
            });

            setHubConnection(hub);
            return hub;
        };

        const hub = createHub();

        return async () => { 
            (await hub).stop(); 
        }

    }, [])

    const sortByDate = (a, b) => {
        if (moment(a.arrivalTime).diff(moment(b.arrivalTime), "milliseconds") > 0) {
            return 1
        }
        return -1;
    }

    const returnPatientToAssessor = (success, outcome, preventAdditional, hasComments, hasAlternateDose) => {
        if (success) {
            var patient = patientQueue.find(x => x.submissionId == submission.submissionIdentifier);
            if (outcome === "SendToRedStream") {
                patient.stream = "Red";
                hubConnection.invoke("UpdateStream", patient, site, location);
                setSubmission(null);
            }
            else {
                let previousPsd = patient.previousPsd;
                const activePsd = patient.activePsd;
                if (patient.activePsd === "flu1") {
                    patient.prescriberOutcomeFlu = outcome;
                } else {
                    patient.prescriberOutcome = outcome;
                }
                if (previousPsd && previousPsd !== activePsd && activePsd === "flu1" && outcome !== "DiscussionNeeded") {
                    patient.activePsd = patient.previousPsd;
                    patient.previousPsd = previousPsd;
                }
                if (preventAdditional) {
                    previousPsd = '';
                    patient.previousPsd = '';
                }
                if (patient) {
                    if (outcome === "DiscussionNeeded") {
                        hubConnection.invoke('updatePatient', patient, site, location);
                    } else {
                        hubConnection.invoke('FinishedPrescribing', patient, site, location, hasComments, hasAlternateDose);
                        if (previousPsd && previousPsd !== activePsd && activePsd !== "flu1" && patient.prescriberOutcomeFlu !== "Not Eligible") {
                            openSubmission(patient);
                        }
                    }
                }
            }
            
        }
    }

    const cancelPrescribing = (submission) => {
        const patient = patientQueue.find(p => p.submissionId == submission.submissionIdentifier)

        if (patient) {
            const currentActivePsd = patient.activePsd;
            patient.activePsd = patient.previousPsd;
            patient.previousPsd = currentActivePsd;
            hubConnection.invoke('updatePatient', patient, site, location);
            openSubmission(patient, "screening");
        }
    }

    if (loading || checkingAccess) {
        return (<></>)
    }

    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    const currentPatient = patientQueue.find(x => x.submissionId == (submission ? submission.submissionIdentifier : ''));
    let showingAsAdditionalPsd = false;
    let hasAdditionalPsd = false;
    if (currentPatient) {
        if (currentPatient.originalPsd && currentPatient.activePsd && currentPatient.previousPsd) {
            if (currentPatient.activePsd !== currentPatient.previousPsd) {
                hasAdditionalPsd = true;
            }
            if (currentPatient.activePsd !== currentPatient.originalPsd && currentPatient.prescriberOutcomeFlu !== "Not Eligible") {
                showingAsAdditionalPsd = true;
            }
            if (currentPatient.activePsd === "flu1") {
                showingAsAdditionalPsd = true;
            } else {
                showingAsAdditionalPsd = false;
            }
        }
    }

    return (
        <div className="assessorContainer">
            {disconnected && <div className="disconnect-warning">Your client has disconnected. Please refresh the browser as soon as possible to avoid queue management issues.</div>}
            {reconnecting && <div className="disconnect-warning">Your client has disconnected and is trying to reconnect... <Spinner /></div>}
            <Row className="assessorMainRow">
                <Col xs={3}></Col>
                <Col id="assessorNav" xs="3" style={{ padding: '10px', 'border': 'solid 4px #41B6E6', background: '#231f20', height: '100vh', position: 'fixed' }}>
                    <Card className="cardHeight">
                        <CardHeader style={{ background: '#0072CE', color: '#fff' }}>Patients</CardHeader>
                        <CardBody style={{ overflowY: 'scroll' }}>
                            <ListGroup>
                                {patientQueue
                                    .map((patient, i) => <ListGroupItem key={i}>
                                        <div style={{ width: "100%", display:"block", backgroundColor: patient.stream, color:"#FFF", padding:"2px", textAlign:"center" }}>
                                            Stream: {patient.stream}
                                        </div>
                                        <div>Patient: <b>{patient.patientName}</b></div>
                                        <div>1st Appt: <strong>{patient.firstAppointmentDate ? (patient.firstAppointmentDate + " @ " + patient.firstAppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>2nd Appt: <strong>{patient.secondAppointmentDate ? (patient.secondAppointmentDate + " @ " + patient.secondAppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>3rd Appt: <strong>{patient.thirdAppointmentDate ? (patient.thirdAppointmentDate + " @ " + patient.thirdAppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>Booster Appt: <strong>{patient.boost1AppointmentDate ? (patient.boost1AppointmentDate + " @ " + patient.boost1AppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>Flu Appt: <strong>{patient.flu1AppointmentDate ? (patient.flu1AppointmentDate + " @ " + patient.flu1AppointmentTimeSlot) : "n/a"}</strong></div>
                                        <div>{patient.desk}</div>
                                        <div>{patient.isPatient && "Patient"}{patient.isHcw && !patient.isStaff && "Hcw"}{patient.isStaff && "Staff"}</div>
                                        <div><Button className="float-right" onClick={() => openSubmission(patient)} block color={patient.startedPrescribingName ? "warning" : "primary"} disabled={(disconnected || reconnecting)}>{patient.startedPrescribingName ? ("In review by " + patient.startedPrescribingName) : "View PSD"}</Button></div>
                                    </ListGroupItem>

                                    )}
                            </ListGroup>
                        </CardBody>
                        {errorSaving && <CardFooter><Alert color="danger">Problem saving record. Please try again.</Alert></CardFooter>}
                    </Card>
                </Col>
                <Col style={{ background: '#E8EDEE', maxHeight: '100%' }}>
                    {!showNewRegistration && submission && prescriberConflict && <div className="prescriber-conflict">Another prescriber is reviewing this record</div>}
                    {!showNewRegistration && submission &&
                        <div>
                        <ViewQuestionnaireSubmission submission={submission} saveChanges={saveChangesHandler} viewAsPsd={true} additionalPsd={showingAsAdditionalPsd} multiplePsds={hasAdditionalPsd} currentDose={currentPatient ? currentPatient.activePsd : ''} originalDose={currentPatient ? currentPatient.originalPsd : ''} returnCallback={returnPatientToAssessor} cancelPrescribingCallback={() => cancelPrescribing(submission)} role="prescriber" />
                        </div>
                    }
                    {errorAuthenticating && <Card>
                        <CardBody>
                            <div>There was a problem loading the screening questions <Button onClick={() => window.location.reload()}>Click Here to Reload</Button></div>
                        </CardBody>
                    </Card>
                    }
                </Col>
                <LoginView nonBanner />
            </Row >
        </div >)
}
