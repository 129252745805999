import React, { Component } from 'react';
import Banner from '../Banner';
import VaccineManagement from './VaccineManagement';
import { Card, CardHeader, CardBody } from 'reactstrap';
import isInRole from '../../../utils/isInRole';

export class CovidVaccineManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkingAccess: true,
            hasRequiredRoles: false
        };
    }

    async componentDidMount() {
        const { getUser, requiredRoles } = this.props;

        if (isInRole(requiredRoles)) {
            this.setState({
                checkingAccess: false,
                hasRequiredRoles: true
            });
        } else {
            this.setState({
                checkingAccess: false
            });
        }
    }

    userHasRequiredRoles = (usersRoles, requiredRoles) => {
        const matchingRoles = usersRoles.filter(ur => requiredRoles.filter(rr => ur.toLowerCase() === rr.toLowerCase()).length > 0);
        return matchingRoles.length > 0;
    };

    render() {

        if (this.state.checkingAccess) {
            return null;
        }

        if (this.state.hasRequiredRoles) {
            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} id="bannerHeader" >
                        <Banner showLogin />
                    </CardHeader>
                    <CardBody>
                        <VaccineManagement />
                    </CardBody>
                </Card>
                <footer>
                    &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust
                </footer>
            </>
            );
        } else {
            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} >
                        <Banner showLogin />
                    </CardHeader>
                    <CardBody>
                        <div>You are not authorized to view this page.</div>
                    </CardBody>
                </Card>
                <footer>
                    &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust
                </footer>
            </>)
        }
    }
}
