import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row, } from 'reactstrap';
import { signalRUtil } from '../../../utils/signalRUtil';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import { queueStatus } from './QueueStatus';


export const SignageTest = ({ match }) => {

    const { site, location } = match.params;
    const patientsToAverageOver = 10;

    const [calledPatients, setCalledPatients] = useState([]);
    const [avgWaitingTimeInMinutes,setAvgWaitingTimeInMinutes] = useState(0);
    const [time, setTime] = useState(new Date());
    const [waitingPatients, setWaitingPatients] = useState([]);

    const getClass = (time) => {
        if (time === null) {
            return '';
        }

        var diff = moment().diff(time, 'seconds');

        if (diff < 10) {
            return 'new';
        }

        if (diff < 20) {
            return 'routine'
        }

        return 'urgent';
    }

    useEffect(() => {
        let diff = 0;
        let i = 0
        for(i; i < patientsToAverageOver && i < calledPatients.length; i++){
            diff += calledPatients[i].callingTime.diff(new moment(calledPatients[i].arrivalTime),'minutes');
        }
        if(i > 0) {
            setAvgWaitingTimeInMinutes(Math.round(diff/i))
        } else {
            setAvgWaitingTimeInMinutes(0);
        }
        
    }, [calledPatients])

    useEffect(() => {
        setInterval(() => {
            setTime(new Date());
        }, 1000)

        const createHub = async () => {
            const hub = await signalRUtil.getHubConnection(site, location);

            hub.on("patientArrived", patient => {
                if (!patient) return;

                setWaitingPatients(waitingPatients => [...waitingPatients, new PatientQueueItem(patient)]);
            });

            hub.on("patientArrivalCancelled", patient => {
                if (!patient) return;

                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients]
                    var i = next.findIndex(x => x.submissionId === patient.submissionId);
                    next.splice(i, 1);
                    return next;
                })
            });

            hub.on("PatientUpdated", patient => {
                if (!patient) return;

                const mappedPatient = new PatientQueueItem(patient);
                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients]
                    var i = next.findIndex(x => x.submissionId === patient.submissionId);
                    next.splice(i, 1, mappedPatient);
                    return next;
                });
                if(mappedPatient.callingTime) {
                    setCalledPatients(calledPatients => { 
                        return [mappedPatient, ...calledPatients]
                    })
                }
            });

            hub.on("connected", patients => {
                const mappedPatients = patients.map(x => new PatientQueueItem(x));
                setWaitingPatients(mappedPatients);
                setCalledPatients(mappedPatients.filter(x => x.callingTime).sort((a,b) => b.callingTime.valueOf() - a.callingTime.valueOf()))
            });
        };

        createHub();
        
    }, [])

    return (<div style={{ display: 'flex', flexFlow: 'column', height: '100vh' }}>

        <Row style={{ color: "#fff" }}>
            <Col xs="2" style={{ height: '100vh', background: '#E8EDEE', paddingRight: "0px", 'border-right': 'solid 4px #41B6E6'}}>
                <div className="logoPanel" style={{}}>
                    <img src="/images/GSTT-logo-2017-colour-no-background.png" alt="GSTT"  className="center responsive" style={{}} />
                </div>

                <div style={{ color: "#444", marginTop: "10px", textAlign: "center" }} >
                    <span style={{ fontSize: "5vw" }}>
                        {time.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5vw" }}>
                        {moment().format('MMMM Do YYYY')}
                    </span>
                </div>

                <div style={{ color: "#444", marginTop: "10px", textAlign: "center" }} >
                    <span style={{ fontSize: "1.5vw" }}>
                        {site}
                    </span>
                    <br />
                    <span style={{ fontSize: "1.5vw" }}>
                        {location}
                    </span>
                </div>

                <div className="notificationPanel mt-3">
                    <Card className="notificationPanelSpace" style={{ color: "#fff", background: "#00A9CE" }}>
                        <CardBody>
                            <strong>
                                <p style={{ textAlign: "center", fontSize: "20px" }}>
                                    Welcome to the GSTT Vaccination Hub, please take a seat and make sure you have checked yourself in.</p> 
                            </strong>
                        </CardBody>
                    </Card>

                </div>

                <div className="hfs mt-3">
                    <img src="/images/hfs.png" alt="GSTT" className="logoWidth" />
                </div>
            </Col>


            <Col xs="10" style={{ height: '100vh', background: '#005EB8' }}>
                <Row className="mt-2 noPadding">
                    {waitingPatients.filter(x => x.waitingStatus=== queueStatus.Called).map((x, i) => {
                        return <Col key={i}>
                            <Card className="cardCustomBorder">
                                <CardBody className={getClass(x.callingTime)}>
                                    <h3>{x.patientName}</h3> 
                                    <h4>Please go to {x.desk}</h4>
                                </CardBody>
                            </Card>
                        </Col>
                    })
                    }
                    {!waitingPatients.filter(x => x.waitingStatus===queueStatus.Called).length && <div>All of our team are busy with other patients</div>}
                </Row>

             

                <Row style={{ flex: 1, padding: '20px 50px 20px 50px', color: "#fff" }} className="mt-3" >
                    <h1 style={{ color: "#fff" }} className="mb-4">WAITING LIST</h1>
                    <div style={{ display: 'flex', flexFlow: 'column wrap', overflowY: 'hidden', maxHeight: '100%', width: '100%' }}>
                        <ul className="splitList">
                            {waitingPatients.filter(x => x.waitingStatus === queueStatus.Waiting).map((x, i) => <li key={i}>{x.patientName}</li>)}
                         
                        </ul>
                    </div>
                </Row>

                <div className="hfs mt-3" style={{textAlign:"center"}}>
                    <h3>Current Estimated Wait Time: {avgWaitingTimeInMinutes} minutes</h3>
                </div>
            </Col>
        </Row>
    </div>)
}
