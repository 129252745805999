import React from 'react';
import RequiredSymb from '../common/RequiredSymb';

const QuestionnaireBlurb = () => (
    <div>
        <div>
            <RequiredSymb /> Required;
        </div>
    </div>);

export default QuestionnaireBlurb;