import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faArrowLeft, faLink, faTimes, faPlus, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faComment as farComment } from '@fortawesome/free-regular-svg-icons';
import { signalRUtil } from '../../../utils/signalRUtil';
import Moment from 'react-moment';
import LoginView from '../../common/LoginView';
import { v4 as UUID } from "uuid";
import tokenService from '../../../services/tokenService';
import questionManager from '../questionManager';

import { Container, Badge, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, Input, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip } from 'reactstrap';
import Spinner from '../../common/Spinner';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import { PatientAppointmentItem } from '../../common/PatientAppointmentItem';
import { queueStatus } from './QueueStatus';
import { Timer } from '../../common/Timer';

import Questionnaire from '../Questionnaire';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import isInRole from '../../../utils/isInRole';

const currentDate = new Date(new Date().toDateString());

export const QueueGreeter = ({ match, getUser, requiredRoles }) => {

    const questionnaireKey = 'COVID Vaccination';
    const { site, location } = match.params;
    const [patientQueue, setPatientQueue] = useState([]);
    const [waitingPatients, setWaitingPatients] = useState([]);

    const [dna, setDna] = useState("-");
    const [walkInsToday, setWalkInsToday] = useState("-");
    const [unregisteredWalkInsToday, setUnregisteredWalkInsToday] = useState("-");
    const [toAttendToday, setToAttendToday] = useState("-");
    const [totalToday, setTotalToday] = useState("-")

    const [modalOpen, setModalOpen] = useState(false);
    const [patientLinkModalOpen, setPatientLinkModalOpen] = useState(false);
    const [walkinModalOpen, setWalkinModalOpen] = useState(false);
    const [modalNewRegistrationOpen, setModalNewRegistrationOpen] = useState(false)
    const [parentPatientForLinking, setParentPatientForLinking] = useState(null);
    const [modalComments, setModalComments] = useState("");
    const [walkinModalNhsNumber, setWalkinModalNhsNumber] = useState("");
    const [walkinModalNhsNumberResults, setWalkinModalNhsNumberResults] = useState([]);
    const [organisationList, setOrganisationList] = useState([]);
    const [basicRegistrationForename, setBasicRegistrationForename] = useState("");
    const [basicRegistrationSurname, setBasicRegistrationSurname] = useState("");
    const [basicRegistrationNhsNumber, setBasicRegistrationNhsNumber] = useState("");
    const [basicRegistrationIsSecondDose, setBasicRegistrationIsSecondDose] = useState("");
    const [basicRegistrationDoseTypeList, setBasicRegistrationDoseTypeList] = useState(["First Dose", "Second Dose", "Third Dose", "Booster Dose", "Flu Dose"]);
    const [basicRegistrationDoseType, setBasicRegistrationDoseType] = useState('');
    const [basicRegistrationIsHcw, setBasicRegistrationIsHcw] = useState(false);
    const [basicRegistrationIsCarehomeWorker, setBasicRegistrationIsCarehomeWorker] = useState(false);
    const [basicRegistrationOrganisation, setBasicRegistrationOrganisation] = useState("");
    const [basicRegistrationIsPriority, setBasicRegistrationIsPriority] = useState(false);
    const [basicRegistrationSubmissionId, setBasicRegistrationSubmissionId] = useState("");
    const [patientFromSubmissionId, setPatientFromSubmissionId] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [timer, setTimer] = useState(new Date());
    const [hubConnection, setHubConnection] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [showDna, setShowDna] = useState(false);
    const [showDeparted, setShowDeparted] = useState(false);
    const [showBasicRegistrationDetails, setShowBasicRegistrationDetails] = useState(false);
    const [showNewRegistration, setShowNewRegistration] = useState(false);
    const [invalidNhsNumber, setInvalidNhsNumber] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [showStatistics, setShowStatistics] = useState(false);
    const [statisticsReady, setStatisticsReady] = useState(false);

    const [disconnected, setDisconnected] = useState(false);
    const [reconnecting, setReconnecting] = useState(false);
    const [reconnected, setReconnected] = useState(false);

    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);
    const [openSendMenu, setOpenSendMenu] = useState("");

    useEffect(() => {
        const checkAccess = async () => {
            setCheckingAccess(true);

            if (isInRole(requiredRoles)) {
                setHasRequiredRoles(true);
                setCheckingAccess(false);
            } else {
                setHasRequiredRoles(false);
                setCheckingAccess(false);
            }
        }
        if (getUser) {
            checkAccess();
        }
    }, [getUser])

    const fetchPatients = async () => {

        const token = tokenService.getToken();

        const response = await fetch(`/CovidVaccinationAttendees?site=${site}&location=${location}`, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });
        if (response.ok) {
            const data = await response.json();
            const mappedPatients = data.sort((a, b) => a.currentTimeSlot < b.currentTimeSlot ? -1 : 1)
                .map((x) => { return new PatientAppointmentItem(x); });
            setPatientQueue(mappedPatients);
            setLoading(false);
        } else if (response.status === 401) {
            setErrorAuthenticating(true);
        } else {
            setErrorRetrieving(true);
        }
    }

    useEffect(() => {
        const createHub = async () => {

            const hub = await signalRUtil.getHubConnection(site, location, "Greeter", "");

            hub.onclose(err => {
                console.error("Hub connection closed. " + err);
                setDisconnected(true);
                setReconnecting(false);
                setReconnected(false);
            });
            hub.onreconnecting(err => {
                console.error("Hub connection re-connecting... " + err);
                setReconnected(false);
                setReconnecting(true);
            });
            hub.onreconnected(() => {
                setReconnected(true);
                setReconnecting(false);
                setDisconnected(false);
            });

            hub.on("patientArrived", patient => {
                if (!patient) return;

                setWaitingPatients(waitingPatients => [...waitingPatients, new PatientQueueItem(patient)]);
            });

            hub.on("patientArrivalCancelled", patient => {
                if (!patient) return;
                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients]
                    var i = next.findIndex(x => x.submissionId === patient.submissionId);
                    next.splice(i, 1);
                    return next;
                })
            });

            hub.on("PatientUpdated", patient => {
                if (!patient) return;

                const mappedPatient = new PatientQueueItem(patient);
                setWaitingPatients(waitingPatients => {
                    const next = [...waitingPatients]
                    var i = next.findIndex(x => x.submissionId === patient.submissionId);
                    next.splice(i, 1, mappedPatient);
                    return next;
                })
            });

            hub.on("connected", patients => {
                const mappedPatients = patients.map(x => new PatientQueueItem(x));
                setWaitingPatients(mappedPatients);
                fetchPatients();
            });

            setHubConnection(hub);
            return hub;
        };

        setOrganisationList(questionManager.getOrganisations());

        const hub = createHub();

        return async () => {
            (await hub).stop();
        }
    }, [])

    useEffect(() => {
        const total = patientQueue.length
        const attended = waitingPatients.length
        const dna = patientQueue.filter(x => { return waitingPatients.findIndex(y => y.submissionId == x.submissionId) == -1 }).filter(x => x.possibleDna).length
        setDna(dna);
        setUnregisteredWalkInsToday(waitingPatients.filter(x => x.isUnregistered).length)
        setWalkInsToday(patientQueue.filter(x => x.appointmentTime && x.appointmentTime.startsWith("W")).length + unregisteredWalkInsToday);
        setTotalToday(total);
        setToAttendToday(total - attended - dna + unregisteredWalkInsToday);
    }, [waitingPatients, patientQueue])

    const arrivePatient = (patient) => {
        hubConnection.invoke('arrivePatient', patient, site, location);
        setPatientFromSubmissionId(null);
    }

    const cancelArrivePatient = (patient) => {
        hubConnection.invoke('cancelArrivePatient', patient, site, location);
    }

    const cancelCall = (patient) => {
        hubConnection.invoke('cancelPatientCall', patient, site, location);
    }

    const patientLeft = (patient) => {
        hubConnection.invoke('patientLeft', patient, site, location);
    }

    const openModalComments = (patient) => {
        setSelectedPatient(patient);
        setModalComments(patient.comments);
        setModalOpen(true);
    }

    const saveModalComments = () => {
        const nextPatient = { ...selectedPatient };
        nextPatient.comments = modalComments;
        hubConnection.invoke('updatePatient', nextPatient, site, location);
        setModalComments('');
        setSelectedPatient(null);
        setModalOpen(false);
    }

    const togglePriority = (patient) => {
        patient.isPriority = !patient.isPriority;
        hubConnection.invoke('updatePatient', patient, site, location);
    }

    const openLinkPatientDialogue = (patientParentLink) => {
        if (patientParentLink.waitingStatus === queueStatus.Waiting) {
            setPatientLinkModalOpen(true);
            setParentPatientForLinking(patientParentLink);
        }
    }

    const closeLinkPatientDialogue = () => {
        setPatientLinkModalOpen(false);
        setParentPatientForLinking(null);
    }

    const linkPatients = (patient1, patient2) => {
        patient1.linkedSubmissions.push(patient2.submissionId);
        patient1.isLinkParent = true;
        patient2.linkedSubmissions.push(patient1.submissionId);

        hubConnection.invoke('patientLinked', patient1, site, location);
        hubConnection.invoke('patientLinked', patient2, site, location);
    }

    const unLinkPatients = (patient1, patient2) => {
        patient1.linkedSubmissions.splice(patient1.linkedSubmissions.indexOf(patient2.submissionId), 1);
        if (!patient1.linkedSubmissions.length) {
            patient1.isLinkParent = false;
        }

        patient2.linkedSubmissions.splice(patient2.linkedSubmissions.indexOf(patient1.submissionId), 1);
        if (!patient2.linkedSubmissions.length) {
            patient2.isLinkParent = false;
        }


        hubConnection.invoke('patientLinked', patient1, site, location);
        hubConnection.invoke('patientLinked', patient2, site, location);
    }

    const searchWalkinModalNhsNumber = () => {
        getPatientByNhsNumber();
    }

    const getPatientByNhsNumber = useCallback(async () => {
        if (walkinModalNhsNumber) {
            setLoadingData(true);
            setErrorSaving(false);
            try {
                const token = tokenService.getToken();
                const url = "/CovidVaccinationAttendees/Lookup/" + encodeURIComponent(walkinModalNhsNumber);
                const response = await fetch(url, {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setWalkinModalNhsNumberResults(data.map(x => new PatientAppointmentItem(x)));
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                } else {
                    setErrorRetrieving(true);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoadingData(false);
            }
        }

    }, [walkinModalNhsNumber]);

    const getStoredDateFormat = (theDate) => {
        return pad(theDate.getDate(), 2) + '/' + pad((theDate.getMonth() + 1), 2) + '/' + theDate.getFullYear();
    }

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    const isValidNhsNumber = (nhsNumber) => {

        var isValid = false;

        if (nhsNumber.length == 10) {

            var total = 0;

            var i = 0;
            for (i = 0; i <= 8; i++) {
                var digit = nhsNumber.substr(i, 1);
                var factor = 10 - i;
                total += (digit * factor);
            }

            var checkDigit = (11 - (total % 11));

            if (checkDigit == 11) { checkDigit = 0; }

            if (checkDigit == nhsNumber.substr(9, 1)) { isValid = true; }
        }

        return isValid;
    }

    const startNewRegistration = (patient) => {
        setBasicRegistrationSubmissionId(patient.submissionId);
        setShowNewRegistration(true);
        setModalNewRegistrationOpen(true);

    }

    const newRegistrationHandler = async (submissionIdentifier) => {

        setLoadingData(true);
        try {
            const token = tokenService.getToken();
            const url = "/CovidVaccinationAttendees/SubmissionLookup/" + encodeURIComponent(submissionIdentifier);
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                const patient = new PatientAppointmentItem(data);
                patient.isUnregistered = false;
                const existingRecord = waitingPatients.find(wp => wp.submissionId == patient.submissionId)

                if (existingRecord) {
                    patient.id = existingRecord.id;
                    hubConnection.invoke('updatePatient', patient, site, location);
                }

                await fetchPatients();
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingData(false);
        }
    }

    const dateMatch = (firstDate, secondDate) => {
        if (!firstDate && !secondDate) {
            return true;
        }

        if (!firstDate || !secondDate) {
            return false;
        }

        if (firstDate.getDate() !== secondDate.getDate()) {
            return false;
        }
        if (firstDate.getMonth() !== secondDate.getMonth()) {
            return false;
        }
        if (firstDate.getFullYear() !== secondDate.getFullYear()) {
            return false;
        }
        return true;
    }

    const returnTo = (patient, stage) => {
        hubConnection.invoke('returnPatientTo', patient, stage, site, location);
    }

    const checkAndUpdateAppointment = async (patient, doseName) => {
        if (!dateMatch(new Date(patient[doseName + 'AppointmentDateTime']), currentDate) || patient[doseName + 'AppointmentSite'] !== site || patient[doseName + 'AppointmentLocation'] !== location) {

            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers';
            const data = {
                questionnaireName: questionnaireKey,
                submissionIdentifier: patient.submissionId,
                questionAnswers: []
            };
            const changes = {};
            changes[doseName + "appointmentsite"] = site;
            changes[doseName + "appointmentlocation"] = location;
            changes[doseName + "appointmentdate"] = getStoredDateFormat(currentDate);
            changes[doseName + "appointmenttimeslot"] = 'Walk-in';
            if (doseName === "third" || doseName === "boost1" || doseName === "flu1") {
                changes["thirdappointmentonly"] = "Yes";
            }

            const method = 'PUT';
            const changeKeys = Object.keys(changes);
            changeKeys.map(a => data.questionAnswers.push({ questionKey: a, answers: [changes[a]] }));

            setLoadingData(true);

            try {
                const response = await fetch(url, {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {

                    setErrorSaving(false);

                    patient[doseName + "AppointmentSite"] = site;
                    patient[doseName + "AppointmentLocation"] = location;
                    patient[doseName + "AppointmentDate"] = getStoredDateFormat(currentDate);
                    patient[doseName + "AppointmentDateTime"] = new Date(currentDate.toDateString());
                    patient[doseName + "AppointmentTimeSlot"] = "Walk-in";
                    patient.appointmentTime = "Walk-in";

                    arrivePatient(patient);
                    setWalkinModalOpen(false);
                    setWalkinModalNhsNumber("");
                    setWalkinModalNhsNumberResults([]);

                } else {
                    setErrorSaving(true);
                }
            }
            catch (e) {
                console.log(e);
                setErrorSaving(true);
            }
            finally {
                setLoadingData(false);
            }
        } else {
            arrivePatient(patient);
            setWalkinModalOpen(false);
            setWalkinModalNhsNumber("");
            setWalkinModalNhsNumberResults([]);
        }
    }

    const arriveNewRegistration = async () => {

        setErrorSaving(false);

        if (!isValidNhsNumber(basicRegistrationNhsNumber)) {
            setInvalidNhsNumber(true);
            return;
        }
        setInvalidNhsNumber(false);

        const token = tokenService.getToken();
        const url = '/QuestionnaireAnswers';
        const submissionIdentifier = UUID();

        const data = {
            questionnaireName: questionnaireKey,
            submissionIdentifier: submissionIdentifier,
            questionAnswers: []
        };

        const changes = {};
        changes.willingtopartake = "Yes";
        changes.eligible = "Yes";
        changes.consentshare = "Yes";
        changes.forename = basicRegistrationForename;
        changes.lastname = basicRegistrationSurname;
        changes.nhsnumber = basicRegistrationNhsNumber;
        changes.healthcareworker = basicRegistrationIsHcw ? "Yes" : "No";
        changes.carehomeworker = basicRegistrationIsCarehomeWorker ? "Yes" : "No";
        changes.organisation = basicRegistrationOrganisation;
        if (basicRegistrationDoseType === "Flu Dose") {
            changes.flu1appointmentsite = site;
            changes.flu1appointmentlocation = location;
            changes.flu1appointmentdate = getStoredDateFormat(new Date());
            changes.flu1appointmenttimeslot = "Walk-in";
            changes.thirdappointmentonly = "Yes";
        } else if (basicRegistrationDoseType === "Booster Dose") {
            changes.boost1appointmentsite = site;
            changes.boost1appointmentlocation = location;
            changes.boost1appointmentdate = getStoredDateFormat(new Date());
            changes.boost1appointmenttimeslot = "Walk-in";
            changes.thirdappointmentonly = "Yes";
        } else if (basicRegistrationDoseType === "Third Dose") {
            changes.thirdappointmentsite = site;
            changes.thirdappointmentlocation = location;
            changes.thirdappointmentdate = getStoredDateFormat(new Date());
            changes.thirdappointmenttimeslot = "Walk-in";
            changes.thirdappointmentonly = "Yes";
        } else if (basicRegistrationDoseType === "Second Dose") {
            changes.secondappointmentsite = site;
            changes.secondappointmentlocation = location;
            changes.secondappointmentdate = getStoredDateFormat(new Date());
            changes.secondappointmenttimeslot = "Walk-in";
            changes.secondappointmentonly = "Yes";
        } else {
            changes.firstappointmentsite = site;
            changes.firstappointmentlocation = location;
            changes.firstappointmentdate = getStoredDateFormat(new Date());
            changes.firstappointmenttimeslot = "Walk-in";
        }
        changes.confirm = "Yes";

        const method = 'POST';
        const changeKeys = Object.keys(changes);

        if (changeKeys.length > 0) {
            changeKeys.map(a => data.questionAnswers.push({ questionKey: a, answers: [changes[a]] }));

            setLoadingData(true);

            try {
                const response = await fetch(url, {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {

                    // build appointment item and arrive;
                    let newArrival = new PatientAppointmentItem();
                    newArrival.submissionId = submissionIdentifier;
                    newArrival.isUnregistered = false;
                    newArrival.patientName = basicRegistrationForename + " " + basicRegistrationSurname;
                    newArrival.nhsNumber = basicRegistrationNhsNumber;
                    newArrival.isHcw = basicRegistrationIsHcw || basicRegistrationIsCarehomeWorker;
                    newArrival.isStaff = basicRegistrationOrganisation === "Guy's and St Thomas' NHS Foundation Trust";
                    newArrival.isPriority = basicRegistrationIsPriority;
                    newArrival.isPatient = !newArrival.isHcw && !newArrival.isHcw;

                    if (basicRegistrationDoseType === "Flu Dose") {
                        newArrival.flu1AppointmentDate = getStoredDateFormat(new Date());
                        newArrival.flu1AppointmentDateTime = new Date(new Date().toDateString());
                        newArrival.flu1AppointmentTimeSlot = "Walk-in";
                    } else if (basicRegistrationDoseType === "Booster Dose") {
                        newArrival.boost1AppointmentDate = getStoredDateFormat(new Date());
                        newArrival.boost1AppointmentDateTime = new Date(new Date().toDateString());
                        newArrival.boost1AppointmentTimeSlot = "Walk-in";
                    } else if (basicRegistrationDoseType === "Third Dose") {
                        newArrival.thirdAppointmentDate = getStoredDateFormat(new Date());
                        newArrival.thirdAppointmentDateTime = new Date(new Date().toDateString());
                        newArrival.thirdAppointmentTimeSlot = "Walk-in";
                    } else if (basicRegistrationDoseType === "Second Dose") {
                        newArrival.secondAppointmentDate = getStoredDateFormat(new Date());
                        newArrival.secondAppointmentDateTime = new Date(new Date().toDateString());
                        newArrival.secondAppointmentTimeSlot = "Walk-in";
                    } else {
                        newArrival.firstAppointmentDate = getStoredDateFormat(new Date());
                        newArrival.firstAppointmentDateTime = new Date(new Date().toDateString());
                        newArrival.firstAppointmentTimeSlot = "Walk-in";
                    }
                    arrivePatient(newArrival);
                    setWalkinModalOpen(false);
                    setWalkinModalNhsNumber("");
                    setWalkinModalNhsNumberResults([]);

                    setErrorSaving(false);

                    clearNewRegistration();
                    setShowBasicRegistrationDetails(false);
                } else {
                    setErrorSaving(true);
                }
            }
            catch (e) {
                console.log(e);
                setErrorSaving(true);
            }
            finally {
                setLoadingData(false);
            }
        }
    }

    const clearNewRegistration = () => {
        setWalkinModalNhsNumber('');
        setBasicRegistrationForename('');
        setBasicRegistrationSurname('');
        setBasicRegistrationNhsNumber('');
        setBasicRegistrationIsHcw(false);
        setBasicRegistrationIsCarehomeWorker(false);
        setBasicRegistrationOrganisation('');
        setBasicRegistrationIsPriority(false);
        setBasicRegistrationSubmissionId('');
        setBasicRegistrationDoseType('');
    }

    if (loading || checkingAccess) {
        return (<></>)
    }

    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    return (
        <>
            {disconnected && <div className="disconnect-warning">Your client has disconnected. Please refresh the browser as soon as possible to avoid queue management issues.</div>}
            {reconnecting && <div className="disconnect-warning">Your client has disconnected and is trying to reconnect... <Spinner /></div>}
            <Modal isOpen={modalOpen}>
                <ModalHeader>Set Comments:</ModalHeader>
                <ModalBody>
                    <Input type="textarea" value={modalComments} onChange={(e) => setModalComments(e.target.value)}></Input>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { saveModalComments() }}>Save</Button>
                    <Button onClick={() => { setModalOpen(false); setModalComments("") }} >Close</Button>
                </ModalFooter>
            </Modal>
            {parentPatientForLinking && <Modal isOpen={patientLinkModalOpen}>
                <ModalHeader>Link Patients to {parentPatientForLinking.patientName}:</ModalHeader>
                <ModalBody style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                    <ListGroup>
                        {
                            waitingPatients.filter((x) => {
                                if (parentPatientForLinking.linkedSubmissions.length && !parentPatientForLinking.isLinkParent) {
                                    return x.waitingStatus === queueStatus.Waiting && parentPatientForLinking.linkedSubmissions.indexOf(x.submissionId) !== -1;
                                }
                                return x.waitingStatus === queueStatus.Waiting && x.submissionId !== parentPatientForLinking.submissionId && (parentPatientForLinking.isLinkParent || x.linkedSubmissions.length === 0)
                            }).map((x, i) => <ListGroupItem key={i}>
                                {x.patientName}
                                {parentPatientForLinking.linkedSubmissions.indexOf(x.submissionId) === -1 && <Button style={{ float: "right" }} color="primary" onClick={() => linkPatients(parentPatientForLinking, x)} disabled={(disconnected || reconnecting)}>link</Button>}
                                {parentPatientForLinking.linkedSubmissions.indexOf(x.submissionId) !== -1 && <Button style={{ float: "right" }} color="danger" onClick={() => unLinkPatients(parentPatientForLinking, x)} disabled={(disconnected || reconnecting)}>un-link</Button>}
                            </ListGroupItem>)
                        }
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => closeLinkPatientDialogue()} >Close</Button>
                </ModalFooter>
            </Modal>
            }
            <Modal isOpen={walkinModalOpen} size="xl">
                <ModalHeader>Walk-in Patient:</ModalHeader>
                <ModalBody>
                    {!showBasicRegistrationDetails && <div>
                        NHS Number:{loadingData && <Spinner />}
                        <Input type="text" value={walkinModalNhsNumber} onChange={(e) => setWalkinModalNhsNumber(e.target.value)} maxLength={10}></Input>
                        {walkinModalNhsNumberResults.length > 0 && <Table striped size="sm">
                            <thead>
                                <tr>
                                    <th rowSpan="2" className="stickyHead">Name</th>
                                    <th rowSpan="2" className="stickyHead">Date of birth</th>
                                    <th colSpan="5" className="stickyHead text-center">Appointments</th>
                                    <th rowSpan="2" className="stickyHead">Arrive For...</th>
                                </tr>
                                    <th className="stickyHead">1st</th>
                                    <th className="stickyHead">2nd</th>
                                    <th className="stickyHead">3rd</th>
                                    <th className="stickyHead">Booster</th>
                                    <th className="stickyHead">Flu</th>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                {walkinModalNhsNumberResults.map((x, i) => <tr key={i}>
                                    <td>{x.patientName}</td>
                                    <td><Moment format="DD/MM/YYYY">{x.dob}</Moment></td>
                                    <td>{x.firstAppointmentDateTime && <Moment format="DD MMM YYYY">{x.firstAppointmentDateTime}</Moment>}<br />{x.firstAppointmentTimeSlot}</td>
                                    <td>{x.secondAppointmentDateTime && <Moment format="DD MMM YYYY">{x.secondAppointmentDateTime}</Moment>}<br />{x.secondAppointmentTimeSlot}</td>
                                    <td>{x.thirdAppointmentDateTime && <Moment format="DD MMM YYYY">{x.thirdAppointmentDateTime}</Moment>}<br />{x.thirdAppointmentTimeSlot}</td>
                                    <td>{x.boost1AppointmentDateTime && <Moment format="DD MMM YYYY">{x.boost1AppointmentDateTime}</Moment>}<br />{x.boost1AppointmentTimeSlot}</td>
                                    <td>{x.flu1AppointmentDateTime && <Moment format="DD MMM YYYY">{x.flu1AppointmentDateTime}</Moment>}<br />{x.flu1AppointmentTimeSlot}</td>
                                    <td><ButtonGroup>
                                        <Button size="sm" color="primary" onClick={() => { checkAndUpdateAppointment(x, "first") }} title="Arrive Patient for 1st appointment" disabled={(disconnected || reconnecting)}>1st</Button>
                                        <Button size="sm" color="primary" onClick={() => { checkAndUpdateAppointment(x, "second") }} title="Arrive Patient for 2nd appointment" disabled={(disconnected || reconnecting)}>2nd</Button>
                                        <Button size="sm" color="primary" onClick={() => { checkAndUpdateAppointment(x, "third") }} title="Arrive Patient for 3rd appointment" disabled={(disconnected || reconnecting)}>3rd</Button>
                                        <Button size="sm" color="primary" onClick={() => { checkAndUpdateAppointment(x, "boost1") }} title="Arrive Patient for booster appointment" disabled={(disconnected || reconnecting)}>Booster</Button>
                                        <Button size="sm" color="primary" onClick={() => { checkAndUpdateAppointment(x, "flu1") }} title="Arrive Patient for flu appointment" disabled={(disconnected || reconnecting)}>Flu</Button>
                                    </ButtonGroup></td>
                                </tr>)}
                            </tbody>
                        </Table>}
                        {errorSaving && <FontAwesomeIcon icon={faExclamationCircle} color="orange" title="Error arriving patient. Please check walk-in slot availability. Try again and if problem persists contact IT Support." />}
                    </div>}
                    {showBasicRegistrationDetails && <div>
                        <Row className="queue-registration-row">
                            <Col xs="3" className="queue-registration-name">Forename:</Col>
                            <Col xs="6"><Input type="text" className="queue-registration-input" value={basicRegistrationForename} onChange={(e) => setBasicRegistrationForename(e.target.value)}></Input></Col>
                        </Row>
                        <Row className="queue-registration-row">
                            <Col xs="3" className="queue-registration-name">Surname:</Col>
                            <Col xs="6"><Input type="text" className="queue-registration-input" value={basicRegistrationSurname} onChange={(e) => setBasicRegistrationSurname(e.target.value)}></Input></Col>
                        </Row>
                        <Row className="queue-registration-row">
                            <Col xs="3" className="queue-registration-name">NHS Number:</Col>
                            <Col xs="6"><Input type="text" className="queue-registration-input" value={basicRegistrationNhsNumber} onChange={(e) => setBasicRegistrationNhsNumber(e.target.value)} maxLength={10}></Input>{invalidNhsNumber && <FontAwesomeIcon icon={faExclamationCircle} color="orange" title="Invalid NHS number!" />}</Col>
                        </Row>
                        <Row className="queue-registration-row">
                            <Col xs="3">Dose Type:</Col>
                            <Col xs="6"><Input type="select" name="dosetype" onChange={(e) => setBasicRegistrationDoseType(e.target.value)} value={basicRegistrationDoseType}>
                                <option value="">Please select</option>
                                {basicRegistrationDoseTypeList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                            </Input></Col>
                        </Row>
                        <Row className="queue-registration-row">
                            <Col xs="3">Is Healthcare Worker:</Col>
                            <Col xs="6"><Input type="checkbox" className="queue-registration-checkbox" value={true} checked={basicRegistrationIsHcw} onChange={(e) => setBasicRegistrationIsHcw(e.target.checked)}></Input></Col>
                        </Row>
                        <Row className="queue-registration-row">
                            <Col xs="3">Is Carehome Worker:</Col>
                            <Col xs="6"><Input type="checkbox" className="queue-registration-checkbox" value={true} onChange={(e) => { setBasicRegistrationIsCarehomeWorker(e.target.checked); if (e.target.checked) { setBasicRegistrationIsHcw(true); setBasicRegistrationOrganisation(""); } }}></Input></Col>
                        </Row>
                        {basicRegistrationIsHcw && !basicRegistrationIsCarehomeWorker && <Row className="queue-registration-row">
                            <Col xs="3">Organisation:</Col>
                            <Col xs="6"><Input type="select" name="organisation" onChange={(e) => setBasicRegistrationOrganisation(e.target.value)} value={basicRegistrationOrganisation}>
                                <option value="">Please select</option>
                                {organisationList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                            </Input></Col>
                        </Row>}
                        <Row className="queue-registration-row">
                            <Col xs="3">Is Priority:</Col>
                            <Col xs="6"><Input type="checkbox" className="queue-registration-checkbox" value={basicRegistrationIsPriority} onChange={(e) => setBasicRegistrationIsPriority(e.target.checked)}></Input></Col>
                        </Row>
                        &nbsp;<br />
                        <Button color="primary" disabled={basicRegistrationForename.length <= 1 || basicRegistrationSurname.length <= 1 || basicRegistrationNhsNumber.length < 10 || !basicRegistrationDoseType || (basicRegistrationIsHcw && !basicRegistrationIsCarehomeWorker && basicRegistrationOrganisation.length <= 1) || loadingData || disconnected || reconnecting} onClick={() => { arriveNewRegistration(); }}>Arrive</Button>&nbsp;{loadingData && <Spinner />}{errorSaving && <FontAwesomeIcon icon={faExclamationCircle} color="orange" title="Error arriving patient. Please check walk-in slot availability. Try again and if problem persists contact IT Support." />}
                    </div>}
                </ModalBody>
                <ModalFooter>
                    {!showBasicRegistrationDetails && <Button color="primary" onClick={() => { searchWalkinModalNhsNumber(); }}>Search</Button>}
                    {!showBasicRegistrationDetails && <Button onClick={() => { setShowBasicRegistrationDetails(true); if (walkinModalNhsNumber) { setBasicRegistrationNhsNumber(walkinModalNhsNumber); } }}>Add New</Button>}
                    <Button onClick={() => { setWalkinModalOpen(false); setWalkinModalNhsNumber(""); setWalkinModalNhsNumberResults([]); setShowBasicRegistrationDetails(false); }} disabled={loadingData}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalNewRegistrationOpen} size="lg">
                <ModalHeader>New Registration</ModalHeader>
                <ModalBody>
                    {showNewRegistration && <Questionnaire submissionIdentifier={basicRegistrationSubmissionId} saveCallback={(si) => newRegistrationHandler(si)} />}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { setModalNewRegistrationOpen(false); setShowNewRegistration(false); }} >Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showStatistics} size="xl" toggle={() => setShowStatistics(!setShowStatistics)}>
                <ModalHeader toggle={() => setShowStatistics(!setShowStatistics)}>Today's Statistics</ModalHeader>
                <ModalBody>
                    {!statisticsReady ? <>
                        <Row>
                            <Col>
                                <FontAwesomeIcon pulse icon={faSpinner}  />
                            </Col>
                        </Row>
                    
                    </> : <> <Row>
                        <Col className="greaterMainCol" style={{ 'marginRight': "15px" }}>
                            <Card style={{ marginBottom: '23px' }}>
                                <CardHeader className="statsCards">Total Patients Today</CardHeader>
                                <CardBody>
                                    <span className="StatFigures d-flex justify-content-center">{totalToday}</span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="greaterMainCol" style={{ 'marginRight': "15px" }}>
                            <Card style={{ marginBottom: '23px' }}>
                                <CardHeader className="statsCards">?DNAs - Over 2hrs Late</CardHeader>
                                <CardBody>
                                    <span className="StatFigures d-flex justify-content-center">{dna}</span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="greaterMainCol" style={{ 'marginRight': "15px" }}>
                            <Card style={{ marginBottom: '23px' }}>
                                <CardHeader className="statsCards">Walk-ins</CardHeader>
                                <CardBody style={{ fontSize: '2vh' }}>
                                    <Table bordered>
                                        <tbody>
                                            <tr>
                                                <th>Total</th>
                                                <th>Unregistered</th>
                                            </tr>
                                            <tr>
                                                <td>{walkInsToday}</td>
                                                <td>{unregisteredWalkInsToday}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                        <Row>
                            <Col xs="4" className="greaterMainCol" style={{ 'marginRight': "15px" }}>
                                <Card style={{ marginBottom: '23px' }}>
                                    <CardHeader className="statsCards">In Clinic</CardHeader>
                                    <CardBody style={{ fontSize: '2vh' }}>
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <th>Waiting</th>
                                                    <th>Calling</th>
                                                    <th>Being Seen</th>
                                                </tr>
                                                <tr>
                                                    <td>{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.Waiting }).length}</td>
                                                    <td>{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.Called }).length}</td>
                                                    <td>{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.WithAssessor }).length}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="6" className="greaterMainCol" style={{ 'marginRight': "15px" }}>
                                <Card style={{ marginBottom: '23px' }}>
                                    <CardHeader className="statsCards">Outcomes</CardHeader>
                                    <CardBody>
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <th rowSpan="2">Total</th>
                                                        <th colSpan="4" className="text-center" style={{ color: "darkgreen", fontWeight: "bold" }}>Vaccinated</th>
                                                        <th rowSpan="2" style={{ color: "darkred", fontWeight: "bold" }}>Not Vaccinated</th>
                                                </tr>
                                                <tr>
                                                        <th style={{ backgroundColor: "#1e90ff", color: "white", fontWeight: "bold"}}>COVID</th>
                                                        <th style={{ backgroundColor: "darkorange", color: "white", fontWeight: "bold" }}>Flu</th>
                                                        <th style={{ backgroundColor: "#c69c6d", color: "white", fontWeight: "bold" }}>Both</th>
                                                        <th style={{ color: "darkgreen", fontWeight: "bold" }}>Total</th>
                                                </tr>
                                                <tr>
                                                        <td className="text-center" style={{ fontWeight: "bold" }}>{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.PatientLeft }).length}</td>
                                                    <td className="text-center">{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.PatientLeft && x.hasBeenVaccinated && !x.hasBeenVaccinatedFlu }).length}</td>
                                                    <td className="text-center">{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.PatientLeft && !x.hasBeenVaccinated && x.hasBeenVaccinatedFlu }).length}</td>
                                                    <td className="text-center">{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.PatientLeft && x.hasBeenVaccinated && x.hasBeenVaccinatedFlu }).length}</td>
                                                        <td className="text-center" style={{ color: "darkgreen", fontWeight: "bold" }}>{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.PatientLeft && (x.hasBeenVaccinated || x.hasBeenVaccinatedFlu) }).length}</td>
                                                    <td className="text-center" style={{ color: "darkred", fontWeight: "bold" }}>{waitingPatients.filter(x => { return x.waitingStatus === queueStatus.PatientLeft && !x.hasBeenVaccinated && !x.hasBeenVaccinatedFlu }).length}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="greaterMainCol" style={{ 'marginRight': "15px" }}>
                                <Card style={{ marginBottom: '23px' }}>
                                    <CardHeader className="statsCards">Remaining Patients</CardHeader>
                                    <CardBody>
                                        <span className="StatFigures d-flex justify-content-center">{toAttendToday}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    }
                </ModalBody>
            </Modal>

            <Row className="greaterMainRow">
                <Col className="greaterMainCol">
                    <Card className="cardHeight" >
                        <CardHeader style={{ background: '#0072CE', color: '#fff' }}>
                            <Row>
                                <Col xs="5">
                                    <h3><i className="fa fa-calendar"></i>&nbsp;Schedule</h3>
                                    <h5>{site} - {location}</h5>
                                </Col>
                                <Col xs="7">
                                    <InputGroup>
                                        <Input placeholder="Search" type="text" onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm}></Input>
                                        <InputGroupAddon addonType="append" onClick={(e) => setSearchTerm("")} style={{ cursor: "pointer" }} >
                                            <InputGroupText><i className="fa fa-times"></i>&nbsp;Clear</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{ overflowY: 'auto', overflowX: 'hidden', Padding: '0px !important' }} className="noPaddingCardBody">

                            <Table striped size="sm" >
                                <thead>
                                    <tr>
                                        <th className="stickyHead" >Appt. Time</th>
                                        <th className="stickyHead" >Patient Name</th>
                                        <th className="stickyHead" style={{ 'zIndex': '1' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        patientQueue
                                            .filter(x => showDna || x.possibleDna === false)
                                            .filter(x => {
                                                return waitingPatients.findIndex(y => y.submissionId == x.submissionId) == -1
                                            })
                                            .filter(x => {
                                                return x.patientName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                                            }).map((x, i) => <tr key={i}>
                                                <td>{x.appointmentTime} {x.possibleDna && <Badge color="danger" pill>?DNA</Badge>} {x.bookingSource === "NBS" && <Badge color="warning">NBS</Badge>} {(x.boost1AppointmentDate && x.boost1AppointmentDate == getStoredDateFormat(currentDate)) && <Badge color="info">Booster</Badge>}</td>
                                                <td>{x.patientName} ({x.isPatient && "Patient"}{x.isHcw && !x.isStaff && "Hcw"}{x.isStaff && "Staff"})</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button size="sm" color="primary" onClick={() => arrivePatient(x)} title="Arrive Patient" disabled={(disconnected || reconnecting)}>Arrive</Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>)
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter>
                            <ButtonGroup>
                                <Button onClick={() => fetchPatients()}>Refresh Schedule</Button>
                                <Button onClick={() => { setWalkinModalOpen(true); setErrorSaving(false); }}>Walk-in Patient</Button>
                            </ButtonGroup>
                            <Button onClick={() => setShowDna(!showDna)} style={{ "float": "right" }} >{showDna ? 'Hide' : 'Show'}&nbsp;?DNA</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col className="greaterMainCol">
                    <Card className="cardHeight" >
                        <CardHeader style={{ background: '#0072CE', color: '#fff' }}><h3><i className="fa fa-clock-o"></i>&nbsp; Waiting Patients <Button style={{ float: "right" }} size="sm" color="success" onClick={async () => {
                            setShowStatistics(true);
                            setStatisticsReady(false);
                            await fetchPatients();
                            setStatisticsReady(true);
                        }}
                        >Show Statistics</Button></h3>
                        </CardHeader>
                        <CardBody style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            <Table striped size="sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Waiting Time</th>
                                        <th>Patient Name</th>
                                        <th>Priority</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        waitingPatients.filter(x => {
                                            return x.waitingStatus === queueStatus.Waiting
                                                || x.waitingStatus === queueStatus.WithAssessor
                                                || x.waitingStatus === queueStatus.Called
                                                || x.waitingStatus === queueStatus.PatientVaccinated
                                                || ((x.waitingStatus === queueStatus.PassedToVaccination || x.waitingStatus === queueStatus.PatientLeft) && (!x.hasBeenVaccinated && !x.hasBeenVaccinatedFlu))
                                                || showDeparted
                                        }).map((x, i) => <tr key={i}>
                                            <td>{!x.desk &&
                                                <Button size="sm" color="warning" onClick={() => cancelArrivePatient(x)} title="Move patient back to scheduled" disabled={(disconnected || reconnecting)}>
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </Button>
                                            }
                                                {x.desk &&
                                                    <Button size="sm" color="danger" onClick={() => cancelCall(x)} title="Remove patient" disabled={(disconnected || reconnecting)}>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </Button>
                                                }
                                            </td>
                                            <td>{
                                                x.waitingStatus === queueStatus.PassedToVaccination || x.waitingStatus === queueStatus.PatientLeft ? <>Left {(x.hasBeenVaccinated || x.hasBeenVaccinatedFlu) ? <span className="text-success">Vx</span> : <span className="text-danger"><b>NoVx</b></span>} </> : <Timer startTime={x.arrivalTime} />
                                            } </td>
                                            <td><span id={"name-" + x.submissionId}>{x.patientName}</span><UncontrolledTooltip placement="right" target={"name-" + x.submissionId}>Arrived: <Moment format="HH:mm">{x.arrivalTime}</Moment><br />Called: {x.callingTime ? <Moment format="HH:mm">{x.callingTime.toString()}</Moment> : "n/a"}<br />Finished: {x.finishedTime ? <Moment format="HH:mm">{x.finishedTime.toString()}</Moment> : "n/a"}</UncontrolledTooltip>&nbsp;
                                                <FontAwesomeIcon className="pointer blueIcon" style={{ marginLeft: "10px" }} icon={x.comments.length > 0 ? faComment : farComment} onClick={() => openModalComments(x)} title="Comment" />
                                                <FontAwesomeIcon className="pointer" icon={faLink} style={{ marginLeft: "10px", color: x.linkedSubmissions.length ? '#444' : '#CCC' }} onClick={() => openLinkPatientDialogue(x)} />
                                                {x.isUnregistered && <FontAwesomeIcon className="pointer" icon={faPlus} style={{ marginLeft: "10px", color: '#ff0000' }} onClick={() => startNewRegistration(x)} />}
                                            </td>
                                            {!x.desk && <>
                                                <td style={{ alignContent: 'center' }}>
                                                    <input type="checkbox" checked={x.isPriority} onChange={() => togglePriority(x)} disabled={(disconnected || reconnecting)}></input>
                                                </td>
                                                <td>
                                                    {x.waitingStatus === queueStatus.Waiting && <Button size="sm" title="Complete appointment" color="danger" onClick={() => patientLeft(x)} disabled={(disconnected || reconnecting)}>Left</Button>}
                                                </td>
                                            </>
                                            }
                                            {x.waitingStatus === queueStatus.PatientLeft ? <td>
                                                <ButtonDropdown block="true" style={{ width: "100%" }}
                                                    isOpen={openSendMenu === x.submissionId}
                                                    toggle={() => { if (openSendMenu === x.submissionId) { setOpenSendMenu('') } else { setOpenSendMenu(x.submissionId) } }} disabled={(disconnected || reconnecting)}>
                                                    <DropdownToggle caret >
                                                        Send
                                                        </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => returnTo(x, 'screener')}>Screener</DropdownItem>
                                                        <DropdownItem onClick={() => returnTo(x, 'prescriber')}>Prescriber</DropdownItem>
                                                        <DropdownItem onClick={() => returnTo(x, 'vaccinator')}>Vaccinator</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </td>
                                                : <></>}

                                            {x.desk && <td colSpan={x.waitingStatus === queueStatus.PatientLeft ? "1" : "2"}>
                                                {x.personFinishingPatient && <>{x.personFinishingPatient.replace("kingsch\\", "")}<br /></>}
                                                {x.waitingStatus == queueStatus.Called && !x.callHolding && <span>Calling:&nbsp;</span>}
                                                {x.waitingStatus == queueStatus.Called && x.callHolding && <span>Call Holding:&nbsp;</span>}
                                                {x.waitingStatus == queueStatus.WithAssessor && <span>At:&nbsp;</span>}
                                                <b>{x.desk}</b>
                                            </td>}
                                        </tr>)
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter>
                            <Button style={{ float: 'right' }} onClick={() => setShowDeparted(!showDeparted)}>{showDeparted ? 'Hide' : 'Show'}&nbsp;Left Patients</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <LoginView nonBanner />
            </Row >

        </>
    )

}

