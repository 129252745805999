const questionManager = {
    getQuestions(willingToPartake, positiveCovidTest, hadAntibodyTest) {
        let newQuestions = [];
        let isWillingToPartake = false;
        let isPositiveCovidTest = false;
        let previouslyHadAntibodyTest = false;
        if (willingToPartake === 'Yes') {
            isWillingToPartake = true;
        }
        if (positiveCovidTest === 'Yes') {
            isPositiveCovidTest = true;
        }
        if (hadAntibodyTest === 'Yes' || hadAntibodyTest === '') {
            previouslyHadAntibodyTest = true;
        }

        if (!isWillingToPartake) {
            newQuestions = [
                { key: "willingtopartake", mandatory: true },
                { key: "positivetest", mandatory: false },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: false },
                { key: "gender", mandatory: false },
                { key: "postcode", mandatory: false },
                { key: "mobile", mandatory: false },
                { key: "email", mandatory: false },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "departmentward", mandatory: true },
                { key: "hadantibodytestbefore", mandatory: false },
                { key: "address", mandatory: false },
                { key: "gpaddress", mandatory: false },
                { key: "testdays", mandatory: false },
                { key: "seenvideoinstructions", mandatory: false },
                { key: "pledgetousetestsforself", mandatory: false },
                { key: "helpadministering", mandatory: false },
                { key: "reason", mandatory: true },
                { key: "kitcode", mandatory: false },
                { key: "kitissueddate", mandatory: false },
                { key: "confirm", mandatory: true }];
        } else {
            if (isPositiveCovidTest) {
                newQuestions = [
                    { key: "willingtopartake", mandatory: true },
                    { key: "positivetest", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: false },
                    { key: "gender", mandatory: false },
                    { key: "postcode", mandatory: false },
                    { key: "mobile", mandatory: false },
                    { key: "email", mandatory: false },
                    { key: "directorate", mandatory: true },
                    { key: "jobrole", mandatory: true },
                    { key: "departmentward", mandatory: true },
                    { key: "hadantibodytestbefore", mandatory: false },
                    { key: "address", mandatory: false },
                    { key: "gpaddress", mandatory: false },
                    { key: "testdays", mandatory: false },
                    { key: "seenvideoinstructions", mandatory: false },
                    { key: "pledgetousetestsforself", mandatory: false },
                    { key: "helpadministering", mandatory: false },
                    { key: "reason", mandatory: false },
                    { key: "kitcode", mandatory: false },
                    { key: "kitissueddate", mandatory: false },
                    { key: "confirm", mandatory: true }];
            } else {
                newQuestions = [
                    { key: "willingtopartake", mandatory: true },
                    { key: "positivetest", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "gender", mandatory: true },
                    { key: "postcode", mandatory: true },
                    { key: "mobile", mandatory: true },
                    { key: "email", mandatory: true },
                    { key: "directorate", mandatory: true },
                    { key: "jobrole", mandatory: true },
                    { key: "departmentward", mandatory: true },
                    { key: "hadantibodytestbefore", mandatory: true },
                    { key: "address", mandatory: !previouslyHadAntibodyTest },
                    { key: "gpaddress", mandatory: !previouslyHadAntibodyTest },
                    { key: "testdays", mandatory: false },
                    { key: "seenvideoinstructions", mandatory: true },
                    { key: "pledgetousetestsforself", mandatory: true },
                    { key: "helpadministering", mandatory: true },
                    { key: "reason", mandatory: false },
                    { key: "kitcode", mandatory: false },
                    { key: "kitissueddate", mandatory: false },
                    { key: "confirm", mandatory: true }];
            }
        }
        return newQuestions;
    },
    getDirectorates() {
        return ["Cancer Services - Haematology",
            "Cancer Services - Oncology",
            "Cancer Services - Surgical Oncology",
            "Cardiovascular Services",
            "Chief Executive's Office",
            "Chief Nurse's Office",
            "Chief Operating Officer",
            "Clinical Imaging & Medical Physics",
            "Commercial",
            "Communications",
            "Data, Technology & Information",
            "Dental Services",
            "Essentia",
            "Evelina London - Community",
            "Evelina London - Medicine & Neonatology",
            "Evelina London - Surgery & Intensive Care",
            "Evelina London - Women's Services",
            "Finance",
            "Gastrointestinal Medicine & Surgery",
            "GSTT Contractors",
            "Hurley UTC",
            "Integrated Care - Acute & General Medicine",
            "Integrated Care - Integrated Local Services",
            "Integrated Care - Therapies & Rehabilitation",
            "KCL",
            "Medical Director's Office",
            "Medical Specialties",
            "Other Corporate Departments",
            "Other Directorate not otherwise listed",
            "PACCS - Pulmonary, Adult Critical Care & Sleep",
            "Pharmacy & Medicines Optimisation",
            "R&D / BRC",
            "Retail Partners",
            "Specialist Ambulatory Services",
            "Strategy",
            "Surgery",
            "Theatres, Anaesthesia & Perioperative",
            "Transplant, Renal & Urology",
            "Vaccination Service",
            "Viapath",
            "Workforce"];
    },
    getJobRoles() {
        return ["Additional Clinical Services",
            "Admin and Clerical Band 2",
            "Admin and Clerical Band 3",
            "Admin and Clerical Band 4",
            "Admin and Clerical Band 5",
            "Admin and Clerical Band 6",
            "Admin and Clerical Band 7",
            "Admin and Clerical Band 8",
            "Admin and Clerical Band 9",
            "Allied Health Professional ",
            "Allied Health Professional Band 4",
            "Allied Health Professional Band 5",
            "Allied Health Professional Band 6",
            "Allied Health Professional Band 7",
            "Allied Health Professional Band 8",
            "Allied Health Professional Band 9",
            "Apprentice Nursing Associate",
            "Clinical Scientist / Additional Professional Scientific & Technical",
            "Director/VSM",
            "Estates & Ancillary - Essentia Accommodation Services",
            "Estates & Ancillary - Essentia Catering Services",
            "Estates & Ancillary - Essentia Housekeeping Services",
            "Estates & Ancillary - Essentia Maintenance",
            "Estates & Ancillary - Essentia Other",
            "Estates & Ancillary - Essentia Portering Services",
            "Estates & Ancillary - Essentia Rapid Response Team",
            "Estates & Ancillary - Essentia Transport Services",
            "Estates & Ancillary - GSTT Engineering",
            "Estates & Ancillary - Other",
            "Estates & Ancillary - Security & Reception",
            "Executive Director",
            "KCL Other (if clinical, please chose from other categories if possible)",
            "Medical & Dental - Clinical Fellow/SAS Grade",
            "Medical & Dental - Consultant",
            "Medical & Dental - Foundation Doctor FY1/FY2/FY3",
            "Medical & Dental - Specialty Registrar (ST1/2)",
            "Medical & Dental - Specialty Registrar (ST3+)",
            "Non-Executive Director",
            "Nurse Practitioner",
            "Nursing & Midwifery Band 2",
            "Nursing & Midwifery Band 3",
            "Nursing & Midwifery Band 4",
            "Nursing & Midwifery Band 5",
            "Nursing & Midwifery Band 6",
            "Nursing & Midwifery Band 7",
            "Nursing & Midwifery Band 8",
            "Nursing & Midwifery Band 9",
            "Other",
            "Retail ",
            "Student - Medical & Dental",
            "Student - Nursing & Midwifery",
            "Student - Other ",
            "Viapath"];
    },
    getGenders() {
        return ["Male",
            "Female",
            "Other"];
    }
};

export default questionManager;