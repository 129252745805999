import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../providers/authProvider";

export const PrivateRoute = ({ component, ...rest }) => {
    const { requiredRoles } = rest;
    const renderFn = (Component, requiredRoles) => props => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect, getUser, parseJwt }) => {
                if (!!Component && isAuthenticated()) {
                    getUser().then(u => {
                        var jwtToken = parseJwt(u.access_token);
                        if ((new Date().getTime() / 1000) > jwtToken.exp) {
                            signinRedirect();
                        }
                        console.warn(jwtToken);
                    });
                    return <Component {...props} requiredRoles={requiredRoles} getUser={getUser} />;
                } else {
                    signinRedirect();
                    return <Component {...props} />;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component, requiredRoles)} />;
};
