import moment from "moment"

export const PatientQueueItem = (item = {}) => {
    return {
        id: item.id,
        submissionId: item.submissionId,
        patientName: item.patientName,
        comments: item.comments || "",
        dob: item.dob,
        nhsNumber: item.nhsNumber,
        isPatient: item.isPatient,
        isHcw : item.isHcw,
        isStaff: item.isStaff,
        isPriority: item.isPriority,
        site: item.site,
        location: item.location,
        appointmentTime : item.currentTimeSlot ?? item.appointmentTime,
        hasArrived: item.hasArrived || false,
        arrivalTime : item.arrivalTime,
        waitingStatus : item.waitingStatus,
        callingTime: item.calledTime ? new moment(item.calledTime) : null,
        desk: item.desk,
        calledBy: item.calledBy,
        deskArrivalTime: item.deskArrivalTime ? new moment(item.deskArrivalTime) : null,
        callHolding: item.callHolding,
        linkedSubmissions: item.linkedSubmissions || [],
        isLinkParent: item.isLinkParent || false,
        isUnregistered: item.isUnregistered,
        firstAppointmentDate: item.firstAppointmentDate,
        firstAppointmentTimeSlot: item.firstAppointmentTimeSlot,
        secondAppointmentDate: item.secondAppointmentDate,
        secondAppointmentTimeSlot: item.secondAppointmentTimeSlot,
        thirdAppointmentDate: item.thirdAppointmentDate,
        thirdAppointmentTimeSlot: item.thirdAppointmentTimeSlot,
        boost1AppointmentDate: item.boost1AppointmentDate,
        boost1AppointmentTimeSlot: item.boost1AppointmentTimeSlot,
        flu1AppointmentDate: item.flu1AppointmentDate,
        flu1AppointmentTimeSlot: item.flu1AppointmentTimeSlot,
        activePsd: item.activePsd,
        previousPsd: item.previousPsd,
        originalPsd: item.originalPsd,
        withPrescriber: item.withPrescriber,
        withPrescriberTime: item.withPrescriberTime ? new moment(item.withPrescriberTime) : null,
        startedPrescribingTime: item.startedPrescribingTime ? new moment(item.startedPrescribingTime) : null,
        startedPrescribingName: item.startedPrescribingName,
        finishedPrescribingTime: item.finishedPrescribingTime ? new moment(item.finishedPrescribingTime) : null,
        prescriberOutcome: item.prescriberOutcome,
        prescriberOutcomeFlu: item.prescriberOutcomeFlu,
        prescriberComments: item.prescriberComments,
        prescriberCommentsFlu: item.prescriberCommentsFlu,
        hasAlternateDose: item.hasAlternateDose,
        hasAlternateDoseFlu: item.hasAlternateDoseFlu,
        stream: item.stream,
        hasBeenVaccinated: item.hasBeenVaccinated,
        hasBeenVaccinatedFlu: item.hasBeenVaccinatedFlu,
        personFinishingPatient: item.personFinishingPatient,
        finishedTime: item.finishedTime ? new moment(item.finishedTime) : null
    }
}