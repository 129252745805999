import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row, } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import { signalRUtil } from '../../../utils/signalRUtil';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import { queueStatus } from './QueueStatus';


export const LiveView = ({ match }) => {

    const { site, location } = match.params;
    const [activeStaff, setActiveStaff] = useState([]);


    useEffect(() => {

        const createHub = async () => {
            const hub = await signalRUtil.getHubConnection(site, location, "LiveView", "");

            hub.on("joined", (deskStatus) => {
                setActiveStaff((current) => {
                            const next = [...current];
                            next.push(deskStatus)
                            return next;
                        });

            });

            hub.on("left", (deskStatus) => {
                setActiveStaff((current) => {
                            const next = [...current];
                            const i = next.findIndex(x => x.connectionId === deskStatus.connectionId);
                            if(i !== -1){
                                next.splice(i,1);
                            }
                            return next;
                        });
            })
        };

        createHub();

    }, [])

    return (<div style={{ display: 'flex', flexFlow: 'column', height: '100vh', overflow: 'hidden' }}>
        <Row>
            <Col xs={2}>
                <Card>
                    <CardHeader>Greeter</CardHeader>
                </Card>
            </Col>
            <Col xs={8}>
                <Row>
                    {activeStaff.filter(x => x.role === "Assessor").map((b, i) => <Card key={i}>
                        <CardHeader>{b.desk}</CardHeader>
                        <CardBody>
                            <div>Name : {b.user}</div>
                            <div>Current Action: </div>
                            <div>Time : </div>
                        </CardBody>
                    </Card>)}
                </Row>
            </Col>
            <Col>
                <Card xs={2}>
                    <CardHeader>Prescriber</CardHeader>
                    <CardBody>
                        {activeStaff.filter(x => x.role === "Prescriber").length == 0 ? <>No Prescriber online</> :
                            <>
                                <div>Online:</div>
                                <div>{activeStaff.filter(x => x.role === "Prescriber").map((p, i) => <div key={i}>
                                    {p.user}
                                </div>)
                                }
                                </div>
                            </>
                        }

                        <div>Queue: 111</div>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    </div>)
}
