import React from 'react';

export const DTC210457K =  {
    dtcNumber: '210457k',
    content: {
        dtcNumber: '210457k',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at Kings College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'COVID-19 Vaccine Moderna, dispersion for injection in multidose container mRNA Vaccine (nucleoside modified)',
        dose: '100 micrograms of mRNA (embedded in SM-102 lipid nanoparticles) per 0.5ml dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'August 2021',
        for: 'For Adults Over 18 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'COVID-19 Vaccine Moderna - mRNA Vaccine (nucleoside modified)',
        includeConsentQuestions: true,
        showAge: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for 5 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if hypersensitivity to vaccine excipients. If hypersensitive to other vaccines consider contacting the on-call allergist.</>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but must be flagged to the prescriber.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient (or recipient) has read the relevant information leaflets, has all of the information they require and gives consent to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient (or recipient) wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient (or recipient) (or their nominated/legal representative) has received all of the relevant information from the Screener-Vaccinator and has provided consent to receive the vaccination on an informed basis.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the recipient’s concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Not-consent' }
            }
        }
    }
}

export const DTC210957D1 = {
    dtcNumber: '210957d1',
    content: {
        dtcNumber: '210957d1',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'COVID-19 Vaccine Moderna, dispersion for injection in multidose container mRNA Vaccine (nucleoside modified)',
        dose: '100 micrograms of mRNA (embedded in SM-102 lipid nanoparticles) per 0.5ml dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Adults Over 18 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'COVID-19 Vaccine Moderna - mRNA Vaccine (nucleoside modified)',
        useVaccineFriendlyNameForScreening: true,
        includeConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: false,
        supportsBoosterDose: false,
        supportsStaffCheck: true,
        supportsCovidDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for 5 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if hypersensitivity to vaccine excipients. If hypersensitive to other vaccines consider contacting the on-call allergist.</>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but must be flagged to the prescriber.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

export const DTC220157B = {
    dtcNumber: '220157b',
    content: {
        dtcNumber: '220157b',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at Guy’s and St. Thomas’ NHS Foundation Trust vaccination hub and administration record.',
        name: 'COVID-19 Vaccine Moderna, dispersion for injection in multidose container mRNA Vaccine (nucleoside modified)',
        dose: '100 micrograms of mRNA (embedded in SM-102 lipid nanoparticles) per 0.5ml dose',
        halfDose: '50 micrograms of mRNA (embedded in SM-102 lipid nanoparticles) per 0.25ml dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'June 2022',
        for: 'For Adults Over 18 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'COVID-19 Vaccine Moderna - mRNA Vaccine (nucleoside modified)',
        useVaccineFriendlyNameForScreening: true,
        includeConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true,
        supportsStaffCheck: true,
        supportsCovidDose: true,
        boosterUsesHalfDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', boost1PsdKey: 'covidpositive4weeksboost1', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for 5 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if hypersensitivity to vaccine excipients. If hypersensitive to other vaccines consider contacting the on-call allergist.</>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but must be flagged to the prescriber.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// In Review PSDs
// None (remove when developing an in-review PSD)