export const PAGE_HEADER = process.env.REACT_APP_SITE_NAME;

export const FOOTER_TEXT = process.env.REACT_APP_FOOTER_TEXT;
export const PRIVACY_LINK = process.env.REACT_APP_FOOTER_PRIVACY_LINK;

export const SHOW_COVID19CC = process.env.REACT_APP_SHOW_COVID19CC === 'true' ? true : false;
export const SHOW_COVID19SA = process.env.REACT_APP_SHOW_COVID19SA === 'true' ? true : false;
export const SHOW_FLUVACCINATION = process.env.REACT_APP_SHOW_FLUVACCINATION === 'true' ? true : false;
export const SHOW_COVID19HOMETESTREPORTING = process.env.REACT_APP_SHOW_COVID19HOMETESTREPORTING === 'true' ? true : false;
export const SHOW_COVID19HOMETESTINGREGISTRATION = process.env.REACT_APP_SHOW_COVID19HOMETESTINGREGISTRATION === 'true' ? true : false;
export const SHOW_COVID19VACCINATION = process.env.REACT_APP_SHOW_COVID19VACCINATION === 'true' ? true : false;