import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Input, Card, CardHeader, CardBody, Table, ButtonGroup, Navbar, NavbarBrand, NavbarText } from 'reactstrap';
import tokenService from '../../services/tokenService';
import { signalRUtil } from '../../utils/signalRUtil';
import LoginView from '../common/LoginView';
import isInRole from '../../utils/isInRole';

export const CsvUpload = ({ getUser, requiredRoles }) => {

    const [file, setFile] = useState();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [, setHubConnection] = useState(false);
    const [progress, setProgress] = useState({});
    const [stats, setStats] = useState({});
    const [complete, setComplete] = useState(false);
    const [controller, setAbortController] = useState();
    const [problemRecords, setProblemRecords] = useState([]);

    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);

    useEffect(() => {
        const checkAccess = async () => {
            setCheckingAccess(true);
                        
            if (isInRole(requiredRoles)) {
                setHasRequiredRoles(true);
                setCheckingAccess(false);
            } else {
                setHasRequiredRoles(false);
                setCheckingAccess(false);
            }
        }
        if (getUser) {
            checkAccess();
        }
    }, [getUser])

    useEffect(() => {
        const createHub = async () => {
            const hub = await signalRUtil.getHubConnectionUpload();
            hub.on("progress", progress => {
                setProgress(progress);
            })
            hub.on("problemRecord", record => {

                setProblemRecords(records => {
                    var next = [record, ...records];
                    return next;
                });
            });
            setHubConnection(hub);
        }

        const getData = async () => {
            const token = tokenService.getToken();
            
            var response = await fetch('/api/CsvUpload', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
            );
            if (!response.ok) {
                setError("Something went wrong")
            } else {
                setStats(await response.json());
            }
        }


        createHub();

        setInterval(getData, 60000);
        getData();

        setAbortController(new AbortController());

    }, []);

    const submit = async () => {
        setLoading(true);
        setProblemRecords([]);
        const token = tokenService.getToken();
        var data = new FormData()
        data.append('file', file[0])

        try {
            setComplete(false);
            var response = await fetch('/api/CsvUpload', { // Your POST endpoint
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token
                },
                body: data,
                signal: controller.signal
            })

            if (!response.ok) {
                setError("Something went wrong")
            } else {
                setLoading(false);
                setComplete(true);
            }
        } catch (ex) {
            setLoading(false);
        }
    }

    const abort = () => {
        controller.abort();
        setAbortController(new AbortController());
    }

    if (checkingAccess) {
        return (<></>)
    }

    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    return <>
        <div style={{ 'backgroundColor': 'rgb(0, 75, 139)', minHeight: "100vh" }}>
            <Navbar>
                <NavbarBrand><h3 className="align-middle" style={{ color: "white" }}>Welcome to the Guy's & St Thomas Patient Portal Upload Screen</h3></NavbarBrand>
                <NavbarText><img src="/images/GSTT-logo-2017-white.png" alt="GSTT" className="float-right p-2" height="75" /></NavbarText>
            </Navbar>
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>Total</CardHeader>
                            <CardBody>{stats.total}</CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader>Booked</CardHeader>
                            <CardBody>{stats.bookings}</CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>Upload</CardHeader>
                            <CardBody>
                                <Input type="file" name="file" id="exampleFile" onChange={(e) => setFile(e.target.files)} />
                                <ButtonGroup>
                                    {!loading && <Button disabled={loading} color="dark" className="mt-3" onClick={() => submit()}>Upload</Button>}
                                    {loading && <Button onClick={abort} className="mt-3">Abort Upload</Button>}
                                </ButtonGroup>
                                {progress.total && !complete && <>Processing: {progress.progress} of {progress.total}</>}
                                {complete && <>Processing completed.</>}
                                <div>
                                    {error && <>{error} </>}
                                </div>
                            </CardBody>
                        </Card>
                        {problemRecords.length !== 0 && <Card>
                            <CardHeader>Problems</CardHeader>
                            <CardBody>
                                <Table responsive={true}>
                                    <tbody>
                                        {problemRecords.map((r, i) => <tr key={i}>
                                            <td>{r.reason}</td>
                                            <td>{JSON.stringify(r.item)}</td>
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>

                            </CardBody>
                        </Card>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    </>

}
