const dateUtil = {
    // Accepts 2013-01-09T00:00:00 or 2013-01-15T15:57:45.113 or "" or "2013-01-15T15:56:57Z"
    ConvertDateFromJSON: function (dateIn) {
        if (dateIn) {
            // alread a valid date object?
            if (dateIn.getMonth && !isNaN(dateIn.getMonth())) {
                return dateIn;
            }
            if (dateIn.indexOf('/Date') !== -1) {
                return new Date(parseInt(dateIn.substr(6)));
            } else {
                var parts = dateIn.replace(/:/g, "-").replace("T", "-").replace(".", "-").replace(/"/g, "").replace("Z", "").replace(/\//g, "-").split("-");
                var date = new Date(NaN);

                date.setUTCFullYear(parts[0], Number(parts[1]) - 1, parts[2]);
                date.setUTCHours(parts[3]);
                date.setUTCMinutes(parts[4]);
                date.setUTCSeconds(parts[5]);

                if (isNaN(Date.parse(date))) {
                    return "";
                } else {
                    return date;
                }
            }
        } else {
            return "";
        }
    },

    //This is for dates that dont require UTC calc for example iCM/EPR
    ConvertDateFromJSONNoUTC: function (dateIn) {
        if (dateIn instanceof Date) {
            dateIn = dateIn.toJSON();
        }
        if (dateIn) {
            if (dateIn.indexOf('/Date') !== -1) {
                var newDate = new Date(parseInt(dateIn.substr(6)));
                newDate.setDate(newDate.getUTCDate());
                newDate.setHours(newDate.getUTCHours());
                return newDate;
            } else {
                var parts = dateIn.replace(/Z/g, "").split(/:|-|T|:Z|\.|g|\//);
                var year = parts[0];
                var month = Number(parts[1]) - 1;
                var day = parts[2];
                var hour = parts[3];
                var mins = parts[4];
                var secs = parts[5];
                return new Date(year, month, day, hour, mins, secs);
            }
        } else {
            return undefined;
        }
    },

    ConvertToDateOnly: function (dateIn) {
        var dateValue = new Date(dateIn);
        var dateOnly = new Date(Date.UTC(dateValue.getUTCFullYear(), dateValue.getUTCMonth(), dateValue.getUTCDay(), 0, 0, 0));
        return dateOnly;
    }
};

export default dateUtil;