import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faExchangeAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import { InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Cleave from 'cleave.js/react';
import { AuthContext } from '../../../providers/authProvider';
import PSDScreenerActions from './PSDScreenerActions';
import PSDPrescriberActions from './PSDPrescriberActions';
import PSDAdministerActions from './PSDAdministerActions';

import { DTC210257D, DTC210357G, DTC210357G1, DTC210457B, DTC210557C, DTC210557H, DTC210957V, DTC211057D } from './DTCConfiguration/AzPsdConfiguration';
import {
    DTC210257E, DTC210357H, DTC210357H1, DTC210457L, DTC210957C, DTC210957T, DTC210957U, 
    DTC210957W, DTC210957X,
    DTC210957Y, DTC210957Z, DTC211157A, DTC211257F, DTC211257E,
    DTC220257A
} from './DTCConfiguration/PfPsdConfiguration';
import { DTC210457K, DTC210957D1, DTC220157B } from './DTCConfiguration/MoPsdConfiguration';
import { DTC210957G1 } from './DTCConfiguration/FluPsdConfiguration';
import { DTCFlu2InReview } from './DTCConfiguration/Flu2PsdConfiguration';

import questionManager from '../questionManager';
import moment from 'moment';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Row from 'reactstrap/lib/Row';
import Table from 'reactstrap/lib/Table';

const CovidVaccinationPSDPrint = ({ role, disallowAdministering, vaccineType, appointmentType, showingAsAdditionalPsd, multiplePsds, changeHandler, patientFirstName, patientLastName, patientDateofBirth, patientNHSNumber, patientHomeAddress, patientPostCode, PSDFirstDose, PSDSecondDose, PSDThirdDose, PSDBoosterDose, PSDFluDose, CurrentDate,
    seriousreaction, allergic, infectionorfeverish, bleedingdisorder, pregnant, breastfeeding, covidtrial, flujabpast7days, isAstraZeneca, gender, ethnicity, carer, isHealthcareWorker, worksInCareHome, covidpositive4weeks, covidpositive12weeks, bcgpast3months,
    assessorName, assessorAdAccount, assessorTimestamp, assessorComments, prescriberName, prescriberAdAccount, prescriberTimestamp, prescriberComments, prescriberRegistrationNumber, vaccinatorName, vaccinatorAdAccount, vaccinatorTimestamp, vaccinatorComments, batchNumber, batchExpiry, vaccinationSite, doseNumber, dtcReference, covidSymptoms,
    carehomeResident, carehomeWorker, residesInCarehome, updateAdminFields, saveCallback, submitting, confirmVaxMatch, cannotLocateOnNivs, confirmStaffCheck, confirmFluVaxCheck, confirmCovidVaxCheck, requiredCovidDose, finishedVaccinationCallback, cancelScreeningCallback, cancelPrescribingCallback, cancelAdministeringCallback,
    parentConsentForVaccine, parentMoreInformationNeededBeforeConsent, guardianFullName, guardianRelationshipToRecipient, patientConsentForVaccine, moreInformationNeededBeforeConsent, satisfiedWithConsent, outcomeAfterConsentDiscussion,
    thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, medicalcondition, capillaryleaksyndrome
}) => {

    const [editingFields, setEditingFields] = useState([]);
    const [loggedInUserName, setLoggedInUserName] = useState("");
    const [loggedInGivenName, setLoggedInGivenName] = useState("");
    const [loggedInSurname, setLoggedInSurname] = useState("");
    const [loggedInUpn, setLoggedInUpn] = useState("");
    const authContext = useContext(AuthContext);
    const isAuthenticated = authContext.isAuthenticated();
    const [psdContent, setPsdContent] = useState({});
    const [missingPsd, setMissingPsd] = useState();
    const [unsupportedPsd, setUnsupportedPsd] = useState(false);

    const [configuration, setConfiguration] = useState("UNKNOWN");
    const [loading, setLoading] = useState(true);
    const [discussionButtonClicked,setDiscussionButtonClicked] = useState(false);
    
    const DTCReferencesConfiguration = {
        PfAdult: '211257f',
        AzAdult: '211057d',
        MoAdult: '220157b',
        PfPaediatric: '211257e',
        Pf511: '220257a',
        FluAdult: '210957g1'
    }

    const DTCs = [DTC210257E, DTC210257D, DTC210357G, DTC210357H, DTC210357G1, DTC210357H1, DTC210457B, DTC210457K, DTC210457L, DTC210557C, DTC210557H, DTC210957C,
        DTC210957W, DTC210957X, DTC210957D1, DTC210957V, DTC210957T, DTC210957U, DTC210957Y, DTC210957Z, DTC210957G1, DTC211057D, DTC211157A, DTC211257F, DTC211257E, DTC220257A, DTC220157B];
  
    const today = new moment(new moment().format("DD/MM/YYYY"),"DD/MM/YYYY"); 
    

    const dateValidator = (val) => {
        const d = new moment(val.property, "DD/MM/YYYY"); 
        if(val.property.length !== 10 || !d.isValid() || d.diff(today, 'minutes') < 0) { 
            return "Must be today or in the future" 
        }; 
        return undefined;
    }

    const sharedQuestions = {
        patientFirstName: { firstPsdKey: 'forename', secondPsdKey: 'forename', thirdPsdKey: 'forename', boost1PsdKey: 'forename', fluPsdKey: 'forename', editableRoles: ['screening'], property: patientFirstName },
        patientLastName: { firstPsdKey: 'lastname', secondPsdKey: 'lastname', thirdPsdKey: 'lastname', boost1PsdKey: 'lastname', fluPsdKey: 'lastname',editableRoles: ['screening'], property: patientLastName },
        patientDateofBirth: { firstPsdKey: 'dateofbirth', secondPsdKey: 'dateofbirth', thirdPsdKey: 'dateofbirth', boost1PsdKey: 'dateofbirth', fluPsdKey: 'dateofbirth', editableRoles: ['screening'], property: patientDateofBirth, mask: 'DD/MM/YYYY', isMandatory: true },
        patientNHSNumber: { firstPsdKey: 'nhsnumber', secondPsdKey: 'nhsnumber', thirdPsdKey: 'nhsnumber', boost1PsdKey: 'nhsnumber', fluPsdKey: 'nhsnumber',editableRoles: ['screening'], property: patientNHSNumber},
        assessorname: { firstPsdKey: 'assessorname', secondPsdKey: 'assessorname2', thirdPsdKey: 'assessorname3', boost1PsdKey: 'assessornameboost1', fluPsdKey: 'assessornameflu1', editableRoles: ['screening'], property: assessorName },
        assessoradaccount: { firstPsdKey: 'assessoradaccount', secondPsdKey: 'assessoradaccount2', thirdPsdKey: 'assessoradaccount3', boost1PsdKey: 'assessoradaccountboost1', fluPsdKey: 'assessoradaccountflu1', editableRoles: ['screening'], property: assessorAdAccount },
        assessortimestamp: { firstPsdKey: 'assessortimestamp', secondPsdKey: 'assessortimestamp2', thirdPsdKey: 'assessortimestamp3', boost1PsdKey: 'assessortimestampboost1', fluPsdKey: 'assessortimestampflu1', editableRoles: ['screening'], property: assessorTimestamp },
        assessorcomments: { firstPsdKey: 'assessorcomments', secondPsdKey: 'assessorcomments2', thirdPsdKey: 'assessorcomments3', boost1PsdKey: 'assessorcommentsboost1', fluPsdKey: 'assessorcommentsflu1', editableRoles: ['screening'], property: assessorComments },
        prescribername: { firstPsdKey: 'prescribername', secondPsdKey: 'prescribername2', thirdPsdKey: 'prescribername3', boost1PsdKey: 'prescribernameboost1', fluPsdKey: 'prescribernameflu1',editableRoles: ['prescriber'], property: prescriberName },
        prescriberadaccount: { firstPsdKey: 'prescriberadaccount', secondPsdKey: 'prescriberadaccount2', thirdPsdKey: 'prescriberadaccount3', boost1PsdKey: 'prescriberadaccountboost1', fluPsdKey: 'prescriberadaccountflu1', editableRoles: ['prescriber'], property: prescriberAdAccount },
        prescribertimestamp: { firstPsdKey: 'prescribertimestamp', secondPsdKey: 'prescribertimestamp2', thirdPsdKey: 'prescribertimestamp3', boost1PsdKey: 'prescribertimestampboost1', fluPsdKey: 'prescribertimestampflu1', editableRoles: ['prescriber'], property: prescriberTimestamp },
        prescribercomments: { firstPsdKey: 'prescribercomments', secondPsdKey: 'prescribercomments2', thirdPsdKey: 'prescribercomments3', boost1PsdKey: 'prescribercommentsboost1', fluPsdKey: 'prescribercommentsflu1', editableRoles: ['prescriber'], property: prescriberComments },
        prescriberregistrationnumber: { firstPsdKey: 'prescriberregistrationnumber', secondPsdKey: 'prescriberregistrationnumber2', thirdPsdKey: 'prescriberregistrationnumber3', boost1PsdKey: 'prescriberregistrationnumberboost1', fluPsdKey: 'prescriberregistrationnumberflu1',editableRoles: ['prescriber'], property: prescriberRegistrationNumber, isMandatory:true },
        vaccinatorname: { firstPsdKey: 'vaccinatorname', secondPsdKey: 'vaccinatorname2', thirdPsdKey: 'vaccinatorname3', boost1PsdKey: 'vaccinatornameboost1', fluPsdKey: 'vaccinatornameflu1', editableRoles: ['administering'], property: vaccinatorName },
        vaccinatoradaccount: { firstPsdKey: 'vaccinatoradaccount', secondPsdKey: 'vaccinatoradaccount2', thirdPsdKey: 'vaccinatoradaccount3', boost1PsdKey: 'vaccinatoradaccountboost1', fluPsdKey: 'vaccinatoradaccountflu1', editableRoles: ['administering'], property: vaccinatorAdAccount },
        vaccinatortimestamp: { firstPsdKey: 'vaccinatortimestamp', secondPsdKey: 'vaccinatortimestamp2', thirdPsdKey: 'vaccinatortimestamp3', boost1PsdKey: 'vaccinatortimestampboost1', fluPsdKey: 'vaccinatortimestampflu1', editableRoles: ['administering'], property: vaccinatorTimestamp },
        vaccinatorcomments: { firstPsdKey: 'vaccinatorcomments', secondPsdKey: 'vaccinatorcomments2', thirdPsdKey: 'vaccinatorcomments3', boost1PsdKey: 'vaccinatorcommentsboost1', fluPsdKey: 'vaccinatorcommentsflu1', editableRoles: [, 'administering'], property: vaccinatorComments },
        batchnumber: { firstPsdKey: 'batchnumber', secondPsdKey: 'batchnumber2', thirdPsdKey: 'batchnumber3', boost1PsdKey: 'batchnumberboost1', fluPsdKey: 'batchnumberflu1', editableRoles: ['administering'], property: batchNumber || "", isMandatory:true },
        batchexpiry: { firstPsdKey: 'batchexpiry', secondPsdKey: 'batchexpiry2', thirdPsdKey: 'batchexpiry3', boost1PsdKey: 'batchexpiryboost1', fluPsdKey: 'batchexpiryflu1', editableRoles: ['administering'], property: batchExpiry || "", mask: 'DD/MM/YYYY', isMandatory:true, validator: dateValidator},
        doseNumber: { firstPsdKey: 'dosenumber', secondPsdKey: 'dosenumber2', thirdPsdKey: 'dosenumber3', boost1PsdKey: 'dosenumberboost1', fluPsdKey: 'dosenumberflu1', editableRoles: ['administering'], property: doseNumber || "", isMandatory:true },
        vaccinationsite: { firstPsdKey: 'vaccinationsite', secondPsdKey: 'vaccinationsite2', thirdPsdKey: 'vaccinationsite3', boost1PsdKey: 'vaccinationsiteboost1', fluPsdKey: 'vaccinationsiteflu1', editableRoles: ['administering'], property: vaccinationSite, dropdownList:['Left', 'Right'], isMandatory:true },
        dtcreference: { firstPsdKey: 'dtcreference', secondPsdKey: 'dtcreference2', thirdPsdKey: 'dtcreference3', boost1PsdKey: 'dtcreferenceboost1', fluPsdKey: 'dtcreferenceflu1', editableRoles: [], property: dtcReference },
        patientIsCarer: { firstPsdKey: 'carer', secondPsdKey: 'carer', thirdPsdKey: 'carer', boost1PsdKey: 'carer', fluPsdKey: 'carer', editableRoles: ['screening'],  property: carer },
        patientEthnicity: { firstPsdKey: 'ethnicity', secondPsdKey: 'ethnicity', thirdPsdKey: 'ethnicity', boost1PsdKey: 'ethnicity', fluPsdKey: 'ethnicity', editableRoles: ['screening'], property: ethnicity, dropdownList: questionManager.getEthnicities() },
        carehomeResident: { firstPsdKey: 'carehomeresident', secondPsdKey: 'carehomeresident', thirdPsdKey: 'carehomeresident', boost1PsdKey: 'carehomeresident', fluPsdKey: 'carehomeresident', editableRoles: ['screening'], property: residesInCarehome },
        carehomeWorker: { firstPsdKey: 'carehomeworker', secondPsdKey: 'carehomeworker', thirdPsdKey: 'carehomeworker', boost1PsdKey: 'carehomeworker', fluPsdKey: 'carehomeworker', editableRoles: ['screening'], property: worksInCareHome },
        confirmvaxmatch: { firstPsdKey: 'confirmvaxmatch', secondPsdKey: 'confirmvaxmatch2', thirdPsdKey: 'confirmvaxmatch3', boost1PsdKey: 'confirmvaxmatchboost1', fluPsdKey: 'confirmvaxmatchflu1', editableRoles: ['screening'], property: confirmVaxMatch },
        confirmstaffcheck: { firstPsdKey: 'confirmstaffcheck', secondPsdKey: 'confirmstaffcheck2', thirdPsdKey: 'confirmstaffcheck3', boost1PsdKey: 'confirmstaffcheckboost1', fluPsdKey: 'confirmstaffcheckflu1', editableRoles: ['screening'], property: confirmStaffCheck },
        confirmcovidvaxcheck: { fluPsdKey: 'confirmcovidvaxcheckflu1', psdKeyValues: ['Yes', 'No (Defer)', 'No (Unsure)'], editableRoles: ['screening'], property: confirmCovidVaxCheck },
        confirmfluvaxcheck: { firstPsdKey: 'confirmfluvaxcheck', secondPsdKey: 'confirmfluvaxcheck2', thirdPsdKey: 'confirmfluvaxcheck3', boost1PsdKey: 'confirmfluvaxcheckboost1', psdKeyValues: ['Yes', 'No (Defer)', 'No (Unsure)'], editableRoles: ['screening'], property: confirmFluVaxCheck },
        requiredcoviddose: { fluPsdKey: 'requiredcoviddoseflu1', dropdownList: ["1st", "2nd", "3rd", "Booster"], editableRoles: ['screening'], property: requiredCovidDose },
        cannotlocateonnivs: { firstPsdKey: 'cannotlocateonnivs', secondPsdKey: 'cannotlocateonnivs2', thirdPsdKey: 'cannotlocateonnivs3', boost1PsdKey: 'cannotlocateonnivsboost1', fluPsdKey: 'cannotlocateonnivsflu1', editableRoles: ['screening'], property: cannotLocateOnNivs },
        guardianFullName: { firstPsdKey: 'guardianfullname', secondPsdKey: 'guardianfullname2', thirdPsdKey: 'guardianfullname3', boost1PsdKey: 'guardianfullnameboost1', fluPsdKey: 'guardianfullnameflu1', editableRoles: ['screening'], property: guardianFullName, isMandatory: true },
        guardianRelationshipToRecipient: { firstPsdKey: 'guardianrelationshiptorecipient', secondPsdKey: 'guardianrelationshiptorecipient2', thirdPsdKey: 'guardianrelationshiptorecipient3', boost1PsdKey: 'guardianrelationshiptorecipientboost1', fluPsdKey: 'guardianrelationshiptorecipientflu1', editableRoles: ['screening'], property: guardianRelationshipToRecipient, isMandatory: true },
    }

    useEffect(() => {
        const dtcNumber = dtcReference || DTCReferencesConfiguration[vaccineType + appointmentType];

        if(!dtcNumber) return;

        if(dtcNumber){
            if(dtcReference !== dtcNumber) {
                if (asBool(PSDFirstDose)) {
                    changeHandler('dtcreference', dtcNumber);
                } else if (asBool(PSDSecondDose)) {
                    changeHandler('dtcreference2', dtcNumber);
                } else if (asBool(PSDThirdDose)) {
                    changeHandler('dtcreference3', dtcNumber);
                } else if (asBool(PSDBoosterDose)) {
                    changeHandler('dtcreferenceboost1', dtcNumber);
                }
            }
        }
        
        var activeDtc = DTCs.find(x => x.dtcNumber === dtcNumber)
        if(!activeDtc){
            setMissingPsd(dtcNumber);
            setLoading(false);
            return;
        }
        if (!activeDtc.content.supportsThirdDose && asBool(PSDThirdDose)) {
            setUnsupportedPsd("Unsupported ePSD. " + activeDtc.content.vaccineFriendlyName + " cannot be used as a 3rd dose vaccine at this time.");
            setLoading(false);
            return;
        }
        if (!activeDtc.content.supportsBoosterDose && asBool(PSDBoosterDose)) {
            setUnsupportedPsd("Unsupported ePSD. " + activeDtc.content.vaccineFriendlyName + " cannot be used as a booster dose vaccine at this time.");
            setLoading(false);
            return;
        }
        setPsdContent(activeDtc.content)
        var dtcQuestions = activeDtc.getQuestions(infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidSymptoms, patientConsentForVaccine, moreInformationNeededBeforeConsent, satisfiedWithConsent, outcomeAfterConsentDiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentConsentForVaccine, parentMoreInformationNeededBeforeConsent, medicalcondition, capillaryleaksyndrome, covidpositive12weeks);
        setConfiguration({...sharedQuestions, ...dtcQuestions});
        setLoading(false);
        
    }, [vaccineType, showingAsAdditionalPsd, multiplePsds, confirmVaxMatch, confirmStaffCheck, confirmFluVaxCheck, confirmCovidVaxCheck, requiredCovidDose, patientFirstName, patientLastName, patientDateofBirth, patientNHSNumber, patientHomeAddress, patientPostCode,
        PSDFirstDose, PSDSecondDose, PSDThirdDose, PSDBoosterDose, PSDFluDose, CurrentDate,
        seriousreaction, allergic, infectionorfeverish, bleedingdisorder, pregnant, breastfeeding, covidtrial,
        flujabpast7days, isAstraZeneca, gender, ethnicity, carer, isHealthcareWorker, worksInCareHome, covidpositive4weeks, covidpositive12weeks, bcgpast3months,
        assessorName, assessorAdAccount, assessorTimestamp, assessorComments, prescriberName, prescriberAdAccount, prescriberTimestamp, prescriberComments, 
        vaccinatorName, vaccinatorAdAccount, vaccinatorTimestamp, vaccinatorComments, batchNumber, batchExpiry, vaccinationSite, doseNumber, dtcReference, 
        residesInCarehome, covidSymptoms, patientConsentForVaccine, moreInformationNeededBeforeConsent, satisfiedWithConsent, outcomeAfterConsentDiscussion,
        thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentConsentForVaccine, parentMoreInformationNeededBeforeConsent, guardianFullName, guardianRelationshipToRecipient, medicalcondition, capillaryleaksyndrome])
   
    useEffect(() => {
        const getUser = async () => {
            if (isAuthenticated) {
                const loggedInUser = await authContext.getUser();
                setLoggedInUserName(loggedInUser.profile.upn)
                setLoggedInGivenName(loggedInUser.profile.unique_name);
               /* setLoggedInSurname(loggedInUser.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']);
                setLoggedInUpn(loggedInUser.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn']);*/
            } 
        }

        getUser();
    }, [])

    const asBool = (value, defaultVal) => {
        return value ? value === "Yes" : (defaultVal ?? true);
    }


    const sign = (role, callback) => {
        var doseKey = asBool(PSDFluDose) ? 'fluPsdKey' : asBool(PSDFirstDose) ? 'firstPsdKey' : (asBool(PSDSecondDose) ? 'secondPsdKey' : (asBool(PSDThirdDose) ? 'thirdPsdKey' : 'boost1PsdKey'));

        switch(role) {
            case 'assessor':
                changeHandler(configuration['assessorname'][doseKey], `${loggedInGivenName} ${loggedInSurname ? loggedInSurname : ""}`,
                    ()=> changeHandler(configuration['assessoradaccount'][doseKey], `${loggedInUserName}`, 
                    ()=> changeHandler(configuration['assessortimestamp'][doseKey], `${new moment().format("DD-MMM-YYYY")}`, 
                    ()=> changeHandler(configuration['prescribername'][doseKey], ``,
                    ()=> changeHandler(configuration['prescriberadaccount'][doseKey], ``, 
                    ()=> changeHandler(configuration['prescribertimestamp'][doseKey], ``,
                    ()=> changeHandler(configuration['vaccinatorname'][doseKey], ``,
                    ()=> changeHandler(configuration['vaccinatoradaccount'][doseKey], ``, 
                    ()=> changeHandler(configuration['vaccinatortimestamp'][doseKey], ``, 
                    callback)))))))));
                break;
            case 'prescriber':
                changeHandler(configuration['prescribername'][doseKey], `${loggedInGivenName} ${loggedInSurname ? loggedInSurname : ""}`,
                    ()=>changeHandler(configuration['prescriberadaccount'][doseKey], `${loggedInUserName}`, 
                    ()=>changeHandler(configuration['prescribertimestamp'][doseKey], `${new moment().format("DD-MMM-YYYY")}`, callback)
                )
            );
                break;
            case 'vaccinator':
                changeHandler(configuration['vaccinatorname'][doseKey], `${loggedInGivenName} ${loggedInSurname ? loggedInSurname : ""}`,
                () => {changeHandler(configuration['vaccinatoradaccount'][doseKey], `${loggedInUserName}`, 
                    ()=>changeHandler(configuration['vaccinatortimestamp'][doseKey], `${new moment().format("DD-MMM-YYYY")}`, callback)
                )}
            );
                break;
        }
    }

    const clearSignature = (role) => {
        var doseKey = asBool(PSDFluDose) ? 'fluPsdKey' : asBool(PSDFirstDose) ? 'firstPsdKey' : (asBool(PSDSecondDose) ? 'secondPsdKey' : (asBool(PSDThirdDose) ? 'thirdPsdKey' : 'boost1PsdKey'));

        switch(role) {
            case 'assessor':
                changeHandler(configuration['assessorname'][doseKey], ``,
                    () => {changeHandler(configuration['assessoradaccount'][doseKey], ``, 
                        ()=>changeHandler(configuration['assessortimestamp'][doseKey], ``)
                    )}
                );
                break;
            case 'prescriber':
                changeHandler(configuration['prescribername'][doseKey], ``,
                () => {changeHandler(configuration['prescriberadaccount'][doseKey], ``, 
                    ()=>changeHandler(configuration['prescribertimestamp'][doseKey], ``)
                )}
            );
                break;
            case 'vaccinator':
                changeHandler(configuration['vaccinatorname'][doseKey], ``,
                () => {changeHandler(configuration['vaccinatoradaccount'][doseKey], ``, 
                    ()=>changeHandler(configuration['vaccinatortimestamp'][doseKey], ``)
                )}
            );
                break;
        }
    }

    const prescriberOutcome = (outcome, preventAdditional) => {
        if (outcome !== 'DiscussionNeeded') {
            if (outcome === "SendToRedStream") {
                if (saveCallback) {
                    saveCallback(true, outcome);
                }
            }
            else {
                sign('prescriber', () => {
                    updateAdminFields(true, (success) => {
                        if (saveCallback) {
                            saveCallback(success, outcome, preventAdditional, prescriberComments ? true : false, ((psdContent.boosterUsesHalfDose || false) && asBool(PSDBoosterDose)));
                        }
                    });
                })
            }
            
        } else {
            setDiscussionButtonClicked(true);
            updateAdminFields(true, (success) => {
                if (saveCallback) {
                    saveCallback(success, outcome);
                }
            });
        }
    }

    const cannotSubmitForPrescribing = () => {

        if(asBool(PSDFluDose)) {
            return submitting
                || (!patientDateofBirth || patientDateofBirth.length < 10)
                || !bcgpast3months
                || !infectionorfeverish
                || !bleedingdisorder
                || !allergic
                || !pregnant
                || !breastfeeding
                || !covidtrial
                || !medicalcondition
                || (!patientConsentForVaccine && !parentConsentForVaccine)
                || (patientConsentForVaccine === "No" && !moreInformationNeededBeforeConsent)
                || (parentConsentForVaccine === "No" && (!parentMoreInformationNeededBeforeConsent || parentMoreInformationNeededBeforeConsent === "No"))
                || (!showingAsAdditionalPsd && (!confirmStaffCheck))
                || (!showingAsAdditionalPsd && (confirmCovidVaxCheck === "Yes" && !requiredCovidDose));
        }

        return submitting
            || (!patientDateofBirth || patientDateofBirth.length < 10)
            || !confirmVaxMatch
            || !flujabpast7days
            || (hasBcgQuestion && !bcgpast3months)
            || !infectionorfeverish
            || !allergic
            || !seriousreaction
            || !pregnant
            || !breastfeeding
            || !covidtrial
            || !bleedingdisorder
            || !covidSymptoms
            || (hasCovidPositive4WeeksQuestion && !covidpositive4weeks)
            || (hasCovidPositive12WeeksQuestion && !covidpositive12weeks)
            || (!patientConsentForVaccine && !parentConsentForVaccine)
            || (patientConsentForVaccine === "No" && !moreInformationNeededBeforeConsent)
            || (parentConsentForVaccine === "No" && !parentMoreInformationNeededBeforeConsent)
            || (psdContent.requireGuardianConsentDetails && parentConsentForVaccine === "Yes" && (!guardianFullName || !guardianRelationshipToRecipient))
            || (psdContent.requireGuardianConsentDetails && parentConsentForVaccine === "No" && parentMoreInformationNeededBeforeConsent === "Yes" && (!guardianFullName || !guardianRelationshipToRecipient))
            || (asBool(PSDSecondDose) && confirmVaxMatch !== "Yes");
    }

    const isMoreInformationNeededBeforeConsent = () => {
        if (psdContent.includeParentConsentQuestions) {
            if (patientConsentForVaccine === "No" || parentConsentForVaccine === "No") {
                if (patientConsentForVaccine && parentConsentForVaccine) {
                    if (moreInformationNeededBeforeConsent === "Yes" || parentMoreInformationNeededBeforeConsent === "Yes") {
                        return "Yes";
                    }
                    if (moreInformationNeededBeforeConsent === "No" && parentMoreInformationNeededBeforeConsent === "No") {
                        return "No";
                    }
                }
                if (patientConsentForVaccine && !parentConsentForVaccine) {
                    return moreInformationNeededBeforeConsent;
                }
                if (!patientConsentForVaccine && parentConsentForVaccine) {
                    return parentMoreInformationNeededBeforeConsent;
                }
            }
            return "";
        }
        return moreInformationNeededBeforeConsent;
    }

    const submitToPrescriber = (currentPsd, additionalPsd) => {
        const patientAge = (patientDateofBirth && patientDateofBirth.length === 10 && getAge(patientDateofBirth) !== "n/a" && getAge(patientDateofBirth)) || 0;
        const stream = flujabpast7days === "Yes"
        || bcgpast3months === "Yes"
        || infectionorfeverish === "Yes"
        || allergic === "Yes"
        || seriousreaction === "Yes"
        || pregnant === "Yes"
        || breastfeeding === "Yes"
        || covidtrial === "Yes"
        || bleedingdisorder === "Yes"
        || covidSymptoms === "Yes"
        || covidpositive4weeks === "Yes"
        || covidpositive12weeks === "Yes"
        || confirmVaxMatch === "No"
        || thirtyandfirstdose === "Yes"
        || lowplateletcountafterfirst === "Yes"
        || historyofthrombosis === "Yes"
        || historyofbleedingdisorder === "Yes"
        || medicalcondition === "Yes"
        || (patientAge < 18)
        || (patientConsentForVaccine === "No" && moreInformationNeededBeforeConsent === "Yes") ? 'Red' : 'Green';

        sign('assessor', () => {
            updateAdminFields(true, (success) => {
                if(saveCallback)
                {
                    saveCallback(success, stream, currentPsd, additionalPsd);
                }
            });
        })
    }

    const finishedAdministration = (vaccinated, currentPsd, additionalPsd) => {
        if(vaccinated) {
            sign('vaccinator', () => {
                updateAdminFields(true, (success) => {
                    if(finishedVaccinationCallback)
                    {
                        finishedVaccinationCallback(vaccinated, currentPsd, additionalPsd);
                    }
                });
            })
        } else {
            updateAdminFields(true, (success) => {
                if(finishedVaccinationCallback)
                {
                    finishedVaccinationCallback(vaccinated, currentPsd, additionalPsd);

                }
            });
        }
    }

    const cancelScreening = () => {
        if (cancelScreeningCallback) {
            cancelScreeningCallback();
        }
    }

    const cancelPrescribing = () => {
        if (cancelPrescribingCallback) {
            cancelPrescribingCallback();
        }
    }

    const cancelAdministering = () => {
        if (cancelAdministeringCallback) {
            cancelAdministeringCallback();
        }
    }

    const getEditableField = (propertyName, readonly) => {
        var propertyDef = configuration[propertyName];

        if(!propertyDef) {
            return <>Can't find definition: {propertyName}</>
        }

        const key = asBool(PSDFluDose) ? propertyDef.fluPsdKey :  asBool(PSDFirstDose) ? propertyDef.firstPsdKey : (asBool(PSDSecondDose) ? propertyDef.secondPsdKey : (asBool(PSDThirdDose) ? propertyDef.thirdPsdKey : propertyDef.boost1PsdKey));

            if (propertyDef.dropdownList) {
                return <>
                     {!readonly && propertyDef.editableRoles.indexOf(role) !== -1 ?
                        <Input type="select" value={propertyDef.property} name={propertyName} style={{ width: "100%" }} onChange={(e) => {
                            changeHandler(key, e.target.value)
                        }}
                        style={{borderColor: propertyDef.isMandatory ? "red" : ""}}
                        ><option value="">Please select</option>
                            {propertyDef.dropdownList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}</Input> :
                        <>{propertyDef.property}</>
                    }
                </>;
            }
            if (propertyDef.mask && propertyDef.mask === "DD/MM/YYYY") {
                return <>
                    {readonly || propertyDef.editableRoles.indexOf(role) === -1 ?
                        <>
                            {propertyDef.editableRoles.indexOf(role) !== -1 && propertyDef.isMandatory && !propertyDef.property ? <span style={{border: "1px solid red", fontWeight: "bold", color: "red", padding: "2px"}}>Required! Please complete</span> : propertyDef.property}
                        </>
                        :
                        <><Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={propertyDef.property} onChange={(e) => {
                            changeHandler(key, e.target.value) }}
                            style={{borderColor: propertyDef.isMandatory ? "red" : ""}}
                        /> 
                            {propertyDef.validator &&
                                <small className="text-danger">{propertyDef.validator(propertyDef)}</small>
                            }
                        </>
                    }
                </>;
            }
            return <>
                {readonly || propertyDef.editableRoles.indexOf(role) === -1 ?
                    <>
                        {propertyDef.property}
                    </>
                    :
                    <Input value={propertyDef.property} name={propertyName} style={{ width: "100%",borderColor: propertyDef.isMandatory ? "red" : ""}} onChange={(e) => {
                        changeHandler(key, e.target.value)
                    }}
                    ></Input>
                }
            </>;
    }

    const getEditableFieldWithTextAddon = (propertyName, readonly, addonText, addonProblem) => {
        var propertyDef = configuration[propertyName];

        if(!propertyDef) {
            return <>Can't find definition: {propertyName}</>
        }

        const key = asBool(PSDFluDose) ? propertyDef.fluPsdKey : asBool(PSDFirstDose) ? propertyDef.firstPsdKey : (asBool(PSDSecondDose) ? propertyDef.secondPsdKey : (asBool(PSDThirdDose) ? propertyDef.thirdPsdKey : propertyDef.boost1PsdKey));

            if (propertyDef.dropdownList) {
                return <>
                     {!readonly && propertyDef.editableRoles.indexOf(role) !== -1 ?
                        <Input type="select" value={propertyDef.property} name={propertyName} style={{ width: "100%" }} onChange={(e) => {
                            changeHandler(key, e.target.value)
                        }}
                        style={{borderColor: propertyDef.isMandatory ? "red" : ""}}
                        ><option value="">Please select</option>
                            {propertyDef.dropdownList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}</Input> :
                        <>{propertyDef.property}</>
                    }
                </>;
            }
            if (propertyDef.mask && propertyDef.mask === "DD/MM/YYYY") {
                return <>
                    {readonly || propertyDef.editableRoles.indexOf(role) === -1 ?
                        <>
                            {propertyDef.editableRoles.indexOf(role) !== -1 && propertyDef.isMandatory && !propertyDef.property ? <span style={{ border: "1px solid red", fontWeight: "bold", color: "red", padding: "2px" }}>Required! Please complete</span> : (<span>{propertyDef.property}&nbsp;<strong><span style={{ color: addonProblem ? "red" : "black" }}>{addonText}</span></strong></span>)}
                        </>
                        :
                        <><InputGroup><Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={propertyDef.property} onChange={(e) => {
                            changeHandler(key, e.target.value) }}
                            style={{borderColor: propertyDef.isMandatory ? "red" : ""}}
                        /> 
                            {propertyDef.validator &&
                                <small className="text-danger">{propertyDef.validator(propertyDef)}</small>
                            }<InputGroupAddon addonType="append"><InputGroupText><strong><span style={{ color: addonProblem ? "red" : "black" }}>{addonText}</span></strong></InputGroupText></InputGroupAddon></InputGroup>
                        </>
                    }
                </>;
            }
            return <>
                {readonly || propertyDef.editableRoles.indexOf(role) === -1 ?
                    <>
                        {propertyDef.property}&nbsp;<strong>{addonText}</strong>test
                    </>
                    :
                    <InputGroup><Input value={propertyDef.property} name={propertyName} style={{ width: "100%",borderColor: propertyDef.isMandatory ? "red" : ""}} onChange={(e) => {
                        changeHandler(key, e.target.value)
                    }}
                    ></Input><InputGroupAddon addonType="append"><InputGroupText><strong>{addonText}</strong></InputGroupText></InputGroupAddon></InputGroup>
                }
            </>;
    }

    const getAge = (dateString) => {
        if (dateString && dateString.length === 10) {
            let year = dateString.substr(6, 4);
            let month = dateString.substr(3, 2);
            let day = dateString.substr(0, 2);
            const today = new Date();
            const birthDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return "n/a";
    }

    const safeToggle = (propertyDef, key, defaultVal, answerDef) => {
        if(propertyDef.editableRoles.indexOf(role) !== -1) {
            if(answerDef) {
                if(propertyDef.property !== answerDef.positive) { changeHandler(key, answerDef.positive)} else { changeHandler(key, answerDef.negative )}
            } else {
                if (asBool(propertyDef.property, defaultVal)) { changeHandler(key, 'No') } else { changeHandler(key, 'Yes') }
            }
        }
    }

    const safeToggleList = (propertyDef, key, defaultVal, values) => {
        if (propertyDef.editableRoles.indexOf(role) !== -1) {
            if (!propertyDef.property) {
                changeHandler(key, values[0]);
            } else {
                const existingMatch = values.indexOf(propertyDef.property);
                if (existingMatch === values.length - 1) {
                    changeHandler(key, values[0]);
                } else {
                    changeHandler(key, values[existingMatch + 1]);
                }
            }
        }
    }

    const getToggle = (propertyDef, readonly) => {

        if(propertyDef.answers) {
            return <>
                <div>
                    {propertyDef.property === propertyDef.answers.positive ? <><span className="text-success" style={{whiteSpace:"nowrap"}}><FontAwesomeIcon icon={faCheck} color="success" className="text-success" />{propertyDef.answers.positive}</span> </> : ""}
                    {propertyDef.property === propertyDef.answers.negative ? <><span className="text-danger" style={{whiteSpace:"nowrap"}}><FontAwesomeIcon color="danger" icon={faTimes} className="text-danger text-center no"/>{propertyDef.answers.negative}</span></> : ""}
                    {!propertyDef.property ? <><span>-</span></> : ""}
                </div>
                {!readonly && propertyDef.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </>
        }

        return <>
            <div>
                {propertyDef.property === "Yes" ? <><span className="text-danger"><FontAwesomeIcon icon={faTimes} color="danger" className="text-danger" />Yes</span> </> : ""}
                {propertyDef.property === "No" ? <><span className="text-success"><FontAwesomeIcon color="success" icon={faCheck} className="text-success text-center" />No</span></> : ""}
                {!propertyDef.property ? <><span>-</span></> : ""}
            </div>
            {!readonly && propertyDef.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
        </>
    }

    const getToggleField = (propertyName, readonly) => {
        var propertyDef = configuration[propertyName];

        if(!propertyDef) {
            return <></>
        }
        const key = asBool(PSDFluDose) ? propertyDef.fluPsdKey : asBool(PSDFirstDose) ? propertyDef.firstPsdKey : (asBool(PSDSecondDose) ? propertyDef.secondPsdKey : (asBool(PSDThirdDose) ? propertyDef.thirdPsdKey : propertyDef.boost1PsdKey))
        return <tr> 
            <td>{propertyDef.question()}</td>
            <td className="text-center" style={{cursor:"pointer"}} onClick={() => { if(!readonly) { safeToggle(propertyDef, key, null, propertyDef.answers)  }}}>
                {getToggle(propertyDef, readonly)}
            </td>
            <td>
                { propertyDef.answers ? <>
                        {propertyDef.property === propertyDef.answers.positive ? propertyDef.yesAnswer() : <></>} 
                        {propertyDef.property === propertyDef.answers.negative ? propertyDef.noAnswer() : <></>}
                    </>
                    : <>
                        {propertyDef.property === "Yes" ? propertyDef.yesAnswer() : <></>} 
                        {propertyDef.property === "No" ? propertyDef.noAnswer() : <></>}
                    </>
                }
            </td>
        </tr>
    }

    const getInfoToggleField = (propertyName) => {
        var propertyDef = configuration[propertyName];

        if(!propertyDef) {
            return <td>Can't find definition: {propertyName}</td>
        }

        if(asBool(PSDFirstDose)) {
            return <> 
                <td className="text-center" style={{cursor:"pointer"}} onClick={() => { safeToggle(propertyDef, propertyDef.firstPsdKey, false) }}>
                    <div>
                        {asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" />Yes</span> </> : ""}
                        {!asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" /> No</span> </> : ""}
                    </div>
                    {propertyDef.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
                </td>
            </>
        }

        if(asBool(PSDSecondDose)) {
            return <> 
            <td className="text-center" style={{cursor:"pointer"}} onClick={() => {  safeToggle(propertyDef, propertyDef.secondPsdKey, false)}}>
                <div>
                    {asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" />Yes</span> </> : ""}
                    {!asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" /> No</span> </> : ""}
                </div>
                {propertyDef.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </td>
        </>
        }

        if (asBool(PSDThirdDose)) {
            return <>
                <td className="text-center" style={{ cursor: "pointer" }} onClick={() => { safeToggle(propertyDef, propertyDef.thirdPsdKey, false) }}>
                    <div>
                        {asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" />Yes</span> </> : ""}
                        {!asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" /> No</span> </> : ""}
                    </div>
                    {propertyDef.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
                </td>
            </>
        }

        if (asBool(PSDBoosterDose)) {
            return <>
                <td className="text-center" style={{ cursor: "pointer" }} onClick={() => { safeToggle(propertyDef, propertyDef.boost1PsdKey, false) }}>
                    <div>
                        {asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" />Yes</span> </> : ""}
                        {!asBool(propertyDef.property, false) ? <><span><FontAwesomeIcon icon={faCheck} className="text-center" /> No</span> </> : ""}
                    </div>
                    {propertyDef.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
                </td>
            </>
        }
    }

    const toggleNivsQuestion = (readonly) => {
        var key = asBool(PSDFirstDose) ? configuration['cannotlocateonnivs'].firstPsdKey : (asBool(PSDSecondDose) ? configuration['cannotlocateonnivs'].secondPsdKey : (asBool(PSDThirdDose) ? configuration['cannotlocateonnivs'].thirdPsdKey : (asBool(PSDBoosterDose) ? configuration['cannotlocateonnivs'].boost1PsdKey : configuration['cannotlocateonnivs'].fluPsdKey)));
        if(configuration['cannotlocateonnivs'].editableRoles.indexOf(role) !== -1 && !readonly) {
            if(cannotLocateOnNivs === "Yes") {
                changeHandler(key, "No")
            } else {
                changeHandler(key, "Yes")
            }
        }
    }

    const getVaxMatch = (readonly) => {
        const config = configuration['confirmvaxmatch'];
        const key = asBool(PSDFirstDose) ? config.firstPsdKey : (asBool(PSDSecondDose) ? config.secondPsdKey : (asBool(PSDThirdDose) ? config.thirdPsdKey : (asBool(PSDBoosterDose) ? config.boost1PsdKey : config.fluPsdKey)));

        return <td className="text-center" style={{cursor:"pointer", width:"100px"}} onClick={() => { if(!assessorAdAccount ) { safeToggle(config, key, false)}}}>
            <>
                <div>
                    {config.property === "Yes" ? <><span className="text-success"><FontAwesomeIcon icon={faCheck} color="success"/>Yes</span> </> : ""}
                    {config.property === "No" ? <><span className="text-danger"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />No</span></> : ""}
                    {!config.property ? <><span>-</span></> : ""}
                </div>
                {!assessorAdAccount && config.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </>
        </td>
    }

    const getVaxMatch3rdOnwards = (readonly) => {
        const config = configuration['confirmvaxmatch'];
        const key = asBool(PSDFirstDose) ? config.firstPsdKey : (asBool(PSDSecondDose) ? config.secondPsdKey : (asBool(PSDThirdDose) ? config.thirdPsdKey : (asBool(PSDBoosterDose) ? config.boost1PsdKey : config.fluPsdKey)));

        return <td className="text-center" style={{ cursor: "pointer", width: "100px" }} onClick={() => { if (!readonly) { safeToggle(config, key, false) } }}>
            <>
                <div>
                    {config.property === "Yes" ? <><span className="text-success"><FontAwesomeIcon icon={faCheck} color="success" />Yes</span> </> : ""}
                    {config.property === "No" ? <><span className="text-danger"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />{"No/Unsure"}</span></> : ""}
                    {!config.property ? <><span>-</span></> : ""}
                </div>
                {!readonly && config.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </>
        </td>
    }

    const getStaffCheck = (readonly) => {
        const config = configuration['confirmstaffcheck'];
        const key = asBool(PSDFirstDose) ? config.firstPsdKey : (asBool(PSDSecondDose) ? config.secondPsdKey : (asBool(PSDThirdDose) ? config.thirdPsdKey : (asBool(PSDBoosterDose) ? config.boost1PsdKey : config.fluPsdKey)));

        return <td className="text-center" style={{ cursor: "pointer", width: "100px" }} onClick={() => { if (!assessorAdAccount) { safeToggle(config, key, true) } }}>
            <>
                <div>
                    {config.property === "Yes" ? <><span className="text-success"><FontAwesomeIcon icon={faCheck} color="success" />Yes</span> </> : ""}
                    {config.property === "No" ? <><span className="text-success"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />No/Not Staff</span></> : ""}
                    {!config.property ? <><span>-</span></> : ""}
                </div>
                {!assessorAdAccount && config.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </>
        </td>
    }

    const getCovidVaxCheck = (readonly) => {
        const config = configuration['confirmcovidvaxcheck'];
        const key = asBool(PSDFirstDose) ? config.firstPsdKey : (asBool(PSDSecondDose) ? config.secondPsdKey : (asBool(PSDThirdDose) ? config.thirdPsdKey : (asBool(PSDBoosterDose) ? config.boost1PsdKey : config.fluPsdKey)));
        const values = config.psdKeyValues;

        return <td className="text-center" style={{ cursor: "pointer", width: "100px" }} onClick={() => { if (!readonly) { safeToggleList(config, key, false, values) } }}>
            <>
                <div>
                    {config.property === "Yes" ? <><span className="text-success"><FontAwesomeIcon icon={faCheck} color="success" />Yes</span> </> : ""}
                    {config.property === "No (Defer)" ? <><span className="text-success"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />No (Defer)</span></> : ""}
                    {config.property === "No (Unsure)" ? <><span className="text-success"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />No (Unsure)</span></> : ""}
                    {!config.property ? <><span>-</span></> : ""}
                </div>
                {!readonly && config.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </>
        </td>
    }

    const getFluVaxCheck = (readonly) => {
        const config = configuration['confirmfluvaxcheck'];
        const key = asBool(PSDFirstDose) ? config.firstPsdKey : (asBool(PSDSecondDose) ? config.secondPsdKey : (asBool(PSDThirdDose) ? config.thirdPsdKey : (asBool(PSDBoosterDose) ? config.boost1PsdKey : config.fluPsdKey)));
        const values = config.psdKeyValues;

        return <td className="text-center" style={{ cursor: "pointer", width: "100px" }} onClick={() => { if (!readonly) { safeToggleList(config, key, false, values) } }}>
            <>
                <div>
                    {config.property === "Yes" ? <><span className="text-success"><FontAwesomeIcon icon={faCheck} color="success" />Yes</span> </> : ""}
                    {config.property === "No (Defer)" ? <><span className="text-success"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />No (Defer)</span></> : ""}
                    {config.property === "No (Unsure)" ? <><span className="text-success"><FontAwesomeIcon color="danger" icon={faTimes} className="text-center" />No (Unsure)</span></> : ""}
                    {!config.property ? <><span>-</span></> : ""}
                </div>
                {!readonly && config.editableRoles.indexOf(role) !== -1 && <FontAwesomeIcon icon={faExchangeAlt} />}
            </>
        </td>
    }

    const getDoseIndicationText = (psdContent) => {
        if (psdContent.supportsBoosterDose) {
            if (psdContent.supportsThirdDose) {
                return "first, second, third or booster";
            }
            else {
                return "first, second or booster";
            }
        }
        else if (psdContent.supportsThirdDose) {
            return "first, second or third";
        }
        else {
            return "first or second";
        }
    }

    const getCurrentDoseType = () => {
        if (asBool(PSDFirstDose)) {
            return "first";
        }
        if (asBool(PSDSecondDose)) {
            return "second";
        }
        if (asBool(PSDThirdDose)) {
            return "third";
        }
        if (asBool(PSDBoosterDose)) {
            return "boost1";
        }
        if (asBool(PSDFluDose)) {
            return "flu1";
        }
        return "";
    }

    if(loading) {
        return <></>
    }

    if(missingPsd) {
        return <>Missing PSD Definition: {missingPsd}</>
    }

    if (unsupportedPsd) {
        return <>{unsupportedPsd}</>
    }

    const currentAge = getAge(patientDateofBirth);
    const isUnder18 = (currentAge && currentAge !== 'n/a' && currentAge < 18) ? true : false;
    const bcgQuestion = configuration['bcgpast3months'];
    const hasBcgQuestion = bcgQuestion ? true : false;
    const covidPositive4WeeksQuestion = configuration['covidpositive4weeks'];
    const hasCovidPositive4WeeksQuestion = covidPositive4WeeksQuestion ? true : false;
    const covidPositive12WeeksQuestion = configuration['covidpositive12weeks'];
    const hasCovidPositive12WeeksQuestion = covidPositive12WeeksQuestion ? true : false;

    return <div id="" style={{ marginBottom: '40px', overflowY: "auto", paddingLeft: "20px", paddingRight: "20px", borderWidth: "10px", borderStyle: "solid", borderColor: asBool(PSDFluDose) ? "darkorange" : "#1e90ff"}}>
        &nbsp;<br/>
        <table className="print-custom-table" style={{ "pageBreakAfter": "always" }}>
            <tbody>
                
                <tr>
                    <td style={{ border: "none" }}>
                        <div className="m-0 p-0">
                            <div className="container-fluid m-0 p-0">
                                {psdContent.vaccineFriendlyName && <h3 className="text-center text-danger p-0 mb-1">ONLY for use with {psdContent.vaccineFriendlyName}</h3>}
                                <p className="text-center text-Primary p-0 mb-1" style={{ fontSize: "14px" }}><strong>{psdContent.headerInfo}</strong></p>

                                <div className="row">
                                    <table className="table table-bordered table-sm" style={{ bordercolor: "black" }}>
                                        <tbody>
                                            <tr>
                                                <th className="psdBgPrimary" style={{ width: "200px" }}>First Name</th>
                                                <td style={{ width: "300px" }}>{getEditableField('patientFirstName', !(!assessorAdAccount))}</td>
                                                <th className="psdBgPrimary">Surname</th>
                                                <td>{getEditableField('patientLastName', !(!assessorAdAccount))}</td>
                                            </tr>
                                            <tr>
                                                <th className="psdBgPrimary">Date of Birth</th>
                                                <td>{psdContent.showAge ? getEditableFieldWithTextAddon('patientDateofBirth', !(!assessorAdAccount), "Age: " + currentAge, isUnder18) : getEditableField('patientDateofBirth', !(!assessorAdAccount))}</td>
                                                <th className="psdBgPrimary">NHS Number</th>
                                                <td>{getEditableField('patientNHSNumber', !(!assessorAdAccount))}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>

                                {psdContent.suppressPSDDoseHeader !== true && 
                                    <div className="row">
                                        <table className="table table-bordered table-sm">
                                            <tbody>
                                                <tr>
                                                    <th className="psdBgPrimary">Indicate if PSD for {getDoseIndicationText(psdContent)} dose (tick box)</th>
                                                    <td>
                                                        <strong>FIRST DOSE - {asBool(PSDFirstDose) ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : ""}   </strong>
                                                    </td>
                                                    <td>
                                                        <strong>SECOND DOSE - {asBool(PSDSecondDose) ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : ""}   </strong>
                                                    </td>
                                                    {psdContent.supportsThirdDose && <td>
                                                        <strong>THIRD DOSE - {asBool(PSDThirdDose) ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : ""}   </strong>
                                                    </td>}
                                                    {psdContent.supportsBoosterDose && <td>
                                                        <strong>BOOSTER DOSE - {asBool(PSDBoosterDose) ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : ""}   </strong>
                                                    </td>}
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                }

                                {psdContent.supportsStaffCheck === true && !showingAsAdditionalPsd &&
                                    <>
                                        <table className="table table-bordered table-sm">
                                            <tbody>
                                                <tr>
                                                    <th style={{ backgroundColor: "#c82333", color: "white" }} >{"Please tick to indicate you have checked the recipient's ID badge and is a member of staff."}</th>
                                                    {getStaffCheck(assessorAdAccount)}
                                                </tr>
                                            </tbody>
                                    </table>
                                    </>
                                }

                                {psdContent.supportsCovidDose === true && confirmStaffCheck === "Yes" && !showingAsAdditionalPsd &&
                                    <>
                                        <table className="table table-bordered table-sm">
                                            <tbody>
                                                <tr>
                                                    <th style={{ backgroundColor: "#c82333", color: "white" }} >{"Please toggle to indicate if staff member is eligible and consents to have their flu vaccine at this appointment."}</th>
                                                    {getFluVaxCheck(assessorAdAccount)}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }

                                {psdContent.supportsCovidDose === true && confirmStaffCheck === "No" && !showingAsAdditionalPsd &&
                                    <>
                                        <table className="table table-bordered table-sm">
                                            <tbody>
                                                <tr>
                                                    <th style={{ backgroundColor: "#c82333", color: "white" }} >{"Please toggle to indicate if patient is eligible and consents to have their flu vaccine at this appointment."}</th>
                                                    {getFluVaxCheck(assessorAdAccount)}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }

                                {psdContent.supportsFluDose === true && !showingAsAdditionalPsd &&
                                    <>
                                        <table className="table table-bordered table-sm">
                                        <tbody>
                                                <tr>
                                                <th style={{ backgroundColor: "#c82333", color: "white" }} >{"Please toggle to indicate that the recipient is eligible and would like their COVID vaccine at this appointment."}</th>
                                                    {getCovidVaxCheck(assessorAdAccount)}
                                                </tr>
                                            </tbody>
                                        </table>
                                        {confirmCovidVaxCheck === "Yes" &&
                                            <table className="table table-bordered table-sm">
                                                <tbody>
                                                    <tr className="psdBgPrimary">
                                                        <th colSpan={3}>
                                                            Please complete for following information relating to their COVID vaccine:
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" style={{ width: "60%" }}>Which dose are you booking for?</td>
                                                        <td>{getEditableField("requiredcoviddose", vaccinatorAdAccount)}</td>
                                                        <td className="" style={{ width: "20%" }}>​​</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        }
                                    </>
                                }

                                {psdContent.suppressNIVSCheckHeader !== true && 
                                <>
                                    <table className="table table-bordered table-sm">
                                        <tbody>
                                            <tr>
                                                <th style={{ backgroundColor: "#c82333", color: "white" }} >{(psdContent.supportsThirdDose || psdContent.supportsBoosterDose || psdContent.supportsFluDose) ? "Tick to confirm that you have checked with the patient and in NIVS for details of previous vaccine doses.  If the patient has already been vaccinated, check that the vaccine they are receiving today is appropriate for their prior dose(s)." : "Tick to confirm that you have checked with the patient and in NIVS for any details of a previous vaccine dose. If they have already had a vaccine, you have checked it is the same vaccine being given today."}</th>
                                            {(psdContent.supportsThirdDose || psdContent.supportsBoosterDose || psdContent.supportsFluDose) ? getVaxMatch3rdOnwards(assessorAdAccount) : getVaxMatch()}
                                            </tr>
                                            <tr>
                                                <th className="psdBgPrimary">Please tick here if the patient cannot be added or located on NIVS, or if NIVS is not available:</th>
                                                <td  className="text-center" onClick={() => toggleNivsQuestion(assessorAdAccount)} style={{cursor: "pointer"}}> 
                                                    <span className="text-danger">
                                                        { cannotLocateOnNivs === "Yes" && <FontAwesomeIcon icon={faCheck} color="danger" /> }
                                                        { cannotLocateOnNivs !== "Yes" && <span>-</span> }
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                
                                    { cannotLocateOnNivs === "Yes" && 
                                        <table className="table table-bordered table-sm">
                                            <tbody>
                                                <tr className="psdBgPrimary">
                                                    <th colSpan={3}>
                                                    Please complete these field if patient cannot be registered / located in NIVS:
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td scope="col" style={{ width: "60%" }}>Is the recipient a carer?</td>
                                                    {getInfoToggleField('patientIsCarer')}
                                                    <td className="" style={{ width: "20%" }}>​​</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Ethnicity of the recipient</td>
                                                    <td className="" colSpan="2">{getEditableField('patientEthnicity')}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Does the recipient live in a Residential Care Home?</td>
                                                    {getInfoToggleField('carehomeResident')}
                                                    <td className="">​​</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Does the recipient work in a Residential Care Home?</td>
                                                    {getInfoToggleField('carehomeWorker')}
                                                    <td className="">​</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                }
                                </>
                            }

                                <small><strong>Patient {psdContent.screenTerm} to receive {psdContent.useVaccineFriendlyNameForScreening ? psdContent.vaccineFriendlyName : psdContent.name} in accordance with {psdContent.immunisationBody ? psdContent.immunisationBody : "PHE"} Immunisation against infectious disease (Green Book) and JCVI recommendations for the protection against COVID-19:</strong></small>
                                <div className="row">
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr className="psdBgLightGrey">
                                                <th scope="col">{psdContent.for}</th>
                                                <th scope="col">Answer</th>
                                                <th scope="col" style={{ width: "600px" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {getToggleField('thirtyandfirstdose',assessorAdAccount)}
                                                {getToggleField('lowplateletcountafterfirst',assessorAdAccount)}
                                                {getToggleField('historyofthrombosis', assessorAdAccount)}
                                                {getToggleField('capillaryleaksyndrome', assessorAdAccount)}
                                                {getToggleField('historyofbleedingdisorder',assessorAdAccount)}
                                                {getToggleField('flujabpast7days', assessorAdAccount)}
                                                {!asBool(PSDFluDose) && getToggleField('bcgpast3months', assessorAdAccount)}
                                                {getToggleField('infectionorfeverish', assessorAdAccount)}
                                                {asBool(PSDFluDose) && getToggleField('covidsymptoms', assessorAdAccount)}
                                                {getToggleField('allergic', assessorAdAccount)}
                                                {getToggleField('seriousreaction', assessorAdAccount)}
                                                {getToggleField('pregnantAz', assessorAdAccount)}
                                            <tr>
                                                <td colSpan="3">If the answer to any of the above questions is Yes, then a further clinical review must take place. If you, or the recipient{psdContent.requireGuardianConsentDetails ? " or parent/carer/guardian" : ""}, are uncertain as to the response made to the questions, or the counselling the recipient should receive, they should be referred to, or brought to the attention of, the prescriber for further advice</td>
                                                </tr>

                                                {asBool(PSDFluDose) && getToggleField('bleedingdisorder', assessorAdAccount)}
                                                {getToggleField('pregnant', assessorAdAccount)}
                                                {getToggleField('breastfeeding', assessorAdAccount)}
                                                {asBool(PSDFluDose) && getToggleField('bcgpast3months', assessorAdAccount)}
                                                {getToggleField('covidtrial', assessorAdAccount)}
                                                {!asBool(PSDFluDose) && getToggleField('bleedingdisorder', assessorAdAccount)}
                                                {!asBool(PSDFluDose) && getToggleField('covidsymptoms',assessorAdAccount)}
                                                {getToggleField('covidpositive4weeks', assessorAdAccount)}
                                                {getToggleField('covidpositive12weeks', assessorAdAccount)}
                                                {getToggleField('medicalCondition', assessorAdAccount)}
                                              
                                                <tr>
                                                        <td colSpan={3}>
                                                        {psdContent.suppressUrlFooter !== true && <small className=" text-Primary"> <a href="https://www.gov.uk/government/publications/covid-19-the-green-book-chapter-14a" target="_blank">https://www.gov.uk/government/publications/covid-19-the-green-book-chapter-14a</a>* </small> }
                                                        </td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    psdContent.includeParentConsentQuestions && <Row>
                                        <Table bordered size="sm">
                                            <thead>
                                                <tr className="psdBgLightGrey">
                                                    <th scope="col">Parent/carer/guardian Agreement To Vaccination:</th>
                                                    <th scope="col">Answer</th>
                                                    <th scope="col" style={{ width: "600px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getToggleField('parentconsentforvaccine')}
                                                {parentConsentForVaccine === "No" && getToggleField('parentmoreinformationneededbeforeconsent')}
                                                {psdContent.requireGuardianConsentDetails && (parentConsentForVaccine === "Yes" || (parentConsentForVaccine === "No" && parentMoreInformationNeededBeforeConsent === "Yes"))
                                                    && <tr><td>Name of parent / carer / guardian</td><td colSpan="2">{getEditableField('guardianFullName', !(!assessorAdAccount))}</td></tr>}
                                                {psdContent.requireGuardianConsentDetails && (parentConsentForVaccine === "Yes" || (parentConsentForVaccine === "No" && parentMoreInformationNeededBeforeConsent === "Yes"))
                                                    && <tr><td>Relationship to recipient</td><td colSpan="2">{getEditableField('guardianRelationshipToRecipient', !(!assessorAdAccount))}</td></tr>}
                                            </tbody>
                                        </Table>
                                    </Row>
                                }

                                {
                                                    psdContent.includeConsentQuestions && <Row>
                                                            <Table bordered size="sm">
                                                                <thead>
                                                                    <tr className="psdBgLightGrey">
                                                                    <th scope="col">{psdContent.patientConsentText ? psdContent.patientConsentText : "Patient understanding of the information"}:</th>
                                                                    <th scope="col">Answer</th>
                                                                    <th scope="col" style={{ width: "600px" }}>Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {getToggleField('patientconsentforvaccine')}
                                                                    {patientConsentForVaccine === "No" && getToggleField('moreinformationneededbeforeconsent')}
                                                                </tbody>
                                                            </Table>
                                                    </Row>
                                }
                              
                                <div className="row">
                                    <table className="table table-bordered table-sm">
                                        <tbody>
                                            <tr>
                                                <th colSpan={6} className="psdBgPrimary">{psdContent.assessorText}</th>
                                            </tr>
                                            <tr>
                                                <th>{psdContent.screenPersonTerm} Comments:</th>
                                                <td colSpan={5}>
                                                    {!assessorAdAccount && <>
                                                    <textarea maxLength="500" style={{width:"100%"}} onChange={(e)=> { 
                                                        if(asBool(PSDFluDose) ) {
                                                            changeHandler('assessorcommentsflu1', e.target.value) 
                                                        }
                                                        else if (asBool(PSDFirstDose)) 
                                                        { changeHandler('assessorcomments', e.target.value)} 
                                                        else if (asBool(PSDSecondDose)) {
                                                            changeHandler('assessorcomments2', e.target.value)
                                                        }
                                                        else if (asBool(PSDThirdDose))  {
                                                            changeHandler('assessorcomments3', e.target.value)
                                                            }
                                                        else if (asBool(PSDBoosterDose)) {
                                                            changeHandler('assessorcommentsboost1', e.target.value)
                                                        }}} value={assessorComments}></textarea>
                                                        <small style={{float:'right'}}>{assessorComments ? assessorComments.length : '0'} of 500</small>
                                                    </>
                                                    }
                                                    {assessorAdAccount && role !== "prescriber" && <div>{assessorComments}</div>}
                                                    {assessorAdAccount && role === "prescriber" && assessorComments && <div style={{ borderColor: "red", borderStyle: "solid", borderWidth: 3 }}>{assessorComments}</div>}
                                                    {assessorAdAccount && role === "prescriber" && !assessorComments && <div>{assessorComments}</div>}
                                                </td>
                                            </tr>
                                            <tr className="psd-input-row">
                                                        <th className="psdBgLightGrey w30">Name</th>
                                                        <td className="w180">{assessorName} </td>
                                                        <th className="psdBgLightGrey w30">Signature</th>
                                                        <td className="w180">
                                                            {role === "screening" ? 
                                                                <> {!assessorAdAccount ? 
                                                                <Button block color="primary" onClick={() => sign('assessor')}>SIGN as {loggedInUserName}</Button> : 
                                                                <>{assessorAdAccount} <Button color="danger" onClick={() => clearSignature('assessor')}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button></>
                                                        } </>  : <>{assessorAdAccount}</>} </td>
                                                        <th className="psdBgLightGrey w30">Date</th>
                                                        <td style={{width:'80px'}}>{assessorTimestamp}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    psdContent.includeConsentQuestions && <Row>
                                            <Table bordered size="sm">
                                                <thead>
                                                    <tr className="psdBgLightGrey">
                                                    <th scope="col">{psdContent.prescriberConsentText ? psdContent.prescriberConsentText : "Patient understanding of the information"}</th>
                                                    <th scope="col">Answer</th>
                                                    <th scope="col" style={{ width: "600px" }}>Action</th>
                                                </tr>
                                                </thead>
                                            <tbody>
                                                {(patientConsentForVaccine === "Yes" || parentConsentForVaccine === "Yes") ? getToggleField('satisfiedwithconsent') : getToggleField('outcomeafterconsentdiscussion')}
                                                </tbody>
                                            </Table>
                                    </Row>
                                }
                              
                                <div className="row" style={{ position: "relative" }}>
                                    <table className="table table-bordered table-sm">
                                        <tbody>
                                            <tr>
                                             <th className="psdBgPrimary" colSpan={4}>Authorising Prescriber Details (PSD valid for 7 days from prescriber signature)</th>
                                            </tr>
                                            <tr>
                                                <th>Prescriber Comments</th>
                                                <td colSpan={3}>
                                                    {role === 'prescriber' && !prescriberAdAccount && <>
                                                        <textarea maxLength="490" style={{width:"100%"}} onChange={(e)=> { 
                                                            if(asBool(PSDFluDose) ) {
                                                                changeHandler('prescribercommentsflu1', e.target.value) 
                                                            } else if (asBool(PSDFirstDose)) 
                                                            { changeHandler('prescribercomments', e.target.value) }
                                                            else if (asBool(PSDSecondDose)) {
                                                                changeHandler('prescribercomments2', e.target.value)
                                                            } else if (asBool(PSDThirdDose))  {
                                                                changeHandler('prescribercomments3', e.target.value)
                                                            }
                                                            else if (asBool(PSDBoosterDose)) {
                                                                changeHandler('prescribercommentsboost1', e.target.value)
                                                            }}} value={prescriberComments}></textarea>
                                                            <small style={{float:'right'}}>{prescriberComments ? prescriberComments.length : '0'} of 500</small>
                                                            </>
                                                    }
                                                        {prescriberAdAccount && role !== "administering" && role !== "screening" && <div>{prescriberComments}</div>}
                                                        {(role === "administering" || role === "screening") && prescriberComments && <div style={{ borderColor: "red", borderStyle: "solid", borderWidth: 3 }}>{prescriberComments}</div>}
                                                        {prescriberAdAccount && (role === "administering" || role === "screening") && !prescriberComments && <div>{prescriberComments}</div>}
                                                </td>
                                            </tr>
                                            <tr className="psd-input-row">
                                                <th className="psdBgLightGrey w180">Name</th>
                                                <td className="w300">{prescriberName}</td>
                                                <th className="psdBgLightGrey w180">Date</th>
                                                <td>{prescriberTimestamp}</td>
                                            </tr>
                                            <tr className="psd-input-row">
                                                <th className="psdBgLightGrey w180">Signature</th>
                                                <td className="w300">                                               
                                                            {role === "prescriber" ? 
                                                                <> {!prescriberAdAccount ? 
                                                                <Button block color="primary" onClick={() => sign('prescriber')} disabled={!prescriberRegistrationNumber}>SIGN as {loggedInUserName}</Button> : 
                                                                <>{prescriberAdAccount} <Button color="danger" onClick={() => clearSignature('prescriber')}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button></>
                                                        } </> : <> {prescriberAdAccount}</>  }
                                                </td>
                                                <th className="psdBgLightGrey w180">Registration number</th>
                                                <td className="w180">{getEditableField('prescriberregistrationnumber', prescriberAdAccount)} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="row">
                                    <table className="table table-bordered table-sm mb-0">
                                        <thead>
                                            <tr className="psdBgLightGrey">
                                                <th scope="col">Vaccine Name</th>
                                                <th scope="col">Dose</th>
                                                <th scope="col">Frequency</th>
                                                <th scope="col">Route</th>
                                                <th scope="col" className="psd-batch-no">Batch No:</th>
                                                <th scope="col" className="psd-expiry">Expiry:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{psdContent.name} </td>
                                                <td>{(psdContent.boosterUsesHalfDose && asBool(PSDBoosterDose)) ? (role === "administering" ? <div style={{ borderColor: "red", borderStyle: "solid", borderWidth: 3 }}>{psdContent.halfDose}</div> : psdContent.halfDose) : psdContent.dose}</td>
                                                <td>{psdContent.frequency}</td>
                                                <td>{psdContent.route}</td>
                                                <td>
                                                    {getEditableField("batchnumber", (vaccinatorAdAccount || disallowAdministering))}
                                                </td>
                                                <td>
                                                    {!disallowAdministering && getEditableField("batchexpiry", vaccinatorAdAccount)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <small>*Check BN/Exp match upon administration or amend as appropriate</small>
                                </div>

                                <div className="row">
                                    <table className="table table-bordered table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th className="psdBgLightGrey w30">Site</th>
                                                                <td className="w180">{getEditableField("vaccinationsite", (vaccinatorAdAccount || disallowAdministering))}</td>
                                                                <th className="psdBgLightGrey w30">Dose number from vial</th>
                                                <td className="w180">{getEditableField("doseNumber", (vaccinatorAdAccount || disallowAdministering))}</td>
                                                            </tr>
                                                        </tbody>
                                    </table>
                                </div>

                                <div className="row">
                                    <table className="table table-bordered table-sm">
                                        <tbody>
                                            <tr>
                                                <th className="psdBgPrimary" colSpan={6}>{psdContent.vaccinatorText}</th>
                                            </tr>
                                            <tr>
                                                <th>Vaccinator Comments</th>
                                                <td colSpan={5}>
                                                    {role === "administering" ? <>
                                                        {(!vaccinatorAdAccount && !disallowAdministering) && <>
                                                    <textarea maxLength="490" style={{width:"100%"}} onChange={(e)=> {
                                                        if(asBool(PSDFluDose) ) {
                                                            changeHandler('vaccinatorcommentsflu1', e.target.value) 
                                                        } else if (asBool(PSDFirstDose)) 
                                                        { changeHandler('vaccinatorcomments', e.target.value) }
                                                        else if (asBool(PSDSecondDose)) {
                                                            changeHandler('vaccinatorcomments2', e.target.value)
                                                        }
                                                        else if (asBool(PSDThirdDose))  {
                                                            changeHandler('vaccinatorcomments3', e.target.value)
                                                                }
                                                        else if (asBool(PSDBoosterDose)) {
                                                            changeHandler('vaccinatorcommentsboost1', e.target.value)
                                                        }}} value={vaccinatorComments}></textarea>
                                                        <small style={{float:'right'}}>{vaccinatorComments ? vaccinatorComments.length : '0'} of 500</small>
                                                        </>
                                                    }
                                                    {vaccinatorAdAccount && <div>{vaccinatorComments}</div>}
                                                    </> : <div>{vaccinatorComments}</div> 
                                                }
                                                </td>
                                            </tr>
                                            <tr className="psd-input-row">
                                                <th className="psdBgLightGrey w30">Name</th>
                                                <td className="w180">{vaccinatorName}</td>
                                                <th className="psdBgLightGrey w30">Signature</th>
                                                <td className="w180">
                                                    {role === "administering" && !disallowAdministering ?
                                                        <> {!vaccinatorAdAccount ?
                                                                <Button block color="primary" onClick={() => sign('vaccinator')}>SIGN as {loggedInUserName}</Button> : 
                                                                <>{vaccinatorAdAccount} <Button color="danger" onClick={() => clearSignature('vaccinator')}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button></>
                                                        } </> : <> {vaccinatorAdAccount}</>  }

                                                </td>
                                                <th className="psdBgLightGrey w30">Date</th>
                                                <td style={{width:'80px'}}>{vaccinatorTimestamp}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>

                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td style={{ border: "none" }}>
                        <div className="print-custom-footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>

        <p style={{ "pageBreakBefore": "always" }}>&nbsp;</p>


        <div className="row">
            <table className="table table-borderless table-sm">
                <tbody>
                    <tr>
                        <td >DTC Reference: {psdContent.dtcNumber}</td>
                        <td style={{ float:"right" }}>Review Date: {psdContent.reviewDate} </td>
                    </tr>
                    <tr>
                        <td colSpan="2">&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="print-custom-footer">
            <small>
                <span style={{ float: "left" }}>
                    <strong> {patientFirstName}&nbsp; {patientLastName}</strong>&nbsp;
                    DoB: &nbsp;<strong>{patientDateofBirth}</strong>&nbsp;
                    NHS No: &nbsp;<strong>{patientNHSNumber}</strong>
                </span>
                <span style={{ float: "right", marginRight: "50px" }}>
                    <strong>TODO - DTC dtcReference</strong>
                </span>
            </small>
        </div>

    {role &&
            <div style={{'position':'fixed', 'bottom':'0', left:'0',background:'white', width:'100%', padding:'5px', display:'flex', justifyContent:"center"}}>
                <ButtonGroup>
                    {role === "prescriber" && <PSDPrescriberActions
                        submitting={submitting} showingAsAdditionalPsd={showingAsAdditionalPsd} multiplePsds={multiplePsds}
                        prescriberAdAccount={prescriberAdAccount} prescriberRegistrationNumber={prescriberRegistrationNumber}
                        confirmFluVaxCheck={confirmFluVaxCheck} confirmStaffCheck={confirmStaffCheck} confirmCovidVaxCheck={confirmCovidVaxCheck}
                        satisfiedWithConsent={satisfiedWithConsent} outcomeAfterConsentDiscussion={outcomeAfterConsentDiscussion}
                        approveActionDisabled={submitting || !prescriberRegistrationNumber || !(satisfiedWithConsent === "Yes" || outcomeAfterConsentDiscussion == "Consent")}
                        approveActionDelegate={() => prescriberOutcome("Approved", !((((confirmFluVaxCheck === "Yes" && (confirmStaffCheck === "Yes" || confirmStaffCheck === "No")) || confirmCovidVaxCheck === "Yes") && !showingAsAdditionalPsd) || (!showingAsAdditionalPsd && multiplePsds && !confirmFluVaxCheck)))}
                        discussionNeededActionDisabled={submitting || discussionButtonClicked}
                        discussionNeededActionDelegate={() => prescriberOutcome("DiscussionNeeded")}
                        declineActionDisabled={submitting || !prescriberRegistrationNumber || !(satisfiedWithConsent === "No" || outcomeAfterConsentDiscussion === "Decline")}
                        declineActionDelegate={() => prescriberOutcome("Deferred", !((((confirmFluVaxCheck === "Yes" && (confirmStaffCheck === "Yes" || confirmStaffCheck === "No")) || confirmCovidVaxCheck === "Yes") && !showingAsAdditionalPsd) || (!showingAsAdditionalPsd && multiplePsds && !confirmFluVaxCheck)))}
                        sendToRedStreamActionDisabled={submitting || prescriberRegistrationNumber || (satisfiedWithConsent || outcomeAfterConsentDiscussion == "Consent")}
                        sendToRedStreamActionDelegate={() => prescriberOutcome("SendToRedStream")}
                        revertActionDelegate={() => cancelPrescribing(false)}
                    />}
                {role === "screening" && <PSDScreenerActions
                    assessorAdAccount={assessorAdAccount} showingAsAdditionalPsd={showingAsAdditionalPsd}
                    confirmCovidVaxCheck={confirmCovidVaxCheck} confirmFluVaxCheck={confirmFluVaxCheck} confirmStaffCheck={confirmStaffCheck} confirmVaxMatch={confirmVaxMatch}
                    moreInformationNeededBeforeConsent={isMoreInformationNeededBeforeConsent()} isFluDose={asBool(PSDFluDose)} requiredCovidDose={requiredCovidDose}
                        approveActionDisabled={cannotSubmitForPrescribing()}
                        approveActionDelegate={() => submitToPrescriber(getCurrentDoseType())}
                        approveAndContinueActionDelegate={() => submitToPrescriber(getCurrentDoseType(), requiredCovidDose)}
                        approveForFluActionDelegate={() => submitToPrescriber(getCurrentDoseType(), "Flu")}
                        declineActionDisabled={confirmCovidVaxCheck === "Yes" && !requiredCovidDose}
                        declineActionDelegate={() => finishedAdministration(false)}
                        declineAndContinueActionDelegate={() => finishedAdministration(false, getCurrentDoseType(), requiredCovidDose)}
                        declineForFluActionDelegate={() => finishedAdministration(false, getCurrentDoseType(), "Flu")}
                        revertActionDelegate={() => cancelScreening(false)} />}
                {role === "administering" && <PSDAdministerActions
                    showingAsAdditionalPsd={showingAsAdditionalPsd} multiplePsds={multiplePsds} disallowAdministering={disallowAdministering}
                    vaccinatorAdAccount={vaccinatorAdAccount}
                    confirmCovidVaxCheck={confirmCovidVaxCheck} confirmFluVaxCheck={confirmFluVaxCheck} confirmStaffCheck={confirmStaffCheck}
                        approveActionDisabled={submitting
                            || !batchNumber
                            || !batchExpiry
                            || !vaccinationSite
                            || !doseNumber
                            || disallowAdministering
                            || configuration.batchexpiry.validator(configuration.batchexpiry) !== undefined}
                        approveActionDelegate={() => finishedAdministration(true)}
                        approveAndContinueActionDelegate={() => finishedAdministration(true, getCurrentDoseType(), requiredCovidDose)}
                        approveForFluActionDelegate={() => finishedAdministration(true, getCurrentDoseType(), "Flu")}
                        declineActionDelegate={() => finishedAdministration(false)}
                        declineAndContinueActionDelegate={() => finishedAdministration(false, getCurrentDoseType(), requiredCovidDose)}
                        declineForFluActionDelegate={() => finishedAdministration(false, getCurrentDoseType(), "Flu")}
                        revertActionDelegate={() => cancelAdministering(false)} />}
                </ButtonGroup>
            </div>
        }        
    </div>
                    
}

export default CovidVaccinationPSDPrint;