import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Question from '../common/Question';
import QuestionnaireBlurb from './QuestionnaireBlurb';
import questionManager from './questionManager';
import { Input, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';

const currentDate = new Date(new Date().toDateString());

class Questionnaire extends Component {

    constructor(props) {
        super(props);
        this.guidanceScreen = React.createRef();

        const { cookies } = props;
        const answers = [];

        const questionnaireCookie = cookies.get('COVIDAsymptomaticTesting');
        const mobileFromCookie = questionnaireCookie && questionnaireCookie.mobile;
        const assignmentNumberFromCookie = questionnaireCookie && questionnaireCookie.assignmentnumber;
        const forenameFromCookie = questionnaireCookie && questionnaireCookie.forename;
        const lastnameFromCookie = questionnaireCookie && questionnaireCookie.lastname;
        const dateOfBirthFromCookie = questionnaireCookie && questionnaireCookie.dateofbirth;
        const genderFromCookie = questionnaireCookie && questionnaireCookie.gender;
        const ethnicityFromCookie = questionnaireCookie && questionnaireCookie.ethnicity;
        const postcodeFromCookie = questionnaireCookie && questionnaireCookie.postcode;
        const directorateFromCookie = questionnaireCookie && questionnaireCookie.directorate;
        const jobRoleFromCookie = questionnaireCookie && questionnaireCookie.jobrole;
        const departmentWardFromCookie = questionnaireCookie && questionnaireCookie.departmentward;

        if (questionnaireCookie) {
            answers.push({ key: 'remember', answer: 'Yes' })
        }
        if (mobileFromCookie) {
            answers.push({ key: 'mobile', answer: mobileFromCookie });
        }
        if (assignmentNumberFromCookie) {
            answers.push({ key: 'assignmentnumber', answer: assignmentNumberFromCookie });
        }
        if (forenameFromCookie) {
            answers.push({ key: 'forename', answer: forenameFromCookie });
        }
        if (lastnameFromCookie) {
            answers.push({ key: 'lastname', answer: lastnameFromCookie });
        }
        if (dateOfBirthFromCookie) {
            answers.push({ key: 'dateofbirth', answer: dateOfBirthFromCookie });
        }
        if (genderFromCookie) {
            answers.push({ key: 'gender', answer: genderFromCookie });
        }
        if (ethnicityFromCookie) {
            answers.push({ key: 'ethnicity', answer: ethnicityFromCookie });
        }
        if (postcodeFromCookie) {
            answers.push({ key: 'postcode', answer: postcodeFromCookie });
        }
        if (directorateFromCookie) {
            answers.push({ key: 'directorate', answer: directorateFromCookie });
        }
        if (jobRoleFromCookie) {
            answers.push({ key: 'jobrole', answer: jobRoleFromCookie });
        }

        this.state = {
            questionnaire: 'COVID Asymptomatic Testing',
            answers: answers,
            mobile: mobileFromCookie || '',
            confirmedMobile: mobileFromCookie || '',
            assignmentnumber: assignmentNumberFromCookie || '',
            forename: forenameFromCookie || '',
            lastname: lastnameFromCookie || '',
            dateofbirth: dateOfBirthFromCookie || '',
            gender: genderFromCookie || '',
            ethnicity: ethnicityFromCookie || '',
            postcode: postcodeFromCookie || '',
            directorate: directorateFromCookie || '',
            jobrole: jobRoleFromCookie || '',
            dateoftest: '',
            timeoftest: '',
            lotnumberoftest1: '',
            resultoftest1: '',
            confirmrepeattest: '',
            lotnumberoftest2: '',
            resultoftest2: '',
            beentowork: '',
            dualreported: '',
            comments: '',
            remember: questionnaireCookie ? 'Yes' : '',
            allMandatoryAnswered: false,
            jobRoleList: [],
            directorateList: [],
            genderList: [],
            ethnicityList: [],
            showPositiveConfirmation: false,
            isInvalid: false,
            completed: false,
            errored: false,
            submitting: false,
            questions: [
                { key: "mobile", mandatory: true },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: true },
                { key: "gender", mandatory: true },
                { key: "ethnicity", mandatory: true },
                { key: "postcode", mandatory: true },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "dateoftest", mandatory: true },
                { key: "timeoftest", mandatory: true },
                { key: "lotnumberoftest1", mandatory: true },
                { key: "resultoftest1", mandatory: true },
                { key: "confirmrepeattest", mandatory: false },
                { key: "lotnumberoftest2", mandatory: false },
                { key: "resultoftest2", mandatory: false },
                { key: "beentowork", mandatory: false },
                { key: "dualreported", mandatory: true },
                { key: "comments", mandatory: false }
            ],
            validationErrors: [],
            blacklistChars: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '¬', '!', '"', '£', '$', '%', '^', '&', '*', '(', ')', '_', '=', '+', '[', '{', ']', '}', ';', ':', '@', '#', '~', '\\', '|', ',', '<', '>', '?', '/']
        };

        this.validateEmail = this.validateEmail.bind(this);
        this.isBlacklisted = this.isBlacklisted.bind(this);
        this.storeAnswer = this.storeAnswer.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.textChangeWithBlacklistHandler = this.textChangeWithBlacklistHandler.bind(this);
        this.textChangeNoStoreHandler = this.textChangeNoStoreHandler.bind(this);
        this.textChangeRawHandler = this.textChangeRawHandler.bind(this);
        this.textChangeNoStoreRawHandler = this.textChangeNoStoreRawHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.checkedChangeHandler = this.checkedChangeHandler.bind(this);
        this.checkedChangeNoStoreHandler = this.checkedChangeNoStoreHandler.bind(this);
        this.dateOfTestChangeHandler = this.dateOfTestChangeHandler.bind(this);
        this.confirmPositiveHandler = this.confirmPositiveHandler.bind(this);
        this.cancelPositiveHandler = this.cancelPositiveHandler.bind(this);
        this.showIf = this.showIf.bind(this);
        this.pad = this.pad.bind(this);
        this.submitAnswers = this.submitAnswers.bind(this);
    }

    async componentDidMount() {
        const availableDirectorates = questionManager.getDirectorates();
        const availableJobRoles = questionManager.getJobRoles();
        const availableGenders = questionManager.getGenders();
        const availableEthnicities = questionManager.getEthnicities();
        this.setState({
            directorateList: availableDirectorates,
            jobRoleList: availableJobRoles,
            genderList: availableGenders,
            ethnicityList: availableEthnicities
        });
        if (this.guidanceScreen.current) {
            this.guidanceScreen.current.scrollIntoView();
        }
    }

    async submitAnswers() {

        this.setState({
            submitting: true
        });

        let dateOfBirth = '';
        let dateOfBirthIsoFormat = '';
        let dateOfBirthInvalid = false;
        let email = ''
        const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
        const emailAnswer = this.state.answers.filter(a => a.key === 'email');
        if (dateOfBirthAnswer.length > 0) {
            dateOfBirth = dateOfBirthAnswer[0].answer;
            if (dateOfBirth.length === 10) {
                dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
                try {
                    let dateWhen16 = new Date(dateOfBirthIsoFormat);
                    dateWhen16.setDate(dateWhen16.getDate() + ((365 * 16) + Math.floor(16 / 4)));
                    let dateWhen90 = new Date(dateOfBirthIsoFormat);
                    dateWhen90.setDate(dateWhen16.getDate() + ((365 * 90) + Math.floor(90 / 4)));
                    if (dateWhen16 > currentDate | dateWhen90 < currentDate) {
                        dateOfBirthInvalid = true;
                    }
                } catch {
                    dateOfBirthInvalid = true;
                }
            }
        }
        if (emailAnswer.length > 0) {
            email = emailAnswer[0].answer;
        }
        let dateOfTest = '';
        let dateOfTestIsoFormat = '';
        const dateOfTestAnswer = this.state.answers.filter(a => a.key === 'dateoftest');
        if (dateOfTestAnswer.length > 0) {
            dateOfTest = dateOfTestAnswer[0].answer;
            if (dateOfTest.length === 10) {
                dateOfTestIsoFormat = dateOfTest.substring(6, 10) + "-" + dateOfTest.substring(3, 5) + "-" + dateOfTest.substring(0, 2);
            }
        }

        let lotNumberOfTest1 = '';
        let lotNumberOfTest2 = '';
        const lotNumberOfTest1Answer = this.state.answers.filter(a => a.key === 'lotnumberoftest1');;
        const lotNumberOfTest2Answer = this.state.answers.filter(a => a.key === 'lotnumberoftest2');;
        if (lotNumberOfTest1Answer.length > 0) {
            lotNumberOfTest1 = lotNumberOfTest1Answer[0].answer;
        }
        if (lotNumberOfTest2Answer.length > 0) {
            lotNumberOfTest2 = lotNumberOfTest2Answer[0].answer;
        }

        // determine which questions are mandatory based upon answers
        let resultOfFirstTest = '';
        let confirmRepeatTest = '';
        let resultOfRepeatTest = '';
        var newQuestions = [];
        const resultOfFirstTestAnswer = this.state.answers.filter(a => a.key === "resultoftest1");
        const confirmRepeatTestAnswer = this.state.answers.filter(a => a.key === "confirmrepeattest");
        const resultOfRepeatTestAnswer = this.state.answers.filter(a => a.key === "resultoftest2");
        if (resultOfFirstTestAnswer.length > 0) {
            resultOfFirstTest = resultOfFirstTestAnswer[0].answer;
        }
        if (confirmRepeatTestAnswer.length > 0) {
            confirmRepeatTest = confirmRepeatTestAnswer[0].answer;
        }
        if (resultOfRepeatTestAnswer.length > 0) {
            resultOfRepeatTest = resultOfRepeatTestAnswer[0].answer;
        }

        newQuestions = questionManager.getQuestions(resultOfFirstTest, confirmRepeatTest, resultOfRepeatTest);

        this.setState({
            questions: newQuestions
        }, async () => {
            let mandatoryQuestionsUnanswered = 0;
            const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
            for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                const mandatoryQuestion = mandatoryQuestions[mandatory];
                const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                if (userAnswer.length === 0 || !userAnswer[0].answer) {
                    mandatoryQuestionsUnanswered++;
                }
            }

            if (mandatoryQuestionsUnanswered > 0) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: []
                });
            }
            else if (this.state.mobile !== this.state.confirmedMobile) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Mobile numbers do not match!"]
                });
            }
            else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth cannot be in the future!"]
                });
            }
            else if (dateOfBirthInvalid) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["You must be between 16 and 90 years of age!"]
                });
            }
            else if (dateOfTest !== '' && dateOfTest.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of test must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfTestIsoFormat) > new Date()) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of test cannot be in the future!"]
                });
            }
            else if (lotNumberOfTest1.length > 0 && (lotNumberOfTest1.length < 5)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Please check the identifier code of the first test strip, which must be at least 5 characters!"]
                });
            }
            else if (lotNumberOfTest2.length > 0 && (lotNumberOfTest2.length < 5)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Please check the identifier code of the repeat test strip, which must be at least 5 characters!"]
                });
            }
            else {
                const { cookies } = this.props;
                if (this.state.remember === 'Yes') {

                    let questionnaireCookie = cookies.get('COVIDAsymptomaticTesting') || {};

                    questionnaireCookie['mobile'] = this.state.mobile;
                    questionnaireCookie['assignmentnumber'] = this.state.assignmentnumber;
                    questionnaireCookie['forename'] = this.state.forename;
                    questionnaireCookie['lastname'] = this.state.lastname;
                    questionnaireCookie['dateofbirth'] = this.state.dateofbirth;
                    questionnaireCookie['gender'] = this.state.gender;
                    questionnaireCookie['ethnicity'] = this.state.ethnicity;
                    questionnaireCookie['postcode'] = this.state.postcode;
                    questionnaireCookie['directorate'] = this.state.directorate;
                    questionnaireCookie['jobrole'] = this.state.jobrole;

                    let expiryDate = new Date();
                    expiryDate.setDate(expiryDate.getDate() + 365);

                    cookies.set('COVIDAsymptomaticTesting', questionnaireCookie, { path: '/', domain: window.location.hostname, expires: expiryDate });
                } else {
                    let questionnaireCookie = cookies.get('COVIDAsymptomaticTesting');
                    if (questionnaireCookie) {
                        questionnaireCookie['mobile'] = this.state.mobile;
                        questionnaireCookie['assignmentnumber'] = this.state.assignmentnumber;
                        questionnaireCookie['forename'] = this.state.forename;
                        questionnaireCookie['lastname'] = this.state.lastname;
                        questionnaireCookie['dateofbirth'] = this.state.dateofbirth;
                        questionnaireCookie['gender'] = this.state.gender;
                        questionnaireCookie['ethnicity'] = this.state.ethnicity;
                        questionnaireCookie['postcode'] = this.state.postcode;
                        questionnaireCookie['directorate'] = this.state.directorate;
                        questionnaireCookie['jobrole'] = this.state.jobrole;
                        let expiryDate = new Date();
                        expiryDate.setDate(expiryDate.getDate() -1);

                        cookies.set('COVIDAsymptomaticTesting', questionnaireCookie, { path: '/', domain: window.location.hostname, expires: expiryDate });
                    }
                }

                const data = {
                    questionnaireName: this.state.questionnaire,
                    questionAnswers: []
                };
                this.state.answers.map(a => data.questionAnswers.push({ questionKey: a.key, answers: [a.answer] }));

                const request = fetch('/QuestionnaireAnswers', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const response = await request;

                if (response.ok) {
                    this.setState({
                        completed: true,
                        submitting: false
                    });
                } else {
                    let validationErrors = [];
                    if (response.status === 400) {
                        try {
                            validationErrors = await response.json();
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    this.setState({
                        errored: true,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
            }
        });       
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isBlacklisted(char) {
        if (this.state.blacklistChars.indexOf(char) > -1) {
            return true;
        }
        return false;
    }

    storeAnswer(questionKey, answer, callback) {
        const existingAnswer = this.state.answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...this.state.answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = this.state.answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        
        this.setState({
            answers: updatedAnswers,
            isInvalid: false
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    textChangeHandler(e, q) {
        this.storeAnswer(q, e.target.value);
        this.setState({
            [e.target.name || q]: e.target.value
        });
    }

    textChangeWithBlacklistHandler(e, q) {
        let newValue = '';
        if (e.target.value) {
            const newValueChars = e.target.value.split('');
            for (var i = 0; i < newValueChars.length; i++) {
                if (!this.isBlacklisted(newValueChars[i])) {
                    newValue += newValueChars[i];
                }
            }
        }

        this.storeAnswer(q, newValue);
        this.setState({
            [e.target.name]: newValue
        });
    }

    textChangeNoStoreHandler(e, q) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeRawHandler(e, q) {
        this.storeAnswer(q, e.target.rawValue);
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    textChangeNoStoreRawHandler(e) {
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    radioChangeHandler(e, q) {
        const newValue = e.target.value;
        this.storeAnswer(q, e.target.checked ? newValue : '', () => {
            let updatedState = {};
            if ((q === "resultoftest1" || q === "resultoftest2") && newValue === "Positive") {
                updatedState.showPositiveConfirmation = true;
            }
            this.setState({
                [q]: newValue,
                ...updatedState
            });
        });
    }

    checkedChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
            this.setState({
                [e.target.name]: e.target.value
            });
        } else {
            this.storeAnswer(q, '');
            this.setState({
                [e.target.name]: ''
            });
        }
    }

    checkedChangeNoStoreHandler(e) {
        if (e.target.checked) {
            this.setState({
                [e.target.name]: e.target.value
            });
        } else {
            this.setState({
                [e.target.name]: ''
            });
        }
    }

    dateOfTestChangeHandler(datePicked) {
        if (datePicked) {
            var formattedDatePicked = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
            this.storeAnswer('dateoftest', formattedDatePicked);
        } else {
            this.storeAnswer('dateoftest', '');
        }

        this.setState({
            dateoftest: datePicked
        });
    }

    confirmPositiveHandler() {
        this.setState({
            showPositiveConfirmation: false
        });
    }

    cancelPositiveHandler() {
        if (this.state.resultoftest2 === "Positive") {
            this.storeAnswer('resultoftest2', '', () => {
                this.setState({
                    resultoftest2: '',
                    showPositiveConfirmation: false
                });
            })
        } else if (this.state.resultoftest1 === "Positive") {
            this.storeAnswer('resultoftest1', '', () => {
                this.setState({
                    resultoftest1: '',
                    showPositiveConfirmation: false
                });
            })
        }
    }

    showIf(firstTestResult, confirmRepeatTestAnswered, confirmRepeatTest, repeatTestResult) {
        let show = true;
        let currentFirstTestAnswer = '';
        let currentConfirmRepeatTestAnswer = '';
        let currentRepeatTestAnswer = ''
        const firstTestAnswer = this.state.answers.filter(a => a.key === "resultoftest1");
        const confirmRepeatTestAnswer = this.state.answers.filter(a => a.key === "confirmrepeattest");
        const repeatTestAnswer = this.state.answers.filter(a => a.key === "resultoftest2");
        if (firstTestAnswer.length > 0) {
            currentFirstTestAnswer = firstTestAnswer[0].answer;
        }
        if (confirmRepeatTestAnswer.length > 0) {
            currentConfirmRepeatTestAnswer = confirmRepeatTestAnswer[0].answer;
        }
        if (repeatTestAnswer.length > 0) {
            currentRepeatTestAnswer = repeatTestAnswer[0].answer;
        }

        if (currentFirstTestAnswer !== firstTestResult) {
            show = false;
        }
        if (confirmRepeatTestAnswered) {
            if (confirmRepeatTest !== '') {
                if (currentConfirmRepeatTestAnswer !== confirmRepeatTest) {
                    show = false;
                } else if (currentConfirmRepeatTestAnswer === 'Yes') {
                    if (repeatTestResult) {
                        if (currentRepeatTestAnswer !== repeatTestResult) {
                            show = false;
                        }
                    }
                }
            } else {
                if (currentConfirmRepeatTestAnswer === '') {
                    show = false;
                }
            }
        }
        return show;
    }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    render() {
        if (this.state.completed) {
            let resultOfFirstTest = '';
            let resultOfRepeatTest = ''
            const resultOfFirstTestAnswer = this.state.answers.filter(a => a.key === "resultoftest1");
            const resultOfRepeatTestAnswer = this.state.answers.filter(a => a.key === "resultoftest2");
            if (resultOfFirstTestAnswer.length > 0) {
                resultOfFirstTest = resultOfFirstTestAnswer[0].answer;
            }
            if (resultOfRepeatTestAnswer.length > 0) {
                resultOfRepeatTest = resultOfRepeatTestAnswer[0].answer;
            }
            if (resultOfFirstTest === 'Positive' || resultOfRepeatTest === 'Positive') {
                return <div ref={this.guidanceScreen} className="questionnaire-body">
                    <p>Thank you for submitting your POSITIVE COVID-19 lateral flow test result.</p>
                    <p><strong>You do not have to take a PCR test to confirm this positive result.</strong></p>
                    <p>However, you must:</p>
                    <div>
                        <ul>
                            <li>Stay at home and avoid contact with other people</li>
                            <li>Contact your line manager to notify them of your absence from work (even if you are on a day off).</li>
                            <li>Follow the instructions set out in the latest <a href="https://www.guysandstthomas.nhs.uk/resources/coronavirus/occupational-health/occupational-health-advice-for-staff.pdf" target="_blank" rel="noopener noreferrer">Occupational Health Guidance</a></li>
                        </ul>
                    </div>
                    <p>If you develop COVID-19 symptoms after you performed the positive LFT, you should follow the steps above and contact Occupational Health. You can find full details in the <a href="https://www.guysandstthomas.nhs.uk/resources/coronavirus/occupational-health/occupational-health-advice-for-staff.pdf" target="_blank" rel="noopener noreferrer">Occupational Health Guidance</a>.</p>
                    <p><strong>Confirmation of report</strong></p>
                    <div>
                        <div>Name: <strong>{this.state.forename} {this.state.lastname}</strong></div>
                        <div>Time of report: <strong><Moment format="DD/MM/YYYY HH:mm:ss">{new Date()}</Moment></strong></div>
                        <div>Date of test: <strong><Moment format="DD/MM/YYYY">{this.state.dateoftest}</Moment></strong></div>
                        <div>Result of test: <strong>POSITIVE</strong></div>
                    </div>                    
                </div>;
            }
            else if (resultOfFirstTest === 'Negative' || resultOfRepeatTest === 'Negative') {
                return <div ref={this.guidanceScreen} className="questionnaire-body">
                    <p><strong>Thank you for recording your NEGATIVE COVID-19 test result.</strong></p>
                    <p><strong>Confirmation of report</strong></p>
                    <div>
                        <div>Name: <strong>{this.state.forename} {this.state.lastname}</strong></div>
                        <div>Time of report: <strong><Moment format="DD/MM/YYYY HH:mm:ss">{new Date()}</Moment></strong></div>
                        <div>Date of test: <strong><Moment format="DD/MM/YYYY">{this.state.dateoftest}</Moment></strong></div>
                        <div>Result of test: <strong>NEGATIVE</strong></div>
                    </div>
                    <div>&nbsp;</div>
                    <p><strong>Next steps</strong></p>
                    <div>
                        <ul>
                            <li>If you do not have any main COVID-19 symptoms you may attend work</li>
                            <li>If you do have COVID-19 symptoms you should e-mail <a href="mailto:covid19testing@gstt.nhs.uk">covid19testing@gstt.nhs.uk</a> immediately. The Trust's staff testing team will then contact you and arrange for you to have a confirmatory PCR test. <strong>Until the results of this second test are known, you and your household should self-isolate according to HM Government guidelines.</strong></li>
                        </ul>
                    </div>
                    <p><strong>If you need more testing kits</strong></p>
                    <p>Replacement testing kits should now be ordered online - visit <a href="http://www.gov.uk/order-coronavirus-rapid-lateral-flow-tests" target="_blank" rel="noopener noreferrer">www.gov.uk/order-coronavirus-rapid-lateral-flow-tests</a> and follow instructions. Select 'yes' to the question 'Do you work for the NHS in England and take part in their staff testing programme?'. It's best to create an account if you don't already have one, to make ordering future kits simpler.</p>
                </div>;
            }
            else if (resultOfFirstTest === 'Invalid' && resultOfRepeatTest === 'Invalid') {
                return <div ref={this.guidanceScreen} className="questionnaire-body">
                    <p><strong>Thank you for submitting your INVALID test result.</strong></p>
                    <p><strong>Confirmation of report</strong></p>
                    <div>
                        <div>Name: <strong>{this.state.forename} {this.state.lastname}</strong></div>
                        <div>Time of report: <strong><Moment format="DD/MM/YYYY HH:mm:ss">{new Date()}</Moment></strong></div>
                        <div>Date of test: <strong><Moment format="DD/MM/YYYY">{this.state.dateoftest}</Moment></strong></div>
                        <div>Result of test: <strong>INVALID</strong></div>
                    </div>
                    <div>&nbsp;</div>
                    <p><strong>Next steps</strong></p>
                    <div>
                        <ul>
                            <li>Repeat the test to secure either a positive or negative result</li>
                            <li>If this is your second 'invalid' test, please call 020 7188 7188 ext 51821 or 82977 or e-mail <a href="mailto:covid19testing@gstt.nhs.uk">covid19testing@gstt.nhs.uk</a> for further assistance. They are available 8am to 4:30pm, Monday to Friday.</li>
                        </ul>
                    </div>
                    <p>You can still attend work as normal.</p>
                    <p><strong>If you need more testing kits</strong></p>
                    <p>Replacement testing kits should now be ordered online - visit <a href="http://www.gov.uk/order-coronavirus-rapid-lateral-flow-tests" target="_blank" rel="noopener noreferrer">www.gov.uk/order-coronavirus-rapid-lateral-flow-tests</a> and follow instructions. Select 'yes' to the question 'Do you work for the NHS in England and take part in their staff testing programme?'. It's best to create an account if you don't already have one, to make ordering future kits simpler.</p>
                </div>;
            }
            else if (resultOfFirstTest === 'Invalid' && !resultOfRepeatTest) {
                return <div ref={this.guidanceScreen} className="questionnaire-body">
                    <p><strong>Thank you for submitting your INVALID test result.</strong></p>
                    <p><strong>Confirmation of report</strong></p>
                    <div>
                        <div>Name: <strong>{this.state.forename} {this.state.lastname}</strong></div>
                        <div>Time of report: <strong><Moment format="DD/MM/YYYY HH:mm:ss">{new Date()}</Moment></strong></div>
                        <div>Date of test: <strong><Moment format="DD/MM/YYYY">{this.state.dateoftest}</Moment></strong></div>
                        <div>Result of test: <strong>INVALID</strong></div>
                    </div>
                    <div>&nbsp;</div>
                    <p><strong>Next steps</strong></p>
                    <div>
                        <ul>
                            <li>Repeat the test to secure either a positive or negative result</li>
                            <li>If this is your second 'invalid' test, please call 020 7188 7188 ext 51821 or 82977 or e-mail <a href="mailto:covid19testing@gstt.nhs.uk">covid19testing@gstt.nhs.uk</a> for further assistance. They are available 8am to 4:30pm, Monday to Friday.</li>
                        </ul>
                    </div>
                    <p>You can still attend work as normal.</p>
                    <p><strong>If you need more testing kits</strong></p>
                    <p>Replacement testing kits should now be ordered online - visit <a href="http://www.gov.uk/order-coronavirus-rapid-lateral-flow-tests" target="_blank" rel="noopener noreferrer">www.gov.uk/order-coronavirus-rapid-lateral-flow-tests</a> and follow instructions. Select 'yes' to the question 'Do you work for the NHS in England and take part in their staff testing programme?'. It's best to create an account if you don't already have one, to make ordering future kits simpler.</p>
                </div>;
            }
            else {
                return <div ref={this.guidanceScreen} className="questionnaire-body">
                    <strong>Thank you! Your responses have been recorded.</strong>
                </div>;
            }
        }
        return <div className="questionnaire-body">
            <Modal isOpen={this.state.showPositiveConfirmation}>
                <ModalHeader>
                    Confirm Positive Result?
                </ModalHeader>
                <ModalBody>
                    <p>Please confirm below that you are recording a <strong>POSITIVE</strong> result by clicking Yes.</p><p>If you have selected this in error, then please click No and correct the result.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => this.confirmPositiveHandler()}>Yes, I am reporting a positive result</Button>
                    <Button color="secondary" onClick={() => this.cancelPositiveHandler()}>No</Button>
                </ModalFooter>
            </Modal>
            <QuestionnaireBlurb />
            <Question questionNumber="1" questionText={"Mobile phone number"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave name="mobile" placeholder="07911 123456" value={this.state.mobile} options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeRawHandler(e, "mobile")} maxLength={15} />
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave name="confirmedMobile" placeholder="07911 123456" value={this.state.confirmedMobile} options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeNoStoreRawHandler(e)} maxLength={15} />
                    </Label>
                    &nbsp;<i>(confirm mobile number by entering again)</i>
                </FormGroup>
            </Question>
            <Question questionNumber={2} questionText={"Assignment number (we need this to increase the accurancy of our records matching. You can find this on your payslip):"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="assignmentnumber" value={this.state.assignmentnumber} onChange={(e) => this.textChangeHandler(e, "assignmentnumber")} maxLength={10} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber="3.1" questionText={"First name"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="forename" value={this.state.forename} onChange={(e) => this.textChangeWithBlacklistHandler(e, "forename")} maxLength={30} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber="3.2" questionText={"Last name"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="lastname" value={this.state.lastname} onChange={(e) => this.textChangeWithBlacklistHandler(e, "lastname")} maxLength={30} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={4} questionText={"Date of birth"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={this.state.dateofbirth} onChange={(e) => this.textChangeHandler(e, "dateofbirth")} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={5} questionText={"Gender"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="gender" onChange={(e) => this.textChangeHandler(e, "gender")} value={this.state.gender}>
                            <option value="">Please select</option>
                            {this.state.genderList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={6} questionText={"Please select your ethnic group:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="ethnicity" onChange={(e) => this.textChangeHandler(e, "ethnicity", true)} value={this.state.ethnicity}>
                            <option value="">Please select</option>
                            {this.state.ethnicityList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={7} questionText={"Postcode of residence"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="postcode" value={this.state.postcode} onChange={(e) => this.textChangeHandler(e, "postcode")} maxLength={10} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={8} questionText={"Please select your directorate:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="directorate" onChange={(e) => this.textChangeHandler(e, "directorate")} value={this.state.directorate}>
                            <option value="">Please select</option>
                            {this.state.directorateList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={9} questionText={"Please select your job role:"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="jobrole" onChange={(e) => this.textChangeHandler(e, "jobrole")} value={this.state.jobrole} >
                            <option value="">Please select</option>
                            {this.state.jobRoleList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                        </Input>
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={10} questionText={"Date of test"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <DatePicker className="form-control date-selection-inputbox" selected={this.state.dateoftest} onChange={this.dateOfTestChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={11} questionText={"Identifier code of test strip (i.e. C42191233)"} isMandatory>
                <img className="questionnnaire-answer-image" src="/images/lfd-covid19-code.png" alt="Example of identifier code on test strip" />
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="lotnumberoftest1" value={this.state.lotnumberoftest1} onChange={(e) => this.textChangeHandler(e, "lotnumberoftest1")} maxLength={11} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber="12.1a" questionText={"What is the result of your test?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="resultoftest1" value="Negative" checked={this.state.resultoftest1 === "Negative"} onChange={(e) => this.radioChangeHandler(e, "resultoftest1")} />Negative
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="resultoftest1" value="Positive" checked={this.state.resultoftest1 === "Positive"} onChange={(e) => this.radioChangeHandler(e, "resultoftest1")} />Positive
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="resultoftest1" value="Invalid" checked={this.state.resultoftest1 === "Invalid"} onChange={(e) => this.radioChangeHandler(e, "resultoftest1")} />Invalid
                    </Label>
                </FormGroup>
            </Question>
            {this.showIf('Invalid', false, '') && <Question questionNumber="12.1b" questionText={"Were you able to perform a repeat test after the first invalid test?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="confirmrepeattest" value="Yes" onChange={(e) => this.radioChangeHandler(e, "confirmrepeattest")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="confirmrepeattest" value="No" onChange={(e) => this.radioChangeHandler(e, "confirmrepeattest")} />No
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf('Invalid', true, 'Yes') && <Question questionNumber="12.1c" questionText={"identifier code of repeat test strip (i.e. C87654321)"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="lotnumberoftest2" value={this.state.lotnumberoftest2} onChange={(e) => this.textChangeHandler(e, "lotnumberoftest2")} maxLength={11} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf('Invalid', true, 'Yes') && <Question questionNumber="12.1d" questionText={"What is the result of your repeat test?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="resultoftest2" value="Negative" checked={this.state.resultoftest2 === "Negative"} onChange={(e) => this.radioChangeHandler(e, "resultoftest2")} />Negative
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="resultoftest2" value="Positive" checked={this.state.resultoftest2 === "Positive"} onChange={(e) => this.radioChangeHandler(e, "resultoftest2")} />Positive
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="resultoftest2" value="Invalid" checked={this.state.resultoftest2 === "Invalid"} onChange={(e) => this.radioChangeHandler(e, "resultoftest2")} />Invalid
                    </Label>
                </FormGroup>
            </Question>}
            {(this.showIf('Positive') || this.showIf('Invalid', true, 'Yes', 'Positive')) && <Question questionNumber="12.2" questionText={"Have you been in to work within the past 48 hours?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="beentowork" value="Yes" onChange={(e) => this.radioChangeHandler(e, "beentowork")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="beentowork" value="No" onChange={(e) => this.radioChangeHandler(e, "beentowork")} />No
                    </Label>
                </FormGroup>
            </Question>}
            <Question questionNumber="13" questionText={"Have you reported this result on GOV.UK?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="dualreported" value="Yes" onChange={(e) => this.radioChangeHandler(e, "dualreported")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="dualreported" value="No" onChange={(e) => this.radioChangeHandler(e, "dualreported")} />No
                    </Label>
                </FormGroup>
            </Question>
            {this.state.dualreported === "Yes" && <div className="questionnaire-error">Please use this Trust portal to report future results instead of GOV.UK. We will upload your result to the national system for you. By using the Trust's portal you will help us to better understand lateral flow testing uptake at King's College Hospital, and provide you with faster access to confirmatory tests and occupational health support, should you need them.</div>}
            <Question questionNumber={14} questionText={"Please tell us of your experience of collecting your kit and performing your test, including any issues or problems."}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="textarea" name="comments" value={this.state.comments} onChange={(e) => this.textChangeHandler(e, "comments")} maxLength={255} cols="80" rows="4" />
                    </Label>
                </FormGroup>
            </Question>
            <div>
                If you are using a personal device, we can use a <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noopener noreferrer">cookie</a> to remember your details so you do not need to type in each time. If you consent to us doing this please tick this box:
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="remember" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "remember")} checked={this.state.remember === 'Yes'} />Yes
                    </Label>
                </FormGroup>
            </div>
            <Button color="primary" onClick={() => this.submitAnswers()} disabled={this.state.submitting}>Submit</Button>
            {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review your answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
        </div>;
    }
};

export default withCookies(Questionnaire);