import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Input } from 'reactstrap';
import questionManager from '../questionManager';

const SubmissionFilter = ({ setFilter, originalDateFrom, originalDateTo, originalDirectorate, originalForename, originalLastname }) => {

    const [dateFrom, setDateFrom] = useState(originalDateFrom);
    const [dateTo, setDateTo] = useState(originalDateTo);
    const [directorate, setDirectorate] = useState(originalDirectorate);
    const [forename, setForename] = useState(originalForename);
    const [lastname, setLastname] = useState(originalLastname);
    const [directorateList, setDirectorateList] = useState([]);

    useEffect(() => {
        const availableDirectorates = questionManager.getDirectorates();
        setDirectorateList(availableDirectorates);
    }, [])

    const filterClickHandler = () => {
        setFilter(dateFrom, dateTo, directorate, forename, lastname);
    }

    const dateFromChangeHandler = (datePicked) => {
        if (datePicked < dateTo || dateTo === null) {
            setDateFrom(datePicked);
        } else {
            setDateFrom(dateTo);
            setDateTo(datePicked);
        }
    };

    const dateToChangeHandler = (datePicked) => {
        if (datePicked > dateFrom) {
            setDateTo(datePicked);
        } else {
            setDateFrom(datePicked);
            setDateTo(dateFrom);
        }
    };

    const directorateChangeHandler = (e) => {
        setDirectorate(e.target.value);
    }

    const forenameChangeHandler = (e) => {
        setForename(e.target.value);
    };

    const lastnameChangeHandler = (e) => {
        setLastname(e.target.value);
    };

    return <Row className="submission-filter-bar">
        <Col xs="3">From: <DatePicker className="form-control date-selection-inputbox" selected={dateFrom} onChange={dateFromChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="3">To: <DatePicker className="form-control date-selection-inputbox" selected={dateTo} onChange={dateToChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        <Col xs="6" className="submission-filter-bar-separator">&nbsp;</Col>
        <Col xs="4">Directorate:&nbsp;<Input type="select" className="filter-control-inline" name="directorate" onChange={(e) => directorateChangeHandler(e)} value={directorate} width="200px">
            <option value="">Please select</option>
            {directorateList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
        </Input></Col>
        <Col xs="3">Forename: <Input className="hospital-number-inputbox" name="forename" value={forename} onChange={forenameChangeHandler} /></Col>
        <Col xs="4">Last name: <Input className="hospital-number-inputbox" name="lastname" value={lastname} onChange={lastnameChangeHandler} /></Col>
        <Col xs="1" className="filter-button-container"><Button color="primary" onClick={filterClickHandler}>Filter</Button></Col>
    </Row>;
};

export default SubmissionFilter;