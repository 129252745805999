import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button } from 'reactstrap';
import Spinner from '../../common/Spinner';
import Moment from 'react-moment';
import dateUtil from '../../../utils/dateUtil';
import tokenService from '../../../services/tokenService';
import ViewQuestionnaireSubmission from './ViewQuestionnaireSubmission';
import SubmissionFilter from './SubmissionFilter';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}

const QuestionnaireResults = () => {

    const questionnaireKey = 'COVID Testing Kit Registration';
    const [questionnaireResults, setQuestionnaireResults] = useState([]);
    const [submissionIdentifier, setSubmissionIdentifier] = useState(null);
    const [submissionResults, setSubmissionResults] = useState(null);
    const [showSubmissionResult, setShowSubmissionResult] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [directorate, setDirectorate] = useState('');
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const downloadLink = useRef();

    const buildFilterUrl = useCallback(() => {

        let keyPart = '';
        let keyValuePart = '';
        let url = "dateFrom=";
        if (dateFrom) {
            url = url + encodeURIComponent(dateFrom.toISOString());
        }
        url = url + "&dateTo=";
        if (dateTo) {
            let dateToEndOfDay = new Date(dateTo.getTime());
            dateToEndOfDay.setDate(dateToEndOfDay.getDate() + 1);
            url = url + encodeURIComponent(dateToEndOfDay.toISOString());
        }

        if (forename) {
            keyPart += keyPart ? '|forename' : 'forename';
            keyValuePart += keyValuePart ? '|' + forename : forename;
        }
        if (lastname) {
            keyPart += keyPart ? '|lastname' : 'lastname';
            keyValuePart += keyValuePart ? '|' + lastname : lastname;
        }
        if (directorate) {
            keyPart += keyPart ? '|directorate' : 'directorate';
            keyValuePart += keyValuePart ? '|' + directorate : directorate;
        }
        
        url = url + '&questionFilterKey=' + encodeURIComponent(keyPart) + '&questionFilterValue=' + encodeURIComponent(keyValuePart);

        return url;
    }, [dateFrom, dateTo, directorate, forename, lastname]);

    const getQuestionnaireSubmission = useCallback(async () => {
        if (submissionIdentifier) {
            setLoading(true);
            try {
                const token = tokenService.getToken();
                const response = await fetch('/QuestionnaireAnswers/' + questionnaireKey + '/' + submissionIdentifier, {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setSubmissionResults(data);
                    setShowSubmissionResult(true);
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                    setSubmissionIdentifier(null);
                } else {
                    setErrorRetrieving(true);
                    setSubmissionIdentifier(null);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }        
    }, [submissionIdentifier]);

    const getQuestionnaireAnswers = useCallback(async () => {
        if (!submissionIdentifier) {
            setLoading(true);
            try {
                const token = tokenService.getToken();
                const url = '/QuestionnaireAnswers?questionnaireKey=' + questionnaireKey + "&" + buildFilterUrl();
                const response = await fetch(url, {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setQuestionnaireResults(data);
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                } else {
                    setErrorRetrieving(true);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }

    }, [buildFilterUrl, submissionIdentifier]);

    useEffect(() => {
        if (!errorRetrieving && !errorAuthenticating) {
            if ((dateFrom || dateTo) || directorate || lastname) {
                getQuestionnaireAnswers();
            }
        }        
    }, [questionnaireKey, getQuestionnaireAnswers, dateFrom, dateTo, directorate, forename, lastname, submissionIdentifier, errorRetrieving, errorAuthenticating])

    useEffect(() => {
        if (submissionIdentifier !== null) {
            getQuestionnaireSubmission();
        }
    }, [submissionIdentifier, getQuestionnaireSubmission])

    const backToListHandler = () => {
        setSubmissionIdentifier(null);
        setShowSubmissionResult(false);
        setErrorSaving(false);
        setErrorRetrieving(false);
        setErrorAuthenticating(false);
    }

    const saveChangesHandler = async (changes) => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers';

            const data = {
                questionnaireName: questionnaireKey,
                submissionIdentifier: submissionIdentifier,
                questionAnswers: []
            };
            const method = changes.deleted === true ? 'DELETE' : 'PUT';
            delete changes.deleted;
            const changeKeys = Object.keys(changes);

            if (changeKeys.length > 0 || method === 'DELETE') {
                changeKeys.map(a => data.questionAnswers.push({ questionKey: a, answers: [changes[a]] }));


                const response = await fetch(url, {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {
                    setErrorSaving(false);
                    backToListHandler();
                } else {
                    setErrorSaving(true);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getForename = (questionAnswers) => {
        const forenameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'forename');
        if (forenameQuestion.length > 0) {
            const answers = forenameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getLastname = (questionAnswers) => {
        const lastnameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'lastname');
        if (lastnameQuestion.length > 0) {
            const answers = lastnameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getKitCode = (questionAnswers) => {
        const lastnameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'kitcode');
        if (lastnameQuestion.length > 0) {
            const answers = lastnameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "";
    }

    const getAssistanceNeeded = (questionAnswers) => {
        const lastnameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'helpadministering');
        if (lastnameQuestion.length > 0) {
            const answers = lastnameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getDirectorate = (questionAnswers) => {
        const lastnameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'directorate');
        if (lastnameQuestion.length > 0) {
            const answers = lastnameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const setFilter = (dateFrom, dateTo, directorate, forename, lastname) => {
        setDateFrom(dateFrom);
        setDateTo(dateTo);
        setDirectorate(directorate);
        setForename(forename);
        setLastname(lastname);
        setErrorRetrieving(false);
        setErrorAuthenticating(false);
    };

    const downloadCsv = async () => {
        setLoadingCsv(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers/' + questionnaireKey + '/Download?' + buildFilterUrl();
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.blob();
                const filename = questionnaireKey + " - Results - " + new Date().toISOString().substring(0, 10) + ".csv";

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(data, filename);
                } else {
                    let objectUrl = window.URL.createObjectURL(data);

                    downloadLink.current.href = objectUrl;
                    downloadLink.current.download = filename;
                    downloadLink.current.click();

                    window.URL.revokeObjectURL(objectUrl);
                }
                
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingCsv(false);
        }
    }

    if (showSubmissionResult) {
        return <ViewQuestionnaireSubmission submission={submissionResults} returnCallback={() => backToListHandler()} saveChanges={saveChangesHandler} errorSaving={errorSaving} />
    } else {
        return <div className="questionnaire-body">
            <h3>Results for COVID-19 Staff Home Testing Registration</h3>
            <SubmissionFilter setFilter={setFilter} originalDateFrom={dateFrom} originalDateTo={dateTo} originalDirectorate={directorate} originalForename={forename} originalLastname={lastname} />
            {loading && <Spinner />}
            {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
            {errorRetrieving && <div className="questionnaire-error">Unexpected error detected whilst retrieving results. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Home Testing service.</div>}
            {questionnaireResults.length === 0 && !loading && <div>No Submissions Found.</div>}
            {questionnaireResults.length > 0 && <Table className="questionnaire-results-list" striped>
                <thead>
                    <tr>
                        <th>Submission Date</th>
                        <th>Forename</th>
                        <th>Last name</th>
                        <th>Directorate</th>
                        <th>Kit Code</th>
                        <th>Assistance Needed</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {questionnaireResults.map(qr => <tr key={qr.submissionIdentifier}>
                        <td><Moment format="DD MMM YYYY HH:mm">{dateUtil.ConvertDateFromJSON(qr.submissionDate)}</Moment></td>
                        <td>{getForename(qr.questionAnswers)}</td>
                        <td>{getLastname(qr.questionAnswers)}</td>
                        <td>{getDirectorate(qr.questionAnswers)}</td>
                        <td>{getKitCode(qr.questionAnswers)}</td>
                        <td>{getAssistanceNeeded(qr.questionAnswers)}</td>
                        <td><Button onClick={() => setSubmissionIdentifier(qr.submissionIdentifier)}>View Results</Button></td>
                    </tr>)}
                </tbody>
            </Table>}
            {loadingCsv && <Spinner />}
            {questionnaireResults.length > 0 && !loadingCsv && <Button color="link" onClick={() => downloadCsv()}>Download as CSV</Button>}
            <a ref={downloadLink} href="/">&nbsp;</a>
        </div>;
    }
};

export default QuestionnaireResults;