import React, { useEffect, useState, useRef } from 'react';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import { Container, Row, Col } from 'reactstrap';
import tokenService from '../../../services/tokenService';
import { signalRUtil } from '../../../utils/signalRUtil';
import Spinner from '../../common/Spinner';
import Card from 'reactstrap/lib/Card';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Table from 'reactstrap/lib/Table';
import LoginView from '../../common/LoginView';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavbarText from 'reactstrap/lib/NavbarText';
import isInRole from '../../../utils/isInRole';

export const AppointmentMoveUpload = ({ getUser, requiredRoles }) => {

    const [file, setFile] = useState();
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [hubConnection, setHubConnection] = useState(false);
    const [progress, setProgress] = useState({});
    const [ignoreUtilisation, setIgnoreUtilisation] = useState('');
    const [validateOnly, setValidateOnly] = useState('');
    
    const [controller, setAbortController] = useState();
    const [problemRecords, setProblemRecords] = useState([]);

    const [checkingAccess, setCheckingAccess] = useState(true);
    const [hasRequiredRoles, setHasRequiredRoles] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const downloadLink = useRef();

    useEffect(() => {
            const checkAccess = async () => {
                setCheckingAccess(true);

                if (isInRole(requiredRoles)) {
                    setHasRequiredRoles(true);
                    setCheckingAccess(false);
                } else {
                    setHasRequiredRoles(false);
                    setCheckingAccess(false);
                }
            }
            if (getUser) {
                checkAccess();
            }
        },
        [getUser]);

    const userHasRequiredRoles = (usersRoles, requiredRoles) => {
        const matchingRoles = usersRoles.filter(ur => requiredRoles.filter(rr => ur.toLowerCase() === rr.toLowerCase()).length > 0);
        return matchingRoles.length > 0;
    };

    useEffect(() => {
        const createHub = async () => {
            const hub = await signalRUtil.getHubConnectionAppointmentMove();
            hub.on("progress", progress => {
                setProgress(progress);
            });

            hub.on("problemRecord", record => {

                setProblemRecords(records => {
                    var next = [record, ...records];
                    return next;
                });
            });

            setHubConnection(hub);
        }

        createHub();

        setAbortController(new AbortController());
    }, []);

    const downloadCsv = async () => {
        setLoadingCsv(true);
        setErrorRetrieving(false);
        try {
            const token = tokenService.getToken();
            const url = '/api/AppointmentMoveUpload';
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.blob();
                const filename = "Bulk Appointment Move Template - generated on " + new Date().toISOString().substring(0, 10) + ".csv";

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(data, filename);
                } else {
                    let objectUrl = window.URL.createObjectURL(data);

                    downloadLink.current.href = objectUrl;
                    downloadLink.current.download = filename;
                    downloadLink.current.click();

                    window.URL.revokeObjectURL(objectUrl);
                }
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
            setErrorRetrieving(true);
        }
        finally {
            setLoadingCsv(false);
        }
    }

    const submit = async () => {
        setLoading(true);
        setProblemRecords([]);
        const token = tokenService.getToken();
        var data = new FormData()
        data.append('ignoreutilisation', ignoreUtilisation);
        data.append('validateonly', validateOnly);
        data.append('file', file[0])

        try {
            var response = await fetch('/api/AppointmentMoveUpload', { // Your POST endpoint
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token
                },
                body: data,
                signal: controller.signal
            })

            if (!response.ok) {
                setError("Something went wrong")
            } else {
                setDone(true);
                setLoading(false);
            }
        } catch (ex) {
            setLoading(false);
        }
    }

    const abort = () => {
        controller.abort();
        setAbortController(new AbortController());
    }

    if (checkingAccess) {
        return (<></>)
    }

    if (!hasRequiredRoles) {
        return (<Container>
            <LoginView nonBanner />
            <Card>
                <CardBody>
                    <div>You are not authorized to view this page.</div>
                </CardBody>
            </Card>
        </Container>)
    }

    return <div style={{ 'backgroundColor': 'rgb(0, 75, 139)', minHeight: "100vh" }}>
        <Navbar>
            <NavbarBrand><h3 className="align-middle" style={{ color: "white" }}>Bulk Appointment Move</h3></NavbarBrand>
            <NavbarText><img src="/images/GSTT-logo-2017-white.png" alt="GSTT" className="float-right p-2" height="75" /></NavbarText>
        </Navbar>
        <Container>
            <Row>
                <Col>
                    <Card style={{ marginBottom: "25px", marginTop: "25px" }}>
                        <CardHeader>Upload</CardHeader>
                        <CardBody>
                            <form>
                                <FormGroup>
                                    <Label>
                                        <Input type="checkbox" className="standard-checkbox" value="Yes" checked={ignoreUtilisation === "Yes"} onChange={(e) => setIgnoreUtilisation(e.target.checked ? e.target.value : '')} />Ignore Schedule Utilisation (will allow over subscribing clinic slots)
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        <Input type="checkbox" className="standard-checkbox" value="Yes" checked={validateOnly === "Yes"} onChange={(e) => { setValidateOnly(e.target.checked ? e.target.value : ''); setProgress({}); }} />Validate Only (will not make any changes)
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="file" name="file" id="exampleFile" onChange={(e) => setFile(e.target.files)} />
                                </FormGroup>
                                { validateOnly === "Yes" && <div><strong>PLEASE NOTE: </strong> This validation is unable to determine if slot utilisation will be exceeded.</div>}
                                <ButtonGroup>
                                    {!loading && <Button disabled={loading || !file} color="dark" className="mt-3" onClick={() => submit()}>Upload</Button>}
                                    {loading && <Button onClick={abort} className="mt-3">Abort Upload</Button>}
                                </ButtonGroup>
                                <br />
                                {progress.total && progress.total > 0 && progress.total !== progress.progress && <>Processing: {progress.progress} of {progress.total}</>}
                                {progress.total && progress.total > 0 && progress.total === progress.progress && <>{!validateOnly ? "Processed" : "Validated"}: {progress.progress} of {progress.total}. Complete!</>}
                                <div>
                                    {error && <>{error} </>}
                                </div>
                            </form>
                            &nbsp;<br />
                            {loadingCsv && <Spinner />}
                            {!loadingCsv && !loading && <Button color="primary" onClick={() => downloadCsv()}>Download Template</Button>}
                            <br />&nbsp;<br />
                            <a ref={downloadLink} href="/">&nbsp;</a>
                        </CardBody>
                    </Card>
                    {problemRecords.length !== 0 && <Card>
                        <CardHeader>Problems</CardHeader>
                        <CardBody>
                            <Table responsive={true}>
                                <tbody>
                                    {problemRecords.map((r, i) => <tr key={i}>
                                        <td>{r.reason}</td>
                                        <td>{JSON.stringify(r.item)}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    }
                </Col>
            </Row>
        </Container>
    </div>

}
