import React from 'react';

// ADULT ePSDS
// ===========

export const DTC210257E =  {
    dtcNumber: '210257e',
    content: {
        dtcNumber: '210257e',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'June 2021',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking clinical assessment:',
        vaccinatorText: 'Record of Registered Healthcare Professional administering Vaccine:',
        screenTerm: 'assessment',
        screenPersonTerm: 'Assessor',
        includeConsentQuestions: false
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine.  May be suitable for AstraZeneca. Consider contacting the oncall allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            }
        }
    }
}

export const DTC210357H = {
    dtcNumber: '210357h',
    content: {
        dtcNumber: '210357h',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'September 2021',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        includeConsentQuestions: false
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine.  May be suitable for AstraZeneca. Consider contacting the oncall allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            }
        }
    }
}

export const DTC210357H1 = {
    dtcNumber: '210357hRev1',
    content: {
        dtcNumber: '210357hRev1',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'September 2021',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer MRNA',
        includeConsentQuestions: true,
        showAge: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine.  May be suitable for AstraZeneca. Consider contacting the oncall allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient (or recipient) has read the relevant information leaflets, has all of the information they require and gives consent to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient (or recipient) wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient (or recipient) (or their nominated/legal representative) has received all of the relevant information from the Screener-Vaccinator and has provided consent to receive the vaccination on an informed basis.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the recipient’s concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Not-consent' }
            }
        }
    }
}

export const DTC210457L= {
    dtcNumber: '210457l',
    content: {
        dtcNumber: '210457l',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'August 2021',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Covid-19 mRNA Vaccine BNT162b2',
        includeConsentQuestions: true,
        showAge: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine.  May be suitable for AstraZeneca. Consider contacting the oncall allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient (or recipient) has read the relevant information leaflets, has all of the information they require and gives consent to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient (or recipient) wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient (or recipient) (or their nominated/legal representative) has received all of the relevant information from the Screener-Vaccinator and has provided consent to receive the vaccination on an informed basis.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the recipient’s concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Not-consent' }
            }
        }
    }
}

// No 3rd dose or Booster Comirnaty adult
export const DTC210957T = {
    dtcNumber: '210957t',
    content: {
        dtcNumber: '210957t',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Adults Over 16 years',
        patientConsentText: "Recipient Agreement To Vaccination:",
        prescriberConsentText: "Prescriber Consent Decision:",
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        includeConsentQuestions: true,
        showAge: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed. </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients in the vaccine, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer Pfizer vaccine if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine.  If reaction to Pfizer then may be suitable for AstraZeneca COVID-19 vaccination. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer Pfizer vaccine if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated/legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// 3rd Dose & Booster
export const DTC210957W = {
    dtcNumber: '210957w',
    content: {
        dtcNumber: '210957w',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Covid-19 mRNA Vaccine BNT162b2',
        useVaccineFriendlyNameForScreening: true,
        includeConsentQuestions: true,
        patientConsentText: "Recipient Agreement To Vaccination:",
        prescriberConsentText: "Prescriber Consent Decision:",
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days after last positive COVID-19 test.</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer Pfizer vaccine if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. If reaction to Pfizer then may be suitable for AstraZeneca COVID-19 vaccination. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer Pfizer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist. Can have flu vaccine.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// 3rd Dose & Booster Comirnaty
export const DTC210957Y = {
    dtcNumber: '210957y',
    content: {
        dtcNumber: '210957y',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        includeConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true,
        supportsStaffCheck: true,
        supportsCovidDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', boost1PsdKey: 'covidpositive4weeksboost1', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days after last positive COVID-19 test.</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients in the vaccine, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer Pfizer vaccine if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. If reaction to Pfizer then may be suitable for AstraZeneca COVID-19 vaccination. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer Pfizer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist. Can have flu vaccine.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// 3rd Dose & Booster Comirnaty (change timings for vaccine in relation to previous infection)
export const DTC211257F = {
    dtcNumber: '211257f',
    content: {
        dtcNumber: '211257f',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        includeConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true,
        supportsStaffCheck: true,
        supportsCovidDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentConsentForVaccine, parentMoreInformationNeededBeforeConsent, medicalcondition, capillaryleaksyndrome, covidpositive12weeks) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', boost1PsdKey: 'covidpositive4weeksboost1', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Is the recipient over 18 years old and had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days after last positive COVID-19 test.</>
            },
            covidpositive12weeks: {
                firstPsdKey: 'covidpositive12weeks', secondPsdKey: 'covidpositive12weeks2', thirdPsdKey: 'covidpositive12weeks3', boost1PsdKey: 'covidpositive12weeksboost1', editableRoles: ['screening'], property: covidpositive12weeks,
                question: () => <>Is the recipient under 18 and had a positive COVID-19 test in the last 12 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 12 weeks after positive COVID-19 test unless under 18 and in clinical at risk group: they can have the vaccine 4 weeks after positive test.</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients in the vaccine, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer Pfizer vaccine if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. If reaction to Pfizer then may be suitable for AstraZeneca COVID-19 vaccination. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer Pfizer if unexplained anaphylaxis. May be suitable for AstraZeneca. Consider contacting the on-call allergist. Can have flu vaccine.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks if over 18 or in clinical at risk group. If under 18 and not in clinical at risk group can have COVID-19 vaccine after 12 weeks.</>
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// In-Review ePSDs
// None (remove when developing an in-review PSD)

// CHILD ePSDs (12-16)
// ===================

export const DTC210957C = {
    dtcNumber: '210957c',
    content: {
        dtcNumber: '210957c',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'December 2021',
        for: 'For Children aged 12 and up to 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Covid-19 mRNA Vaccine BNT162b2',
        useVaccineFriendlyNameForScreening: true,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The patient / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// No 3rd dose or booster Comirnaty paediatric 12-16 year olds
export const DTC210957U = {
    dtcNumber: '210957u',
    content: {
        dtcNumber: '210957u',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Children aged 12 and up to 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        useVaccineFriendlyNameForScreening: false,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The patient / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

    // 3rd Dose & Booster
export const DTC210957X = {
    dtcNumber: '210957x',
    content: {
        dtcNumber: '210957x',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'SARS-COV-2 mRNA Vaccine Concentrate for injection BNT 162b2',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Children aged 12 and up to 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Covid-19 mRNA Vaccine BNT162b2',
        useVaccineFriendlyNameForScreening: true,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', thirdPsdKey: 'parentconsentforvaccine3', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The patient / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', thirdPsdKey: 'parentmoreinformationneededbeforeconsent3', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

    // 3rd Dose & Booster Comirnaty
export const DTC210957Z = {
    dtcNumber: '210957z',
    content: {
        dtcNumber: '210957z',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Children aged 12 and up to 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        useVaccineFriendlyNameForScreening: false,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', boost1PsdKey: 'covidpositive4weeksboost1', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', thirdPsdKey: 'parentconsentforvaccine3', boost1PsdKey: 'parentconsentforvaccineboost1', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The patient / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', thirdPsdKey: 'parentmoreinformationneededbeforeconsent3', boost1PsdKey: 'parentmoreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

    // 3rd Dose & Booster Comirnaty (fixed parent consent question)
export const DTC211157A = {
    dtcNumber: '211157a',
    content: {
        dtcNumber: '211157a',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Children aged 12 and up to 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        useVaccineFriendlyNameForScreening: false,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', boost1PsdKey: 'covidpositive4weeksboost1', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive patient can have the vaccine after 4 weeks</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', thirdPsdKey: 'parentconsentforvaccine3', boost1PsdKey: 'parentconsentforvaccineboost1', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The parent / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', thirdPsdKey: 'parentmoreinformationneededbeforeconsent3', boost1PsdKey: 'parentmoreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// 3rd Dose & Booster Comirnaty (change timings for vaccine in relation to previous infection)
export const DTC211257E = {
    dtcNumber: '211257e',
    content: {
        dtcNumber: '211257e',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '30micrograms/0.3mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2022',
        for: 'For Children aged 12 and up to 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 Vaccine (Comirnaty)',
        useVaccineFriendlyNameForScreening: false,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent, medicalcondition, capillaryleaksyndrome, covidpositive12weeks) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive12weeks: {
                firstPsdKey: 'covidpositive12weeks', secondPsdKey: 'covidpositive12weeks2', thirdPsdKey: 'covidpositive12weeks3', boost1PsdKey: 'covidpositive12weeksboost1', editableRoles: ['screening'], property: covidpositive12weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 12 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Reschedule appointment for 12 weeks after positive COVID-19 test unless in clinical at risk group: they can have the vaccine 4 weeks after positive test.</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive reschedule appointment for 12 weeks after positive COVID-19 test unless in clinical at risk group: they can have the vaccine 4 weeks after positive test.</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', thirdPsdKey: 'parentconsentforvaccine3', boost1PsdKey: 'parentconsentforvaccineboost1', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The parent / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', thirdPsdKey: 'parentmoreinformationneededbeforeconsent3', boost1PsdKey: 'parentmoreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// In-Review ePSDs
// None (remove when developing an in-review PSD)

// CHILD ePSDs (5-11)
// ==================

export const DTC220257A = {
    dtcNumber: '220257a',
    content: {
        dtcNumber: '220257a',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the COVID-19 vaccine to children aged 5-11 years by trained vaccinators at Guy’s and St. Thomas’ NHS Foundation Trust vaccination hub and administration record.',
        name: 'Comirnaty 10 micrograms/dose concentrate for dispersion for injection COVID-19 mRNA Vaccine (nucleoside modified)',
        dose: '10micrograms/0.2mL dose',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'January 2024',
        immunisationBody: 'UKHSA',
        for: 'For Children aged 5 and up to 11 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Pfizer-BioNTech COVID-19 10 micrograms/dose Vaccine (Comirnaty)',
        useVaccineFriendlyNameForScreening: false,
        includeConsentQuestions: true,
        includeParentConsentQuestions: true,
        requireGuardianConsentDetails: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: true,
        supportsBoosterDose: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder, underageandfirstdose, parentconsentforvaccine, parentmoreinformationneededbeforeconsent, medicalcondition, capillaryleaksyndrome, covidpositive12weeks) => {
        return {
            infectionorfeverish: {
                firstPsdKey: 'infectionorfeverish', secondPsdKey: 'infectionorfeverish2', thirdPsdKey: 'infectionorfeverish3', boost1PsdKey: 'infectionorfeverishboost1', editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
            covidpositive12weeks: {
                firstPsdKey: 'covidpositive12weeks', secondPsdKey: 'covidpositive12weeks2', thirdPsdKey: 'covidpositive12weeks3', boost1PsdKey: 'covidpositive12weeksboost1', editableRoles: ['screening'], property: covidpositive12weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 12 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Reschedule appointment for 12 weeks after positive COVID-19 test unless in clinical at risk group: they can have the vaccine 4 weeks after positive test.</>
            },
            bleedingdisorder: {
                firstPsdKey: 'bleedingdisorder', secondPsdKey: 'bleedingdisorder2', thirdPsdKey: 'bleedingdisorder3', boost1PsdKey: 'bleedingdisorderboost1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for at least 2 minutes post-injection.</>
            },
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', boost1PsdKey: 'flujabpast7daysboost1', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine.  </>
            },
            bcgpast3months: {
                firstPsdKey: 'bcgpast3months', secondPsdKey: 'bcgpast3months2', thirdPsdKey: 'bcgpast3months3', boost1PsdKey: 'bcgpast3monthsboost1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Has the recipient had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine but must be given in alternate arm. No further immunisation should be given in the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis.  </>
            },
            allergic: {
                firstPsdKey: 'allergic', secondPsdKey: 'allergic2', thirdPsdKey: 'allergic3', boost1PsdKey: 'allergicboost1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients of the COVID-19 vaccines, drug or other vaccine (excipients list is available for recipient or parent/carer/guardian to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if anaphylaxis to multiple classes of drugs or serious reaction to previous dose of this vaccine. Consider contacting the on-call allergist. </>
            },
            seriousreaction: {
                firstPsdKey: 'seriousreaction', secondPsdKey: 'seriousreaction2', thirdPsdKey: 'seriousreaction3', boost1PsdKey: 'seriousreactionboost1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <> Defer if unexplained anaphylaxis. Consider contacting the on-call allergist.</>
            },
            breastfeeding: {
                firstPsdKey: 'breastfeeding', secondPsdKey: 'breastfeeding2', thirdPsdKey: 'breastfeeding3', boost1PsdKey: 'breastfeedingboost1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                firstPsdKey: 'pregnant', secondPsdKey: 'pregnant2', thirdPsdKey: 'pregnant3', boost1PsdKey: 'pregnantboost1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                firstPsdKey: 'covidtrial', secondPsdKey: 'covidtrial2', thirdPsdKey: 'covidtrial3', boost1PsdKey: 'covidtrialboost1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                firstPsdKey: 'covidsymptoms', secondPsdKey: 'covidsymptoms2', thirdPsdKey: 'covidsymptoms3', boost1PsdKey: 'covidsymptomsboost1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and apyrexial. If the COVID-19 swab is positive reschedule appointment for 12 weeks after positive COVID-19 test unless in clinical at risk group: they can have the vaccine 4 weeks after positive test.</>
            },
            parentconsentforvaccine: {
                firstPsdKey: 'parentconsentforvaccine', secondPsdKey: 'parentconsentforvaccine2', thirdPsdKey: 'parentconsentforvaccine3', boost1PsdKey: 'parentconsentforvaccineboost1', editableRoles: ['screening'], property: parentconsentforvaccine,
                question: () => <>The parent / carer / guardian has read the relevant information leaflets, has all of the information they require and agrees to administration of the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            parentmoreinformationneededbeforeconsent: {
                firstPsdKey: 'parentmoreinformationneededbeforeconsent', secondPsdKey: 'parentmoreinformationneededbeforeconsent2', thirdPsdKey: 'parentmoreinformationneededbeforeconsent3', boost1PsdKey: 'parentmoreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: parentmoreinformationneededbeforeconsent,
                question: () => <>The parent / carer / guardian wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Parent / Carer / Guardian has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the parent / carer / guardian.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            patientconsentforvaccine: {
                firstPsdKey: 'patientconsentforvaccine', secondPsdKey: 'patientconsentforvaccine2', thirdPsdKey: 'patientconsentforvaccine3', boost1PsdKey: 'patientconsentforvaccineboost1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The recipient has read the relevant information leaflets, has all of the information they require, is considered to have a full appreciation of the vaccine and vaccination process, and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                firstPsdKey: 'moreinformationneededbeforeconsent', secondPsdKey: 'moreinformationneededbeforeconsent2', thirdPsdKey: 'moreinformationneededbeforeconsent3', boost1PsdKey: 'moreinformationneededbeforeconsentboost1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                firstPsdKey: 'satisfiedwithconsent', secondPsdKey: 'satisfiedwithconsent2', thirdPsdKey: 'satisfiedwithconsent3', boost1PsdKey: 'satisfiedwithconsentboost1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                firstPsdKey: 'outcomeafterconsentdiscussion', secondPsdKey: 'outcomeafterconsentdiscussion2', thirdPsdKey: 'outcomeafterconsentdiscussion3', boost1PsdKey: 'outcomeafterconsentdiscussionboost1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the recipient's (or their nominated legal representative's) concerns (or provided more information) and the final decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}

// In-Review ePSDs
// None (remove when developing an in-review PSD)