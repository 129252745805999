import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { ComposedChart, BarChart, Bar, LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import Moment from 'react-moment';
import Spinner from '../../common/Spinner';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import { PatientAppointmentItem } from '../../common/PatientAppointmentItem';
import tokenService from '../../../services/tokenService';



const HubInfoDetail = ({ site, location }) => {
    let currentDate = new Date();
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [originalSlotUtilisations, setOriginalSlotUtilisations] = useState([]);
    const [patientQueueList, setPatientQueueList] = useState([]);
    const [patientScheduleList, setPatientScheduleList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [deskStatusList, setDeskStatusList] = useState([]);
    const [bookedOutcomeChartData, setBookedOutcomeChartData] = useState([]);
    const [prescriberChartData, setPrescriberChartData] = useState([]);
    const [generalStatsData, setGeneralStatsData] = useState([]);
    const [vaccinated, setVaccinated] = useState(0);
    const [unvaccinated, setUnvaccinated] = useState(0);
    const [stillToGo, setStillToGo] = useState(0);
    const [screenUpdating, setScreenUpdating] = useState(true);

    const buildFilterUrl = useCallback(() => {
        let url = "siteName=";
        if (site) {
            url = url + encodeURIComponent(site);
        }
        url = url + "&locationName=";
        if (location) {
            url = url + encodeURIComponent(location);
        }
        url = url + "&startDate=";
        const newValue = `${currentDate.getFullYear()}-${pad(currentDate.getMonth() + 1, 2)}-${pad(currentDate.getDate(), 2)}`;
        url = url + encodeURIComponent(newValue);
        url = url + "&endDate=";
        url = url + encodeURIComponent(newValue);
        return url;
    }, [site, location]);

    useEffect(() => {
        getScheduleUtilisation();
        getDeskStatuses();
        getLocations();
        getPatientQueues();
        getPatientSchedules();
    }, [])

    useEffect(() => {
        calculateChartData();       
    }, [patientQueueList, originalSlotUtilisations]);

    useEffect(() => {
        calculateTableData();
    }, [patientQueueList, originalSlotUtilisations]);

    useEffect(() => {
        calculateCountStats();
    }, [patientQueueList, patientScheduleList])

    useEffect(() => {
        const timer = setTimeout(async () => {
            setScreenUpdating(false);
            currentDate = new Date();
            await getPatientSchedules();
            await getPatientQueues();
            await getDeskStatuses();
            setScreenUpdating(true);
        }, 120000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    })

    const getScheduleUtilisation = async () => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const response = await fetch('/ScheduleUtilisation?' + buildFilterUrl(), {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                data.sort((f, s) => {
                    if (f.scheduleDate > s.scheduleDate) {
                        return 1;
                    } else if (f.scheduleDate < s.scheduleDate) {
                        return -1;
                    } else {
                        return f.sortOrder - s.sortOrder;
                    }
                })
                for (let i = 0; i < data.length; i++) {
                    data[i].slotChanges.sort((f, s) => f.sortOrder - s.sortOrder);
                }
                setOriginalSlotUtilisations(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getLocations = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleLocation', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLocationList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getDeskStatuses = async () => {
        setLoading(true);
        try {
            const response = await fetch('/DeskStatus', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setDeskStatusList(data.filter(d => d.role === 'Assessor'));
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getPatientQueues = async () => {
        setLoading(true);
        try {
            const response = await fetch('/PatientQueue', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPatientQueueList(data.map(d => new PatientQueueItem(d)).filter(pqi => pqi.site === site && pqi.location === location));
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getPatientSchedules = async () => {
        const token = tokenService.getToken();

        try {
            const response = await fetch(`/CovidVaccinationAttendees?site=${site}&location=${location}`, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                const mappedPatients = data.sort((a, b) => a.currentTimeSlot < b.currentTimeSlot ? -1 : 1)
                    .map((x) => { return new PatientAppointmentItem(x); });
                setPatientScheduleList(mappedPatients);
                setLoading(false);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setLoading(false);
        }
    }

    const calculateChartData = () => {
        if (originalSlotUtilisations.length > 0 && patientScheduleList.length > 0) {
            // Set hour group to which the appointment belongs (from scheduled list)
            const scheduleItemsWithHourGrouping = patientScheduleList.map(pql => {
                const newItem = {};
                newItem.item = pql;
                newItem.hourGroup = pql.appointmentTime ? pql.appointmentTime.substring(0, 2) : 'Wa';
                if (newItem.hourGroup !== 'Wa' && newItem.hourGroup !== "Ad") {
                    newItem.hourGroup = pad(parseInt(newItem.hourGroup), 2) + ':00-' + pad((parseInt(newItem.hourGroup) + 1), 2) + ":00"
                } else {
                    newItem.hourGroup = 'Walk-in';
                }
                return newItem
            });
            // Set hour group to which the appointment belongs (from waiting/arrived list)
            const itemsWithHourGrouping = patientQueueList.map(pql => {
                const newItem = {};
                newItem.item = pql;
                newItem.hourGroup = pql.appointmentTime ? pql.appointmentTime.substring(0, 2) : 'Wa';
                if (newItem.hourGroup !== 'Wa') {
                    newItem.hourGroup = pad(parseInt(newItem.hourGroup), 2) + ':00-' + pad((parseInt(newItem.hourGroup) + 1), 2) + ":00"
                } else {
                    newItem.hourGroup = 'Walk-in';
                }
                return newItem
            });

            let chartData = [];
            let prescriberChartData = [];

            // Generate hour slots in chart data according to today's schedule.
            var startHour = originalSlotUtilisations[0].slotChanges.filter(sc => !sc.overflowSlot).reduce((agg, cur) => agg === 0 ? parseInt(cur.slotName.substring(0, 2)) : (parseInt(cur.slotName.substring(0, 2)) < agg ? parseInt(cur.slotName.substring(0, 2)) : agg), 24);
            var endHour = originalSlotUtilisations[0].slotChanges.filter(sc => !sc.overflowSlot).reduce((agg, cur) => agg === 0 ? parseInt(cur.slotName.substring(0, 2)) : (parseInt(cur.slotName.substring(0, 2)) > agg ? parseInt(cur.slotName.substring(0, 2)) : agg), 0);
            for (let hourPos = startHour; hourPos <= endHour; hourPos++) {
                chartData.push({ hourGroup: pad(hourPos, 2) + ":00-" + pad(hourPos + 1, 2) + ":00", recordCount: 0, bookedTotal: 0, correlatedOutcomedTotal: 0, outcomedTotal: 0, prescribingTime: 0, prescribingRecordCount: 0 })
                prescriberChartData.push({ hourGroup: pad(hourPos, 2) + ":00-" + pad(hourPos + 1, 2) + ":00", prescribingTime: 0, prescribingRecordCount: 0 })
            }
            chartData.push({ hourGroup: 'Walk-in', recordCount: 0, bookedTotal: 0, correlatedOutcomedTotal: 0, outcomedTotal: 0, prescribingTime: 0, prescribingRecordCount: 0 });

            // For every item in waiting/arrived list, increment the outcomed count (where outcome present) for the relevant hour group
            for (let i = 0; i < itemsWithHourGrouping.length; i++) {
                const existingChartItemForCorrelatedOutcome = chartData.find(cd => cd.hourGroup === itemsWithHourGrouping[i].hourGroup);
                if (existingChartItemForCorrelatedOutcome) {
                    existingChartItemForCorrelatedOutcome.recordCount += 1;
                    existingChartItemForCorrelatedOutcome.correlatedOutcomedTotal += (itemsWithHourGrouping[i].item.finishedTime ? 1 : 0);
                    chartData = [...chartData.filter(cd => cd.hourGroup !== existingChartItemForCorrelatedOutcome.hourGroup), existingChartItemForCorrelatedOutcome];
                }
            }

            for (let j = 0; j < itemsWithHourGrouping.length; j++) {
                const existingChartItemForOutcome = chartData.find(cd => cd.hourGroup === ((itemsWithHourGrouping[j].item.finishedTime) ? (pad(itemsWithHourGrouping[j].item.finishedTime.toDate().getHours(), 2) + ':00-' + pad((itemsWithHourGrouping[j].item.finishedTime.toDate().getHours() + 1), 2) + ":00") : "0"));
                if (existingChartItemForOutcome) {
                    existingChartItemForOutcome.recordCount += 1;
                    existingChartItemForOutcome.outcomedTotal += (itemsWithHourGrouping[j].item.finishedTime ? 1 : 0);
                    chartData = [...chartData.filter(cd => cd.hourGroup !== existingChartItemForOutcome.hourGroup), existingChartItemForOutcome];
                } else {
                    let hourGroup = ((itemsWithHourGrouping[j].item.finishedTime) ? (pad(itemsWithHourGrouping[j].item.finishedTime.toDate().getHours(), 2) + ':00-' + pad((itemsWithHourGrouping[j].item.finishedTime.toDate().getHours() + 1), 2) + ":00") : "0");
                    if (hourGroup !== "0") {
                        let newChartItemForOutcome = {};
                        newChartItemForOutcome.hourGroup = hourGroup;
                        newChartItemForOutcome.outcomedTotal = (itemsWithHourGrouping[j].item.finishedTime ? 1 : 0);
                        newChartItemForOutcome.recordCount = 1;
                        chartData.push(newChartItemForOutcome);
                    }
                }
            }

            // For every item in the scheduled list, increment the booked count for the relevant hour group
            for (let k = 0; k < scheduleItemsWithHourGrouping.length; k++) {
                const existingChartItemForBooking = chartData.find(cd => cd.hourGroup === scheduleItemsWithHourGrouping[k].hourGroup);
                if (existingChartItemForBooking) {
                    existingChartItemForBooking.bookedTotal += 1;
                    chartData = [...chartData.filter(cd => cd.hourGroup !== existingChartItemForBooking.hourGroup), existingChartItemForBooking];
                }
            }

            // For every item in the waiting/arrived list, increment the prescribing time (where prescribing finished) for the relevant hour group
            for (let m = 0; m < itemsWithHourGrouping.length; m++) {
                const existingChartItemForPrescriberTime = prescriberChartData.find(cd => cd.hourGroup === ((itemsWithHourGrouping[m].item.withPrescriberTime && itemsWithHourGrouping[m].item.startedPrescribingTime && itemsWithHourGrouping[m].item.finishedPrescribingTime) ? (pad(itemsWithHourGrouping[m].item.withPrescriberTime.toDate().getHours(), 2) + ':00-' + pad((itemsWithHourGrouping[m].item.withPrescriberTime.toDate().getHours() + 1), 2) + ":00") : "0"))
                if (existingChartItemForPrescriberTime) {
                    existingChartItemForPrescriberTime.prescribingTime += (itemsWithHourGrouping[m].item.startedPrescribingTime && itemsWithHourGrouping[m].item.finishedPrescribingTime) ? (itemsWithHourGrouping[m].item.finishedPrescribingTime.diff(itemsWithHourGrouping[m].item.withPrescriberTime, "seconds")) : 0;
                    existingChartItemForPrescriberTime.prescribingRecordCount += 1;
                    prescriberChartData = [...prescriberChartData.filter(cd => cd.hourGroup !== existingChartItemForPrescriberTime.hourGroup), existingChartItemForPrescriberTime];
                } else {
                    let newChartItemForPrescriberTime = {};
                    let hourGroup = ((itemsWithHourGrouping[m].item.withPrescriberTime && itemsWithHourGrouping[m].item.startedPrescribingTime && itemsWithHourGrouping[m].item.finishedPrescribingTime) ? (pad(itemsWithHourGrouping[m].item.withPrescriberTime.toDate().getHours(), 2) + ':00-' + pad((itemsWithHourGrouping[m].item.withPrescriberTime.toDate().getHours() + 1), 2) + ":00") : "0");
                    if (hourGroup !== "0") {
                        newChartItemForPrescriberTime.hourGroup = hourGroup;
                        newChartItemForPrescriberTime.prescribingTime = (itemsWithHourGrouping[m].item.startedPrescribingTime && itemsWithHourGrouping[m].item.finishedPrescribingTime) ? (itemsWithHourGrouping[m].item.finishedPrescribingTime.diff(itemsWithHourGrouping[m].item.withPrescriberTime, "seconds")) : 0;
                        newChartItemForPrescriberTime.prescribingRecordCount = 1;
                        prescriberChartData.push(newChartItemForPrescriberTime);
                    }                    
                }
            }

            for (let p = 0; p < prescriberChartData.length; p++) {
                prescriberChartData[p].prescribingTime = prescriberChartData[p].prescribingTime / prescriberChartData[p].prescribingRecordCount;
            }

            chartData = chartData.sort((a, b) => {
                if (a.hourGroup === 'Walk-in' && b.hourGroup !== 'Walk-in') {
                    return 1;
                } else if (a.hourGroup !== 'Walk-in' && b.hourGroup === 'Walk-in') {
                    return -1;
                }
                return parseInt(a.hourGroup.substring(0, 2)) - parseInt(b.hourGroup.substring(0, 2));
            });
            prescriberChartData = prescriberChartData.sort((a, b) => {
                if (a.hourGroup === 'Walk-in' && b.hourGroup !== 'Walk-in') {
                    return 1;
                } else if (a.hourGroup !== 'Walk-in' && b.hourGroup === 'Walk-in') {
                    return -1;
                }
                return parseInt(a.hourGroup.substring(0, 2)) - parseInt(b.hourGroup.substring(0, 2));
            });

            setBookedOutcomeChartData(chartData);
            setPrescriberChartData(prescriberChartData);
        }
    }

    const calculateTableData = () => {
        if (originalSlotUtilisations.length > 0 && deskStatusList.length > 0) {
            const itemsWithDeskAssignments = patientQueueList.filter(pql => pql.desk && pql.calledBy);
            let tableData = [];

            for (let i = 0; i < itemsWithDeskAssignments.length; i++) {
                const existingDataItem = tableData.find(cd => cd.calledBy === itemsWithDeskAssignments[i].calledBy && cd.desk === itemsWithDeskAssignments[i].desk);
                const deskStatus = deskStatusList.find(ds => ds.user === itemsWithDeskAssignments[i].calledBy && ds.desk === itemsWithDeskAssignments[i].desk && ds.site === site && ds.location === location);
                if (existingDataItem) {
                    existingDataItem.recordCount += 1;
                    existingDataItem.deskOnline = deskStatus ? deskStatus.online : false;
                    existingDataItem.numberVaccinated += (itemsWithDeskAssignments[i].hasBeenVaccinated || itemsWithDeskAssignments[i].hasBeenVaccinatedFlu) ? 1 : 0;
                    existingDataItem.screeningTime += (itemsWithDeskAssignments[i].deskArrivalTime && itemsWithDeskAssignments[i].withPrescriberTime) ? (itemsWithDeskAssignments[i].withPrescriberTime.diff(itemsWithDeskAssignments[i].deskArrivalTime, "seconds")) : 0;
                    existingDataItem.prescribingTime += (itemsWithDeskAssignments[i].withPrescriberTime && itemsWithDeskAssignments[i].finishedPrescribingTime) ? (itemsWithDeskAssignments[i].finishedPrescribingTime.diff(itemsWithDeskAssignments[i].withPrescriberTime, "seconds")) : 0;
                    existingDataItem.vaccinationTime += (itemsWithDeskAssignments[i].finishedPrescribingTime && itemsWithDeskAssignments[i].finishedTime) ? (itemsWithDeskAssignments[i].finishedTime.diff(itemsWithDeskAssignments[i].finishedPrescribingTime, "seconds")) : 0;
                    existingDataItem.timePerVaccination += (itemsWithDeskAssignments[i].deskArrivalTime && itemsWithDeskAssignments[i].finishedTime) ? (itemsWithDeskAssignments[i].finishedTime.diff(itemsWithDeskAssignments[i].deskArrivalTime, "seconds")) : 0;
                    tableData = [...tableData.filter(cd => cd.calledBy !== itemsWithDeskAssignments[i].calledBy || cd.desk !== itemsWithDeskAssignments[i].desk), existingDataItem];
                } else {
                    const newChartItem = {};
                    newChartItem.calledBy = itemsWithDeskAssignments[i].calledBy;
                    newChartItem.desk = itemsWithDeskAssignments[i].desk;
                    newChartItem.recordCount = 1;
                    newChartItem.deskOnline = deskStatus ? deskStatus.online : false;
                    newChartItem.numberVaccinated = (itemsWithDeskAssignments[i].hasBeenVaccinated || itemsWithDeskAssignments[i].hasBeenVaccinatedFlu) ? 1 : 0;
                    newChartItem.screeningTime = (itemsWithDeskAssignments[i].deskArrivalTime && itemsWithDeskAssignments[i].withPrescriberTime) ? (itemsWithDeskAssignments[i].withPrescriberTime.diff(itemsWithDeskAssignments[i].deskArrivalTime, "seconds")) : 0;
                    newChartItem.prescribingTime = (itemsWithDeskAssignments[i].withPrescriberTime && itemsWithDeskAssignments[i].finishedPrescribingTime) ? (itemsWithDeskAssignments[i].finishedPrescribingTime.diff(itemsWithDeskAssignments[i].withPrescriberTime, "seconds")) : 0;
                    newChartItem.vaccinationTime = (itemsWithDeskAssignments[i].finishedPrescribingTime && itemsWithDeskAssignments[i].finishedTime) ? (itemsWithDeskAssignments[i].finishedTime.diff(itemsWithDeskAssignments[i].finishedPrescribingTime, "seconds")) : 0;
                    newChartItem.timePerVaccination = (itemsWithDeskAssignments[i].deskArrivalTime && itemsWithDeskAssignments[i].finishedTime) ? (itemsWithDeskAssignments[i].finishedTime.diff(itemsWithDeskAssignments[i].deskArrivalTime, "seconds")) : 0;
                    tableData.push(newChartItem);
                }
            }
            tableData = tableData.sort((a, b) => {
                if (a.desk < b.desk) {
                    return -1;
                } else if (a.desk > b.desk) {
                    return 1;
                }
                return 0;
            });
            tableData = tableData.map(td => {
                const curItem = { ...td };
                const avgScreeningTime = curItem.screeningTime / curItem.recordCount / 60;
                const avgPrescribingTime = curItem.prescribingTime / curItem.recordCount / 60;
                const avgVaccinationTime = curItem.vaccinationTime / curItem.recordCount / 60;
                const avgTimePerVaccination = curItem.timePerVaccination / curItem.recordCount / 60;
                curItem.screeningTimeDisplay = Math.floor(avgScreeningTime) + " min " + (Math.round((((avgScreeningTime - Math.floor(avgScreeningTime)) / 100 * 60) + Number.EPSILON) * 100)) + "s";
                curItem.prescribingTimeDisplay = Math.floor(avgPrescribingTime) + " min " + (Math.round((((avgPrescribingTime - Math.floor(avgPrescribingTime)) / 100 * 60) + Number.EPSILON) * 100)) + "s";
                curItem.vaccinationTimeDisplay = Math.floor(avgVaccinationTime) + " min " + (Math.round((((avgVaccinationTime - Math.floor(avgVaccinationTime)) / 100 * 60) + Number.EPSILON) * 100)) + "s";
                curItem.timePerVaccinationDisplay = Math.floor(avgTimePerVaccination) + " min " + (Math.round((((avgTimePerVaccination - Math.floor(avgTimePerVaccination)) / 100 * 60) + Number.EPSILON) * 100)) + "s";
                return curItem;
            })
            tableData.sort((a, b) => {
                let curDiff = 0;
                if (a.deskOnline && !b.deskOnline) {
                    curDiff = -1;
                } else if (!a.deskOnline && b.deskOnline) {
                    curDiff = 1;
                }
                if (curDiff === 0) {
                    let aDeskSplit = a.desk.split(':');
                    let bDeskSplit = b.desk.split(':');
                    let aDeskNum = parseInt(aDeskSplit[aDeskSplit.length - 1]);
                    let bDeskNum = parseInt(bDeskSplit[aDeskSplit.length - 1]);
                    curDiff = aDeskNum - bDeskNum;
                }
                return curDiff;
            });
            setGeneralStatsData(tableData);
        }
    }

    const calculateCountStats = () => {
        const vaccinatedCount = patientQueueList.filter(pql => pql.finishedTime && (pql.hasBeenVaccinated || pql.hasBeenVaccinatedFlu)).length;
        const unvaccinatedCount = patientQueueList.filter(pql => pql.finishedTime && !pql.hasBeenVaccinated && !pql.hasBeenVaccinatedFlu).length;
        const dna = patientScheduleList.filter(x => { return patientQueueList.findIndex(y => y.submissionId == x.submissionId) == -1 }).filter(x => x.possibleDna).length;
        const stillToGoCount = patientScheduleList.length - (vaccinatedCount + unvaccinatedCount + dna);
        setVaccinated(vaccinatedCount);
        setUnvaccinated(unvaccinatedCount);
        setStillToGo(stillToGoCount);
    }

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    if (loading && screenUpdating) {
        return <div>Loading detail for {site} &gt; {location}. <Spinner /></div>;
    }

    if (locationList.length > 0 && bookedOutcomeChartData.length > 0 && originalSlotUtilisations.length > 0) {
        return <div>
            <div><Link to="/covid19vaccinationhubs">Back</Link></div>
            <p>{site} &gt; {location}</p>
            Last Updated: <strong><Moment format="DD MMM YYYY HH:mm:ss">{currentDate}</Moment></strong>
            <Row>
                <Col className="dashboard-chart-container" md="6">
                    <ResponsiveContainer width="100%" height="35%">
                        <ComposedChart
                            width={500}
                            height={300}
                            data={bookedOutcomeChartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 70,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="hourGroup" interval={0} angle={70} tickMargin={50} />
                            <YAxis allowDecimals={false}>
                                <Label value="# patients" offset={20} position="insideLeft" angle={-90} className="hub-dashboard-chart-label" />
                            </YAxis>
                            <Tooltip />
                            <Legend verticalAlign="top" height={30} />
                            <Bar dataKey="bookedTotal" fill="#5B9BD5" name="Booked" />
                            <Bar dataKey="correlatedOutcomedTotal" fill="#ED7D31" name="Seen" />
                            <Line dataKey="outcomedTotal" stroke="#009900" name="Outcomed" />
                        </ComposedChart>
                    </ResponsiveContainer>
                    <ResponsiveContainer width="100%" height="35%">
                        <LineChart
                            width={500}
                            height={300}
                            data={prescriberChartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 70,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="hourGroup" interval={0} angle={70} tickMargin={50} />
                            <YAxis yAxisId="left" allowDecimals={false}>
                                <Label value="seconds" offset={20} position="insideLeft" angle={-90} className="hub-dashboard-chart-label" />
                            </YAxis>
                            <YAxis yAxisId="right" orientation="right" allowDecimals={false}>
                                <Label value="# ePSDs" offset={20} position="insideRight" angle={90} className="hub-dashboard-chart-label" />
                            </YAxis>
                            <Tooltip />
                            <Legend verticalAlign="top" height={30} />
                            <Line yAxisId="left" dataKey="prescribingTime" stroke="#5B9BD5" name="ePSD approval time" />
                            <Line yAxisId="right" dataKey="prescribingRecordCount" stroke="#009900" name="ePSD count" />
                        </LineChart>
                    </ResponsiveContainer>
                    <Table striped className="dashboard-table-totals">
                        <thead>
                            <tr>
                                <th>Vaccinated</th>
                                <th>Unvaccinated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{vaccinated}</td>
                                <td>{unvaccinated}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col md="6">
                    <Table striped className="dashboard-table-stats">
                        <thead>
                            <tr>
                                <th>Desk</th>
                                <th>User</th>
                                <th># Vaccinated</th>
                                <th>Avg Screening Time</th>
                                <th>Avg Prescribing Time</th>
                                <th>Avg Vaccination Time</th>
                                <th>Avg Time per Vaccination</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalStatsData.map((gsd, idx) => <tr key={idx} className={ gsd.deskOnline ? "dashboard-table-online" : "dashboard-table-offline"}>
                                <td>{gsd.desk}</td>
                                <td>{gsd.calledBy}</td>
                                <td>{gsd.numberVaccinated}</td>
                                <td>{gsd.screeningTimeDisplay}</td>
                                <td>{gsd.prescribingTimeDisplay}</td>
                                <td>{gsd.vaccinationTimeDisplay}</td>
                                <td>{gsd.timePerVaccinationDisplay}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    }

    return <div>
        <div><Link to="/covid19vaccinationhubs">Back</Link></div>
        <p>{site} &gt; {location}</p>
    </div>
};

export default HubInfoDetail;