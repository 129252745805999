import React from 'react';
import { Button } from 'reactstrap';

const PSDPrescriberActions = ({
    showingAsAdditionalPsd, multiplePsds,
    prescriberAdAccount, prescriberRegistrationNumber,
    confirmFluVaxCheck, confirmStaffCheck, confirmCovidVaxCheck,
    satisfiedWithConsent, outcomeAfterConsentDiscussion,
    approveActionDisabled,
    approveActionDelegate,
    discussionNeededActionDisabled,
    discussionNeededActionDelegate,
    declineActionDisabled,
    declineActionDelegate,
    sendToRedStreamActionDisabled,
    sendToRedStreamActionDelegate,
    revertActionDelegate
}) => {

    return <>
        <Button color="success" onClick={approveActionDelegate} disabled={approveActionDisabled}>
            {!prescriberAdAccount && <>Sign and </>}Approve Vaccination{((((confirmFluVaxCheck === "Yes" && (confirmStaffCheck === "Yes" || confirmStaffCheck === "No")) || confirmCovidVaxCheck === "Yes") && !showingAsAdditionalPsd) || (!showingAsAdditionalPsd && multiplePsds && !confirmFluVaxCheck)) && <> and continue to next ePSD</>}
        </Button>
        <Button color="warning" onClick={discussionNeededActionDelegate} disabled={discussionNeededActionDisabled}>Discussion Needed</Button>
        <Button color="danger" onClick={declineActionDelegate} disabled={declineActionDisabled}>
            {!prescriberAdAccount && <>Sign and </>}Reject / Defer Vaccination{((((confirmFluVaxCheck === "Yes" && (confirmStaffCheck === "Yes" || confirmStaffCheck === "No")) || confirmCovidVaxCheck === "Yes") && !showingAsAdditionalPsd) || (!showingAsAdditionalPsd && multiplePsds && !confirmFluVaxCheck)) && <> and continue to next ePSD</>}
        </Button>
        <Button color="danger" onClick={sendToRedStreamActionDelegate} disabled={sendToRedStreamActionDisabled}>Send to Red Stream</Button>
        {showingAsAdditionalPsd && <Button color="danger" onClick={revertActionDelegate}>Revert to previous ePSD</Button>}
    </>;
}

export default PSDPrescriberActions;