import React, { Component } from 'react';
import Question from '../common/Question';
import QuestionnaireBlurb from './QuestionnaireBlurb';
import questionManager from './questionManager';
import { Input, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import RequiredSymb from '../common/RequiredSymb';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';

const currentDate = new Date(new Date().toDateString());

const defaultSecondBookingWindowStart = 9; // weeks (this is the end of the window i.e. 8-9 weeks)
const secondBookingWindowStartList = [
  { cohort: "5", weeks: 13 },
  { cohort: "12", weeks: 13 }
];
/* example
const secondBookingWindowStartList = [
    { cohort: 'CarehomeWorker', weeks: 8 },
    { cohort: 'SocialCareWorker', weeks: 8 }
];*/
const defaultThirdBookingWindowStart = 9; // weeks (this is the end of the window i.e. 8-9 weeks)
const thirdBookingWindowStartList = [];
const defaultBoosterBookingWindowStart = 27; // weeks (this is the end of the window i.e. 8-9 weeks)
const boosterBookingWindowStartList = [];

class Questionnaire extends Component {

    constructor(props) {
        super(props);
        
        const answers = [];
        answers.push({ key: 'confirm', answer: 'Yes' });

        this.state = {
            questionnaire: 'COVID Vaccination',
            submissionIdentifier: props.submissionIdentifier,
            allowDateOverride: props.hasDateOverrideRole || false,
            isBookingLead: props.hasBookingLeadRole || false,
            answers: answers,
            eligible: '',
            healthcareworker: '',
            carehomeworker: '',
            carehomeorganisation: '',
            onbehalfof: '',
            onbehalfofrelationship: '',
            onbehalfofname: '',
            willingtopartake: '',
            infectionorfeverish: '',
            covidpositive4weeks: '',
            othervaccinepast14days: '',
            othervaccinedate: '',
            seriousreaction: '',
            allergic: '',
            breastfeeding: '',
            pregnant: '',
            covidtrial: '',
            confirmflujabrestrictions: '',
            restrictedmobility: '',
            vaccinetype: '',
            vaccinetype2: '',
            nhsnumber: '',
            forename: '',
            lastname: '',
            dateofbirth: '',
            gender: '',
            postcode: '',
            ethnicity: '',
            mobile: '',
            confirmedMobile: '',
            email: '',
            confirmedEmail: '',
            organisation: '',
            directorate: '',
            jobrole: '',
            departmentward: '',
            assignmentnumber: '',
            confirmedAssignmentNumber: '',
            address: '',
            firstappointmentsite: '',
            firstappointmentlocation: '',
            firstappointmentdate: '',
            firstappointmenttimeslot: '',
            secondappointmentsite: '',
            secondappointmentlocation: '',
            secondappointmentdate: '',
            secondappointmenttimeslot: '',
            consentshare: '',
            confirmbringmedlist: '',
            reason: '',
            confirm: 'Yes',
            allMandatoryAnswered: false,
            carehomeOrganisationList: [],
            organisationList: [],
            directorateList: [],
            jobRoleList: [],
            genderList: [],
            ethnicityList: [],
            firstAppointmentScheduleList: [],
            firstAppointmentSiteList: [],
            firstAppointmentLocationList: [],
            firstAppointmentSlotList: [],
            secondAppointmentScheduleList: [],
            secondAppointmentSiteList: [],
            secondAppointmentLocationList: [],
            secondAppointmentSlotList: [],
            thirdAppointmentScheduleList: [],
            thirdAppointmentSiteList: [],
            thirdAppointmentLocationList: [],
            thirdAppointmentSlotList: [],
            boost1AppointmentScheduleList: [],
            boost1AppointmentSiteList: [],
            boost1AppointmentLocationList: [],
            boost1AppointmentSlotList: [],
            flu1AppointmentScheduleList: [],
            flu1AppointmentSiteList: [],
            flu1AppointmentLocationList: [],
            flu1AppointmentSlotList: [],
            defaultSecondVaccinationDate: '',
            defaultThirdVaccinationDate: '',
            defaultBoosterVaccinationDate: '',
            defaultFluVaccinationDate: currentDate,
            allowedVaccineType: '',
            availableVaccineList: [],
            anyAppsAvailable: true,
            anyFollowUpAppsAvailable: true,
            anyThirdAppsAvailable: true,
            anyBoosterAppsAvailable: true,
            anyFluAppsAvailable: true,
            isInvalid: false,
            completed: false,
            errored: false,
            submitting: false,
            questions: [
                { key: "eligible", mandatory: true },
                { key: "healthcareworker", mandatory: true },
                { key: "carehomeworker", mandatory: false },
                { key: "carehomeorganisation", mandatory: false },
                { key: "onbehalfof", mandatory: true },
                { key: "onbehalfofrelationship", mandatory: true },
                { key: "onbehalfofname", mandatory: true },
                { key: "willingtopartake", mandatory: true },
                { key: "infectionorfeverish", mandatory: false },
                { key: "covidpositive4weeks", mandatory: false },
                { key: "othervaccinepast14days", mandatory: false },
                { key: "othervaccinedate", mandatory: false },
                { key: "flujabplanned", mandatory: false },
                { key: "flujabplanneddate", mandatory: false },
                { key: "allergic", mandatory: false },
                { key: "allergicconfirmed", mandatory: false },
                { key: "seriousreaction", mandatory: false },
                { key: "seriousreactionconfirmed", mandatory: false },
                { key: "bleedingdisorder", mandatory: false },
                { key: "bleedingdisorderconfirmed", mandatory: false },
                { key: "warfarin", mandatory: false },
                { key: "warfarinthresholdok", mandatory: false },
                { key: "breastfeeding", mandatory: false },
                { key: "pregnant", mandatory: false },
                { key: "covidtrial", mandatory: false },
                { key: "covidtrialapproved", mandatory: false },
                { key: "confirmflujabrestrictions", mandatory: false },
                { key: "restrictedmobility", mandatory: false },
                { key: "nhsnumber", mandatory: false },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: false },
                { key: "gender", mandatory: false },
                { key: "postcode", mandatory: false },
                { key: "ethnicity", mandatory: false },
                { key: "mobile", mandatory: false },
                { key: "email", mandatory: false },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "departmentward", mandatory: true },
                { key: "address", mandatory: false },
                { key: "firstappointmentsite", mandatory: false },
                { key: "firstappointmentlocation", mandatory: false },
                { key: "firstappointmentdate", mandatory: false },
                { key: "firstappointmenttimeslot", mandatory: false },
                { key: "secondappointmentsite", mandatory: false },
                { key: "secondappointmentlocation", mandatory: false },
                { key: "secondappointmentdate", mandatory: false },
                { key: "secondappointmenttimeslot", mandatory: false },
                { key: "consentshare", mandatory: false },
                { key: "confirmbringmedlist", mandatory: false },
                { key: "reason", mandatory: false },
                { key: "confirm", mandatory: true }
            ],
            validationErrors: []
        };

        this.submitAnswers = this.submitAnswers.bind(this);
        this.getVaccines = this.getVaccines.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.dateMatch = this.dateMatch.bind(this);
        this.availableDateForOtherVaccineJab = this.availableDateForOtherVaccineJab.bind(this);
        this.availableDateForFirstVaccination = this.availableDateForFirstVaccination.bind(this);
        this.availableDateForSecondVaccination = this.availableDateForSecondVaccination.bind(this);
        this.availableDateForThirdVaccination = this.availableDateForThirdVaccination.bind(this);
        this.availableDateForBoosterVaccination = this.availableDateForBoosterVaccination.bind(this);
        this.availableDateForFluVaccination = this.availableDateForFluVaccination.bind(this);
        this.getAvailableSchedules = this.getAvailableSchedules.bind(this);
        this.getSchedulesForFirstAppointment = this.getSchedulesForFirstAppointment.bind(this);
        this.getSchedulesForFollowUpAppointment = this.getSchedulesForFollowUpAppointment.bind(this);
        this.getSchedulesForThirdAppointment = this.getSchedulesForThirdAppointment.bind(this);
        this.getSchedulesForBoosterAppointment = this.getSchedulesForBoosterAppointment.bind(this);
        this.getSchedulesForFluAppointment = this.getSchedulesForFluAppointment.bind(this);
        this.getAvailableSites = this.getAvailableSites.bind(this);
        this.getAvailableLocations = this.getAvailableLocations.bind(this);
        this.getAvailableSitesForFirstAppointment = this.getAvailableSitesForFirstAppointment.bind(this);
        this.getAvailableSitesForSecondAppointment = this.getAvailableSitesForSecondAppointment.bind(this);
        this.getAvailableSitesForThirdAppointment = this.getAvailableSitesForThirdAppointment.bind(this);
        this.getAvailableSitesForBoosterAppointment = this.getAvailableSitesForBoosterAppointment.bind(this);
        this.getAvailableSitesForFluAppointment = this.getAvailableSitesForFluAppointment.bind(this);
        this.getVaccineFromLocation = this.getVaccineFromLocation.bind(this);
        this.getVaccineFullName = this.getVaccineFullName.bind(this);
        this.getAvailableLocationsForFirstAppointment = this.getAvailableLocationsForFirstAppointment.bind(this);
        this.getAvailableLocationsForSecondAppointment = this.getAvailableLocationsForSecondAppointment.bind(this)
        this.getAvailableLocationsForThirdAppointment = this.getAvailableLocationsForThirdAppointment.bind(this);
        this.getAvailableLocationsForBoosterAppointment = this.getAvailableLocationsForBoosterAppointment.bind(this);
        this.getAvailableLocationsForFluAppointment = this.getAvailableLocationsForFluAppointment.bind(this);
        this.getAvailableTimeSlots = this.getAvailableTimeSlots.bind(this);
        this.getAvailableTimeSlotsForFirstAppointment = this.getAvailableTimeSlotsForFirstAppointment.bind(this);
        this.getAvailableTimeSlotsForSecondAppointment = this.getAvailableTimeSlotsForSecondAppointment.bind(this);
        this.getAvailableTimeSlotsForThirdAppointment = this.getAvailableTimeSlotsForThirdAppointment.bind(this);
        this.getAvailableTimeSlotsForBoosterAppointment = this.getAvailableTimeSlotsForBoosterAppointment.bind(this);
        this.getAvailableTimeSlotsForFluAppointment = this.getAvailableTimeSlotsForFluAppointment.bind(this);
        this.getAnswersForStorage = this.getAnswersForStorage.bind(this);
        this.getSecondBookingWindowEnd = this.getSecondBookingWindowEnd.bind(this);
        this.checkAndClearUnusedAnswers = this.checkAndClearUnusedAnswers.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.textChangeNoStoreHandler = this.textChangeNoStoreHandler.bind(this);
        this.textChangeRawHandler = this.textChangeRawHandler.bind(this);
        this.textChangeNoStoreRawHandler = this.textChangeNoStoreRawHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.checkedChangeHandler = this.checkedChangeHandler.bind(this);
        this.checkedChangeNoStoreHandler = this.checkedChangeNoStoreHandler.bind(this);
        this.dateOfOtherVaccineChangeHandler = this.dateOfOtherVaccineChangeHandler.bind(this);
        this.dateOfFirstVaccinationChangeHandler = this.dateOfFirstVaccinationChangeHandler.bind(this);
        this.dateOfSecondVaccinationChangeHandler = this.dateOfSecondVaccinationChangeHandler.bind(this);
        this.dateOfThirdVaccinationChangeHandler = this.dateOfThirdVaccinationChangeHandler.bind(this);
        this.dateOfBoosterVaccinationChangeHandler = this.dateOfBoosterVaccinationChangeHandler.bind(this);
        this.dateOfFluVaccinationChangeHandler = this.dateOfFluVaccinationChangeHandler.bind(this);
        this.isValidNhsNumber = this.isValidNhsNumber.bind(this);
        this.isPaediatricCohort = this.isPaediatricCohort.bind(this);
        this.isFiveToElevenCohort = this.isFiveToElevenCohort.bind(this);
        this.isOnlyEligibleForSingleAppointment = this.isOnlyEligibleForSingleAppointment.bind(this);
        this.showIf = this.showIf.bind(this);
        this.readyToCollectDemographics = this.readyToCollectDemographics.bind(this);
        this.pad = this.pad.bind(this);
    }

    async componentDidMount() {
        const availableDirectorates = questionManager.getDirectorates();
        const availableJobRoles = questionManager.getJobRoles();
        const availableGenders = questionManager.getGenders();
        const availableEthnicities = questionManager.getEthnicities();
        const availableOrganisations = questionManager.getOrganisations();
        const availableCarehomeOrganisations = questionManager.getCarehomeOrganisations();
        const availableVaccines = await this.getVaccines();
        
        this.setState({
            carehomeOrganisationList: availableCarehomeOrganisations,
            directorateList: availableDirectorates,
            jobRoleList: availableJobRoles,
            genderList: availableGenders,
            ethnicityList: availableEthnicities,
            organisationList: availableOrganisations,
            availableVaccineList: availableVaccines
        });
    }

    async getVaccines() {
        try {
            const response = await fetch('/ScheduleVaccine', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            }
        }
        catch (err) {
            console.log(err);
        }
        return [];
    }

    async submitAnswers() {

        this.setState({
            submitting: true
        });

        let dateOfBirth = '';
        let dateOfBirthIsoFormat = '';
        let email = ''
        const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
        const emailAnswer = this.state.answers.filter(a => a.key === 'email');
        if (dateOfBirthAnswer.length > 0) {
            dateOfBirth = dateOfBirthAnswer[0].answer;
            if (dateOfBirth.length === 10) {
                dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
            }
        }
        if (emailAnswer.length > 0) {
            email = emailAnswer[0].answer;
        }
        let appointmentsTooClose = true;
        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }
        if (this.state.firstappointmentdate && this.state.secondappointmentdate) {
            let firstAppDate = new Date(this.state.firstappointmentdate.toDateString());
            firstAppDate.setDate(firstAppDate.getDate() + ((secondWindowBookingStart * 7) - 7));
            if (this.state.secondappointmentdate >= firstAppDate) {
                appointmentsTooClose = false;
            }
        }
        else {
            appointmentsTooClose = false;
        }

        // determine which questions are mandatory based upon answers
        let healthcareWorker = '';
        let carehomeWorker = '';
        let onBehalfOf = '';
        let willingToPartake = '';
        let infectionOrFeverish = '';
        let covidPositive4Weeks = '';
        let otherVaccinePast14Days = '';
        let fluJabPlanned = '';
        let seriousReaction = '';
        let seriousReactionConfirmed = '';
        let allergic = '';
        let allergicConfirmed = '';
        let bleedingDisorder = '';
        let bleedingDisorderConfirmed = '';
        let warfarin = '';
        let warfarinThresholdOk = '';
        let breastfeeding = '';
        let pregnant = '';
        let covidTrial = '';
        let covidTrialApproved = '';
        let organisation = '';
        var newQuestions = [];
        const healthcareWorkerAnswer = this.state.answers.filter(a => a.key === "healthcareworker");
        const carehomeWorkerAnswer = this.state.answers.filter(a => a.key === "carehomeworker");
        const onBehalfOfAnswer = this.state.answers.filter(a => a.key === "onbehalfof");
        const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
        const infectionOrFeverishAnswer = this.state.answers.filter(a => a.key === "infectionorfeverish");
        const covidPositive4WeeksAnswer = this.state.answers.filter(a => a.key === "covidpositive4weeks");
        const otherVaccinePast14DaysAnswer = this.state.answers.filter(a => a.key === "othervaccinepast14days");
        const fluJabPlannedAnswer = this.state.answers.filter(a => a.key === "flujabplanned");
        const seriousReactionAnswer = this.state.answers.filter(a => a.key === "seriousreaction");
        const seriousReactionConfirmedAnswer = this.state.answers.filter(a => a.key === "seriousreactionconfirmed");
        const allergicAnswer = this.state.answers.filter(a => a.key === "allergic");
        const allergicConfirmedAnswer = this.state.answers.filter(a => a.key === "allergicconfirmed");
        const bleedingDisorderAnswer = this.state.answers.filter(a => a.key === "bleedingdisorder");
        const bleedingDisorderConfirmedAnswer = this.state.answers.filter(a => a.key === "bleedingdisorderconfirmed");
        const warfarinAnswer = this.state.answers.filter(a => a.key === "warfarin");
        const warfarinThresholdOkAnswer = this.state.answers.filter(a => a.key === "warfarinthresholdok");
        const breastfeedingAnswer = this.state.answers.filter(a => a.key === "breastfeeding");
        const pregnantAnswer = this.state.answers.filter(a => a.key === "pregnant");
        const covidTrialAnswer = this.state.answers.filter(a => a.key === "covidtrial");
        const covidTrialApprovedAnswer = this.state.answers.filter(a => a.key === "covidtrialapproved");
        const organisationAnswer = this.state.answers.filter(a => a.key === "organisation");

        if (healthcareWorkerAnswer.length > 0) {
            healthcareWorker = healthcareWorkerAnswer[0].answer;
        }
        if (carehomeWorkerAnswer.length > 0) {
            carehomeWorker = carehomeWorkerAnswer[0].answer;
        }
        if (onBehalfOfAnswer.length > 0) {
            onBehalfOf = onBehalfOfAnswer[0].answer;
        }
        if (willingToPartakeAnswer.length > 0) {
            willingToPartake = willingToPartakeAnswer[0].answer;
        }
        if (infectionOrFeverishAnswer.length > 0) {
            infectionOrFeverish = infectionOrFeverishAnswer[0].answer;
        }
        if (covidPositive4WeeksAnswer.length > 0) {
            covidPositive4Weeks = covidPositive4WeeksAnswer[0].answer;
        }
        if (otherVaccinePast14DaysAnswer.length > 0) {
            otherVaccinePast14Days = otherVaccinePast14DaysAnswer[0].answer;
        }
        if (fluJabPlannedAnswer.length > 0) {
            fluJabPlanned = fluJabPlannedAnswer[0].answer;
        }
        if (seriousReactionAnswer.length > 0) {
            seriousReaction = seriousReactionAnswer[0].answer;
        }
        if (seriousReactionConfirmedAnswer.length > 0) {
            seriousReactionConfirmed = seriousReactionConfirmedAnswer[0].answer;
        }
        if (allergicAnswer.length > 0) {
            allergic = allergicAnswer[0].answer;
        }
        if (allergicConfirmedAnswer.length > 0) {
            allergicConfirmed = allergicConfirmedAnswer[0].answer;
        }
        if (bleedingDisorderAnswer.length > 0) {
            bleedingDisorder = bleedingDisorderAnswer[0].answer;
        }
        if (bleedingDisorderConfirmedAnswer.length > 0) {
            bleedingDisorderConfirmed = bleedingDisorderConfirmedAnswer[0].answer;
        }
        if (warfarinAnswer.length > 0) {
            warfarin = warfarinAnswer[0].answer;
        }
        if (warfarinThresholdOkAnswer.length > 0) {
            warfarinThresholdOk = warfarinThresholdOkAnswer[0].answer;
        }
        if (breastfeedingAnswer.length > 0) {
            breastfeeding = breastfeedingAnswer[0].answer;
        }
        if (pregnantAnswer.length > 0) {
            pregnant = pregnantAnswer[0].answer;
        }
        if (covidTrialAnswer.length > 0) {
            covidTrial = covidTrialAnswer[0].answer;
        }
        if (covidTrialApprovedAnswer.length > 0) {
            covidTrialApproved = covidTrialApprovedAnswer[0].answer;
        }
        if (organisationAnswer.length > 0) {
            organisation = organisationAnswer[0].answer;
        }
        newQuestions = questionManager.getQuestions(healthcareWorker, carehomeWorker, onBehalfOf, willingToPartake, infectionOrFeverish, covidPositive4Weeks, otherVaccinePast14Days, fluJabPlanned, seriousReaction, seriousReactionConfirmed, 'No', '', bleedingDisorder, bleedingDisorderConfirmed, warfarin, warfarinThresholdOk, breastfeeding, pregnant, covidTrial, covidTrialApproved, organisation, '');

        this.setState({
            questions: newQuestions
        }, async () => {
            let mandatoryQuestionsUnanswered = 0;
            const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
            for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                const mandatoryQuestion = mandatoryQuestions[mandatory];
                const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                if (userAnswer.length === 0 || !userAnswer[0].answer) {
                    if (((this.state.secondappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes") && (mandatoryQuestion.key === "firstappointmentsite" || mandatoryQuestion.key === "firstappointmentlocation" || mandatoryQuestion.key === "firstappointmentdate" || mandatoryQuestion.key === "firstappointmenttimeslot"))
                        || ((this.state.firstappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes") && (mandatoryQuestion.key === "secondappointmentsite" || mandatoryQuestion.key === "secondappointmentlocation" || mandatoryQuestion.key === "secondappointmentdate" || mandatoryQuestion.key === "secondappointmenttimeslot"))) {
                        // Ignore mandatory question
                    } else {
                        mandatoryQuestionsUnanswered++;
                    }                    
                }
            }

            if (mandatoryQuestionsUnanswered > 0) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: []
                });
            }
            else if (this.state.onbehalfofrelationship && this.state.onbehalfofrelationship.length < 5) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Name of person providing information should be at least 5 characters."]
                });
            }
            else if (this.state.onbehalfofname && this.state.onbehalfofname.length < 5) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Name of booking team member should be at least 5 characters."]
                });
            }
            else if (this.state.nhsnumber && !this.isValidNhsNumber(this.state.nhsnumber)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["NHS Number is not in the correct format! It should be a 10 digit number."]
                });
            }
            else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth cannot be in the future!"]
                });
            }
            else if (this.state.mobile && this.state.mobile !== this.state.confirmedMobile) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Mobile numbers do not match!"]
                });
            }
            else if (email && !this.validateEmail(email)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Email address is not in a valid format!"]
                });
            }
            else if (this.state.email && this.state.email !== this.state.confirmedEmail) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Email addresses do not match!"]
                });
            }
            else if (this.state.assignmentnumber && this.state.assignmentnumber !== this.state.confirmedassignmentnumber) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Assignment numbers do not match!"]
                });
            }
            else if (appointmentsTooClose) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["First appointment and follow-up appointment must be at least " + (secondWindowBookingStart - 1).toString() + " weeks apart!"]
                });
            }
            else {
                const data = {
                    questionnaireName: this.state.questionnaire,
                    questionAnswers: []
                };
                this.state.answers.map(a => data.questionAnswers.push({ questionKey: a.key, answers: [a.answer] }));
                if (this.state.submissionIdentifier) {
                    data.submissionIdentifier = this.state.submissionIdentifier;
                }

                const request = fetch('/QuestionnaireAnswers', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const response = await request;

                if (response.ok) {
                    this.setState({
                        completed: true,
                        submitting: false
                    }, () => {
                        if (this.props.saveCallback) {
                            this.props.saveCallback(this.state.submissionIdentifier);
                        }
                    });
                } else {
                    let validationErrors = [];
                    if (response.status === 400) {
                        try {
                            validationErrors = await response.json();
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    this.setState({
                        errored: true,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
            }
        });
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    dateMatch(firstDate, secondDate) {
        if (!firstDate && !secondDate) {
            return true;
        }

        if (!firstDate || !secondDate) {
            return false;
        }

        if (firstDate.getDate() !== secondDate.getDate()) {
            return false;
        }
        if (firstDate.getMonth() !== secondDate.getMonth()) {
            return false;
        }
        if (firstDate.getFullYear() !== secondDate.getFullYear()) {
            return false;
        }
        return true;
    }

    availableDateForOtherVaccineJab(theDate) {
        let invalidDate = false;
        const availabilityStartDate = new Date();

        availabilityStartDate.setHours(0);
        availabilityStartDate.setMinutes(0);
        availabilityStartDate.setSeconds(0);
        availabilityStartDate.setMilliseconds(0);
        availabilityStartDate.setDate(availabilityStartDate.getDate() - 7);

        if (theDate < availabilityStartDate || theDate > new Date()) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForFirstVaccination(theDate) {
        const availabilityStartDate = new Date(new Date().toDateString());
        const availabilityEndDate = new Date(new Date().toDateString());

        availabilityEndDate.setDate(availabilityEndDate.getDate() + 24); // was 27

        let invalidDate = false;
        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            invalidDate = true;
        }
        if (this.state.othervaccinedate) {
            let earliestVaccinationDate = new Date(this.state.othervaccinedate.getFullYear(), this.state.othervaccinedate.getMonth(), this.state.othervaccinedate.getDate());
            earliestVaccinationDate.setDate(earliestVaccinationDate.getDate() + 8);
            if (theDate < earliestVaccinationDate) {
                invalidDate = true;
            }
        }

        if (this.state.flujabplanneddate) {
            let startOfRestrictedPeriod = new Date(this.state.flujabplanneddate.getFullYear(), this.state.flujabplanneddate.getMonth(), this.state.flujabplanneddate.getDate());
            let endOfRestrictedPeriod = new Date(this.state.flujabplanneddate.getFullYear(), this.state.flujabplanneddate.getMonth(), this.state.flujabplanneddate.getDate());
            startOfRestrictedPeriod.setDate(startOfRestrictedPeriod.getDate() - 15); // was 8
            endOfRestrictedPeriod.setDate(endOfRestrictedPeriod.getDate() + 15); // was 8
            if (theDate > startOfRestrictedPeriod && theDate < endOfRestrictedPeriod) {
                invalidDate = true;
            }
        }
        let availableSchedules = this.state.firstAppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.allowedVaccineType) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.vaccine === this.state.allowedVaccineType);
        }
        if (this.state.firstappointmentsite && this.state.firstappointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.firstappointmentsite && s.scheduleLocation.locationName === this.state.firstappointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForSecondVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (this.state.secondappointmentonly === "Yes") {
            availabilityStartDate = new Date(currentDate.toDateString());
            availabilityEndDate = new Date(currentDate.toDateString());
            availabilityStartDate.setDate(availabilityStartDate.getDate());
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (secondWindowBookingStart * 7)); // was 84
        } else {
            availabilityStartDate = new Date(this.state.firstappointmentdate.getFullYear(), this.state.firstappointmentdate.getMonth(), this.state.firstappointmentdate.getDate());
            availabilityEndDate = new Date(this.state.firstappointmentdate.getFullYear(), this.state.firstappointmentdate.getMonth(), this.state.firstappointmentdate.getDate());
            availabilityStartDate.setDate(availabilityStartDate.getDate() + ((secondWindowBookingStart * 7) - 7)); // was 77
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (secondWindowBookingStart * 7)); // was 84
        }        

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            invalidDate = true;
        }
        let availableSchedules = this.state.secondAppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.vaccinetype) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.vaccine === this.state.vaccinetype);
        }
        if (this.state.secondappointmentsite && this.state.secondappointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.secondappointmentsite && s.scheduleLocation.locationName === this.state.secondappointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForThirdVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (this.state.thirdappointmentonly === "Yes") {
            availabilityStartDate = new Date(currentDate.toDateString());
            availabilityEndDate = new Date(currentDate.toDateString());
            availabilityStartDate.setDate(availabilityStartDate.getDate());
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (thirdWindowBookingStart * 7));
        } else {
            availabilityStartDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityEndDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityStartDate.setDate(availabilityStartDate.getDate() + ((thirdWindowBookingStart * 7) - 7));
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (thirdWindowBookingStart * 7));
        }

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            invalidDate = true;
        }
        let availableSchedules = this.state.thirdAppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.thirdappointmentsite && this.state.thirdappointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.thirdappointmentsite && s.scheduleLocation.locationName === this.state.thirdappointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForBoosterVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        let boosterWindowBookingStart = defaultBoosterBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = boosterBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                boosterWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (this.state.thirdappointmentonly === "Yes") {
            availabilityStartDate = new Date(currentDate.toDateString());
            availabilityEndDate = new Date(currentDate.toDateString());
            availabilityStartDate.setDate(availabilityStartDate.getDate());
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (boosterWindowBookingStart * 7));
        } else {
            availabilityStartDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityEndDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            availabilityStartDate.setDate(availabilityStartDate.getDate() + ((boosterWindowBookingStart * 7) - 7));
            availabilityEndDate.setDate(availabilityEndDate.getDate() + (boosterWindowBookingStart * 7));
        }

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            invalidDate = true;
        }
        let availableSchedules = this.state.boost1AppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.boost1appointmentsite && this.state.boost1appointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.boost1appointmentsite && s.scheduleLocation.locationName === this.state.boost1appointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    availableDateForFluVaccination(theDate) {
        let invalidDate = false;

        let availabilityStartDate;
        let availabilityEndDate;

        availabilityStartDate = new Date(currentDate.toDateString());
        availabilityEndDate = new Date(currentDate.toDateString());
        availabilityStartDate.setDate(availabilityStartDate.getDate());
        availabilityEndDate.setDate(availabilityEndDate.getDate() + 60);

        if (theDate < availabilityStartDate || theDate > availabilityEndDate) {
            invalidDate = true;
        }
        let availableSchedules = this.state.flu1AppointmentScheduleList.filter(s => this.dateMatch(new Date(s.scheduleDate), theDate));
        if (this.state.flu1appointmentsite && this.state.flu1appointmentlocation) {
            availableSchedules = availableSchedules.filter(s => s.scheduleLocation.scheduleSite.siteName === this.state.flu1appointmentsite && s.scheduleLocation.locationName === this.state.flu1appointmentlocation);
        }
        if (availableSchedules.length === 0) {
            invalidDate = true;
        }

        return !invalidDate;
    }

    async getAvailableSchedules(earliestDate, latestDate, vaccine, isFlu, isAccessible, ignoreUtilisation, paediatricAppointments, fiveToElevenAppointments) {
        const earliestDateAsString = earliestDate ? `${earliestDate.getFullYear()}-${this.pad(earliestDate.getMonth() + 1, 2)}-${this.pad(earliestDate.getDate(), 2)}` : '';
        const latestDateAsString = latestDate ? `${latestDate.getFullYear()}-${this.pad(latestDate.getMonth() + 1, 2)}-${this.pad(latestDate.getDate(), 2)}` : '';
        const response = await fetch('/Schedule?questionnaireKey=' + encodeURIComponent(this.state.questionnaire) + '&earliestDate=' + (earliestDate ? encodeURIComponent(earliestDateAsString) : '') + '&ignoreUtilisation=' + (ignoreUtilisation ? 'true' : 'false') + '&latestDate=' + (latestDate ? encodeURIComponent(latestDateAsString) : '') + '&vaccineType=' + (vaccine ? encodeURIComponent(vaccine) : '') + '&isFlu=' + (isFlu ? 'true' : 'false') + '&accessibleLocationRequired=' + (isAccessible ? 'true' : 'false') + '&paediatricAppointments=' + (paediatricAppointments ? 'true' : 'false') + '&fiveToElevenAppointments=' + (fiveToElevenAppointments ? 'true' : 'false'), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else if (response.status === 404) {
            return [];
        } else {
            return [];
        }
    }

    async getSchedulesForFirstAppointment() {
        const availabilityEndDate = new Date(new Date().toDateString());
        availabilityEndDate.setDate(availabilityEndDate.getDate() + 24); // was 27
        let availableSchedules = await this.getAvailableSchedules(currentDate, availabilityEndDate, (this.state.allowedVaccineType ? this.state.allowedVaccineType : ''), false, this.state.restrictedmobility === 'Yes' ? true : false, false, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        // restrict vaccines
        var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
        if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
            availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
        }
        this.setState({
            anyAppsAvailable: availableSchedules.length > 0,
            firstAppointmentScheduleList: availableSchedules.filter(as => (this.state.allowedVaccineType ? as.scheduleLocation.vaccine === this.state.allowedVaccineType : 1 === 1) && (this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1) && (this.state.firstappointmentlocation ? as.scheduleLocation.locationName === this.state.firstappointmentlocation : 1 === 1) && (this.state.firstappointmentsite ? as.scheduleLocation.scheduleSite.siteName === this.state.firstappointmentsite : 1 === 1))
        }, function () {
            this.getAvailableSitesForFirstAppointment();
        });
    }

    async getSchedulesForFollowUpAppointment(callback) {
        let availableSchedules;

        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }

        const earliestFollowUpDate = new Date(this.state.firstappointmentdate);
        earliestFollowUpDate.setDate(earliestFollowUpDate.getDate() + ((secondWindowBookingStart * 7) - 7));
        if (this.state.secondappointmentonly !== "Yes") {
            const latestDate = new Date(this.state.firstappointmentdate.getFullYear(), this.state.firstappointmentdate.getMonth(), this.state.firstappointmentdate.getDate());
            latestDate.setDate(latestDate.getDate() + (secondWindowBookingStart * 7));
            availableSchedules = await this.getAvailableSchedules(earliestFollowUpDate, latestDate, this.state.vaccinetype, false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        } else {
            availableSchedules = await this.getAvailableSchedules(currentDate, null, (this.state.vaccinetype || ''), false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        }
        this.setState({
            anyFollowUpAppsAvailable: availableSchedules.length > 0 && (this.state.secondappointmentonly === "Yes" ? availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1) : availableSchedules.filter(as => as.scheduleLocation.vaccine === this.state.vaccinetype && (this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1) && as.scheduleLocation.locationName === this.state.firstappointmentlocation && as.scheduleLocation.scheduleSite.siteName === this.state.firstappointmentsite)).length > 0,
            secondAppointmentScheduleList: this.state.secondappointmentonly === "Yes" ? availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1) : availableSchedules.filter(as => as.scheduleLocation.vaccine === this.state.vaccinetype && (this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1) && as.scheduleLocation.locationName === this.state.firstappointmentlocation && as.scheduleLocation.scheduleSite.siteName === this.state.firstappointmentsite)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    async getSchedulesForThirdAppointment(callback) {
        let availableSchedules;

        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
        }

        const earliestFollowUpDate = this.state.secondappointmentdate ? new Date(this.state.secondappointmentdate) : new Date();
        earliestFollowUpDate.setDate(earliestFollowUpDate.getDate() + ((thirdWindowBookingStart * 7) - 7));
        if (this.state.thirdappointmentonly !== "Yes") {
            const latestDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            latestDate.setDate(latestDate.getDate() + (thirdWindowBookingStart * 7));
            availableSchedules = await this.getAvailableSchedules(earliestFollowUpDate, latestDate, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        } else {
            availableSchedules = await this.getAvailableSchedules(currentDate, null, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        }
        this.setState({
            anyThirdAppsAvailable: availableSchedules.length > 0 && (availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)).length > 0,
            thirdAppointmentScheduleList: availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    async getSchedulesForBoosterAppointment(callback) {
        let availableSchedules;

        let boosterWindowBookingStart = defaultBoosterBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = boosterBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                boosterWindowBookingStart = newBookingWindow.weeks;
            }
        }

        const earliestFollowUpDate = this.state.secondappointmentdate ? new Date(this.state.secondappointmentdate) : new Date();
        earliestFollowUpDate.setDate(earliestFollowUpDate.getDate() + ((boosterWindowBookingStart * 7) - 7));
        if (this.state.thirdappointmentonly !== "Yes") {
            const latestDate = new Date(this.state.secondappointmentdate.getFullYear(), this.state.secondappointmentdate.getMonth(), this.state.secondappointmentdate.getDate());
            latestDate.setDate(latestDate.getDate() + (boosterWindowBookingStart * 7));
            availableSchedules = await this.getAvailableSchedules(earliestFollowUpDate, latestDate, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        } else {
            availableSchedules = await this.getAvailableSchedules(currentDate, null, 'Pf', false, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
            // restrict vaccines
            var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
            if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
                availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.vaccine === rv.vaccineCode));
            }
        }
        this.setState({
            anyBoosterAppsAvailable: availableSchedules.length > 0 && (availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)).length > 0,
            boost1AppointmentScheduleList: availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    async getSchedulesForFluAppointment(callback) {
        let availableSchedules;
        availableSchedules = await this.getAvailableSchedules(currentDate, null, 'Flu', true, this.state.restrictedmobility === 'Yes' ? true : false, true, this.isPaediatricCohort(), this.isFiveToElevenCohort());
        // restrict vaccines
        var restrictedVaccines = this.state.availableVaccineList.filter(avl => !avl.availableForBookingTeam);
        if (restrictedVaccines.length > 0 && !this.state.isBookingLead) {
            availableSchedules = availableSchedules.filter(s => !restrictedVaccines.find(rv => s.scheduleLocation.fluVaccine === rv.vaccineCode));
        }
        this.setState({
            anyFluAppsAvailable: availableSchedules.length > 0 && (availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)).length > 0,
            flu1AppointmentScheduleList: availableSchedules.filter(as => this.state.restrictedmobility === "Yes" ? as.scheduleLocation.isAccessible : 1 === 1)
        }, () => {
            if (callback) {
                callback()
            }
        });
    }

    getAvailableSites(availableSchedules) {
        let availableSites = [];
        for (var i = 0; i < availableSchedules.length; i++) {
            let siteName = availableSchedules[i].scheduleLocation.scheduleSite.siteName;
            let existingSites = availableSites.filter(as => as === siteName);
            if (existingSites.length === 0) {
                availableSites.push(siteName);
            }
        }
        return availableSites;
    }

    getAvailableLocations(availableSchedules, siteName) {
        let availableLocations = [];
        let availableSchedulesForSite = availableSchedules.filter(as => as.scheduleLocation.scheduleSite.siteName === siteName);
        for (var i = 0; i < availableSchedulesForSite.length; i++) {
            let locationName = availableSchedulesForSite[i].scheduleLocation.locationName;
            let existingSites = availableLocations.filter(as => as === locationName);
            if (existingSites.length === 0) {
                availableLocations.push(locationName);
            }
        }
        return availableLocations;
    }

    getVaccineFromLocation(availableSchedules, siteName, locationName, scheduleDate) {
        let availableSchedulesForSite = availableSchedules.filter(as => as.scheduleLocation.scheduleSite.siteName === siteName);
        for (var i = 0; i < availableSchedulesForSite.length; i++) {
            if (availableSchedulesForSite[i].scheduleLocation.locationName === locationName) {
                if (this.dateMatch(new Date(availableSchedulesForSite[i].scheduleDate), scheduleDate)) {
                    return availableSchedulesForSite[i].scheduleLocation.vaccine;
                }
            }
        }
        return '';
    }

    getVaccineFullName(vaccineCode) {
        const locatedVaccine = this.state.availableVaccineList.find(vl => vl.vaccineCode === vaccineCode);
        if (locatedVaccine) {
            return locatedVaccine.vaccineName;
        }
        return "Cannot determine vaccine";
    }

    getAvailableSitesForFirstAppointment() {
        const sites = this.getAvailableSites(this.state.firstAppointmentScheduleList);
        this.setState({
            firstAppointmentSiteList: sites
        });
    }

    getAvailableSitesForSecondAppointment(callback) {
        const sites = this.getAvailableSites(this.state.secondAppointmentScheduleList);
        this.setState({
            secondAppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForThirdAppointment(callback) {
        const sites = this.getAvailableSites(this.state.thirdAppointmentScheduleList);
        this.setState({
            thirdAppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForBoosterAppointment(callback) {
        const sites = this.getAvailableSites(this.state.boost1AppointmentScheduleList);
        this.setState({
            boost1AppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableSitesForFluAppointment(callback) {
        const sites = this.getAvailableSites(this.state.flu1AppointmentScheduleList);
        this.setState({
            flu1AppointmentSiteList: sites
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForFirstAppointment() {
        const locations = this.getAvailableLocations(this.state.firstAppointmentScheduleList, this.state.firstappointmentsite);
        this.setState({
            firstAppointmentLocationList: locations
        });
    }

    getAvailableLocationsForSecondAppointment() {
        const locations = this.getAvailableLocations(this.state.secondAppointmentScheduleList, this.state.secondappointmentsite);
        this.setState({
            secondAppointmentLocationList: locations
        });
    }

    getAvailableLocationsForThirdAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.thirdAppointmentScheduleList, this.state.thirdappointmentsite);
        this.setState({
            thirdAppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForBoosterAppointment(callback) {
        const locations = this.getAvailableLocations(this.state.boost1AppointmentScheduleList, this.state.boost1appointmentsite);
        this.setState({
            boost1AppointmentLocationList: locations
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getAvailableLocationsForFluAppointment() {
        const locations = this.getAvailableLocations(this.state.flu1AppointmentScheduleList, this.state.flu1appointmentsite);
        this.setState({
            flu1AppointmentLocationList: locations
        });
    }

    async getAvailableTimeSlots(scheduleDate, siteName, locationName, ignoreUtilisation) {
        if (scheduleDate && siteName && locationName) {
            const dateString = `${scheduleDate.getFullYear()}-${this.pad(scheduleDate.getMonth()+1,2)}-${this.pad(scheduleDate.getDate(),2)}`;

            const response = await fetch('/ScheduleSlot?questionnaireKey=' + encodeURIComponent(this.state.questionnaire) + '&scheduleDate=' + encodeURIComponent(dateString) + '&siteName=' + encodeURIComponent(siteName) + '&locationName=' + encodeURIComponent(locationName) + '&ignoreUtilisation=' + (ignoreUtilisation ? 'true' : 'false'), {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else if (response.status === 404) {
                return [];
            } else {
                return [];
            }
        }
        return [];
    }

    async getAvailableTimeSlotsForFirstAppointment(callback) {
        let timeSlots = await this.getAvailableTimeSlots(this.state.firstappointmentdate, this.state.firstappointmentsite, this.state.firstappointmentlocation, false);
        if (this.state.firstappointmentdate > currentDate) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
        }
        if (!this.dateMatch(this.state.firstappointmentdate, this.state.flu1appointmentdate)) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
        }
        this.setState({
            firstAppointmentSlotList: timeSlots
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    async getAvailableTimeSlotsForSecondAppointment() {
        let timeSlots = await this.getAvailableTimeSlots(this.state.secondappointmentdate, this.state.secondappointmentsite, this.state.secondappointmentlocation, false);
        if (this.state.secondappointmentdate > currentDate) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
        }
        if (!this.dateMatch(this.state.secondappointmentdate, this.state.flu1appointmentdate)) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
        }
        this.setState({
            secondAppointmentSlotList: timeSlots
        });
    }

    async getAvailableTimeSlotsForThirdAppointment() {
        let timeSlots = await this.getAvailableTimeSlots(this.state.thirdappointmentdate, this.state.thirdappointmentsite, this.state.thirdappointmentlocation, false);
        if (this.state.thirdappointmentdate > currentDate) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
        }
        if (!this.dateMatch(this.state.thirdappointmentdate, this.state.flu1appointmentdate)) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
        }
        this.setState({
            thirdAppointmentSlotList: timeSlots
        });
    }

    async getAvailableTimeSlotsForBoosterAppointment() {
        let timeSlots = await this.getAvailableTimeSlots(this.state.boost1appointmentdate, this.state.boost1appointmentsite, this.state.boost1appointmentlocation, false);
        if (this.state.boost1appointmentdate > currentDate) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
        }
        if (!this.dateMatch(this.state.boost1appointmentdate, this.state.flu1appointmentdate)) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
        }
        this.setState({
            boost1AppointmentSlotList: timeSlots
        });
    }

    async getAvailableTimeSlotsForFluAppointment() {
        let timeSlots = await this.getAvailableTimeSlots(this.state.flu1appointmentdate, this.state.flu1appointmentsite, this.state.flu1appointmentlocation, false);
        if (this.state.flu1appointmentdate > currentDate) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Walk-in');
        }
        if (!this.dateMatch(this.state.firstappointmentdate, this.state.flu1appointmentdate) &&
            !this.dateMatch(this.state.secondappointmentdate, this.state.flu1appointmentdate) &&
            !this.dateMatch(this.state.thirdappointmentdate, this.state.flu1appointmentdate) &&
            !this.dateMatch(this.state.boost1appointmentdate, this.state.flu1appointmentdate)) {
            timeSlots = timeSlots.filter(ts => ts.slotName !== 'Add-on');
        } else {
            timeSlots = timeSlots.filter(ts => ts.slotName === 'Add-on');
        }
        this.setState({
            flu1AppointmentSlotList: timeSlots
        });
    }

    getAnswersForStorage(questionKey, answer, answersList) {
        const answers = answersList || this.state.answers;
        const existingAnswer = answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        return updatedAnswers;
    }

    getSecondBookingWindowEnd() {
        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
            if (this.isOnlyEligibleForSingleAppointment()) {
                secondWindowBookingStart = 0;
            }
        }
        return secondWindowBookingStart;
    }

    getThirdBookingWindowEnd() {
        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
            if (this.isOnlyEligibleForSingleAppointment()) {
                thirdWindowBookingStart = 0;
            }
        }
        return thirdWindowBookingStart;
    }

    getBoosterBookingWindowEnd() {
        let boosterWindowBookingStart = defaultBoosterBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = boosterBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                boosterWindowBookingStart = newBookingWindow.weeks;
            }
            if (this.isOnlyEligibleForSingleAppointment()) {
                boosterWindowBookingStart = 0;
            }
        }
        return boosterWindowBookingStart;
    }

    checkAndClearUnusedAnswers(questionKey, answer, callback) {
        let newAnswers = [];
        if (questionKey === 'eligible' && answer !== '') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'confirm' || a.key === 'healthcareworker' || a.key === 'carehomeworker');
        }
        if (questionKey === 'healthcareworker' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'confirm');
        }
        if (questionKey === 'healthcareworker' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'confirm');
        }
        if (questionKey === 'carehomeworker' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'confirm');
        }
        if (questionKey === 'carehomeworker' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'confirm');
        }
        if (questionKey === 'willingtopartake' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'nhsnumber' || a.key === "forename" || a.key === "lastname" || a.key === "dateofbirth" || a.key === "gender" || a.key === "ethnicity" || a.key === "organisation" || a.key === "directorate" || a.key === "jobrole" || a.key === "departmentward" || a.key === "assignmentnumber" || a.key === 'confirm');
        }
        if (questionKey === 'willingtopartake' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'nhsnumber' || a.key === "forename" || a.key === "lastname" || a.key === "dateofbirth" || a.key === "gender" || a.key === "ethnicity" || a.key === "organisation" || a.key === "directorate" || a.key === "jobrole" || a.key === "departmentward" || a.key === "assignmentnumber" || a.key === 'confirm');
        }
        if (questionKey === 'infectionorfeverish' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'confirm');
        }
        if (questionKey === 'infectionorfeverish' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'confirm');
        }
        if (questionKey === 'othervaccinepast14days' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'confirm');
        }
        if (questionKey === 'othervaccinepast14days' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'confirm');
        }
        if (questionKey === 'seriousreaction' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'confirm');
        }
        if (questionKey === 'seriousreaction' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'confirm');
        }
        if (questionKey === 'breastfeeding' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'confirm');
        }
        if (questionKey === 'pregnant' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'pregnant' || a.key === 'confirm');
        }
        if (questionKey === 'covidtrial' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'pregnant' || a.key === 'covidtrial' || a.key === 'confirm');
        }
        if (questionKey === 'covidtrial' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'pregnant' || a.key === 'covidtrial' || a.key === 'confirm');
        }
        if (questionKey === 'covidtrialapproved' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'pregnant' || a.key === 'covidtrial' || a.key === 'covidtrialapproved' || a.key === 'confirm');
        }
        if (questionKey === 'restrictedmobility' && answer === 'Yes') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'pregnant' || a.key === 'covidtrial' || a.key === 'covidtrialapproved' || a.key === 'restrictedmobility' || a.key === 'confirm');
        }
        if (questionKey === 'restrictedmobility' && answer === 'No') {
            newAnswers = this.state.answers.filter(a => a.key === 'eligible' || a.key === 'healthcareworker' || a.key === 'carehomeworker' || a.key === 'carehomeorganisation' || a.key === 'onbehalfof' || a.key === 'onbehalfofrelationship' || a.key === 'onbehalfofname' || a.key === 'willingtopartake' || a.key === 'infectionorfeverish' || a.key === 'covidpositive4weeks' || a.key === 'othervaccinepast14days' || a.key === 'othervaccinedate' || a.key === 'confirmflujabrestrictions' || a.key === 'seriousreaction' || a.key === 'seriousreactionconfirmed' || a.key === 'breastfeeding' || a.key === 'pregnant' || a.key === 'covidtrial' || a.key === 'covidtrialapproved' || a.key === 'restrictedmobility' || a.key === 'confirm');
        }

        if (newAnswers.length > 0) {
            const allOtherAnswers = this.state.answers.filter(a => newAnswers.filter(na => na.key === a.key).length === 0);
            const newState = {};
            for (var i = 0; i < allOtherAnswers.length; i++) {
                newState[allOtherAnswers[i].key] = '';
            }
            newState.anyAppsAvailable = true;

            this.setState({
                ...newState,
                answers: newAnswers
            }, () => {
                if (callback) {
                    callback()
                }
            });
        }
    }

    textChangeHandler(e, q) {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const updatedAnswers = this.getAnswersForStorage(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
            answers: updatedAnswers,
            isInvalid: false
        }, async function () {
            if (targetName === 'firstappointmentsite' && targetValue) {
                this.getAvailableLocationsForFirstAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', this.state.firstappointmentonly === "Yes" ? '' : targetValue)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    secondappointmentsite: this.state.firstappointmentonly === "Yes" ? '' : targetValue,
                    firstappointmentlocation: '',
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    secondappointmentlocation: '',
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype: '',
                    vaccinetype2: '',
                    anyFollowUpAppsAvailable: true,
                    answers: nextUpdatedAnswers
                }, () => {
                        this.getAvailableSitesForSecondAppointment(() => this.getAvailableLocationsForSecondAppointment());
                });
            }
            if (targetName === 'firstappointmenttimeslot' && targetValue) {
                await this.getSchedulesForFollowUpAppointment(() => {
                    this.getAvailableSitesForSecondAppointment(() => this.getAvailableLocationsForSecondAppointment());
                });
                
            }
            if (targetName === 'firstappointmentlocation' && targetValue) {
                let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', this.state.firstappointmentonly === "Yes" ? '' : targetValue)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    secondappointmentlocation: this.state.firstappointmentonly === "Yes" ? '' : targetValue,
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype: '',
                    vaccinetype2: '',
                    anyFollowUpAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'secondappointmentsite' && targetValue) {
                let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', targetValue)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    secondappointmentlocation: '',
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                }, () => {
                    this.getAvailableLocationsForSecondAppointment();
                });
            }
            if (targetName === 'secondappointmentlocation' && targetValue) {
                let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '')
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'secondappointmenttimeslot' && targetValue) {
                await this.getSchedulesForThirdAppointment(() => {
                    this.getAvailableSitesForThirdAppointment(() => this.getAvailableLocationsForThirdAppointment());
                });

            }
            if (targetName === 'thirdappointmentsite' && targetValue) {
                this.getAvailableLocationsForThirdAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype3', '', nextUpdatedAnswers)
                this.setState({
                    thirdappointmentlocation: '',
                    thirdappointmentdate: '',
                    thirdappointmenttimeslot: '',
                    vaccinetype3: '',
                    anyThirdAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'boost1appointmentsite' && targetValue) {
                this.getAvailableLocationsForBoosterAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetypeboost1', '', nextUpdatedAnswers)
                this.setState({
                    boost1appointmentlocation: '',
                    boost1appointmentdate: '',
                    boost1appointmenttimeslot: '',
                    vaccinetypeboost1: '',
                    anyBoosterAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
            if (targetName === 'flu1appointmentsite' && targetValue) {
                this.getAvailableLocationsForFluAppointment();
                let nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetypeflu1', '', nextUpdatedAnswers)
                this.setState({
                    flu1appointmentlocation: '',
                    flu1appointmentdate: '',
                    flu1appointmenttimeslot: '',
                    vaccinetypeboostflu1: '',
                    anyFluAppsAvailable: true,
                    answers: nextUpdatedAnswers
                });
            }
        });
    }

    textChangeNoStoreHandler(e, q) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeRawHandler(e, q) {
        const updatedAnswers = this.getAnswersForStorage(q, e.target.rawValue);
        this.setState({
            [e.target.name]: e.target.rawValue,
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    textChangeNoStoreRawHandler(e) {
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    radioChangeHandler(e, q) {
        if (e.target.checked) {
            const newValue = e.target.value;
            const newStateValues = {};
            let updatedAnswers = this.getAnswersForStorage(q, newValue);
            if (q === 'eligible') {
                if (newValue === 'CarehomeWorker' || newValue === 'SocialCareWorker' || newValue === 'HealthcareWorker') {
                    updatedAnswers = this.getAnswersForStorage('healthcareworker', 'Yes', updatedAnswers);
                    newStateValues.healthcareworker = 'Yes';
                } else {
                    updatedAnswers = this.getAnswersForStorage('healthcareworker', 'No', updatedAnswers);
                    updatedAnswers = this.getAnswersForStorage('carehomeorganisation', '', updatedAnswers);
                    newStateValues.healthcareworker = 'No';
                    newStateValues.carehomeorganisation = '';
                }
                if (newValue === 'CarehomeWorker') {
                    updatedAnswers = this.getAnswersForStorage('carehomeworker', 'Yes', updatedAnswers);
                    newStateValues.carehomeworker = 'Yes';
                } else {
                    updatedAnswers = this.getAnswersForStorage('carehomeworker', 'No', updatedAnswers);
                    updatedAnswers = this.getAnswersForStorage('carehomeorganisation', '', updatedAnswers);
                    newStateValues.carehomeworker = 'No';
                    newStateValues.carehomeorganisation = '';
                }
            }
            this.setState({
                [q]: newValue,
                ...newStateValues,
                answers: updatedAnswers,
                isInvalid: false
            }, function () {
                    this.checkAndClearUnusedAnswers(q, newValue, () => {
                        if (q === 'restrictedmobility') {
                            var appOnlyStateValues = {};
                            var updatedAppOnlyAnswers = [];
                            if (this.isOnlyEligibleForSingleAppointment()) {
                                updatedAppOnlyAnswers = this.getAnswersForStorage('firstappointmentonly', 'Yes', this.state.answers);
                                updatedAppOnlyAnswers = this.getAnswersForStorage('secondappointmentonly', '', updatedAppOnlyAnswers);
                                appOnlyStateValues.firstappointmentonly = 'Yes';
                                appOnlyStateValues.secondappointmentonly = '';
                            }
                            else {
                                updatedAppOnlyAnswers = this.getAnswersForStorage('firstappointmentonly', '', this.state.answers);
                                updatedAppOnlyAnswers = this.getAnswersForStorage('secondappointmentonly', '', updatedAppOnlyAnswers);
                                appOnlyStateValues.firstappointmentonly = '';
                                appOnlyStateValues.secondappointmentonly = '';
                            }
                            this.setState({
                                ...appOnlyStateValues,
                                answers: updatedAppOnlyAnswers,
                            }, function () {
                                // refresh schedules
                                this.getSchedulesForFirstAppointment();
                            })                            
                        }
                        if (q === 'seriousreaction') {
                            if (newValue === 'Yes') {
                                this.setState({
                                    allowedVaccineType: 'Az'
                                }, () => {
                                    // refresh schedules
                                    this.getSchedulesForFirstAppointment();
                                });
                            } else {
                                this.setState({
                                    allowedVaccineType: ''
                                }, () => {
                                    // refresh schedules
                                    this.getSchedulesForFirstAppointment();
                                });
                            }
                        }
                    });
            })
        }
    }

    checkedChangeHandler(e, q) {
        let newValue = '';
        if (e.target.checked) {
            newValue = e.target.value;
        }

        const updatedAnswers = this.getAnswersForStorage(q, newValue);

        this.setState({
            [e.target.name]: newValue,
            answers: updatedAnswers,
            isInvalid: false
        }, () => {
                if (q === "firstappointmentonly" && newValue === 'Yes') {

                    let nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', '')
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                    this.setState({
                        secondappointmentsite: '',
                        secondappointmentlocation: '',
                        secondappointmentdate: '',
                        secondappointmenttimeslot: '',
                        vaccinetype2: '',
                        answers: nextUpdatedAnswers
                    });
                }
                if (q === "firstappointmentonly" && newValue === '') {
                    let nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentsite', '')
                    nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentlocation', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                    nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                    this.setState({
                        firstappointmentsite: '',
                        firstappointmentlocation: '',
                        firstappointmentdate: '',
                        firstappointmenttimeslot: '',
                        secondappointmentsite: '',
                        secondappointmentlocation: '',
                        secondappointmentdate: '',
                        secondappointmenttimeslot: '',
                        vaccinetype: '',
                        vaccinetype2: '',
                        answers: nextUpdatedAnswers
                    });
                }
            // 2nd appointment only
            if (q === "secondappointmentonly" && newValue === 'Yes') {

                let nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentsite', '')
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('thirdappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype3', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('boost1appointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetypeboost1', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('flu1appointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetypeflu1', '', nextUpdatedAnswers)
                this.setState({
                    firstappointmentsite: '',
                    firstappointmentlocation: '',
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    vaccinetype: '',
                    thirdappointmentsite: '',
                    thirdappointmentlocation: '',
                    thirdappointmentdate: '',
                    thirdappointmenttimeslot: '',
                    vaccinetype3: '',
                    boost1appointmentsite: '',
                    boost1appointmentlocation: '',
                    boost1appointmentdate: '',
                    boost1appointmenttimeslot: '',
                    vaccinetypeboost1: '',
                    flu1appointmentsite: '',
                    flu1appointmentlocation: '',
                    flu1appointmentdate: '',
                    flu1appointmenttimeslot: '',
                    vaccinetypeflu1: '',
                    answers: nextUpdatedAnswers
                }, () => {
                    this.getSchedulesForFollowUpAppointment(() => {
                        this.getAvailableSitesForSecondAppointment(() => this.getAvailableLocationsForSecondAppointment());
                    });
                });
            }
            if (q === "secondappointmentonly" && newValue === '') {
                let nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentsite', '')
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    firstappointmentsite: '',
                    firstappointmentlocation: '',
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    secondappointmentsite: '',
                    secondappointmentlocation: '',
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                });
            }

            // Third appointment only
            if (q === "thirdappointmentonly" && newValue === 'Yes') {

                let nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentsite', '')
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentsite', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentlocation', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', nextUpdatedAnswers)
                nextUpdatedAnswers = this.getAnswersForStorage('vaccinetype2', '', nextUpdatedAnswers)
                this.setState({
                    firstappointmentsite: '',
                    firstappointmentlocation: '',
                    firstappointmentdate: '',
                    firstappointmenttimeslot: '',
                    vaccinetype: '',
                    secondappointmentsite: '',
                    secondappointmentlocation: '',
                    secondappointmentdate: '',
                    secondappointmenttimeslot: '',
                    vaccinetype2: '',
                    answers: nextUpdatedAnswers
                }, () => {
                    this.getSchedulesForThirdAppointment(() => {
                        this.getAvailableSitesForThirdAppointment(() => this.getAvailableLocationsForThirdAppointment(() =>
                            this.getSchedulesForBoosterAppointment(() => {
                                this.getAvailableSitesForBoosterAppointment(() => this.getAvailableLocationsForBoosterAppointment(() =>
                                    this.getSchedulesForFluAppointment(() => {
                                        this.getAvailableSitesForFluAppointment();
                                    })));
                            })
                        ));
                    });
                });
            }
        });
    }

    checkedChangeNoStoreHandler(e, q) {
        let newValue = '';
        if (e.target.checked) {
            newValue = e.target.value;
        }

        this.setState({
            [e.target.name]: newValue,
            isInvalid: false
        });
    }

    dateOfOtherVaccineChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        const updatedAnswers = this.getAnswersForStorage('othervaccinedate', newValue);

        this.setState({
            othervaccinedate: datePicked ? datePicked : '',
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    dateOfPlannedFluJabChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        const updatedAnswers = this.getAnswersForStorage('flujabplanneddate', newValue);

        this.setState({
            flujabplanneddate: datePicked ? datePicked : '',
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    dateOfFirstVaccinationChangeHandler(datePicked) {
        let newValue = '';
        let defaultSecondVaccinationDate = '';

        let secondWindowBookingStart = defaultSecondBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = secondBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                secondWindowBookingStart = newBookingWindow.weeks;
            }
        }

        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
            defaultSecondVaccinationDate = new Date(datePicked);
            defaultSecondVaccinationDate.setDate(defaultSecondVaccinationDate.getDate() + ((secondWindowBookingStart * 7) - 7));
        }

        let updatedAnswers = this.getAnswersForStorage('firstappointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('firstappointmenttimeslot', '', updatedAnswers);
        updatedAnswers = this.getAnswersForStorage('secondappointmentdate', '', updatedAnswers);
        updatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', updatedAnswers);

        this.setState({
            firstappointmentdate: datePicked ? datePicked : '',
            defaultSecondVaccinationDate: defaultSecondVaccinationDate,
            firstappointmenttimeslot: '',
            secondappointmentdate: '',
            secondappointmenttimeslot: '',
            anyFollowUpAppsAvailable: true,
            answers: updatedAnswers,
            isInvalid: false
        }, function () {
            const vaccine = this.getVaccineFromLocation(this.state.firstAppointmentScheduleList, this.state.firstappointmentsite, this.state.firstappointmentlocation, datePicked) || '';
            let updatedVaccineAnswers = this.getAnswersForStorage('vaccinetype', vaccine);
            updatedVaccineAnswers = this.getAnswersForStorage('vaccinetype2', vaccine, updatedVaccineAnswers);
            this.setState({
                vaccinetype: vaccine,
                vaccinetype2: vaccine,
                answers: updatedVaccineAnswers
            }, () => {
                this.getAvailableTimeSlotsForFirstAppointment(() => {
                    this.getSchedulesForFollowUpAppointment();
                });
            })                
        });
    }

    dateOfSecondVaccinationChangeHandler(datePicked) {
        let newValue = '';
        let defaultThirdVaccinationDate = '';
        let defaultBoosterVaccinationDate = '';
        let thirdWindowBookingStart = defaultThirdBookingWindowStart;
        let boosterWindowBookingStart = defaultBoosterBookingWindowStart;
        if (this.state.eligible) {
            const newBookingWindow = thirdBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBookingWindow) {
                thirdWindowBookingStart = newBookingWindow.weeks;
            }
            const newBoosterBookingWindow = boosterBookingWindowStartList.find(bw => bw.cohort === this.state.eligible);
            if (newBoosterBookingWindow) {
                boosterWindowBookingStart = newBoosterBookingWindow.weeks;
            }
        }

        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
            defaultThirdVaccinationDate = new Date(datePicked);
            defaultThirdVaccinationDate.setDate(defaultThirdVaccinationDate.getDate() + ((thirdWindowBookingStart * 7) - 7));
            defaultBoosterVaccinationDate = new Date(datePicked);
            defaultBoosterVaccinationDate.setDate(defaultBoosterVaccinationDate.getDate() + ((boosterWindowBookingStart * 7) - 7));
        }

        let updatedAnswers = this.getAnswersForStorage('secondappointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('secondappointmenttimeslot', '', updatedAnswers);

        this.setState({
            secondappointmentdate: datePicked ? datePicked : '',
            secondappointmenttimeslot: '',
            defaultThirdVaccinationDate: defaultThirdVaccinationDate,
            defaultBoosterVaccinationDate: defaultBoosterVaccinationDate,
            answers: updatedAnswers,
            isInvalid: false
        }, function () {
            this.getAvailableTimeSlotsForSecondAppointment(() => {
                this.getSchedulesForThirdAppointment();
            });
        });
    }

    dateOfThirdVaccinationChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('thirdappointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('thirdappointmenttimeslot', '', updatedAnswers);

        this.setState({
            thirdappointmentdate: datePicked ? datePicked : '',
            thirdappointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, function () {
            this.getAvailableTimeSlotsForThirdAppointment();
        });
    }

    dateOfBoosterVaccinationChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('boost1appointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('boost1appointmenttimeslot', '', updatedAnswers);

        this.setState({
            boost1appointmentdate: datePicked ? datePicked : '',
            boost1appointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, function () {
            this.getAvailableTimeSlotsForBoosterAppointment();
        });
    }

    dateOfFluVaccinationChangeHandler(datePicked) {
        let newValue = '';
        if (datePicked) {
            newValue = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
        }

        let updatedAnswers = this.getAnswersForStorage('flu1appointmentdate', newValue);
        updatedAnswers = this.getAnswersForStorage('flu1appointmenttimeslot', '', updatedAnswers);

        this.setState({
            flu1appointmentdate: datePicked ? datePicked : '',
            flu1appointmenttimeslot: '',
            answers: updatedAnswers,
            isInvalid: false
        }, function () {
            this.getAvailableTimeSlotsForFluAppointment();
        });
    }

    isValidNhsNumber(nhsNumber) {

        var isValid = false;

        if (nhsNumber.length == 10) {

            var total = 0;

            var i = 0;
            for (i = 0; i <= 8; i++) {
                var digit = nhsNumber.substr(i, 1);
                var factor = 10 - i;
                total += (digit * factor);
            }

            var checkDigit = (11 - (total % 11));

            if (checkDigit == 11) { checkDigit = 0; }

            if (checkDigit == nhsNumber.substr(9, 1)) { isValid = true; }
        }

        return isValid;
    }

    isPaediatricCohort() {
        return (this.state.eligible === "12CEV" || this.state.eligible === "12") || this.isFiveToElevenCohort();
    }

    isFiveToElevenCohort() {
        return (this.state.eligible === "5CEV" || this.state.eligible === "5");
    }

    isOnlyEligibleForSingleAppointment() {
        return (this.state.eligible === "16");
    }

    showIf(answerConfig) {
        var keys = Object.keys(answerConfig);
        let show = true;
        let currentAnswer = '';
        for (var keyIter = 0; keyIter < keys.length; keyIter++) {
            currentAnswer = '';
            if (keys[keyIter].endsWith('Answered')) {
                const answerVal = answerConfig[keys[keyIter].replace('Answered', '')] || '';
                const answer = this.state.answers.filter(a => a.key === keys[keyIter].replace('Answered', '').toLowerCase());
                if (answer.length > 0) {
                    currentAnswer = answer[0].answer;
                }
                if (answerVal !== '') {
                    if (Array.isArray(answerVal)) {
                        if (!answerVal.find(av => av === currentAnswer)) {
                            show = false;
                        }
                    } else {
                        if (currentAnswer !== answerVal) {
                            show = false;
                        }
                    }
                } else {
                    if (currentAnswer === '') {
                        show = false;
                    }
                }
            }
        }
        return show;
    }

    readyToCollectDemographics() {
        return (this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "No" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Not Yet" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", secondAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", firstAppointmentOnlyAnswered: true, firstAppointmentOnly: "Yes", firstAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", thirdAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", boost1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", flu1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", infectionOrFeverishAnswered: true, infectionOrFeverish: "Yes" })
            || !this.state.anyAppsAvailable);
    }

    readyToCollectDemographicsNonHealthcareWorker() {
        return (this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "No", willingToPartakeAnswered: true, willingToPartake: "No" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "No", willingToPartakeAnswered: true, willingToPartake: "Not Yet" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", secondAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", firstAppointmentOnlyAnswered: true, firstAppointmentOnly: "Yes", firstAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", thirdAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", boost1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", flu1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", infectionOrFeverishAnswered: true, infectionOrFeverish: "Yes" })
            || (!this.state.anyAppsAvailable && this.showIf({ healthcareWorkerAnswered: true, healthcareWorker: "No" })));
    }

    readyToCollectDemographicsHealthcareWorker() {
        return (this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", willingToPartakeAnswered: true, willingToPartake: "No" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", willingToPartakeAnswered: true, willingToPartake: "Not Yet" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", secondAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", firstAppointmentOnlyAnswered: true, firstAppointmentOnly: "Yes", firstAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", thirdAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", boost1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", flu1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No", infectionOrFeverishAnswered: true, infectionOrFeverish: "Yes" })
            || (!this.state.anyAppsAvailable && this.showIf({ healthcareWorkerAnswered: true, healthcareWorker: "Yes", carehomeWorkerAnswered: true, carehomeWorker: "No" })));
    }

    readyToCollectDirectorateInfo() {
        return this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", organisationAnswered: true, organisation: 'Guy\'s and St Thomas\' NHS Foundation Trust' });
    }

    readyToCollectJobInfo() {
        return (this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", willingToPartakeAnswered: true, willingToPartake: "No" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", willingToPartakeAnswered: true, willingToPartake: "Not Yet" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", secondAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", firstAppointmentOnlyAnswered: true, firstAppointmentOnly: "Yes", firstAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", thirdAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", boost1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", flu1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes", infectionOrFeverishAnswered: true, infectionOrFeverish: "Yes" })
            || (!this.state.anyAppsAvailable && this.showIf({ healthcareWorkerAnswered: true, healthcareWorker: "Yes" })));
    }

    readyToCollectMobileNumber() {
        return (this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", secondAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", firstAppointmentOnlyAnswered: true, firstAppointmentOnly: "Yes", firstAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", thirdAppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", boost1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", thirdAppointmentOnlyAnswered: true, thirdAppointmentOnly: "Yes", flu1AppointmentTimeSlotAnswered: true })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", infectionOrFeverishAnswered: true, infectionOrFeverish: "Yes" })
            || !this.state.anyAppsAvailable);
    }

    isNotTakingPart() {
        return (this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "No" })
            || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Not Yet" }));
    }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    render() {
        if (this.state.completed) {
            return <div className="questionnaire-body">
                <strong>Thank you! Your responses have been recorded.</strong>
            </div>
        }
        return <div className="questionnaire-body">
            <QuestionnaireBlurb />
            <div>
                <Row>
                    <div className="col-12">
                        There is a national process to prioritise vaccination for those people who are either most at risk or who need it because of the work they do. This includes those people over the age of 70, those who are classed as clinically extremely vulnerable and people working in healthcare, social care and care homes.
                    </div>
                    <div className="col-12 question-text">
                        pre-1. <span><strong>Please indicate which of the priority groups you fall into?</strong></span> <RequiredSymb />
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="CarehomeWorker" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Carehome Worker
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="SocialCareWorker" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Frontline Social Care Worker
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="HealthcareWorker" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Frontline Healthcare Worker
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="80" onChange={(e) => this.radioChangeHandler(e, "eligible")} />80 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="75" onChange={(e) => this.radioChangeHandler(e, "eligible")} />75 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="70" onChange={(e) => this.radioChangeHandler(e, "eligible")} />70 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="ClinVulnerable" onChange={(e) => this.radioChangeHandler(e, "eligible")} />18 to 69 years of age and clinically extremely vulnerable
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="65" onChange={(e) => this.radioChangeHandler(e, "eligible")} />65 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="16To64Underlying" onChange={(e) => this.radioChangeHandler(e, "eligible")} />16 to 64 years with underlying health conditions which put them at higher risk of serious disease and mortality
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="60" onChange={(e) => this.radioChangeHandler(e, "eligible")} />60 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="55" onChange={(e) => this.radioChangeHandler(e, "eligible")} />55 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="50" onChange={(e) => this.radioChangeHandler(e, "eligible")} />50 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="40" onChange={(e) => this.radioChangeHandler(e, "eligible")} />40 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="30" onChange={(e) => this.radioChangeHandler(e, "eligible")} />30 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="18" onChange={(e) => this.radioChangeHandler(e, "eligible")} />18 years of age and over
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="16CEV" onChange={(e) => this.radioChangeHandler(e, "eligible")} />16 to 17 years of age and clinically extremely vulnerable
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="16" onChange={(e) => this.radioChangeHandler(e, "eligible")} />16 to 17 years of age
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="12CEV" onChange={(e) => this.radioChangeHandler(e, "eligible")} />12 to 15 years of age and clinically extremely vulnerable
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="12" onChange={(e) => this.radioChangeHandler(e, "eligible")} />12 to 15 years of age
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="5CEV" onChange={(e) => this.radioChangeHandler(e, "eligible")} />5 to 11 years of age and clinically extremely vulnerable
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="5" onChange={(e) => this.radioChangeHandler(e, "eligible")} />5 to 11 years of age
                            </Label>
                        </FormGroup>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="radio" name="eligible" value="Carer" onChange={(e) => this.radioChangeHandler(e, "eligible")} />Carer of somebody in <strong>any of the above</strong> priority groups
                            </Label>
                        </FormGroup>
                    </div>
                </Row>
            </div>
            {this.showIf({ eligibleAnswered: true, eligible: "No" }) && <div className="questionnaire-error"><strong>You are not in a group that the Government have currently invited into the vaccination programme. When the vaccine is rolled out further you will be invited to participate.</strong></div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"] }) &&
                <Question questionNumber="pre-2" questionText={"Are you a Frontline Healthcare Worker?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="radio" name="healthcareworker" value="Yes" checked={this.state.healthcareworker === "Yes"} onChange={(e) => this.radioChangeHandler(e, "healthcareworker")} disabled />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="radio" name="healthcareworker" value="No" checked={this.state.healthcareworker === "No"} onChange={(e) => this.radioChangeHandler(e, "healthcareworker")} disabled />No
                        </Label>
                    </FormGroup>
                </Question>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "Yes" }) &&
                <Question questionNumber="pre-2" questionText={"Are you a Care Home Worker?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="radio" name="carehomeworker" value="Yes" checked={this.state.carehomeworker === "Yes"} onChange={(e) => this.radioChangeHandler(e, "carehomeworker")} disabled />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="radio" name="carehomeworker" value="No" checked={this.state.carehomeworker === "No"} onChange={(e) => this.radioChangeHandler(e, "carehomeworker")} disabled />No
                        </Label>
                    </FormGroup>
                </Question>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], carehomeWorkerAnswered: true, carehomeWorker: "Yes" }) &&
                <Question questionNumber="2.2a.ii" questionText={"Please select the Care Home you are registering for:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="carehomeorganisation" onChange={(e) => this.textChangeHandler(e, "carehomeorganisation", true)} value={this.state.carehomeorganisation}>
                                <option value="">Please select</option>
                                {this.state.carehomeOrganisationList.map((di, idx) => <option key={idx} value={di.id}>{di.name}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question>}
            {(this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, healthcareWorker: "No" }) || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], carehomeWorkerAnswered: true, carehomeWorker: "No" }) || this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], carehomeWorkerAnswered: true, carehomeWorker: "Yes", carehomeOrganisationAnswered: true })) &&
                <Question questionNumber="pre-3.1" questionText={"Is this information being provided by someone other than the patient?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="radio" name="onbehalfof" value="Yes" checked={this.state.onbehalfof === "Yes"} onChange={(e) => this.radioChangeHandler(e, "onbehalfof")} />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="radio" name="onbehalfof" value="No" checked={this.state.onbehalfof === "No"} onChange={(e) => this.radioChangeHandler(e, "onbehalfof")} />No
                        </Label>
                    </FormGroup>
                </Question>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, onBehalfOf: "Yes" }) &&
                <Question questionNumber="pre-3.2" questionText={"Name of person providing information and their relationship to the patient:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="onbehalfofrelationship" value={this.state.onbehalfofrelationship} onChange={(e) => this.textChangeHandler(e, "onbehalfofrelationship", true)} maxLength={120} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true }) &&
                <Question questionNumber="pre-4" questionText={"Name of vaccination booking team member completing form:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="onbehalfofname" value={this.state.onbehalfofname} onChange={(e) => this.textChangeHandler(e, "onbehalfofname", true)} maxLength={30} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], onBehalfOfAnswered: true, onBehalfOf: "Yes" }) && <div className="questionnaire-error"><strong>Please answer the questions below as if they are directed to the person you are registering for the vaccination.</strong></div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true }) &&
                <Question questionNumber="1.1a" questionText={this.isOnlyEligibleForSingleAppointment() ? "Are you willing to take part in the COVID-19 Vaccination programme?" : ("Are you willing to take part in the COVID-19 Vaccination programme and will you be available for your second vaccination between " + (this.getSecondBookingWindowEnd() - 1) + " to " + this.getSecondBookingWindowEnd() + " weeks after your first appointment, or are you booking for alternative dose or vaccine type (i.e. 3rd/booster/flu)?")} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="willingtopartake" value="Yes" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />Yes
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="willingtopartake" value="No" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />No
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="willingtopartake" value="Not Yet" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />Yes, but NOT at this time
                        </Label>
                    </FormGroup>
                </Question>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes" }) &&
                <Question questionNumber="1.2" questionText={"Do you have restricted mobility, or do you use a walking aid, mobility scooter or wheelchair?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="restrictedmobility" value="Yes" onChange={(e) => this.radioChangeHandler(e, "restrictedmobility")} />Yes
                    </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="radio" name="restrictedmobility" value="No" onChange={(e) => this.radioChangeHandler(e, "restrictedmobility")} />No
                    </Label>
                    </FormGroup>
                </Question>}
            <br />&nbsp;<br />
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && !this.state.anyAppsAvailable &&
                <div className="questionnaire-error"><p><strong>Unfortunately there are no appointments available. This could be either due to lack of appointment availability or no suitable vaccine or accessible location based on the answers above.</strong></p></div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && this.state.anyAppsAvailable && !this.state.allowDateOverride && !this.isOnlyEligibleForSingleAppointment() &&
                <div className="questionnaire-error">
                <p><strong>The immunisation programme consists of two doses of the vaccine, administered {this.getSecondBookingWindowEnd() - 1} - {this.getSecondBookingWindowEnd()} weeks apart for the selected cohort.</strong></p>
                    <p><strong>You need to book both vaccination appointments now, and you must attend both appointments to complete the immunisation.</strong></p>
                </div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && this.state.anyAppsAvailable && !this.state.allowDateOverride && this.isOnlyEligibleForSingleAppointment() &&
                <div className="questionnaire-error">
                    <p><strong>The immunisation programme requires only a single dose of the vaccine, for this cohort, to be booked at this time.</strong></p>
                </div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && this.state.anyAppsAvailable && this.state.allowDateOverride && !this.isOnlyEligibleForSingleAppointment() &&
                <div className="questionnaire-error">
                <p><strong>The immunisation programme consists of two doses of the vaccine, administered {this.getSecondBookingWindowEnd() - 1} - {this.getSecondBookingWindowEnd()} weeks apart for the selected cohort.</strong></p>
                </div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && this.state.anyAppsAvailable && this.state.allowDateOverride && this.isOnlyEligibleForSingleAppointment() &&
                <div className="questionnaire-error">
                <p><strong>The immunisation programme requires only a single dose of the vaccine, for this cohort, to be booked at this time.</strong></p>
                </div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && this.state.anyAppsAvailable && this.state.allowDateOverride &&
                <div className="questionnaire-error">
                    <p><strong>If a patient requires only a single appointment for this vaccination booking, please indicate this below otherwise proceed to book both vaccination appointments now. Patient must attend both appointments to complete the immunisation. Third appointments are currently offered only to those at most clinical risk.</strong></p>
                <div>
                    <FormGroup className="questionnaire-answers">
                        <Label><Input type="checkbox" name="firstappointmentonly" value="Yes" checked={this.state.firstappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "firstappointmentonly")} disabled={this.state.secondappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes"} />Appointment is ONLY required for FIRST vaccination</Label>
                    </FormGroup>
                </div>
                    <div>
                        <FormGroup className="questionnaire-answers">
                        <Label><Input type="checkbox" name="secondappointmentonly" value="Yes" checked={this.state.secondappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "secondappointmentonly")} disabled={this.state.firstappointmentonly === "Yes" | this.thirdappointmentonly === "Yes"} />Appointment is ONLY required for SECOND vaccination</Label>
                        </FormGroup>
                </div>
                    <div>
                        <FormGroup className="questionnaire-answers">
                            <Label><Input type="checkbox" name="thirdappointmentonly" value="Yes" checked={this.state.thirdappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "thirdappointmentonly")} disabled={this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes"} />Appointment is ONLY required for ALTERNATIVE vaccination dose/type</Label>
                        </FormGroup>
                    </div>
                </div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && this.state.anyAppsAvailable && !this.state.allowDateOverride &&
                    <div className="questionnaire-error">
                        <p><strong>If a patient requires only an alternative appointment for this vaccination booking, please indicate this below otherwise proceed to book both vaccination appointments now. Patient must attend both appointments to complete the immunisation. Third appointments are currently offered only to those at most clinical risk.</strong></p>
                    <div>
                        <FormGroup className="questionnaire-answers">
                            <Label><Input type="checkbox" name="thirdappointmentonly" value="Yes" checked={this.state.thirdappointmentonly === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "thirdappointmentonly")} disabled={this.state.firstappointmentonly === "Yes" || this.state.secondappointmentonly === "Yes"} />Appointment is ONLY required for THIRD vaccination</Label>
                        </FormGroup>
                    </div>
                </div>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <div><strong><h3>1st Appointment</h3></strong></div>}
            <Row>
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <Col><Question questionNumber="1.3a" questionText={"Please select a site for your first vaccination appointment."} isMandatory={this.state.secondappointmentonly !== "Yes" || this.state.thirdappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="select" name="firstappointmentsite" onChange={(e) => this.textChangeHandler(e, "firstappointmentsite", true)} value={this.state.firstappointmentsite} disabled={this.state.secondappointmentonly === "Yes" || this.state.thirdappointmentonly === "Yes"}>
                                <option value="">Please select</option>
                                {this.state.firstAppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentLocationAnswered: true })) &&
                <Col><Question questionNumber="1.3b" questionText={"Please select a location for your first vaccination appointment."} isMandatory={this.state.secondappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="firstappointmentlocation" onChange={(e) => this.textChangeHandler(e, "firstappointmentlocation", true)} value={this.state.firstappointmentlocation} disabled={!this.state.firstappointmentsite} >
                                <option value="">Please select</option>
                                {this.state.firstAppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentDateAnswered: true })) &&
                <Col><Question questionNumber="1.3c" questionText={"Please select an available date for first vaccination appointment."} isMandatory={this.state.secondappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <DatePicker className="form-control date-selection-inputbox" selected={this.state.firstappointmentdate} filterDate={this.availableDateForFirstVaccination} onChange={this.dateOfFirstVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.firstappointmentlocation} />
                    </Label>
                    {(this.state.vaccinetype && this.state.firstappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype)}</span> : null}
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentTimeSlotAnswered: true })) &&
                <Col><Question questionNumber="1.3d" questionText={"Please select a time slot for your first appointment:"} isMandatory={this.state.secondappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="firstappointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "firstappointmenttimeslot", true)} value={this.state.firstappointmenttimeslot} disabled={!this.state.firstappointmentdate} >
                                <option value="">Please select</option>
                                {this.state.firstAppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                            </Input><Button color="link" onClick={() => this.getAvailableTimeSlotsForFirstAppointment()}>refresh available slots</Button>
                        </Label>
                    </FormGroup>
                    </Question></Col>}
            </Row>
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <div><strong><h3>2nd Appointment</h3></strong></div>}
            <Row>
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <Col><Question questionNumber="1.3e.i" questionText={"Please select a site for your SECOND vaccination appointment."} isMandatory={this.state.firstappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="select" name="secondappointmentsite" onChange={(e) => this.textChangeHandler(e, "secondappointmentsite", true)} value={this.state.secondappointmentsite} disabled={this.state.secondappointmentonly !== 'Yes'}>
                                <option value="">Please select</option>
                                {this.state.secondAppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                <Col><Question questionNumber="1.3e.ii" questionText={"Please select a location for your SECOND vaccination appointment."} isMandatory={this.state.firstappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                        <Input type="select" name="secondappointmentlocation" onChange={(e) => this.textChangeHandler(e, "secondappointmentlocation", true)} value={this.state.secondappointmentlocation} disabled={this.state.secondappointmentonly !== 'Yes' || !this.state.secondappointmentsite}>
                                <option value="">Please select</option>
                                {this.state.secondAppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                    </Label>
                        {!this.state.anyFollowUpAppsAvailable && <div className="questionnaire-error">There is no availability in {this.getSecondBookingWindowEnd() - 1} - {this.getSecondBookingWindowEnd()} weeks time at a suitable location.</div>}
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                <Col><Question questionNumber="1.3f" questionText={"Please select an available SECOND appointment date. IMPORTANT: This SHOULD be between " + (this.getSecondBookingWindowEnd() - 1) + " - " + this.getSecondBookingWindowEnd() + " weeks after your first appointment."} isMandatory={this.state.firstappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <DatePicker className="form-control date-selection-inputbox" selected={this.state.secondappointmentdate} openToDate={this.state.defaultSecondVaccinationDate} filterDate={this.availableDateForSecondVaccination} onChange={this.dateOfSecondVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.secondappointmentlocation || (!this.state.firstappointmenttimeslot && this.state.secondappointmentonly !== 'Yes') || this.state.firstappointmentonly === 'Yes'} />
                    </Label>
                    {(this.state.vaccinetype2 && this.state.secondappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype2)}</span> : null}
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                <Col><Question questionNumber="1.3g" questionText={"Please select a time slot for your SECOND appointment:"} isMandatory={this.state.firstappointmentonly !== "Yes"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="secondappointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "secondappointmenttimeslot", true)} value={this.state.secondappointmenttimeslot} disabled={!this.state.secondappointmentdate}>
                                <option value="">Please select</option>
                                {this.state.secondAppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                            </Input>
                            <Button color="link" onClick={() => this.getAvailableTimeSlotsForSecondAppointment()}>refresh available slots</Button>
                        </Label>
                    </FormGroup>
                    </Question></Col>}
            </Row>
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <div><strong><h3>3rd Appointment</h3></strong></div>}
            <Row>
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <Col><Question questionNumber="1.3h.i" questionText={"Please select a site for your THIRD vaccination appointment."}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="thirdappointmentsite" onChange={(e) => this.textChangeHandler(e, "thirdappointmentsite", true)} value={this.state.thirdappointmentsite}>
                                <option value="">Please select</option>
                                {this.state.thirdAppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                <Col><Question questionNumber="1.3h.ii" questionText={"Please select a location for your THIRD vaccination appointment."}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="thirdappointmentlocation" onChange={(e) => this.textChangeHandler(e, "thirdappointmentlocation", true)} value={this.state.thirdappointmentlocation} disabled={!this.state.thirdappointmentsite}>
                                <option value="">Please select</option>
                                {this.state.thirdAppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                        </Label>
                        {!this.state.anyThirdAppsAvailable && <div className="questionnaire-error">There is no availability in {this.getThirdBookingWindowEnd() - 1} - {this.getThirdBookingWindowEnd()} weeks time at a suitable location.</div>}
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3i" questionText={"Please select an available THIRD appointment date. IMPORTANT: This SHOULD be between " + (this.getThirdBookingWindowEnd() - 1) + " to " + this.getThirdBookingWindowEnd() + " weeks after your second appointment."}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <DatePicker className="form-control date-selection-inputbox" selected={this.state.thirdappointmentdate} openToDate={this.state.defaultThirdVaccinationDate} filterDate={this.availableDateForThirdVaccination} onChange={this.dateOfThirdVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.thirdappointmentlocation || (!this.state.secondappointmenttimeslot && this.state.thirdappointmentonly !== 'Yes') || this.state.firstappointmentonly === 'Yes'} />
                        </Label>
                        {(this.state.vaccinetype3 && this.state.thirdappointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetype3)}</span> : null}
                    </FormGroup>
                </Question></Col>}
            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                <Col><Question questionNumber="1.3j" questionText={"Please select a time slot for your THIRD appointment:"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="thirdappointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "thirdappointmenttimeslot", true)} value={this.state.thirdappointmenttimeslot} disabled={!this.state.thirdappointmentdate}>
                                <option value="">Please select</option>
                                {this.state.thirdAppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                            </Input>
                            <Button color="link" onClick={() => this.getAvailableTimeSlotsForThirdAppointment()}>refresh available slots</Button>
                        </Label>
                    </FormGroup>
                </Question></Col>}
            </Row>

            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <div><strong><h3>Booster Appointment</h3></strong></div>}
            <Row>
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                    <Col><Question questionNumber="1.3k.i" questionText={"Please select a site for your BOOSTER vaccination appointment."}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="select" name="boost1appointmentsite" onChange={(e) => this.textChangeHandler(e, "boost1appointmentsite", true)} value={this.state.boost1appointmentsite}>
                                    <option value="">Please select</option>
                                    {this.state.boost1AppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input>
                            </Label>
                        </FormGroup>
                    </Question></Col>}
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3k.ii" questionText={"Please select a location for your BOOSTER vaccination appointment."}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="select" name="boost1appointmentlocation" onChange={(e) => this.textChangeHandler(e, "boost1appointmentlocation", true)} value={this.state.boost1appointmentlocation} disabled={!this.state.boost1appointmentsite}>
                                    <option value="">Please select</option>
                                    {this.state.boost1AppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input>
                            </Label>
                        {!this.state.anyBoosterAppsAvailable && <div className="questionnaire-error">There is no availability in {this.getBoosterBookingWindowEnd() - 1} - {this.getBoosterBookingWindowEnd()} weeks time at a suitable location.</div>}
                        </FormGroup>
                    </Question></Col>}
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3l" questionText={"Please select an available BOOSTER appointment date. IMPORTANT: This SHOULD be between " + (this.getBoosterBookingWindowEnd() - 1) + " to " + this.getBoosterBookingWindowEnd() + " weeks after your 1st booster appointment."}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <DatePicker className="form-control date-selection-inputbox" selected={this.state.boost1appointmentdate} openToDate={this.state.defaultBoosterVaccinationDate} filterDate={this.availableDateForBoosterVaccination} onChange={this.dateOfBoosterVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.boost1appointmentlocation || (!this.state.secondappointmenttimeslot && this.state.thirdappointmentonly !== 'Yes') || this.state.firstappointmentonly === 'Yes'} />
                            </Label>
                            {(this.state.vaccinetypeboost1 && this.state.boost1appointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetypeboost1)}</span> : null}
                        </FormGroup>
                    </Question></Col>}
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3m" questionText={"Please select a time slot for your BOOSTER appointment:"}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="select" name="boost1appointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "boost1appointmenttimeslot", true)} value={this.state.boost1appointmenttimeslot} disabled={!this.state.boost1appointmentdate}>
                                    <option value="">Please select</option>
                                    {this.state.boost1AppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                                </Input>
                                <Button color="link" onClick={() => this.getAvailableTimeSlotsForBoosterAppointment()}>refresh available slots</Button>
                            </Label>
                        </FormGroup>
                    </Question></Col>}
            </Row>

            {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                <div><strong><h3>Flu Appointment</h3></strong></div>}
            <Row>
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], healthcareWorkerAnswered: true, onBehalfOfAnswered: true, willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable || this.showIf({ firstAppointmentSiteAnswered: true })) &&
                    <Col><Question questionNumber="1.3k.i" questionText={"Please select a site for your FLU vaccination appointment."}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="select" name="flu1appointmentsite" onChange={(e) => this.textChangeHandler(e, "flu1appointmentsite", true)} value={this.state.flu1appointmentsite}>
                                    <option value="">Please select</option>
                                    {this.state.flu1AppointmentSiteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input>
                            </Label>
                        </FormGroup>
                    </Question></Col>}
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3k.ii" questionText={"Please select a location for your FLU vaccination appointment."}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="select" name="flu1appointmentlocation" onChange={(e) => this.textChangeHandler(e, "flu1appointmentlocation", true)} value={this.state.flu1appointmentlocation} disabled={!this.state.flu1appointmentsite}>
                                    <option value="">Please select</option>
                                    {this.state.flu1AppointmentLocationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                                </Input>
                            </Label>
                            {!this.state.anyFluAppsAvailable && <div className="questionnaire-error">There is no availability within 60 days time at a suitable location.</div>}
                        </FormGroup>
                    </Question></Col>}
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3l" questionText={"Please select an available FLU appointment date:"}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <DatePicker className="form-control date-selection-inputbox" selected={this.state.flu1appointmentdate} openToDate={this.state.defaultFluVaccinationDate} filterDate={this.availableDateForFluVaccination} onChange={this.dateOfFluVaccinationChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" disabled={!this.state.flu1appointmentlocation || (!this.state.secondappointmenttimeslot && this.state.thirdappointmentonly !== 'Yes') || this.state.firstappointmentonly === 'Yes'} />
                            </Label>
                            {(this.state.vaccinetypeflu1 && this.state.flu1appointmentdate) ? <span>&nbsp;Vaccine: {this.getVaccineFullName(this.state.vaccinetypeflu1)}</span> : null}
                        </FormGroup>
                    </Question></Col>}
                {this.showIf({ eligibleAnswered: true, eligible: ["CarehomeWorker", "SocialCareWorker", "HealthcareWorker", "80", "75", "70", "ClinVulnerable", "65", "16To64Underlying", "60", "55", "50", "40", "30", "18", "16CEV", "16", "12CEV", "12", "5CEV", "5", "Carer"], willingToPartakeAnswered: true, willingToPartake: "Yes", restrictedMobilityAnswered: true }) && (this.state.anyAppsAvailable) &&
                    <Col><Question questionNumber="1.3m" questionText={"Please select a time slot for your FLU appointment:"}>
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="select" name="flu1appointmenttimeslot" onChange={(e) => this.textChangeHandler(e, "flu1appointmenttimeslot", true)} value={this.state.flu1appointmenttimeslot} disabled={!this.state.flu1appointmentdate}>
                                    <option value="">Please select</option>
                                    {this.state.flu1AppointmentSlotList.map((di, idx) => <option key={idx} value={di.slotName}>{di.slotName}</option>)}
                                </Input>
                                <Button color="link" onClick={() => this.getAvailableTimeSlotsForFluAppointment()}>refresh available slots</Button>
                            </Label>
                        </FormGroup>
                    </Question></Col>}
            </Row>

            {this.readyToCollectDemographics() &&
                <Question questionNumber="2.1a" questionText={"NHS Number"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="nhsnumber" value={this.state.nhsnumber} onChange={(e) => this.textChangeHandler(e, "nhsnumber", true)} maxLength={10} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Question questionNumber="2.1b.i" questionText={"Forename"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="forename" value={this.state.forename} onChange={(e) => this.textChangeHandler(e, "forename", true)} maxLength={30} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Question questionNumber="2.1b.ii" questionText={"Last name"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="lastname" value={this.state.lastname} onChange={(e) => this.textChangeHandler(e, "lastname", true)} maxLength={30} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Question questionNumber="2.1c" questionText={"Date of birth"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={this.state.dateofbirth} onChange={(e) => this.textChangeHandler(e, "dateofbirth", true)} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Question questionNumber="2.1d" questionText={"Gender"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="gender" onChange={(e) => this.textChangeHandler(e, "gender")} value={this.state.gender}>
                                <option value="">Please select</option>
                                {this.state.genderList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Question questionNumber="2.1e" questionText={"Please select your Ethnic Group:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="ethnicity" onChange={(e) => this.textChangeHandler(e, "ethnicity", true)} value={this.state.ethnicity}>
                                <option value="">Please select</option>
                                {this.state.ethnicityList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographicsNonHealthcareWorker() &&
                <Question questionNumber="2.1f" questionText={"Postcode of residence"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="postcode" value={this.state.postcode} onChange={(e) => this.textChangeHandler(e, "postcode", true)} maxLength={10} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectMobileNumber() &&
                <Question questionNumber="2.1g" questionText={"Mobile number"}>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Cleave name="mobile" placeholder="07911 123456" options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeRawHandler(e, "mobile")} maxLength={15} />
                        </Label>
                    </FormGroup>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Cleave name="confirmedMobile" placeholder="07911 123456" options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeNoStoreRawHandler(e)} maxLength={15} />
                        </Label>
                    &nbsp;<i>(confirm mobile number by entering again)</i>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographicsHealthcareWorker() &&
                <Question questionNumber="2.2a.i" questionText={"Please select your Organisation:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="organisation" onChange={(e) => this.textChangeHandler(e, "organisation", true)} value={this.state.organisation}>
                                <option value="">Please select</option>
                                {this.state.organisationList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDirectorateInfo() &&
                <Question questionNumber="2.2a.ii" questionText={"Please select your Directorate:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="directorate" onChange={(e) => this.textChangeHandler(e, "directorate", true)} value={this.state.directorate}>
                                <option value="">Please select</option>
                                {this.state.directorateList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectJobInfo() &&
                <Question questionNumber="2.2b" questionText={"Please select your Job Role:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="select" name="jobrole" onChange={(e) => this.textChangeHandler(e, "jobrole", true)} value={this.state.jobrole} >
                                <option value="">Please select</option>
                                {this.state.jobRoleList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                            </Input>
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectJobInfo() &&
                <Question questionNumber="2.2c" questionText={"Please enter your Department or Ward:"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="departmentward" value={this.state.departmentward} onChange={(e) => this.textChangeHandler(e, "departmentward", true)} maxLength={50} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.isNotTakingPart() &&
                <Question questionNumber="2.2d" questionText={"Please let us know the reason why you are not willing to take part in this programme."} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="textarea" name="reason" value={this.state.reason} onChange={(e) => this.textChangeHandler(e, "reason", true)} maxLength={255} cols={80} rows={2} />
                        </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Question questionNumber="3.1" questionText={"If you have question about how and why your information is collected and shared, please refer to our website www.guysandstthomas.nhs.uk and search for \"your health records\""} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="checkbox" name="consentshare" value="Yes" checked={this.state.consentshare === 'Yes'} onChange={(e) => this.checkedChangeHandler(e, "consentshare")} />Patient has been informed
                    </Label>
                    </FormGroup>
                </Question>}
            {this.readyToCollectDemographics() &&
                <Button color="primary" onClick={() => this.submitAnswers()} disabled={this.state.submitting}>Submit</Button>}
            {this.state.errored &&
                <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid &&
                <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review your answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
        </div>;
    }
};

export default Questionnaire;