import React, { Component } from 'react';
import { AuthProvider } from './providers/authProvider'
import { Route } from 'react-router';
import { PrivateRoute } from './routes/PrivateRoute';
import { Layout } from './components/Layout';
import { FullLayout } from './components/FullLayout';
import { Callback } from './auth/Callback';
import { Logout } from './auth/Logout';
import { LogoutCallback } from './auth/LogoutCallback';
import { SilentRenew } from './auth/SilentRenew';
import { Covid19CCQuestionnaire } from './components/covid19cc/Covid19CCQuestionnaire';
import { Covid19QuestionnaireResults } from './components/covid19cc/results/Covid19QuestionnaireResults';
import { Covid19StaffAntibodyRegistration } from './components/covid19sa/Covid19StaffAntibodyRegistration';
import { Covid19StaffAntibodyRegistrationResults } from './components/covid19sa/results/Covid19StaffAntibodyRegistrationResults';
import { FluVaccinationQuestionnaire } from './components/fluvaccination/FluVaccinationQuestionnaire';
import { FluVaccinationQuestionnaireResults } from './components/fluvaccination/results/FluVaccinationQuestionnaireResults';
import { FluVaccinationNotice } from './components/fluvaccination/FluVaccinationNotice';
import { CovidAsymptomaticTestingQuestionnaire } from './components/covidasymptomatictesting/CovidAsymptomaticTestingQuestionnaire';
import { CovidAsymptomaticTestingQuestionnaireResults } from './components/covidasymptomatictesting/results/CovidAsymptomaticTestingQuestionnaireResults';
import { CovidTestingKitAllocationQuestionnaire } from './components/covidtestingkitallocation/CovidTestingKitAllocationQuestionnaire';
import { CovidTestingKitAllocationQuestionnaireResults } from './components/covidtestingkitallocation/results/CovidTestingKitAllocationQuestionnaireResults';
import { CovidVaccinationQuestionnaire } from './components/covidvaccination/CovidVaccinationQuestionnaire';
import { CovidVaccinationQuestionnaireResults } from './components/covidvaccination/results/CovidVaccinationQuestionnaireResults';
import { CovidVaccinationSchedule } from './components/covidvaccination/schedule/CovidVaccinationSchedule';
import { CovidVaccinationManagement } from './components/covidvaccination/schedule/CovidVaccinationManagement';
import { CovidSiteManagement } from './components/covidvaccination/schedule/CovidSiteManagement';
import { CovidVaccineManagement } from './components/covidvaccination/schedule/CovidVaccineManagement';
import { CovidLocationManagement } from './components/covidvaccination/schedule/CovidLocationManagement';
import { CsvUpload } from './components/covidvaccination/CsvUpload';
import { AppointmentMoveUpload } from './components/covidvaccination/schedule/AppointmentMoveUpload';
import Home from './components/Home';
import { ApiVersionChecker } from './components/common/ApiVersionChecker';

import './custom.css'
import { Signage } from './components/covidvaccination/Queueing/QueueSignage';
import { SignageTest } from './components/covidvaccination/Queueing/QueueSignageTest';
import { QueueGreeter } from './components/covidvaccination/Queueing/QueueGreeter';
import { QueueAssessor } from './components/covidvaccination/Queueing/QueueAssessor';
import { QueueLandingPage } from './components/covidvaccination/Queueing/QueueLandingPage';
import { AppointmentUpload } from './components/covidvaccination/AppointmentUpload';
import { QueuePrescriber } from './components/covidvaccination/Queueing/QueuePrescriber';

import { HubInfoContainer } from './components/covidvaccination/dashboards/HubInfoContainer';
import { LiveView } from './components/covidvaccination/Queueing/QueueLiveView';
import { HubInfoDetailContainer } from './components/covidvaccination/dashboards/HubInfoDetailContainer';
import { HubInfoConfigContainer } from './components/covidvaccination/dashboards/HubInfoConfigContainer';


export default class App extends Component {
    static displayName = App.name;
    render() {
        return (
            <AuthProvider>
                <ApiVersionChecker />
                <>
                    <PrivateRoute exact path='/covid19vaccinationQueue/signage/:site/:location' component={Signage} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin"]} />
                    <PrivateRoute exact path='/covid19vaccinationQueue/signageTest/:site/:location' component={SignageTest} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin"]} />
                    <PrivateRoute exact path='/covid19vaccinationQueue/greeter/:site/:location' component={QueueGreeter} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin"]}/>
                    <PrivateRoute exact path='/covid19vaccinationQueue/assessor/:site/:location/:desk' component={QueueAssessor} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin"]}/>
                    <PrivateRoute exact path='/covid19vaccinationQueue/prescriber/:site/:location' component={QueuePrescriber} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationPrescriber"]}/>
                    <PrivateRoute exact path='/covid19vaccinationQueue/live/:site/:location' component={LiveView} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin"]} />
                    <PrivateRoute exact path='/covid19vaccinationQueue' component={QueueLandingPage} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin", "kingsch\\COVIDVaccinationPrescriber"]} />
                </>
                <Layout>
                    <Route path="/callback" component={Callback} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/logout/callback" component={LogoutCallback} />
                    <Route exact={true} path="/silentrenew" component={SilentRenew} />
                    <Route exact path='/' component={Home} />
                    <Route exact path='/covid19cc/:hospitalnumber?' component={Covid19CCQuestionnaire} />
                    <Route exact path='/covid19sa' component={Covid19StaffAntibodyRegistration} />
                    <Route exact path='/fluvaccination' component={FluVaccinationQuestionnaire} />
                    <Route exact path='/fluvaccinationnotice' component={FluVaccinationNotice} />
                    <Route exact path='/covid19hometestreporting' component={CovidAsymptomaticTestingQuestionnaire} />
                    <Route exact path='/covid19hometestingregistration' component={CovidTestingKitAllocationQuestionnaire} />
                    <PrivateRoute exact path='/covid19vaccination' component={CovidVaccinationQuestionnaire} requiredRoles={["kingsch\\COVIDVaccinationAdmin"]} />
                </Layout>
                <FullLayout>
                    <PrivateRoute exact path='/results/covid19cc' component={Covid19QuestionnaireResults} requiredRoles={["gstt\\IT Paas"]} />
                    <PrivateRoute exact path='/results/covid19sa' component={Covid19StaffAntibodyRegistrationResults} requiredRoles={["Patient Survey-Staff COVID19 Antibody", "gstt\\IT Paas"]} />
                    <PrivateRoute exact path='/results/fluvaccination' component={FluVaccinationQuestionnaireResults} requiredRoles={["kingsch\\FluUptakeAdmin", "gstt\\IT Paas"]} />
                    <PrivateRoute exact path='/results/covid19hometestreporting/:submissionId?' component={CovidAsymptomaticTestingQuestionnaireResults} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDHomeTestingAdmin"]} />
                    <PrivateRoute exact path='/results/covid19hometestingregistration' component={CovidTestingKitAllocationQuestionnaireResults} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDHomeTestingAdmin"]} />
                    <PrivateRoute exact path='/results/covid19vaccination' component={CovidVaccinationQuestionnaireResults} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationAdmin", "kingsch\\COVIDVaccinationReportAdmin"]} />
                    <PrivateRoute exact path='/scheduling/download' component={CovidVaccinationSchedule} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationScheduling"]} />
                    <PrivateRoute exact path='/scheduling/management' component={CovidVaccinationManagement} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationScheduling"]} />
                    <PrivateRoute exact path='/scheduling/site' component={CovidSiteManagement} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationSiteManage"]} />
                    <PrivateRoute exact path='/scheduling/location/:site' component={CovidLocationManagement} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationSiteManage"]} />
                    <PrivateRoute exact path='/scheduling/vaccine' component={CovidVaccineManagement} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationSiteManage"]} />
                    <PrivateRoute exact path='/upload' component={CsvUpload} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationScheduling"]} />
                    <PrivateRoute exact path='/appointmentUpload' component={AppointmentUpload} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationScheduling"]} />
                    <PrivateRoute exact path='/bulkmove' component={AppointmentMoveUpload} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationScheduling"]} />
                    <PrivateRoute exact path='/covid19vaccinationhubs' component={HubInfoContainer} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationReportAdmin"]} />
                    <PrivateRoute exact path='/covid19vaccinationhubs/config' component={HubInfoConfigContainer} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationReportAdmin"]} />
                    <PrivateRoute exact path='/covid19vaccinationhubs/:site/:location' component={HubInfoDetailContainer} requiredRoles={["gstt\\IT Paas", "kingsch\\COVIDVaccinationReportAdmin"]} />
                </FullLayout>
            </AuthProvider>
    );
  }
}
