import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

const PSDAdministerActions = ({
    vaccinatorAdAccount, showingAsAdditionalPsd, multiplePsds, disallowAdministering,
    confirmCovidVaxCheck, confirmFluVaxCheck, confirmStaffCheck,
    approveActionDisabled, approveActionDelegate, approveAndContinueActionDelegate, approveForFluActionDelegate,
    declineActionDelegate, declineAndContinueActionDelegate, declineForFluActionDelegate,
    revertActionDelegate
}) => {

    if ((confirmCovidVaxCheck !== "Yes" && (confirmFluVaxCheck !== "Yes") && !showingAsAdditionalPsd) && (!showingAsAdditionalPsd && !multiplePsds)) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineActionDelegate}>Finish with patient unvaccinated{((confirmFluVaxCheck === "Yes" && confirmStaffCheck === "Yes") || confirmCovidVaxCheck === "Yes") || (!showingAsAdditionalPsd && multiplePsds) && <> and continue to next ePSD</>}</Button>
                {!disallowAdministering && <Button color="success" onClick={approveActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed{((confirmFluVaxCheck === "Yes" && confirmStaffCheck === "Yes") || confirmCovidVaxCheck === "Yes") || (!showingAsAdditionalPsd && multiplePsds) && <> and continue to next ePSD</>}</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmCovidVaxCheck !== "Yes" && confirmFluVaxCheck !== "Yes" && showingAsAdditionalPsd && multiplePsds) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineActionDelegate}>Finish with patient unvaccinated</Button>
                {!disallowAdministering && <Button color="success" onClick={approveActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed</Button>}
                <Button color="danger" onClick={revertActionDelegate}>Revert to previous ePSD</Button>
            </ButtonGroup>
        </>;
    }

    if ((confirmCovidVaxCheck !== "Yes" && confirmFluVaxCheck !== "Yes" && !showingAsAdditionalPsd) && (!showingAsAdditionalPsd && multiplePsds)) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineForFluActionDelegate}>Finish with patient unvaccinated Continue to next ePSD</Button>
                {!disallowAdministering && <Button color="success" onClick={approveForFluActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed and Continue to next ePSD</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmFluVaxCheck === "Yes" && (confirmStaffCheck === "Yes" || confirmStaffCheck === "No") && !showingAsAdditionalPsd) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineForFluActionDelegate}>Finish with patient unvaccinated{(confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && <> and continue to next ePSD</>}</Button>
                {!disallowAdministering && <Button color="success" onClick={approveForFluActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed{(confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && <> and continue to next ePSD</>}</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmFluVaxCheck === "Yes" && showingAsAdditionalPsd) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineForFluActionDelegate}>Finish with patient unvaccinated{(confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && <> and continue to next ePSD</>}</Button>
                {!disallowAdministering && <Button color="success" onClick={approveForFluActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed{(confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && <> and continue to next ePSD</>}</Button>}
                {showingAsAdditionalPsd && <Button color="danger" onClick={revertActionDelegate}>Revert to previous ePSD</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmCovidVaxCheck === "Yes" && !showingAsAdditionalPsd) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineAndContinueActionDelegate}>Finish with patient unvaccinated{(confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && <> and continue to next ePSD</>}</Button>
                {!disallowAdministering && <Button color="success" onClick={approveAndContinueActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed{(confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && <> and continue to next ePSD</>}</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmCovidVaxCheck === "Yes" && showingAsAdditionalPsd) {
        return <>
            <ButtonGroup>
                <Button color="danger" disabled={vaccinatorAdAccount} onClick={declineActionDelegate}>Finish with patient unvaccinated{((confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes")) && !showingAsAdditionalPsd && <> and continue to next ePSD</>}</Button>
                {!disallowAdministering && <Button color="success" onClick={approveActionDelegate} disabled={approveActionDisabled}>{!vaccinatorAdAccount && <>Sign and </>}Mark Vaccine Administration Completed{((confirmFluVaxCheck === "Yes" || confirmCovidVaxCheck === "Yes") && !showingAsAdditionalPsd) && <> and continue to next ePSD</>}</Button>}
                {showingAsAdditionalPsd && <Button color="danger" onClick={revertActionDelegate}>Revert to previous ePSD</Button>}
            </ButtonGroup>
        </>;
    }
};

export default PSDAdministerActions;