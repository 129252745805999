import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const Timer = ({ startTime }) => {
    const [, setCurrentTime] = useState(new Date());
    const [timerRef, setTimerRef] = useState();

    const getWaitingTime = (arrivalTime) => {
        let waitingSeconds = new moment().diff(arrivalTime, 'seconds');
        return `${(Math.floor(waitingSeconds / 60))}:${('0' + waitingSeconds % 60).slice(-2)}`;
    }

    useEffect(() => {
        if (!timerRef) {
            const interval = setInterval(() => {
                setCurrentTime(new Date());
            }, 100);
            setTimerRef(interval);
        }

        return () => {
            if(timerRef) {
                clearInterval(timerRef);
            }
        }
    },[timerRef])

    return <span>{getWaitingTime(startTime)}</span>
}