import jwt_decode from 'jwt-decode';

const isInRole = (requiredRoles) => {
    const oidcStorage = JSON.parse(
        sessionStorage.getItem(
            `oidc.user:${process.env.REACT_APP_IDP_AUTHORITY}:${process.env.REACT_APP_IDP_CLIENT_ID}`
        )
    );

    if (oidcStorage) {
        const userRoles = getUserRoles(oidcStorage);
        const matchingRoles = userRoles.filter(ur => requiredRoles.filter(rr => ur.toLowerCase() === rr.toLowerCase()).length > 0);
        return matchingRoles.length > 0;
    };

    return false;
};

const getUserRoles = (oidcStorage) => {
    switch (process.env.REACT_APP_AUTHENTICATION_TYPE) {
        case "KCH": {
            let decodedToken = jwt_decode(oidcStorage.access_token);
            return decodedToken.role ? decodedToken.role : [];
        }
        default: {
            return oidcStorage.profile['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        }
    }
}

export default isInRole;
