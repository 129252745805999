const tokenService = {
    getToken: () => {
        const oidcStorage = JSON.parse(
            sessionStorage.getItem(
                `oidc.user:${process.env.REACT_APP_IDP_AUTHORITY}:${process.env.REACT_APP_IDP_CLIENT_ID}`
            )
        );

        return oidcStorage.access_token;
    }
};

export default tokenService;
