import React, { Component } from 'react';
import Question from '../common/Question';
import QuestionnaireBlurb from './QuestionnaireBlurb';
import questionManager from './questionManager';
import { Input, FormGroup, Label, Button, Row } from 'reactstrap';
import RequiredSymb from '../common/RequiredSymb';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';

const currentDate = new Date(new Date().toDateString());

class Questionnaire extends Component {

    constructor(props) {
        super(props);

        const answers = [];

        this.state = {
            questionnaire: 'Flu Vaccination Uptake',
            answers: answers,
            directorate: '',
            directorateList: [],
            forename: '',
            lastname: '',
            jobtitle: '',
            decline_reason: '',
            assignmentnumber: '',
            confirmedassignmentnumber: '',
            dateofjab: '',
            allMandatoryAnswered: false,
            isInvalid: false,
            completed: false,
            errored: false,
            submitting: false,
            questions: [
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true},
                { key: "dateofbirth", mandatory: true },
                { key: "assignmentnumber", mandatory: true },
                { key: "jobtitle", mandatory: true },
                { key: "jabelsewhere", mandatory: true },
                { key: "jablocation", mandatory: false },
                { key: "dateofjab", mandatory: false },
                { key: "intendflujab", mandatory: false },
                { key: "decline_needles", mandatory: false },
                { key: "decline_wontgetflu", mandatory: false },
                { key: "decline_dontbelieveevidence", mandatory: false },
                { key: "decline_dontthinkeffective", mandatory: false },
                { key: "decline_concernsideeffects", mandatory: false },
                { key: "decline_difficultplace", mandatory: false },
                { key: "decline_difficulttime", mandatory: false },
                { key: "decline_other", mandatory: false },
                { key: "decline_reason", mandatory: false },
                { key: "confirm", mandatory: true }
            ],
            validationErrors: []
        };

        this.storeAnswer = this.storeAnswer.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.textChangeNoStoreHandler = this.textChangeNoStoreHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.submitAnswers = this.submitAnswers.bind(this);
        this.checkedChangeHandler = this.checkedChangeHandler.bind(this);
        this.dateOfJabChangeHandler = this.dateOfJabChangeHandler.bind(this);
        this.pad = this.pad.bind(this);
    }

    async submitAnswers() {

        this.setState({
            submitting: true
        });

        let newQuestions = [];

        let hadJabElsewhere = '';
        let intendFluJab = '';
        let declineOther = '';

        const hadJabElsewhereAnswer = this.state.answers.filter(a => a.key === "jabelsewhere");
        const intendFluJabAnswer = this.state.answers.filter(a => a.key === "intendflujab");
        const declineOtherAnswer = this.state.answers.filter(a => a.key === "decline_other");
        if (hadJabElsewhereAnswer.length > 0) {
            hadJabElsewhere = hadJabElsewhereAnswer[0].answer;
        }
        if (intendFluJabAnswer.length > 0) {
            intendFluJab = intendFluJabAnswer[0].answer;
        }
        if (declineOtherAnswer.length > 0) {
            declineOther = declineOtherAnswer[0].answer;
        }

        let dateOfBirth = '';
        let dateOfBirthIsoFormat = '';
        const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
        if (dateOfBirthAnswer.length > 0) {
            dateOfBirth = dateOfBirthAnswer[0].answer;
            if (dateOfBirth.length === 10) {
                dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
            }
        }
        let dateOfJab = '';
        let dateOfJabIsoFormat = '';
        const dateOfJabAnswer = this.state.answers.filter(a => a.key === 'dateofjab');
        if (dateOfJabAnswer.length > 0) {
            dateOfJab = dateOfJabAnswer[0].answer;
            if (dateOfJab.length === 10) {
                dateOfJabIsoFormat = dateOfJab.substring(6, 10) + "-" + dateOfJab.substring(3, 5) + "-" + dateOfJab.substring(0, 2);
            }
        }

        newQuestions = questionManager.getQuestions(hadJabElsewhere, intendFluJab, declineOther);

        this.setState({
            questions: newQuestions
        }, async () => {

            let mandatoryQuestionsUnanswered = 0;
            const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
            for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                const mandatoryQuestion = mandatoryQuestions[mandatory];
                const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                if (userAnswer.length === 0 || !userAnswer[0].answer) {
                    mandatoryQuestionsUnanswered++;
                }
            }

            if (mandatoryQuestionsUnanswered > 0) {
                this.setState({
                    isInvalid: true,
                    submitting: false
                })
            }
            else if (isNaN(this.state.assignmentnumber)) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Assignment number must be numeric!"]
                });
            }
            else if (this.state.assignmentnumber && this.state.assignmentnumber.length !== 8) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Assignment number must be 8 characters!"]
                });
            }
            else if (this.state.assignmentnumber !== this.state.confirmedassignmentnumber) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Assignment numbers do not match!"]
                });
            }
            else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of birth cannot be in the future!"]
                });
            }
            else if (dateOfJab !== '' && dateOfJab.length < 10) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of jab must be in the format DD/MM/YYYY!"]
                });
            }
            else if (new Date(dateOfJabIsoFormat) > currentDate) {
                this.setState({
                    isInvalid: true,
                    submitting: false,
                    validationErrors: ["Date of jab cannot be in the future!"]
                });
            }
            else {
                const data = {
                    questionnaireName: this.state.questionnaire,
                    questionAnswers: []
                };
                this.state.answers.map(a => data.questionAnswers.push({ questionKey: a.key, answers: [a.answer] }));

                const request = fetch('/QuestionnaireAnswers', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                const response = await request;

                if (response.ok) {
                    this.setState({
                        completed: true,
                        submitting: false
                    });
                } else {
                    let validationErrors = [];
                    if (response.status === 400) {
                        try {
                            validationErrors = await response.json();
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    this.setState({
                        errored: true,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
            }
        });       
    }

    storeAnswer(questionKey, answer) {
        const existingAnswer = this.state.answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...this.state.answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = this.state.answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        this.setState({
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    textChangeHandler(e, q) {
        this.storeAnswer(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeNoStoreHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    radioChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
        }
    }

    checkedChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
        } else {
            this.storeAnswer(q, '');
        }
    }

    dateOfJabChangeHandler(datePicked) {
        if (datePicked) {
            var formattedDatePicked = this.pad(datePicked.getDate(), 2) + '/' + this.pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear()
            this.storeAnswer('dateofjab', formattedDatePicked);
        } else {
            this.storeAnswer('dateofjab', '');
        }
        
        this.setState({
            dateofjab: datePicked
        });
    }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    showIf(hadJabElsewhereAnswered, hadJabElsewhere, intendFluJabAnswered, intendFluJab, declineOther) {
        let show = true;
        let currentHadJabElsewhereAnswer = '';
        let currentIntendFluJabAnswer = '';
        let currentDeclineOtherAnswer = '';
        const hadJabElsewhereAnswer = this.state.answers.filter(a => a.key === "jabelsewhere");
        const intendFluJabAnswer = this.state.answers.filter(a => a.key === "intendflujab");
        const declineOtherAnswer = this.state.answers.filter(a => a.key === "decline_other");
        if (hadJabElsewhereAnswer.length > 0) {
            currentHadJabElsewhereAnswer = hadJabElsewhereAnswer[0].answer;
        }
        if (intendFluJabAnswer.length > 0) {
            currentIntendFluJabAnswer = intendFluJabAnswer[0].answer;
        }
        if (declineOtherAnswer.length > 0) {
            currentDeclineOtherAnswer = declineOtherAnswer[0].answer;
        }

        if (hadJabElsewhereAnswered) {
            if (hadJabElsewhere !== '') {
                if (currentHadJabElsewhereAnswer !== hadJabElsewhere) {
                    show = false;
                }
            } else {
                if (currentHadJabElsewhereAnswer === '') {
                    show = false;
                }
            }
        }
        if (intendFluJabAnswered) {
            if (intendFluJab !== '') {
                if (currentIntendFluJabAnswer !== intendFluJab) {
                    show = false;
                }
            } else {
                if (currentIntendFluJabAnswer === '') {
                    show = false;
                }
            }
        }
        if (declineOther && declineOther !== '') {
            if (currentDeclineOtherAnswer !== declineOther) {
                show = false;
            } else {
                if (currentDeclineOtherAnswer === '') {
                    show = false;
                }
            }
        }
        
        return show;
    }

    render() {
        if (this.state.completed) {
            return <div className="questionnaire-body">
                <strong>Thank you! Your responses have been recorded.</strong>
            </div>
        }
        return <div className="questionnaire-body">
            <QuestionnaireBlurb />
            <Question questionNumber={1} questionText={"Forename"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="forename" autoFocus value={this.state.forename} onChange={(e) => this.textChangeHandler(e, "forename")} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={2} questionText={"Last name"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="lastname" value={this.state.lastname} onChange={(e) => this.textChangeHandler(e, "lastname")} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={3} questionText={"Date of birth"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" onChange={(e) => this.textChangeHandler(e, "dateofbirth")} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber={4} questionText={"Assignment number"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="assignmentnumber" value={this.state.assignmentnumber} onChange={(e) => this.textChangeHandler(e, "assignmentnumber")} maxLength={8} />
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="confirmedassignmentnumber" value={this.state.confirmedassignmentnumber} onChange={(e) => this.textChangeNoStoreHandler(e)} maxLength={8} />
                    </Label>
                    &nbsp;<i>(confirm assignment number by entering again)</i>
                </FormGroup>
            </Question>
            <Question questionNumber={5} questionText={"Job title"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="text" name="jobtitle" value={this.state.jobtitle} onChange={(e) => this.textChangeHandler(e, "jobtitle")} />
                    </Label>
                </FormGroup>
            </Question>
            <Question questionNumber="6.1" questionText={"Have you had your flu jab elsewhere (not at this Trust)?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="jabelsewhere" value="Yes" onChange={(e) => this.radioChangeHandler(e, "jabelsewhere")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="jabelsewhere" value="No" onChange={(e) => this.radioChangeHandler(e, "jabelsewhere")} />No
                    </Label>
                </FormGroup>
            </Question>
            {this.showIf(true, 'Yes') && <Question questionNumber="6.1a" questionText={"Please indicate where you had your flu jab"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="jablocation" value="Pharmacist / Chemist" onChange={(e) => this.radioChangeHandler(e, "jablocation")} />Pharmacist / Chemist
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="jablocation" value="GP Surgery" onChange={(e) => this.radioChangeHandler(e, "jablocation")} />GP Surgery
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="jablocation" value="Other Trust" onChange={(e) => this.radioChangeHandler(e, "jablocation")} />Other Trust
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="jablocation" value="Elsewhere" onChange={(e) => this.radioChangeHandler(e, "jablocation")} />Elsewhere
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, 'Yes') && <Question questionNumber="6.1b" questionText={"Date of flu jab"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <DatePicker className="form-control date-selection-inputbox" selected={this.state.dateofjab} onChange={this.dateOfJabChangeHandler} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, 'No') && <Question questionNumber="6.2" questionText={"Do you intend to have a flu jab?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="intendflujab" value="Yes" onChange={(e) => this.radioChangeHandler(e, "intendflujab")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="intendflujab" value="No" onChange={(e) => this.radioChangeHandler(e, "intendflujab")} />No
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, '', true, 'No') && <Question questionNumber="6.3" questionText={"Please let us know why you are choosing not to have your flu jab: (check all that apply)"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_needles" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_needles")} />I don't like needles
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_wontgetflu" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_wontgetflu")} />I don't think I will get flu
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_dontbelieveevidence" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_dontbelieveevidence")} />I don't believe the evidence for being vaccinated is beneficial
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_dontthinkeffective" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_dontthinkeffective")} />I don't think the flu jab is effective
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_concernsideeffects" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_concernsideeffects")} />I'm concerned about possible side effects
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_difficultplace" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_difficultplace")} />It was difficult/inconvenient to get to the place where flu jabs were being offered
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_difficulttime" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_difficulttime")} />The times when the flu jabs were being offered were not convenient
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="checkbox" name="decline_other" value="true" onChange={(e) => this.checkedChangeHandler(e, "decline_other")} />Other
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers" style={{ 'display': this.showIf(true, '', true, 'No', 'true') ? '' : 'none' }}>
                    <Label check>
                        <Input type="text" name="decline_reason" value={this.state.declinereason} onChange={(e) => this.textChangeHandler(e, "decline_reason")} maxLength={255} />
                    </Label>
                </FormGroup>
            </Question>}
            {this.showIf(true, 'No', true, 'Yes') && <div>
                <p>For information on how you can get a flu vaccine go to:</p>
                <p><a href="http://gti/services/occhealth/2020-flu-campaign/2020-flu-campaign.aspx" target="_blank" rel="noopener noreferrer">http://gti/services/occhealth/2020-flu-campaign/2020-flu-campaign.aspx</a> (access via Trust network / VPN only)</p>
            </div>}
            {(this.showIf(true, 'Yes') || this.showIf(true, 'No', true, 'No')) && <div>
                <Row>
                    <div className="col-12 question-text">
                        7. I agree for my answers to be used for the <a href="/fluvaccinationnotice" target="_blank" rel="noopener noreferrer">stated purposes</a>. <RequiredSymb />
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <FormGroup className="questionnaire-answers">
                            <Label check>
                                <Input type="checkbox" name="confirm" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "confirm")} />Yes
                            </Label>
                        </FormGroup>
                    </div>
                </Row>
            </div>}
            {(this.showIf(true, 'Yes') || this.showIf(true, 'No', true, 'No')) && <Button color="primary" onClick={() => this.submitAnswers()} disabled={this.state.submitting}>Submit</Button>}
            {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review your answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
        </div>;
    }
};

export default Questionnaire;