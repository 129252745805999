import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';
import Moment from 'react-moment';
import Spinner from '../../common/Spinner';
import { PatientQueueItem } from '../../common/PatientQueueItem';
import { PatientAppointmentItem } from '../../common/PatientAppointmentItem';
import tokenService from '../../../services/tokenService';



const HubInfo = () => {
    let currentDate = new Date();
    const patientsToAverageOver = 10;

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [patientQueueList, setPatientQueueList] = useState([]);
    const [patientScheduleList, setPatientScheduleList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [deskStatusList, setDeskStatusList] = useState([]);
    const [ragConfig, setRagConfig] = useState(null);
    const [screenUpdating, setScreenUpdating] = useState(true);

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getLocations();
        if (!errorRetrieving && !errorAuthenticating) {
            getDeskStatuses();
        }        
    }, [])

    useEffect(() => {
        getPatientQueues();
        getPatientSchedules();
    }, [])

    useEffect(() => {
        const timer = setTimeout(async () => {
            setScreenUpdating(false);
            currentDate = new Date();
            await getConfig();
            await getDeskStatuses();
            await getPatientSchedules();
            await getPatientQueues();
            setScreenUpdating(true);
        }, 120000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    })

    const getConfig = async () => {
        const token = tokenService.getToken();

        const response = await fetch(`/CovidHubConfig`, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });
        if (response.ok) {
            const data = await response.json();
            setRagConfig(data);
            setLoading(false);
        } else if (response.status === 401) {
            setErrorAuthenticating(true);
        } else {
            setErrorRetrieving(true);
        }
    }

    const getLocations = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleLocation', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLocationList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getDeskStatuses = async () => {
        setLoading(true);
        try {
            const response = await fetch('/DeskStatus', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setDeskStatusList(data.filter(d => d.role === 'Assessor'));
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getPatientQueues = async () => {
        setLoading(true);
        try {
            const response = await fetch('/PatientQueue', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPatientQueueList(data.map(d => new PatientQueueItem(d)));
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getPatientSchedules = async () => {
        const token = tokenService.getToken();

        const response = await fetch(`/CovidVaccinationAttendees?site=&location=`, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });
        if (response.ok) {
            const data = await response.json();
            const mappedPatients = data.sort((a, b) => a.currentTimeSlot < b.currentTimeSlot ? -1 : 1)
                .map((x) => { return new PatientAppointmentItem(x); });
            setPatientScheduleList(mappedPatients);
            setLoading(false);
        } else if (response.status === 401) {
            setErrorAuthenticating(true);
        } else {
            setErrorRetrieving(true);
        }
    }

    const getRagStatus = (type, curVal, site, location) => {
        if (ragConfig === null) {
            return 'unknown';
        }
        let comparisonValue = 0;
        const locationSpecificQueue = patientQueueList.filter(pql => pql.site === site && pql.location === location);
        const locationDetail = locationList.find(ldi => ldi.scheduleSite.siteName === site && ldi.locationName === location);
        switch (type) {
            case 'waiting': {
                comparisonValue = curVal;
                if (ragConfig.waitingRagAsPercent) {
                    comparisonValue = (curVal / locationDetail.waitingCapacity) * 100;
                }
                if (comparisonValue >= ragConfig.waitingRagBlackThreshold) {
                    return 'black';
                } else if (comparisonValue >= ragConfig.waitingRagRedThreshold) {
                    return 'red';
                } else if (comparisonValue >= ragConfig.waitingRagAmberThreshold) {
                    return 'amber';
                } else {
                    return 'green';
                }
                break;
            };
            case 'currentwait': {
                comparisonValue = curVal;
                if (ragConfig.currentWaitRagAsPercent) {
                    comparisonValue = (curVal / locationSpecificQueue.length) * 100;
                }
                if (comparisonValue >= ragConfig.currentWaitRagBlackThreshold) {
                    return 'black';
                } else if (comparisonValue >= ragConfig.currentWaitRagRedThreshold) {
                    return 'red';
                } else if (comparisonValue >= ragConfig.currentWaitRagAmberThreshold) {
                    return 'amber';
                } else {
                    return 'green';
                }
                break;
            };
            case 'desksopen': {
                comparisonValue = curVal;
                if (ragConfig.desksOpenRagAsPercent) {
                    comparisonValue = (curVal / locationDetail.availableDesks) * 100;
                }
                if (comparisonValue < ragConfig.desksOpenRagBlackThreshold) {
                    return 'black';
                } else if (comparisonValue < ragConfig.desksOpenRagRedThreshold) {
                    return 'red';
                } else if (comparisonValue < ragConfig.desksOpenRagAmberThreshold) {
                    return 'amber';
                } else {
                    return 'green';
                }
                break;
            };
            case 'averagetimepervaccination': {
                comparisonValue = curVal;
                if (comparisonValue >= ragConfig.averageVaccinationTimeRagBlackThreshold) {
                    return 'black';
                } else if (comparisonValue >= ragConfig.averageVaccinationTimeRagRedThreshold) {
                    return 'red';
                } else if (comparisonValue >= ragConfig.averageVaccinationTimeRagAmberThreshold) {
                    return 'amber';
                } else {
                    return 'green';
                }
                break;
            };
            case 'percentagecomplete': {
                comparisonValue = curVal;
                if (comparisonValue <= ragConfig.percentageCompleteRagBlackThreshold) {
                    return 'black';
                } else if (comparisonValue <= ragConfig.percentageCompleteRagRedThreshold) {
                    return 'red';
                } else if (comparisonValue <= ragConfig.percentageCompleteRagAmberThreshold) {
                    return 'amber';
                } else {
                    return 'green';
                }
                break;
            };
        }
    }

    const dateInUkFormat = (datePicked) => {
        let formattedDatePicked = pad(datePicked.getDate(), 2) + '/' + pad((datePicked.getMonth() + 1), 2) + '/' + datePicked.getFullYear();
        return formattedDatePicked;
    }

    const getScheduled = (site, location) => {
        if (site && location && patientScheduleList.length > 0) {
            const locationSpecificQueue = patientScheduleList.filter(pql =>
                (pql.firstAppointmentSite === site && pql.firstAppointmentLocation === location && pql.firstAppointmentDate === dateInUkFormat(currentDate))
                || (pql.secondAppointmentSite === site && pql.secondAppointmentLocation === location && pql.secondAppointmentDate === dateInUkFormat(currentDate))
                || (pql.thirdAppointmentSite === site && pql.thirdAppointmentLocation === location && pql.thirdAppointmentDate === dateInUkFormat(currentDate))
                || (pql.boost1AppointmentSite === site && pql.boost1AppointmentLocation === location && pql.boost1AppointmentDate === dateInUkFormat(currentDate))
                || (pql.flu1AppointmentSite === site && pql.flu1AppointmentLocation === location && pql.flu1AppointmentDate === dateInUkFormat(currentDate))
            );
            return locationSpecificQueue.length;
        }
        return 0;
    }

    const getWaiting = (site, location) => {
        if (site && location && patientQueueList.length > 0) {
            const locationSpecificQueue = patientQueueList.filter(pql => pql.site === site && pql.location === location && pql.waitingStatus === 'Waiting');
            return locationSpecificQueue.length;
        }
        return 0;
    }

    const getCurrentWait = (site, location) => {
        if (site && location && patientQueueList.length > 0) {
            const locationSpecificQueue = patientQueueList.filter(pql => pql.site === site && pql.location === location && pql.callingTime).sort(sortByCallingTime);
            let diff = 0;
            let i = 0
            for (i; i < patientsToAverageOver && i < locationSpecificQueue.length; i++) {
                diff += locationSpecificQueue[i].callingTime.diff(new moment(locationSpecificQueue[i].arrivalTime), 'minutes');
            }
            if (i > 0) {
                return Math.round(diff / i);
            }
        }
        return 0;
    }

    const getDesksOpen = (site, location) => {
        if (site && location && patientScheduleList.length > 0 && deskStatusList.length > 0) {
            const locationSpecificDesks = deskStatusList.filter(ds => ds.site === site && ds.location === location && ds.online);
            return locationSpecificDesks.length;
        }
        return 0;
    }

    const getTotalDesks = (site, location) => {
        if (site && location) {
            const locationSpecificDesk = locationList.find(ldi => ldi.scheduleSite.siteName === site && ldi.locationName === location);
            if (locationSpecificDesk) {
                return locationSpecificDesk.availableDesks;
            }
        }
        return 0;
    }

    const getAverageTimePerVaccination = (site, location) => {
        if (site && location && patientQueueList.length > 0) {
            const locationSpecificQueue = patientQueueList.filter(pql => pql.site === site && pql.location === location && pql.finishedTime && pql.deskArrivalTime).sort(sortByFinishedTime);

            let diff = 0;
            let i = 0
            for (i; i < patientsToAverageOver && i < locationSpecificQueue.length; i++) {
                diff += locationSpecificQueue[i].finishedTime.diff(new moment(locationSpecificQueue[i].deskArrivalTime), 'minutes');
            }
            if (i > 0) {
                return Math.round(diff / i);
            }
        }
        return 0;
    }

    const getPercentageComplete = (site, location) => {
        if (site && location && patientScheduleList.length > 0 && patientQueueList.length > 0) {
            const locationSpecificQueueTotal = patientScheduleList.filter(pql =>
                (pql.firstAppointmentSite === site && pql.firstAppointmentLocation === location && pql.firstAppointmentDate === dateInUkFormat(currentDate))
                || (pql.secondAppointmentSite === site && pql.secondAppointmentLocation === location && pql.secondAppointmentDate === dateInUkFormat(currentDate))
                || (pql.thirdAppointmentSite === site && pql.thirdAppointmentLocation === location && pql.thirdAppointmentDate === dateInUkFormat(currentDate))
                || (pql.boost1AppointmentSite === site && pql.boost1AppointmentLocation === location && pql.boost1AppointmentDate === dateInUkFormat(currentDate))
                || (pql.flu1AppointmentSite === site && pql.flu1AppointmentLocation === location && pql.flu1AppointmentDate === dateInUkFormat(currentDate))
            ).length;
            const locationSpecificQueueFinished = patientQueueList.filter(pql => pql.site === site && pql.location === location && (pql.hasBeenVaccinated || pql.finishedTime)).length;
            if (locationSpecificQueueTotal > 0) {
                const percentage = Math.round((locationSpecificQueueFinished / locationSpecificQueueTotal) * 100);
                return percentage;
            }            
        }
        return 0;
    }

    const getTargetPercentageComplete = (site, location) => {
        if (site && location && patientScheduleList.length > 0) {
            const locationSpecificQueue = patientScheduleList.filter(pql =>
                (pql.firstAppointmentSite === site && pql.firstAppointmentLocation === location && pql.firstAppointmentDate === dateInUkFormat(currentDate))
                || (pql.secondAppointmentSite === site && pql.secondAppointmentLocation === location && pql.secondAppointmentDate === dateInUkFormat(currentDate))
                || (pql.thirdAppointmentSite === site && pql.thirdAppointmentLocation === location && pql.thirdAppointmentDate === dateInUkFormat(currentDate))
                || (pql.boost1AppointmentSite === site && pql.boost1AppointmentLocation === location && pql.boost1AppointmentDate === dateInUkFormat(currentDate))
                || (pql.flu1AppointmentSite === site && pql.flu1AppointmentLocation === location && pql.flu1AppointmentDate === dateInUkFormat(currentDate))
            );
            const locationSpecificWaitingQueue = patientQueueList.filter(pql => pql.site === site && pql.location === location && pql.finishedTime);
            const dna = locationSpecificQueue.filter(x => { return locationSpecificWaitingQueue.findIndex(y => y.submissionId == x.submissionId) == -1 }).filter(x => x.possibleDna).length;
            const targetCount = getPatientsInTimeWindow(locationSpecificQueue) - dna;
            const totalCount = locationSpecificQueue.filter(lsq => lsq.appointmentTime !== 'Walk-in').length - dna;
            
            const percentageThrough = targetCount / totalCount * 100;
            if (!isNaN(percentageThrough)) {
                return Math.round(percentageThrough * 100) / 100;
            }
            
        }
        return -1;
    }

    const sortByCallingTime = (a, b) => {
        let aCallingTime = a.callingTime && a.callingTime.valueOf();
        let bCallingTime = b.callingTime && b.callingTime.valueOf();
        if (aCallingTime && !bCallingTime) {
            return 1;
        }
        if (!aCallingTime && bCallingTime) {
            return -1;
        }
        if (aCallingTime && bCallingTime) {
            return bCallingTime - aCallingTime;
        }
        return 0;
    }

    const sortByFinishedTime = (a, b) => {
        let aFinishedTime = a.finishedTime && a.finishedTime.valueOf();
        let bFinishedTime = b.finishedTime && b.finishedTime.valueOf();
        if (aFinishedTime && !bFinishedTime) {
            return 1;
        }
        if (!aFinishedTime && bFinishedTime) {
            return -1;
        }
        if (aFinishedTime && bFinishedTime) {
            return bFinishedTime - aFinishedTime;
        }
        return 0;
    }

    const getPatientsInTimeWindow = (queue) => {
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();
        const countOfPatientsDue = queue.filter(lsq => lsq.appointmentTime !== 'Walk-in' && lsq.appointmentTime.length === 11 && (parseInt(lsq.appointmentTime.substring(0, 2)) < currentHour || (parseInt(lsq.appointmentTime.substring(0, 2)) === currentHour && ((parseInt(lsq.appointmentTime.substring(3, 5)) + 15) <= currentMinute)))).length;
        const patientsInCurrentTimeSlot = queue.filter(lsq => lsq.appointmentTime !== 'Walk-in' && lsq.appointmentTime.length === 11 && (parseInt(lsq.appointmentTime.substring(0, 2)) === currentHour && (parseInt(lsq.appointmentTime.substring(0, 2)) === currentHour && (parseInt(lsq.appointmentTime.substring(3, 5)) < currentMinute) && (parseInt(lsq.appointmentTime.substring(3, 5)) >= (currentMinute - 15))))).length;
        const patientCountTargetWithinTimeslot = (patientsInCurrentTimeSlot / 15) * ((currentMinute % 15) + 1)
        return countOfPatientsDue + patientCountTargetWithinTimeslot;
    }

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    if (loading && screenUpdating) {
        return <div><Spinner /></div>;
    }

    if (locationList.length > 0) {
        return <div>
            {!loading && ragConfig === null && <div className="hub-config-error">Unable to load config. Showing values without RAG status.</div>}
            Last Updated: <strong><Moment format="DD MMM YYYY HH:mm:ss">{currentDate}</Moment></strong>
            <Row>
                {locationList.map((l, idx) => {
                    const waitingValue = getWaiting(l.scheduleSite.siteName, l.locationName);
                    const currentWaitValue = getCurrentWait(l.scheduleSite.siteName, l.locationName);
                    const desksOpenValue = getDesksOpen(l.scheduleSite.siteName, l.locationName);
                    const totalDesksValue = getTotalDesks(l.scheduleSite.siteName, l.locationName);
                    const averageTimePerVaccinationValue = getAverageTimePerVaccination(l.scheduleSite.siteName, l.locationName);
                    const percentageCompleteValue = getPercentageComplete(l.scheduleSite.siteName, l.locationName);
                    const targetPercentageComplete = getTargetPercentageComplete(l.scheduleSite.siteName, l.locationName);
                    const percentageOfTarget = percentageCompleteValue / targetPercentageComplete * 100;

                    let globalRag = '';
                    const waitingRag = getRagStatus('waiting', waitingValue, l.scheduleSite.siteName, l.locationName);
                    const currentWaitRag = getRagStatus('currentwait', currentWaitValue, l.scheduleSite.siteName, l.locationName);
                    const desksOpenRag = getRagStatus('desksopen', desksOpenValue, l.scheduleSite.siteName, l.locationName);
                    const averageTimePerVaccinationRag = getRagStatus('averagetimepervaccination', averageTimePerVaccinationValue, l.scheduleSite.siteName, l.locationName);
                    const percentageCompleteRag = getRagStatus('percentagecomplete', percentageOfTarget, l.scheduleSite.siteName, l.locationName);

                    const ragStatuses = [waitingRag, currentWaitRag, desksOpenRag, averageTimePerVaccinationRag, percentageCompleteRag];
                    if (ragStatuses.filter(s => s === 'unknown').length > 0) {
                        globalRag = 'unknown';
                    } else if (ragStatuses.filter(s => s === 'black').length > 0) {
                        globalRag = 'black';
                    } else if (ragStatuses.filter(s => s === 'red').length > 0) {
                        globalRag = 'red';
                    } else if (ragStatuses.filter(s => s === 'amber').length > 0) {
                        globalRag = 'amber';
                    } else {
                        globalRag = 'green';
                    }

                    return <Col md="4" key={idx} className={getScheduled(l.scheduleSite.siteName, l.locationName) === 0 ? "rag-card-hidden" : ""}>
                        <Card className={"dashboard-card" + " " + (getScheduled(l.scheduleSite.siteName, l.locationName) === 0 ? "rag-card-disabled" : ("rag-card-" + globalRag))}>
                            <CardHeader className="dashboard-title"><Link to={`/covid19vaccinationhubs/${l.scheduleSite.siteName}/${l.locationName}`}>{l.scheduleSite.siteName} - {l.locationName}</Link></CardHeader>
                            <CardBody>
                                <p>Waiting: <span className={"rag-status-" + waitingRag}>{waitingValue}</span></p>
                                <p>Current wait*: <span className={"rag-status-" + currentWaitRag}>{currentWaitValue} min</span></p>
                                <p>Desks open: <span className={"rag-status-" + desksOpenRag}>{desksOpenValue}</span> / { totalDesksValue }</p>
                                <p>Avg time per Vaccination*: <span className={"rag-status-" + averageTimePerVaccinationRag}>{averageTimePerVaccinationValue} min</span></p>
                                <p>Percentage complete: <span className={"rag-status-" + percentageCompleteRag}>{percentageCompleteValue}%</span> target: {targetPercentageComplete > -1 ? (targetPercentageComplete + "%") : "n/a" }</p>
                            </CardBody>
                        </Card>
                    </Col>;
                })}
            </Row>
            <div>* over most recent 10 patients in pod.</div>
            </div>
    };

    return <></>;
};

export default HubInfo;