import React, { useState, useEffect, useCallback} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import tokenService from '../../../services/tokenService';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}
startOfWeek.setDate(startOfWeek.getDate() - 7);
let tomorrow = new Date(new Date().toDateString());
tomorrow.setDate(tomorrow.getDate() + 1);

const ScheduleManagement = () => {

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [site, setSite] = useState('');
    const [location, setLocation] = useState('');
    const [dateFrom, setDateFrom] = useState(currentDate);
    const [dateTo, setDateTo] = useState(currentDate);
    const [originalSlotUtilisations, setOriginalSlotUtilisations] = useState([]);
    const [slotUtilisations, setSlotUtilisations] = useState([]);
    const [slotChanges, setSlotChanges] = useState([]);
    const [availableVaccinesList, setAvailableVaccinesList] = useState([]);
    const [availableFluVaccinesList, setAvailableFluVaccinesList] = useState([]);
    const [yesNoList, setYesNoList] = useState([]);

    useEffect(() => {
        setYesNoList([{ value: true, name: "Yes" }, { value: false, name: "No" }])
    }, []);

    useEffect(() => {
        getSites();
        getVaccines();
    }, [])

    useEffect(() => {
        if (site) {
            getLocations();
        }
    }, [site])

    const getSites = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleSite', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSiteList(data.map(d => d.siteName));
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getLocations = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleLocation?siteName=' + encodeURIComponent(site), {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLocationList(data.map(d => d.locationName));
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getVaccines = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleVaccine', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                const covidVaccines = data.filter(d => !d.isFlu);
                const fluVaccines = data.filter(d => d.isFlu);
                setAvailableVaccinesList(covidVaccines);
                setAvailableFluVaccinesList(fluVaccines);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const buildFilterUrl = useCallback(() => {
        let url = "siteName=";
        if (site) {
            url = url + encodeURIComponent(site);
        }
        url = url + "&locationName=";
        if (location) {
            url = url + encodeURIComponent(location);
        }
        url = url + "&startDate=";
        if (dateFrom) {
            const newValue = `${dateFrom.getFullYear()}-${pad(dateFrom.getMonth() + 1, 2)}-${pad(dateFrom.getDate(), 2)}`;
            url = url + encodeURIComponent(newValue);
        }
        url = url + "&endDate=";
        if (dateTo) {
            const newValue = `${dateTo.getFullYear()}-${pad(dateTo.getMonth() + 1, 2)}-${pad(dateTo.getDate(), 2)}`;
            url = url + encodeURIComponent(newValue);
        }
        return url;
    }, [site, location, dateFrom, dateTo]);

    useEffect(() => {
        if (site && location && dateFrom && dateTo) {
            if (dateFrom <= dateTo) {
                getScheduleUtilisation();
            }            
        }
        
    }, [site, location, dateFrom, dateTo])

    const getScheduleUtilisation = async () => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const response = await fetch('/ScheduleUtilisation?' + buildFilterUrl(), {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.json();
                data.sort((f, s) => {
                    if (f.scheduleDate > s.scheduleDate) {
                        return 1;
                    } else if (f.scheduleDate < s.scheduleDate) {
                        return -1;
                    } else {
                        return f.sortOrder - s.sortOrder;
                    }
                })
                for (let i = 0; i < data.length; i++) {
                    data[i].slotChanges.sort((f, s) => f.sortOrder - s.sortOrder);
                }
                setOriginalSlotUtilisations(data);
                setSlotUtilisations(JSON.parse(JSON.stringify(data)));
                setSlotChanges([]);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const siteChangeHandler = (e) => {
        setLocation('');
        setOriginalSlotUtilisations([]);
        setSlotChanges([]);
        setSlotUtilisations([]);
        setSite(e.target.value);
        setSaved(false);
    }

    const locationChangeHandler = (e) => {
        setLocation(e.target.value);
        setSaved(false);
    }

    const dateFromChangeHandler = (datePicked) => {
        setDateFrom(datePicked);
        setSaved(false);
    };

    const dateToChangeHandler = (datePicked) => {
        setDateTo(datePicked);
        setSaved(false);
    };

    const vaccineChangeHandler = (e, scheduleDate) => {
        const { curSlotUtilisations, curSlotChanges } = getUpdatedVaccination(e.target.value, scheduleDate, slotUtilisations, slotChanges);
        setSlotUtilisations(curSlotUtilisations);
        setSlotChanges(curSlotChanges);
        setSaved(false);
    }

    const fluVaccineChangeHandler = (e, scheduleDate) => {
        const { curSlotUtilisations, curSlotChanges } = getUpdatedFluVaccination(e.target.value, scheduleDate, slotUtilisations, slotChanges);
        setSlotUtilisations(curSlotUtilisations);
        setSlotChanges(curSlotChanges);
        setSaved(false);
    }

    const availabilityChangeHandler = (e, scheduleDate) => {
        const { curSlotUtilisations, curSlotChanges } = getUpdatedAvailability(e.target.value === "true" ? true : false, scheduleDate, slotUtilisations, slotChanges);
        setSlotUtilisations(curSlotUtilisations);
        setSlotChanges(curSlotChanges);
        setSaved(false);
    }

    const changeMaxAllocation = (e, id) => {
        const { curSlotUtilisations, curSlotChanges } = getUpdatedMaxAllocation(e.target.value, id, slotUtilisations, slotChanges);
        setSlotUtilisations(curSlotUtilisations);
        setSlotChanges(curSlotChanges);
        
        setSaved(false);
    }

    const getUpdatedMaxAllocation = (newValue, id, curSlotUtilisations, curSlotChanges) => {
        let updatedCurSlotUtilisations = [...curSlotUtilisations];
        let updatedCurSlotChanges = [...curSlotChanges];
        let existingSchedule = curSlotUtilisations.find(su => su.slotChanges.filter(sc => sc.slotId === id).length > 0);
        if (existingSchedule) {
            let existingSlot = existingSchedule.slotChanges.filter(su => su.slotId === id);
            if (existingSlot.length > 0) {

                existingSlot[0].maxAllocations = parseInt(newValue);
                let updatedSlotUtilisations = [...existingSchedule.slotChanges.filter(su => su.slotId !== id), existingSlot[0]];
                updatedSlotUtilisations.sort((f, s) => {
                    if (f.scheduleDate > s.scheduleDate) {
                        return 1;
                    } else if (f.scheduleDate < s.scheduleDate) {
                        return -1;
                    } else {
                        return f.sortOrder - s.sortOrder;
                    }
                });
                let updatedSchedules = [...curSlotUtilisations.filter(su => su.slotChanges.filter(sc => sc.slotId === id).length === 0), { ...existingSchedule, slotChanges: updatedSlotUtilisations }]
                updatedSchedules.sort((f, s) => {
                    if (f.scheduleDate > s.scheduleDate) {
                        return 1;
                    } else if (f.scheduleDate < s.scheduleDate) {
                        return -1;
                    } else {
                        return f.sortOrder - s.sortOrder;
                    }
                });
                updatedCurSlotUtilisations = updatedSchedules;

                let existingChange = curSlotChanges.find(sch => sch.slotChanges.filter(sc => sc.slotId === id).length > 0);
                if (existingChange) {
                    let updatedSlotChanges = [...existingChange.slotChanges.filter(sc => sc.slotId !== id), existingSlot[0]];
                    let otherChanges = curSlotChanges.filter(sch => sch.scheduleDate !== existingChange.scheduleDate);
                    let updatedChanges = [...otherChanges, { ...existingSchedule, slotChanges: updatedSlotChanges }];
                    setSlotChanges(updatedChanges);
                } else {
                    const existingChangeSchedule = curSlotChanges.find(sch => sch.scheduleDate === curSlotUtilisations.find(su => su.slotChanges.filter(sc => sc.slotId === id).length > 0).scheduleDate);
                    if (existingChangeSchedule) {
                        // Update slotChanges of existing schedule
                        let updatedChangeSchedule = { ...existingChangeSchedule, slotChanges: [...existingChangeSchedule.slotChanges, existingSlot[0]] };
                        let otherDateChanges = curSlotChanges.filter(sch => sch.scheduleDate !== curSlotUtilisations.find(su => su.slotChanges.filter(sc => sc.slotId === id).length > 0).scheduleDate);
                        updatedCurSlotChanges = [...otherDateChanges, updatedChangeSchedule];
                    } else {
                        // Add new schedule and slot change
                        updatedCurSlotChanges = [...curSlotChanges, { ...existingSchedule, slotChanges: [existingSlot[0]] }];
                    }
                }
            }
        }

        return { curSlotUtilisations: updatedCurSlotUtilisations, curSlotChanges: updatedCurSlotChanges };
    }

    const getUpdatedVaccination = (newValue, scheduleDate, curSlotUtilisations, curSlotChanges) => {
        let updatedCurSlotUtilisations = [...curSlotUtilisations];
        let updatedCurSlotChanges = [...curSlotChanges];
        let existingSchedule = curSlotUtilisations.find(su => su.scheduleDate === scheduleDate);
        if (existingSchedule) {
            existingSchedule.vaccine = newValue;
            let updatedSchedules = [...curSlotUtilisations.filter(su => su.scheduleDate !== scheduleDate), { ...existingSchedule }]
            updatedSchedules.sort((f, s) => {
                if (f.scheduleDate > s.scheduleDate) {
                    return 1;
                } else if (f.scheduleDate < s.scheduleDate) {
                    return -1;
                } else {
                    return f.sortOrder - s.sortOrder;
                }
            });
            updatedCurSlotUtilisations = updatedSchedules;

            let existingChange = curSlotChanges.find(sch => sch.scheduleDate === scheduleDate);
            if (existingChange) {
                let updatedChanges = [...curSlotChanges.filter(sc => sc.scheduleDate !== scheduleDate), { ...existingChange, vaccine: newValue }]
                updatedCurSlotChanges = updatedChanges;
            } else {
                let updatedChanges = [...curSlotChanges, { ...existingSchedule, slotChanges: [] }]
                updatedCurSlotChanges = updatedChanges;
            }
        }

        return { curSlotUtilisations: updatedCurSlotUtilisations, curSlotChanges: updatedCurSlotChanges };
    }

    const getUpdatedFluVaccination = (newValue, scheduleDate, curSlotUtilisations, curSlotChanges) => {
        let updatedCurSlotUtilisations = [...curSlotUtilisations];
        let updatedCurSlotChanges = [...curSlotChanges];
        let existingSchedule = curSlotUtilisations.find(su => su.scheduleDate === scheduleDate);
        if (existingSchedule) {
            existingSchedule.fluVaccine = newValue;
            let updatedSchedules = [...curSlotUtilisations.filter(su => su.scheduleDate !== scheduleDate), { ...existingSchedule }]
            updatedSchedules.sort((f, s) => {
                if (f.scheduleDate > s.scheduleDate) {
                    return 1;
                } else if (f.scheduleDate < s.scheduleDate) {
                    return -1;
                } else {
                    return f.sortOrder - s.sortOrder;
                }
            });
            updatedCurSlotUtilisations = updatedSchedules;

            let existingChange = curSlotChanges.find(sch => sch.scheduleDate === scheduleDate);
            if (existingChange) {
                let updatedChanges = [...curSlotChanges.filter(sc => sc.scheduleDate !== scheduleDate), { ...existingChange, fluVaccine: newValue }]
                updatedCurSlotChanges = updatedChanges;
            } else {
                let updatedChanges = [...curSlotChanges, { ...existingSchedule, slotChanges: [] }]
                updatedCurSlotChanges = updatedChanges;
            }
        }

        return { curSlotUtilisations: updatedCurSlotUtilisations, curSlotChanges: updatedCurSlotChanges };
    }

    const getUpdatedAvailability = (newValue, scheduleDate, curSlotUtilisations, curSlotChanges) => {
        let updatedCurSlotUtilisations = [...curSlotUtilisations];
        let updatedCurSlotChanges = [...curSlotChanges];
        let existingSchedule = curSlotUtilisations.find(su => su.scheduleDate === scheduleDate);
        if (existingSchedule) {
            existingSchedule.available = newValue;
            let updatedSchedules = [...curSlotUtilisations.filter(su => su.scheduleDate !== scheduleDate), { ...existingSchedule }]
            updatedSchedules.sort((f, s) => {
                if (f.scheduleDate > s.scheduleDate) {
                    return 1;
                } else if (f.scheduleDate < s.scheduleDate) {
                    return -1;
                } else {
                    return f.sortOrder - s.sortOrder;
                }
            });
            updatedCurSlotUtilisations = updatedSchedules;

            let existingChange = curSlotChanges.find(sch => sch.scheduleDate === scheduleDate);
            if (existingChange) {
                let updatedChanges = [...curSlotChanges.filter(sc => sc.scheduleDate !== scheduleDate), { ...existingChange, available: newValue }]
                updatedCurSlotChanges = updatedChanges;
            } else {
                let updatedChanges = [...curSlotChanges, { ...existingSchedule, slotChanges: [] }]
                updatedCurSlotChanges = updatedChanges;
            }
        }

        return { curSlotUtilisations: updatedCurSlotUtilisations, curSlotChanges: updatedCurSlotChanges };
    }

    const copyDownInDay = (e, id) => {
        let existingSchedule = slotUtilisations.find(su => su.slotChanges.filter(sc => sc.slotId === id).length > 0);
        if (existingSchedule) {
            let existingSlot = existingSchedule.slotChanges.filter(su => su.slotId === id);
            if (existingSlot.length > 0) {

                let currentAllocation = existingSlot[0].maxAllocations;
                let currentOrder = existingSlot[0].sortOrder;

                let updatedSlotUtilisations = [...existingSchedule.slotChanges.filter(su => su.slotId !== id), existingSlot[0]];
                updatedSlotUtilisations.sort((f, s) => {
                    if (f.scheduleDate > s.scheduleDate) {
                        return 1;
                    } else if (f.scheduleDate < s.scheduleDate) {
                        return -1;
                    } else {
                        return f.sortOrder - s.sortOrder;
                    }
                });
                for (var i = 0; i < updatedSlotUtilisations.length; i++) {
                    if (updatedSlotUtilisations[i].sortOrder > currentOrder && !updatedSlotUtilisations[i].ignoreAllocation) {
                        updatedSlotUtilisations[i].maxAllocations = currentAllocation;
                    }
                }
                let updatedSchedules = [...slotUtilisations.filter(su => su.slotChanges.filter(sc => sc.slotId === id).length === 0), { ...existingSchedule, slotChanges: updatedSlotUtilisations }]
                updatedSchedules.sort((f, s) => {
                    if (f.scheduleDate > s.scheduleDate) {
                        return 1;
                    } else if (f.scheduleDate < s.scheduleDate) {
                        return -1;
                    } else {
                        return f.sortOrder - s.sortOrder;
                    }
                });
                setSlotUtilisations(updatedSchedules);

                let updatedChanges = [...slotChanges];

                for (var j = 0; j < existingSchedule.slotChanges.length; j++) {
                    updatedChanges = [...updatedChanges];
                    if (existingSchedule.slotChanges[j].sortOrder > currentOrder && !existingSchedule.slotChanges[j].ignoreAllocation) {
                        let currentId = existingSchedule.slotChanges[j].slotId;
                        let existingChange = updatedChanges.find(sch => sch.slotChanges.filter(sc => sc.slotId === currentId).length > 0);
                        if (existingChange) {
                            let updatedSlotChanges = [...existingChange.slotChanges.filter(sc => sc.slotId !== currentId), existingSchedule.slotChanges[j]];
                            let otherChanges = updatedSlotChanges.filter(sch => sch.scheduleDate !== existingChange.scheduleDate);
                            let updatedChanges = [...otherChanges, { ...existingSchedule, slotChanges: updatedSlotChanges }];
                            updatedChanges = [...updatedChanges];
                        } else {
                            const existingChangeSchedule = updatedChanges.find(sch => sch.scheduleDate === slotUtilisations.find(su => su.slotChanges.filter(sc => sc.slotId === currentId).length > 0).scheduleDate);
                            if (existingChangeSchedule) {
                                // Update slotChanges of existing schedule
                                let updatedChangeSchedule = { ...existingChangeSchedule, slotChanges: [...existingChangeSchedule.slotChanges, existingSchedule.slotChanges[j]] };
                                let otherDateChanges = updatedChanges.filter(sch => sch.scheduleDate !== slotUtilisations.find(su => su.slotChanges.filter(sc => sc.slotId === currentId).length > 0).scheduleDate);
                                updatedChanges = [...otherDateChanges, updatedChangeSchedule];
                            } else {
                                // Add new schedule and slot change
                                updatedChanges = [...updatedChanges, { ...existingSchedule, slotChanges: [existingSchedule.slotChanges[j]] }];
                            }
                        }
                    }
                }

                setSlotChanges(updatedChanges);
                
            }
        }

        setSaved(false);
    }

    const copyDownAcrossDays = (e, scheduleDate) => {
        let amendedSlotUtilisations = [...slotUtilisations];
        let amendedSlotChanges = [...slotChanges];

        let targetUtilisation = slotUtilisations.find(su => su.scheduleDate === scheduleDate);

        if (targetUtilisation) {
            for (var i = 0; i < slotUtilisations.length; i++) {
                if (new Date(slotUtilisations[i].scheduleDate) > new Date(scheduleDate) && slotUtilisations[i].available) {
                    for (var j = 0; j < slotUtilisations[i].slotChanges.length; j++) {
                        let existingSlot = targetUtilisation.slotChanges.find(sc => sc.slotName === slotUtilisations[i].slotChanges[j].slotName);
                        if (existingSlot && existingSlot.maxAllocations !== slotUtilisations[i].slotChanges[j].maxAllocations && !existingSlot.ignoreAllocation) {
                            let { curSlotUtilisations, curSlotChanges } = getUpdatedMaxAllocation(existingSlot.maxAllocations, slotUtilisations[i].slotChanges[j].slotId, amendedSlotUtilisations, amendedSlotChanges)
                            amendedSlotUtilisations = curSlotUtilisations;
                            amendedSlotChanges = curSlotChanges;
                        }
                    }
                }
            }
        }

        setSlotUtilisations(amendedSlotUtilisations);
        setSlotChanges(amendedSlotChanges);

        setSaved(false);
    }

    const copyVaccinationDownAcrossDays = (e, scheduleDate) => {
        let amendedSlotUtilisations = [...slotUtilisations];
        let amendedSlotChanges = [...slotChanges];

        let targetUtilisation = slotUtilisations.find(su => su.scheduleDate === scheduleDate);

        if (targetUtilisation) {
            for (var i = 0; i < slotUtilisations.length; i++) {
                if (new Date(slotUtilisations[i].scheduleDate) > new Date(scheduleDate)) {
                    let curUtilisation = slotUtilisations[i];
                    if (curUtilisation.vaccine !== targetUtilisation.vaccine) {
                        let { curSlotUtilisations, curSlotChanges } = getUpdatedVaccination(targetUtilisation.vaccine, slotUtilisations[i].scheduleDate, amendedSlotUtilisations, amendedSlotChanges)
                        amendedSlotUtilisations = curSlotUtilisations;
                        amendedSlotChanges = curSlotChanges;
                    }                    
                }
            }
        }

        setSlotUtilisations(amendedSlotUtilisations);
        setSlotChanges(amendedSlotChanges);

        setSaved(false);
    }

    const copyFluVaccinationDownAcrossDays = (e, scheduleDate) => {
        let amendedSlotUtilisations = [...slotUtilisations];
        let amendedSlotChanges = [...slotChanges];

        let targetUtilisation = slotUtilisations.find(su => su.scheduleDate === scheduleDate);

        if (targetUtilisation) {
            for (var i = 0; i < slotUtilisations.length; i++) {
                if (new Date(slotUtilisations[i].scheduleDate) > new Date(scheduleDate)) {
                    let curUtilisation = slotUtilisations[i];
                    if (curUtilisation.fluVaccine !== targetUtilisation.fluVaccine) {
                        let { curSlotUtilisations, curSlotChanges } = getUpdatedFluVaccination(targetUtilisation.fluVaccine, slotUtilisations[i].scheduleDate, amendedSlotUtilisations, amendedSlotChanges)
                        amendedSlotUtilisations = curSlotUtilisations;
                        amendedSlotChanges = curSlotChanges;
                    }
                }
            }
        }

        setSlotUtilisations(amendedSlotUtilisations);
        setSlotChanges(amendedSlotChanges);

        setSaved(false);
    }

    const copyAvailabilityDownAcrossDays = (e, scheduleDate) => {
        let amendedSlotUtilisations = [...slotUtilisations];
        let amendedSlotChanges = [...slotChanges];

        let targetUtilisation = slotUtilisations.find(su => su.scheduleDate === scheduleDate);

        if (targetUtilisation) {
            for (var i = 0; i < slotUtilisations.length; i++) {
                if (new Date(slotUtilisations[i].scheduleDate) > new Date(scheduleDate)) {
                    let curUtilisation = slotUtilisations[i];
                    if (curUtilisation.available !== targetUtilisation.available) {
                        let { curSlotUtilisations, curSlotChanges } = getUpdatedAvailability(targetUtilisation.available, slotUtilisations[i].scheduleDate, amendedSlotUtilisations, amendedSlotChanges)
                        amendedSlotUtilisations = curSlotUtilisations;
                        amendedSlotChanges = curSlotChanges;
                    }
                }
            }
        }

        setSlotUtilisations(amendedSlotUtilisations);
        setSlotChanges(amendedSlotChanges);

        setSaved(false);
    }

    const updateSlots = async () => {
        setSaved(false);
        setUpdating(true);

        try {
            const token = tokenService.getToken();
            const data = { changes: [...slotChanges] };
            const request = fetch('/ScheduleUtilisation', {
                method: 'PUT',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify(data)
            });

            const response = await request;

            if (response.ok) {
                setSaved(true);
                setSlotChanges([]);
                getScheduleUtilisation();
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            setErrorRetrieving(true);
            console.log(err);
        }
        finally {
            setUpdating(false);
        }
    }

    const anyBlank = () => {
        const anyBlank = slotChanges.filter(sch => sch.slotChanges.filter(sc => !sc.maxAllocations && sc.maxAllocations !== 0).length > 0);
        if (anyBlank.length > 0 || slotChanges.length === 0) {
            return true;
        }
        return false;
    }

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    const dateMatch = (firstDate, secondDate) => {
        if (!firstDate && !secondDate) {
            return true;
        }

        if (!firstDate || !secondDate) {
            return false;
        }

        if (firstDate.getDate() !== secondDate.getDate()) {
            return false;
        }
        if (firstDate.getMonth() !== secondDate.getMonth()) {
            return false;
        }
        if (firstDate.getFullYear() !== secondDate.getFullYear()) {
            return false;
        }
        return true;
    }

    const renderScheduleUtilisations = () => {
        if (slotUtilisations.length === 0) {
            if (site && location) {
                return <div>No schedules found</div>;
            } else {
                return <div>Please select Site and Location to view schedule information.</div>;
            }
            
        } else {
            return <table className="table-schedule-utilisation">
                <thead>
                    <tr>
                        <th>Site</th>
                        <th>Location</th>
                        <th>Time Slot</th>
                        <th>Allocated</th>
                        <th>Maximum</th>
                        <th>Remaining</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {slotUtilisations.map((sch, idx) => {
                        const originalSchedule = originalSlotUtilisations.find(su => su.scheduleDate === sch.scheduleDate);
                        let output = [];
                        output.push(<tr className={sch.available ? "table-schedule-utilisation-break" : "table-schedule-utilisation-break-unavailable"} key={sch.scheduleDate}>
                            <td colSpan="6"><strong><Moment format="dddd Do MMMM YYYY">{sch.scheduleDate}</Moment></strong>&nbsp;<Button title="Copy entire schedule to below dates" onClick={(e) => copyDownAcrossDays(e, sch.scheduleDate)} disabled={dateMatch(new Date(sch.scheduleDate), new Date(slotUtilisations[slotUtilisations.length - 1].scheduleDate))}><FontAwesomeIcon title="Copy entire schedule to below dates" icon={faLevelDownAlt} /></Button></td>
                            <td>Vaccine at location on date: <Input className="schedule-utilisation-dropdown" type="select" name={"vaccine-" + sch.scheduleDate} onChange={(e) => vaccineChangeHandler(e, sch.scheduleDate)} value={sch.vaccine}>{availableVaccinesList.map((v, idx) => <option key={idx} value={v.vaccineCode}>{v.vaccineName}</option>)}</Input>&nbsp;<Button title="Copy vaccine type to below dates" onClick={(e) => copyVaccinationDownAcrossDays(e, sch.scheduleDate)} disabled={dateMatch(new Date(sch.scheduleDate), new Date(slotUtilisations[slotUtilisations.length - 1].scheduleDate))}><FontAwesomeIcon title="Copy vaccine type to below dates" icon={faLevelDownAlt} /></Button>&nbsp;
                                Flu at location on date: <Input className="schedule-utilisation-dropdown" type="select" name={"vaccineflu-" + sch.scheduleDate} onChange={(e) => fluVaccineChangeHandler(e, sch.scheduleDate)} value={sch.fluVaccine}>{availableFluVaccinesList.map((v, idx) => <option key={idx} value={v.vaccineCode}>{v.vaccineName}</option>)}</Input>&nbsp;<Button title="Copy flu type to below dates" onClick={(e) => copyFluVaccinationDownAcrossDays(e, sch.scheduleDate)} disabled={dateMatch(new Date(sch.scheduleDate), new Date(slotUtilisations[slotUtilisations.length - 1].scheduleDate))}><FontAwesomeIcon title="Copy flu type to below dates" icon={faLevelDownAlt} /></Button>&nbsp;
                                Date available for bookings: <Input className="schedule-utilisation-dropdown" type="select" onChange={(e) => availabilityChangeHandler(e, sch.scheduleDate)} name={"available-" + sch.scheduleDate} value={sch.available}>{yesNoList.map((v, idx) => <option key={idx} value={v.value}>{v.name}</option>)}</Input>&nbsp;<Button title="Copy availability to below dates" onClick={(e) => copyAvailabilityDownAcrossDays(e, sch.scheduleDate)} disabled={dateMatch(new Date(sch.scheduleDate), new Date(slotUtilisations[slotUtilisations.length - 1].scheduleDate))}><FontAwesomeIcon title="Copy availability to below dates" icon={faLevelDownAlt} /></Button></td></tr>);
                        output.push(sch.slotChanges.map((su, idx) => <tr key={su.slotId} className={su.overflowSlot ? (su.ignoreAllocation ? "table-schedule-utilisation-addon" : "table-schedule-utilisation-walkin") : ""}>
                                <td key={su.slotId + "site"}>{sch.siteName}</td>
                                <td key={su.slotId + "location"}>{sch.locationName}</td>
                                <td key={su.slotId + "slotname"}>{su.slotName}</td>
                                <td key={su.slotId + "allocated"}>{su.allocatedSlots}</td>
                            <td key={su.slotId + "max"}><Input className="table-schedule-utilisation-slotbox" type="number" onChange={(e) => changeMaxAllocation(e, su.slotId)} value={su.maxAllocations} disabled={su.ignoreAllocation} /><Button className="schedule-utilisation-slotcopy" title="Copy to below slots" size="sm" onClick={(e) => copyDownInDay(e, su.slotId)}><FontAwesomeIcon title="Copy to below slots" icon={faLevelDownAlt} /></Button></td>
                            <td key={su.slotId + "remaining"}>{su.remainingSlots}</td>
                            {idx === 0 ? <td key={su.slotId + "daychanges"} className="table-schedule-utilisation-daychanges" rowSpan={sch.slotChanges.length}>
                                <strong>Standard Slots:</strong><br />
                                Allocated: {sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.allocatedSlots, 0)}<br />
                                Maximum: {originalSchedule.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0)}{sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) !== originalSchedule.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) ? <span className="table-schedule-utilisations-slotchangedetected">&nbsp;&gt; {sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0)}</span> : null}<br />
                                Remaining: {sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.remainingSlots, 0)}{sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) !== originalSchedule.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) ? <span className="table-schedule-utilisations-slotchangedetected">&nbsp;&gt; {sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.remainingSlots, 0) + (sch.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) - originalSchedule.slotChanges.filter(su => su.overflowSlot === false).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0))}</span> : null}<br />
                                <strong>Walk-in Slots:</strong><br />
                                Allocated: {sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.allocatedSlots, 0)}<br />
                                Maximum: {originalSchedule.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0)}{sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) !== originalSchedule.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) ? <span className="table-schedule-utilisations-slotchangedetected">&nbsp;&gt; {sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0)}</span> : null}<br />
                                Remaining: {sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.remainingSlots, 0)}{sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) !== originalSchedule.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) ? <span className="table-schedule-utilisations-slotchangedetected">&nbsp;&gt; {sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.remainingSlots, 0) + (sch.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) - originalSchedule.slotChanges.filter(su => su.overflowSlot === true).reduce((agg, cur) => agg = agg + cur.maxAllocations, 0))}</span> : null}<br />&nbsp;<br />
                                <strong>Total Slots:</strong><br />
                                Allocated: {sch.slotChanges.reduce((agg, cur) => agg = agg + cur.allocatedSlots, 0)}<br />
                                Maximum: {originalSchedule.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0)}{sch.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) !== originalSchedule.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) ? <span className="table-schedule-utilisations-slotchangedetected">&nbsp;&gt; {sch.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0)}</span> : null}<br />
                                Remaining: {sch.slotChanges.reduce((agg, cur) => agg = agg + cur.remainingSlots, 0)}{sch.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) !== originalSchedule.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) ? <span className="table-schedule-utilisations-slotchangedetected">&nbsp;&gt; {sch.slotChanges.reduce((agg, cur) => agg = agg + cur.remainingSlots, 0) + (sch.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0) - originalSchedule.slotChanges.reduce((agg, cur) => agg = agg + cur.maxAllocations, 0))}</span> : null}</td>
                                : null}
                            </tr>));
                        return output;
                    })}
                </tbody>
            </table>;
        }
    }

    return <div className="questionnaire-body">
        <h3>Schedule Management for COVID-19 Vaccination</h3>
        <Link to="/scheduling/site">Manage Sites</Link>&nbsp;|&nbsp;<Link to="/scheduling/vaccine">Manage Vaccines</Link>
        <Row className="submission-filter-bar">
            <Col xs="3">Site:&nbsp;<Input type="select" name="site" onChange={(e) => siteChangeHandler(e)} value={site} width="100px">
                <option value="">Please select</option>
                {siteList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
            </Input></Col>
            <Col xs="3">Location:&nbsp;<Input type="select" name="location" onChange={(e) => locationChangeHandler(e)} value={location} width="100px" disabled={locationList.length === 0}>
                <option value="">Please select</option>
                {locationList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
            </Input></Col>
            <Col xs="1">From:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={dateFrom} onChange={dateFromChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
            <Col xs="1">&nbsp;</Col>
            <Col xs="1">To:&nbsp;<DatePicker className="form-control date-selection-inputbox" selected={dateTo} onChange={dateToChangeHandler} dateFormat="dd-MMM-yyyy" /></Col>
        </Row>
        {(loading || updating) && <Spinner />}
        {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
        {errorRetrieving && <div className="questionnaire-error">Unexpected error detected. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Vaccination Admin service.</div>}
        {!loading && renderScheduleUtilisations()}
        <br />&nbsp;<br />
        <Button color="danger" onClick={(e) => updateSlots()} disabled={anyBlank()}>{slotChanges.reduce((agg, cur) => agg + cur.slotChanges.length, 0) === 0 ? "Update 0 Slots" : (slotChanges.reduce((agg, cur) => agg + cur.slotChanges.length, 0) === 1 ? "Update 1 Slot" : "Update " + slotChanges.reduce((agg, cur) => agg + cur.slotChanges.length, 0) + " Slots")} / {slotChanges.length} {slotChanges.length === 0 || slotChanges.length > 1 ? "Sites" : "Site"}</Button>
        {saved && <span className="schedule-utilisation-success">Slots updated!</span>}
    </div>;
};

export default ScheduleManagement;