import React from 'react';
import RequiredSymb from '../common/RequiredSymb';

const QuestionnaireBlurb = () => (
    <div>
        <div>
            <p className="questionnaire-blurb-important">This booking form is for all staff who work within GSTT who wish to book an appointment for COVID-19 antibody testing.</p> 
            <p>All staff are entitled to an antibody test irrespective of whether they have previously have had COVID symptoms. We are currently not undertaking repeat tests for those who have previously had an antibody screen taken at GSTT. Staff who are participating in COVID vaccine trials do not need to book an additional test as this is already incorporated into the vaccines testing programme.</p>
            <p>This booking form must be fully completed in order to receive an appointment slot. In you are unable to provide all of the required information please contact:  <a href="mailto:covidantibodytesting@gstt.nhs.uk">covidantibodytesting@gstt.nhs.uk</a>.</p>
        </div>
        <div>
            <RequiredSymb /> Required;
        </div>        
    </div>);

export default QuestionnaireBlurb;