import React from 'react';

export const DTC210957G1 = {
    dtcNumber: '210957g1',
    content: {
        dtcNumber: '210957g1',
        headerInfo: 'Patient specific direction (PSD) for the instruction to administer the seasonal influenza vaccine to adults by trained vaccinators at King’s College Hospital NHS Foundation Trust vaccination hub and administration record',
        name: 'Flucelvax Tetra Influenza vaccine (surface antigen, inactivated, prepared in cell cultures)',
        dose: '0.5ml',
        frequency: 'Once only',
        route: 'Intramuscular injection into deltoid muscle',
        reviewDate: 'September 2022',
        for: 'For Adults Over 16 years',
        assessorText: 'Record of person undertaking screening:',
        vaccinatorText: 'Record of person administering vaccine:',
        screenTerm: 'screening',
        screenPersonTerm: 'Screener',
        vaccineFriendlyName: 'Flucelvax Tetra Influenza vaccine (surface antigen, inactivated, prepared in cell cultures)',
        useVaccineFriendlyNameForScreening: true,
        includeConsentQuestions: true,
        patientConsentText: 'Recipient Agreement To Vaccination',
        prescriberConsentText: 'Prescriber Consent Decision',
        showAge: true,
        supportsThirdDose: false,
        supportsBoosterDose: false,
        supportsFluDose: true,
        supportsStaffCheck: true,
        suppressPSDDoseHeader: true,
        suppressNIVSCheckHeader: false,
        suppressUrlFooter: true
    },
    getQuestions: (infectionorfeverish, covidpositive4weeks, bleedingdisorder, flujabpast7days, bcgpast3months, allergic, seriousreaction, breastfeeding, pregnant, covidtrial, covidsymptoms, patientconsentforvaccine, moreinformationneededbeforeconsent, satisfiedwithconsent, outcomeafterconsentdiscussion, thirtyandfirstdose, lowplateletcountafterfirst, historyofthrombosis, historyofbleedingdisorder,underageandfirstdose, parentConsentForVaccine, parentMoreInformationNeededBeforeConsent, medicalCondition) => {
        return {
            infectionorfeverish: {
                fluPsdKey: 'infectionorfeverishflu1',editableRoles: ['screening'], property: infectionorfeverish,
                question: () => <>Is the recipient currently unwell with fever?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer:  Patient should be rescheduled for when afebrile and feeling better. Consider swab for COVID-19.</>

            },
           /* covidpositive4weeks: {
                firstPsdKey: 'covidpositive4weeks', secondPsdKey: 'covidpositive4weeks2', thirdPsdKey: 'covidpositive4weeks3', editableRoles: ['screening'], property: covidpositive4weeks,
                question: () => <>Has the recipient had a positive COVID-19 test in the last 4 weeks? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Rearrange appointment for 28 days</>
            },*/
            bleedingdisorder: {
                fluPsdKey: 'bleedingdisorderflu1', editableRoles: ['screening'], property: bleedingdisorder,
                question: () => <>Is the recipient taking anticoagulant medication, or do they have a bleeding disorder? Please check the list of anticoagulant drugs for names of relevant medications. </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Note to prescriber: If on warfarin – ensure INR is in range (refer to Green Book*). If INR is {'>'}4 defer until INR is in therapeutic range. Advise applying pressure to injection site for 5 minutes post-injection.</>
            },/*
            flujabpast7days: {
                firstPsdKey: 'flujabpast7days', secondPsdKey: 'flujabpast7days2', thirdPsdKey: 'flujabpast7days3', editableRoles: ['screening'], property: flujabpast7days,
                question: () => <>Has the recipient had any other vaccine in the last 7 days?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have but remind that might have similar side effects of the other vaccine. If the other vaccine is shingles, a 7 day interval should be observed.  </>
            },*/
            bcgpast3months: {
                fluPsdKey: 'bcgpast3monthsflu1', editableRoles: ['screening'], property: bcgpast3months,
                question: () => <>Have you had a BCG vaccine in the last 3 months?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Do not use the arm used for BCG immunisation for at least three months because of the risk of regional lymphadenitis. Both influenza and COVID-19 vaccines can be given in the same arm with at least 2.5cm (one inch) distance between the injection sites.</>
            },
            allergic: {
                fluPsdKey: 'allergicflu1', editableRoles: ['screening'], property: allergic,
                question: () => <>Has the recipient ever had any serious allergic reaction to any ingredients in the vaccine (excipients list is available for recipient to view) </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if allergy to any of the ingredients, may require alternative vaccine. Consider contacting on-call allergist.</>
            },/*
            seriousreaction: {
                fluPsdKey: 'seriousreactionflu1', editableRoles: ['screening'], property: seriousreaction,
                question: () => <>Has the recipient ever had an unexplained anaphylaxis reaction? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer if allergy to any of the ingredients, may require alternative vaccine. Consider contacting on-call allergist.</>
            },*/
            breastfeeding: {
                fluPsdKey: 'breastfeedingflu1', editableRoles: ['screening'], property: breastfeeding,
                question: () => <>Is the recipient breastfeeding?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine</>
            },
            pregnant: {
                fluPsdKey: 'pregnantflu1', editableRoles: ['screening'], property: pregnant,
                question: () => <>Is, or could, the recipient be pregnant? </>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Can have the vaccine if they have read the additional information and consented to have the vaccine </>
            },
            covidtrial: {
                fluPsdKey: 'covidtrialflu1', editableRoles: ['screening'], property: covidtrial,
                question: () => <>Has the recipient taken part or is currently taking part in a trial of a potential COVID-19 vaccine?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Exclude unless un-blinded as receiving placebo or agreed with trial coordinator </>
            },
            covidsymptoms: {
                fluPsdKey: 'covidsymptomsflu1', editableRoles: ['screening'], property: covidsymptoms,
                question: () => <>Does the recipient currently have any symptoms of COVID-19 infection?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Defer: Patient should be swabbed for COVID-19 infection. If the swab is negative, reschedule when feeling better and isolation period complete.</>
            },
            medicalCondition: {
                fluPsdKey: 'medicalconditionflu1', editableRoles: ['screening'], property: medicalCondition,
                question: () => <>Do you have any medical condition, or take medication, that may affect your immune system?</>,
                noAnswer: () => <>Can have the vaccine</>,
                yesAnswer: () => <>Immunosuppressed individuals should be advised that they may not make a full immune response to the vaccine. It is important to still immunise this group. Provide patient with PIL if required.</>
            },
            patientconsentforvaccine: {
                fluPsdKey: 'patientconsentforvaccineflu1', editableRoles: ['screening'], property: patientconsentforvaccine,
                question: () => <>The patient / recipient has read the relevant information leaflets, has all of the information they require and agrees to receive the vaccination.</>,
                noAnswer: () => <>Please answer the following Question.</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            moreinformationneededbeforeconsent: {
                fluPsdKey: 'moreinformationneededbeforeconsentflu1', editableRoles: ['screening'], property: moreinformationneededbeforeconsent,
                question: () => <>The patient / recipient wishes to receive more information from a clinician before proceeding further.</>,
                noAnswer: () => <>Defer: Patient has not consented to vaccination.</>,
                yesAnswer: () => <>Prescriber will discuss with the patient.</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            satisfiedwithconsent: {
                fluPsdKey: 'satisfiedwithconsentflu1', editableRoles: ['prescriber'], property: satisfiedwithconsent,
                question: () => <>I am satisfied that the patient / recipient (or their nominated legal representative) has received all of the relevant information from the Screener and has provided consent to receive the vaccination.</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Yes', negative: 'No' }
            },
            outcomeafterconsentdiscussion: {
                fluPsdKey: 'outcomeafterconsentdiscussionflu1', editableRoles: ['prescriber'], property: outcomeafterconsentdiscussion,
                question: () => <>I have discussed the patient / recipient's concerns (or provided more information) and the recipient decision for the vaccination is to:</>,
                noAnswer: () => <>Defer, decline vaccine and screener to depart patient unvaccinated</>,
                yesAnswer: () => <>Can have the vaccine</>,
                answers: { positive: 'Consent', negative: 'Decline' }
            }
        }
    }
}