import React, { useState, useEffect, useCallback} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Input, Table, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import tokenService from '../../../services/tokenService';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}
startOfWeek.setDate(startOfWeek.getDate() - 7);
let tomorrow = new Date(new Date().toDateString());
tomorrow.setDate(tomorrow.getDate() + 1);

const VaccineManagement = () => {

    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [vaccineList, setVaccineList] = useState([]);
    const [editedVaccine, setEditedVaccine] = useState(null);
    const [addMode, setAddMode] = useState(false);
    const [yesNoList, setYesNoList] = useState([]);

    useEffect(() => {
        setYesNoList([{ value: true, name: "Yes" }, { value: false, name: "No" }])
    }, []);

    useEffect(() => {
        getVaccines();
    }, [])

    const getVaccines = async () => {
        setLoading(true);
        try {
            const response = await fetch('/ScheduleVaccine', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setVaccineList(data);
            } else if (response.status === 401) {
                setErrorAuthenticating(true);
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const saveVaccine = async () => {
        setSaved(false);
        setUpdating(true);

        try {
            const token = tokenService.getToken();
            const data = { ...editedVaccine };
            const request = fetch('/ScheduleVaccine', {
                method: 'PUT',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                body: JSON.stringify(data)
            });

            const response = await request;

            if (response.ok) {
                setSaved(true);
                setEditedVaccine(null);
                getVaccines();
            } else {
                setErrorRetrieving(true);
            }
        }
        catch (err) {
            setErrorRetrieving(true);
            console.log(err);
        }
        finally {
            setUpdating(false);
        }
    }

    const editVaccine = (vaccine) => {
        setSaved(false);
        if (!vaccine) {
            setAddMode(false);
            setEditedVaccine(null);
        } else {
            setEditedVaccine({ ...vaccine });
        }
    }

    const textChangeHandler = (e) => {
        if (editedVaccine) {
            setEditedVaccine({ ...editedVaccine, [e.target.name]: e.target.value });
        }
    }

    const checkedChangeHandler = (e) => {
        if (editedVaccine) {
            setEditedVaccine({ ...editedVaccine, [e.target.name]: e.target.checked });
        }
    }

    const saveIsEnabled = () => {
        if (!editedVaccine.vaccineCode) {
            return false;
        }
        if (!editedVaccine.vaccineName) {
            return false;
        }
        if (addMode) {
            if (vaccineList.find(ll => ll.vaccineCode.toLowerCase() === editedVaccine.vaccineCode.toLowerCase())) {
                return false;
            }
        }
        if (updating) {
            return false;
        }
        return true;
    }

    const renderVaccinesTable = () => {
        if (!vaccineList.length) {
            return <span>There are no vaccines configured.</span>
        }

        return <Table>
            <thead>
                <tr>
                    <th>Vaccine Code</th>
                    <th>Vaccine Name</th>
                    <th>Available for Booking Team (SFP)</th>
                    <th>Available for Patient Booking (PFP)</th>
                    <th>&nbsp;</th>
                 </tr>
            </thead>
            <tbody>
                {vaccineList.map((s, idx) => <tr key={ idx }>
                    <td>{s.vaccineCode}</td>
                    <td>{s.vaccineName}</td>
                    <td>{s.availableForBookingTeam ? "Yes" : "No"}</td>
                    <td>{s.availableForPatientBooking ? "Yes" : "No"}</td>
                    <td>
                        <Button color="warning" onClick={() => { setAddMode(false); editVaccine(s) }}>Edit</Button>
                    </td>
                </tr>)}
            </tbody>
        </Table>
    }

    const renderEditVaccine = () => {
        return <div>
            <Row>
                <Col xs="3">Vaccine Code:</Col>
                <Col xs="3">
                    {addMode && <div className="questionnaire-error"><strong>IMPORTANT: Once set this cannot be changed!</strong></div>}
                    <Input type="text" className="site-management-textbox" name="vaccineCode" autoFocus={addMode} value={editedVaccine.vaccineCode} onChange={(e) => textChangeHandler(e)} disabled={!addMode} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Vaccine Name:</Col>
                <Col xs="3">
                    <Input type="text" className="site-management-textbox" name="vaccineName" autoFocus={!addMode} value={editedVaccine.vaccineName} onChange={(e) => textChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Available for Booking Team (SFP):</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="availableForBookingTeam" checked={editedVaccine.availableForBookingTeam} onChange={(e) => checkedChangeHandler(e)} />
                </Col>
            </Row>
            <Row>
                <Col xs="3">Available for Patient Booking (PFP):</Col>
                <Col xs="3">
                    <input type="checkbox" className="site-management-checkbox" name="availableForPatientBooking" checked={editedVaccine.availableForPatientBooking} onChange={(e) => checkedChangeHandler(e)} />
                </Col>
            </Row>
            <br/>&nbsp;<br/>
            <Button color="danger" onClick={(e) => saveVaccine()} disabled={!saveIsEnabled()}>Save</Button>&nbsp;<Button color="warning" onClick={(e) => editVaccine(null)} disabled={updating}>Cancel</Button>
        </div>
    }

    return <div className="questionnaire-body">
        <h3>Vaccine Management for COVID-19 Vaccination</h3>
        {!editedVaccine && <div><Link to="/scheduling/management">Back to Schedules</Link></div>}
        {(loading || updating) && <Spinner />}
        {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
        {errorRetrieving && <div className="questionnaire-error">Unexpected error detected. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Vaccination Admin service.</div>}
        {!loading && !editedVaccine && renderVaccinesTable()}
        {!loading && editedVaccine && renderEditVaccine()}
        <br />
        {saved && <span className="schedule-utilisation-success">Vaccine saved!</span>}        
    </div>;
};

export default VaccineManagement;