export const queueStatus = {
    NotArrived : 'NotArrived',
        Waiting : 'Waiting',
        Called : 'Called',
        Departed : 'Departed',
        WithAssessor : 'WithAssessor',
        PassedToVaccination: 'PassedToVaccination',
        PatientVaccinated: 'PatientVaccinated',
        PatientLeft : 'PatientLeft'

}