import React from 'react';
import RequiredSymb from '../common/RequiredSymb';

const QuestionnaireBlurb = () => (
    <div>
        <div>
            <div><h4>COVID-19 testing for colleagues who do not have symptoms (i.e. are 'asymptomatic')</h4></div>
            <p className="guidance-problem-warning"><strong>Please read the following information carefully and then answer the questions at the bottom of this page if you wish to register in to the COVID-19 home testing programme.</strong></p>
            <div><h3>Background</h3></div>
            <p>There is evidence that you can carry COVID-19 without any symptoms (asymptomatic), and can also pass the virus to others without being aware of it.</p>
            <p>One powerful way of reducing COVID-19 transmission is to identify those who are asymptomatically infected so they can isolate themselves to stop transmission to others. The most effective way to do this is through quick, reliable and regular home testing, which is offered by the Trust to all staff, including those working in Essentia and Viapath.</p>
            <p>Please <a href="https://www.guysandstthomas.nhs.uk/coronavirus/testing-no-symptoms.aspx" target="_blank" rel="noopener noreferrer">see our information page for more details</a> about the test and how it is done. You should <a href="https://learninghub.nhs.uk/self-swab" target="_blank" rel="noopener noreferrer">watch the demonstration video</a> to ensure you are comfortable with performing the test.</p>
            <div><h3>Is the test mandatory?</h3></div>
            <p>No, the test is completely voluntary. If you decide not to participate in testing, you can continue working as usual. However, in rare circumstances (such as an outbreak), depending on the occupational health and infection prevention and control advice, staff who are not participating in regular testing may have to be redeployed to work in other areas.</p>
            <div><h3>Your information</h3></div>
            <p>If you take part, you will be asked to submit the test results along with basic demographic data (such as your name, date of birth, postcode and job title) to the <a href="/Covid19HomeTestReporting" target="_blank" rel="noopener noreferrer">Trust's online staff testing portal</a>.The information you submit will only be accessed by Occupational Health, Health Informatics and the Staff Testing Service. Some information will be also shared with the Public Health England and NHS Digital in order to assist with further planning to protect the NHS and patients. Your directorate management team will be notified whether you have registered to participate in asymptomatic testing and whether you are undertaking the test twice weekly. By submitting your data, you consent the data to be shared as described above.</p>
            <div><h3>Your commitment</h3></div>
            <div>
                This test is provided freely so we can protect our patients, our colleagues and our loved ones. By participating in regular testing you agree to:
                <ul>
                    <li>Keep the test kit safe as instructed to avoid damage</li>
                    <li>Only use it to test yourself</li>
                    <li>Test twice a week, 3-4 days apart</li>
                    <li>Report all results, even if they are negative or invalid</li>
                    <li>Not use the kits to test anyone else, e.g. colleagues, friends or family members. Using tests kits for anyone else may lead to disciplinary action.</li>
                </ul>
            </div>
            <div><h3>Collecting your test kit</h3></div>
            <p>Once you have registered you can collect your testing kit from your <a href="https://bit.ly/GSTTLFDkitleads" target="_blank" rel="noopener noreferrer">directorate's asymptomatic testing lead</a>.</p>
            <div><h3>Further information</h3></div>
            <p>For further information visit our <a href="http://gti.gstt.local/clinical/directorates/grida/directorate-services/infection/infection_prevention_control/wuhan-novel-coronavirus/covid-19-testing-for-colleagues-who-do-not-have-symptoms.aspx" target="_blank" rel="noopener noreferrer">Asymptomatic Testing page on GTi</a> (requires network access)</p>
        </div>
        <div>
            <RequiredSymb /> Required;
        </div>
    </div>);

export default QuestionnaireBlurb;