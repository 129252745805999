import React, { Component } from 'react';
import { FormGroup, Label, Button, Input, Row, Col } from 'reactstrap';
import questionManager from '../questionManager';
import Question from '../../common/Question';
import Moment from 'react-moment';
import Cleave from 'cleave.js/react';

const currentDate = new Date(new Date().toDateString());

class ViewQuestionnaireSubmission extends Component {

    constructor(props) {
        super(props);

        const { submission } = this.props;

        const answers = [];
        const originalSubmissionState = {};

        let availableTestDays = [];
        availableTestDays.push({ day: 'Monday', sort: 1, checked: false });
        availableTestDays.push({ day: 'Tuesday', sort: 2, checked: false });
        availableTestDays.push({ day: 'Wednesday', sort: 3, checked: false });
        availableTestDays.push({ day: 'Thursday', sort: 4, checked: false });
        availableTestDays.push({ day: 'Friday', sort: 5, checked: false });
        availableTestDays.push({ day: 'Saturday', sort: 6, checked: false });
        availableTestDays.push({ day: 'Sunday', sort: 7, checked: false });

        submission.questionAnswers.map(qa => {
            let answer = '';
            if (qa.answers.length > 1) {
                const combinedAnswer = qa.answers.reduce((acc, cur, idx) => idx === 0 ? cur : acc + ", " + cur, '');
                answer = combinedAnswer;
                originalSubmissionState[qa.questionKey] = combinedAnswer;
            } else {
                answer = qa.answers[0];
                originalSubmissionState[qa.questionKey] = answer;
            }
            if (qa.questionKey === 'testdays') {
                const selectedDays = answer.replace(/ /g, '').split(',');
                selectedDays.map(sd => {
                    let availableTestDay = availableTestDays.filter(atd => atd.day === sd);
                    let filteredTestDays = availableTestDays.filter(atd => atd.day !== sd);
                    if (availableTestDay.length > 0) {
                        availableTestDay[0].checked = true;
                        filteredTestDays.push(availableTestDay[0]);
                        availableTestDays = filteredTestDays;
                    }                    
                });
            }
            answers.push({ key: qa.questionKey, answer: answer});
        });

        this.state = {
            questionnaire: 'COVID Testing Kit Registration',
            answers: answers,
            originalState: { ...originalSubmissionState },
            willingtopartake: '',
            positivetest: '',
            forename: '',
            lastname: '',
            dateofbirth: '',
            gender: '',
            postcode: '',
            mobile: '',
            email: '',
            directorate: '',
            jobrole: '',
            departmentward: '',
            hadantibodytestbefore: '',
            address: '',
            gpaddress: '',
            testdays: '',
            seenvideoinstructions: '',
            pledgetousetestsforself: '',
            helpadministering: '',
            reason: '',
            kitcode: '',
            allMandatoryAnswered: false,
            jobRoleList: [],
            directorateList: [],
            genderList: [],
            testDaysAvailable: availableTestDays,
            inEdit: [],
            isInvalid: false,
            completed: false,
            errored: false,
            showDeleteConfirm: false,
            submitting: false,
            questions: [
                { key: "willingtopartake", mandatory: true },
                { key: "positivetest", mandatory: false },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: false },
                { key: "gender", mandatory: false },
                { key: "postcode", mandatory: false },
                { key: "mobile", mandatory: false },
                { key: "mobile", mandatory: false },
                { key: "directorate", mandatory: true },
                { key: "jobrole", mandatory: true },
                { key: "departmentward", mandatory: true },
                { key: "hadantibodytestbefore", mandatory: false },
                { key: "address", mandatory: false },
                { key: "gpaddress", mandatory: false },
                { key: "testdays", mandatory: false },
                { key: "seenvideoinstructions", mandatory: false },
                { key: "pledgetousetestsforself", mandatory: false },
                { key: "helpadministering", mandatory: false },
                { key: "reason", mandatory: false },
                { key: "kitcode", mandatory: false },
                { key: "kitissueddate", mandatory: false },
                { key: "confirm", mandatory: true }
            ],
            validationErrors: []
        };

        this.validateEmail = this.validateEmail.bind(this);
        this.renderAnswer = this.renderAnswer.bind(this);
        this.storeAnswer = this.storeAnswer.bind(this);
        this.textChangeHandler = this.textChangeHandler.bind(this);
        this.textChangeRawHandler = this.textChangeRawHandler.bind(this);
        this.textChangeNoStoreRawHandler = this.textChangeNoStoreRawHandler.bind(this);
        this.radioChangeHandler = this.radioChangeHandler.bind(this);
        this.checkedChangeHandler = this.checkedChangeHandler.bind(this);
        this.getDayOfWeekValue = this.getDayOfWeekValue.bind(this);
        this.dayOfWeekChanged = this.dayOfWeekChanged.bind(this);
        this.showIf = this.showIf.bind(this);
        this.editField = this.editField.bind(this);
        this.isEditing = this.isEditing.bind(this);
        this.isMandatory = this.isMandatory.bind(this);
        this.showDeleteSubmission = this.showDeleteSubmission.bind(this);
        this.deleteSubmission = this.deleteSubmission.bind(this);
        this.updateAdminFields = this.updateAdminFields.bind(this);
        this.pad = this.pad.bind(this);
    }

    async componentDidMount() {
        const availableDirectorates = questionManager.getDirectorates();
        const availableJobRoles = questionManager.getJobRoles();
        const availableGenders = questionManager.getGenders();
        let mappedAnswers = {};
        this.state.answers.map(a => {
            mappedAnswers[a.key] = a.answer;
            if (a.key.endsWith("issueddate")) {
                if (a.answer) {
                    let answerAsDate = new Date(a.answer);
                    mappedAnswers[a.key] = answerAsDate;
                }
            }
        });
        this.setState({
            directorateList: availableDirectorates,
            jobRoleList: availableJobRoles,
            genderList: availableGenders,
            ...mappedAnswers
        });
    }

    componentDidUpdate() {
        const { errorSaving } = this.props;
        if (errorSaving !== this.state.errored) {
            this.setState({
                errored: errorSaving,
                submitting: false
            });
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderAnswer(questions, questionKey) {
        const filteredQuestion = questions.filter(q => q.questionKey.toLowerCase() === questionKey.toLowerCase());
        const question = filteredQuestion.length > 0 ? filteredQuestion[0] : null;
        if (question === null || question.answers.length === 0) {
            return "Not Answered";
        } else {
            if (question.answers.length === 1) {
                return <strong>{question.answers[0]}</strong>;
            } else {
                return <ol>{question.answers.map((a, idx) => <li key={idx}><strong>{a}</strong></li>)}</ol>;
            }
        }
    }

    storeAnswer(questionKey, answer) {
        const existingAnswer = this.state.answers.filter(a => a.key === questionKey);
        let updatedAnswers = [...this.state.answers];
        if (existingAnswer.length > 0) {
            updatedAnswers = this.state.answers.filter(a => a.key !== questionKey);
        }
        updatedAnswers.push({ key: questionKey, answer: answer });
        this.setState({
            answers: updatedAnswers,
            isInvalid: false
        });
    }

    textChangeHandler(e, q) {
        this.storeAnswer(q, e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    textChangeRawHandler(e, q) {
        this.storeAnswer(q, e.target.rawValue);
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    textChangeNoStoreRawHandler(e) {
        this.setState({
            [e.target.name]: e.target.rawValue
        });
    }

    radioChangeHandler(e, q) {
        if (e.target.checked) {
            this.storeAnswer(q, e.target.value);
        }
    }

    checkedChangeHandler(e, q) {
        const newValue = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            this.storeAnswer(q, newValue);
        } else {
            this.storeAnswer(q, '');
        }
        this.setState({
            [q]: isChecked ? e.target.value : ''
        }, () => {
            if (q.endsWith("issued")) {
                const updatedState = {};
                if (newValue === "Yes" && isChecked) {
                    let issuedDate = '';
                    let kitNumber = q.substr(3, 1);
                    if (isNaN(kitNumber)) {
                        kitNumber = 0;
                    }
                    if (parseInt(kitNumber) > 2) {
                        issuedDate = new Date(new Date(this.state["kit" + (parseInt(kitNumber) - 1) + "issueddate"]).toDateString());
                    } else {
                        if (this.state.kitissueddate) {
                            issuedDate = new Date(this.state.kitissueddate.toDateString());
                        } else {
                            return;
                        }
                    }
                    let today = new Date(new Date().toDateString());
                    today.setDate(today.getDate() - 76); // approx 2.5 months
                    if (issuedDate > today) {
                        updatedState[q + "warning"] = "Yes";
                    }
                } else {
                    updatedState[q + "warning"] = "";
                }
                this.setState({
                    ...updatedState
                })
            }
        })
    }

    getDayOfWeekValue(day) {
        const availabilityValue = this.state.testDaysAvailable.filter(a => a.day === day);
        return availabilityValue[0].checked;
    }

    dayOfWeekChanged(e, day) {
        if (e.target.checked) {
            const checkedDays = this.state.testDaysAvailable.filter(a => a.checked === true);
            if (checkedDays.length > 1) {
                return;
            }
        }
        const filteredTestDays = this.state.testDaysAvailable.filter(a => a.day !== day);
        this.setState({
            testDaysAvailable: [...filteredTestDays, { day: day, checked: e.target.checked }]
        }, () => {
            const testDays = this.state.testDaysAvailable.filter(ad => ad.checked === true).sort((f, s) => {
                return f.sort - s.sort;
            }).reduce((acc, cur, idx) => idx === 0 ? cur.day : acc + ", " + cur.day, '');
            this.storeAnswer("testdays", testDays);
        });

    }

    showIf(willingToPartakeAnswered, willingToPartake, positiveCovidTestAnswered, positiveCovidTest, hadAntibodyTestAnswered, hadAntibodyTest) {
        let show = true;
        let currentWillingToPartakeAnswer = '';
        let currentPositiveCovidTestAnswer = '';
        let currentHadAntibodyTestAnswer = '';
        const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
        const positiveCovidTestAnswer = this.state.answers.filter(a => a.key === "positivetest");
        const hadAntibodyTestAnswer = this.state.answers.filter(a => a.key === "hadantibodytestbefore");

        if (willingToPartakeAnswer.length > 0) {
            currentWillingToPartakeAnswer = willingToPartakeAnswer[0].answer;
        }
        if (positiveCovidTestAnswer.length > 0) {
            currentPositiveCovidTestAnswer = positiveCovidTestAnswer[0].answer;
        }
        if (hadAntibodyTestAnswer.length > 0) {
            currentHadAntibodyTestAnswer = hadAntibodyTestAnswer[0].answer;
        }

        if (willingToPartakeAnswered) {
            if (willingToPartake !== '') {
                if (currentWillingToPartakeAnswer !== willingToPartake) {
                    show = false;
                }
            } else {
                if (currentWillingToPartakeAnswer === '') {
                    show = false;
                }
            }
        }
        if (positiveCovidTestAnswered) {
            if (positiveCovidTest !== '') {
                if (currentPositiveCovidTestAnswer !== positiveCovidTest) {
                    show = false;
                }
            } else {
                if (currentPositiveCovidTestAnswer === '') {
                    show = false;
                }
            }
        }
        if (hadAntibodyTestAnswered) {
            if (hadAntibodyTest !== '') {
                if (currentHadAntibodyTestAnswer !== hadAntibodyTest) {
                    show = false;
                }
            } else {
                if (currentHadAntibodyTestAnswer === '') {
                    show = false;
                }
            }
        }

        return show;
    }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    editField(key) {
        let itemsInEdit = this.state.inEdit.filter(k => k !== key);
        itemsInEdit.push(key);
        this.setState({
            inEdit: itemsInEdit
        });
    }

    isEditing(key) {
        var isInEdit = this.state.inEdit.filter(k => k === key);
        if (isInEdit.length > 0) {
            return true;
        }
        return false;
    }

    isMandatory(key) {
        // determine which questions are mandatory based upon answers
        let willingToPartake = '';
        let positiveTest = '';
        let hadAntibodyTest = '';
        var newQuestions = [];
        const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
        const positiveTestAnswer = this.state.answers.filter(a => a.key === "positivetest");
        const hadAntibodyTestAnswer = this.state.answers.filter(a => a.key === "hadantibodytestbefore");
        if (willingToPartakeAnswer.length > 0) {
            willingToPartake = willingToPartakeAnswer[0].answer;
        }
        if (positiveTestAnswer.length > 0) {
            positiveTest = positiveTestAnswer[0].answer;
        }
        if (hadAntibodyTestAnswer.length > 0) {
            hadAntibodyTest = hadAntibodyTestAnswer[0].answer;
        }
        newQuestions = questionManager.getQuestions(willingToPartake, positiveTest, hadAntibodyTest);

        const locatedQuestion = newQuestions.filter(nq => nq.key === key);
        if (locatedQuestion.length > 0) {
            return locatedQuestion[0].mandatory;
        }
        return false;
    }

    showDeleteSubmission() {
        this.setState({
            showDeleteConfirm: true
        });
    }

    deleteSubmission() {
        const { saveChanges } = this.props;

        const changes = {
            deleted: true
        }

        saveChanges(changes);
    }

    updateAdminFields() {
        const { submission, saveChanges } = this.props;

        let changes = {};

        this.state.answers.map(a => {
            const answerInSubmission = submission.questionAnswers.filter(qa => qa.questionKey === a.key);
            if (answerInSubmission.length > 0) {
                let answer = '';
                if (answerInSubmission[0].answers.length > 1) {
                    const combinedAnswer = answerInSubmission[0].answers.reduce((acc, cur, idx) => idx === 0 ? cur : acc + ", " + cur, '');
                    answer = combinedAnswer;
                } else {
                    answer = answerInSubmission[0].answers[0];
                }
                if (answer !== a.answer) {
                    changes[a.key] = a.answer;
                }
            } else {
                if (a.key === 'kitcode' && a.answer) {
                    changes['kitissueddate'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                }
                if (a.key.endsWith('issued') && a.answer) {
                    changes[a.key + 'date'] = new Date().toISOString().substr(0, 19).replace(/T/, ' ');
                }
                changes[a.key] = a.answer;
            }
        });

        if (Object.keys(changes).length > 0) {
            this.setState({
                submitting: true,
                errored: false
            });

            let dateOfBirth = '';
            let dateOfBirthIsoFormat = '';
            let email = '';
            const dateOfBirthAnswer = this.state.answers.filter(a => a.key === 'dateofbirth');
            const emailAnswer = this.state.answers.filter(a => a.key === 'email');
            if (dateOfBirthAnswer.length > 0) {
                dateOfBirth = dateOfBirthAnswer[0].answer;
                if (dateOfBirth.length === 10) {
                    dateOfBirthIsoFormat = dateOfBirth.substring(6, 10) + "-" + dateOfBirth.substring(3, 5) + "-" + dateOfBirth.substring(0, 2);
                }
            }
            if (emailAnswer.length > 0) {
                email = emailAnswer[0].answer;
            }
            const selectedTestDays = this.state.testDaysAvailable.filter(d => d.checked === true);

            // determine which questions are mandatory based upon answers
            let willingToPartake = '';
            let positiveTest = '';
            let hadAntibodyTest = '';
            var newQuestions = [];
            const willingToPartakeAnswer = this.state.answers.filter(a => a.key === "willingtopartake");
            const positiveTestAnswer = this.state.answers.filter(a => a.key === "positivetest");
            const hadAntibodyTestAnswer = this.state.answers.filter(a => a.key === "hadantibodytestbefore");
            if (willingToPartakeAnswer.length > 0) {
                willingToPartake = willingToPartakeAnswer[0].answer;
            }
            if (positiveTestAnswer.length > 0) {
                positiveTest = positiveTestAnswer[0].answer;
            }
            if (hadAntibodyTestAnswer.length > 0) {
                hadAntibodyTest = hadAntibodyTestAnswer[0].answer;
            }
            newQuestions = questionManager.getQuestions(willingToPartake, positiveTest, hadAntibodyTest);

            this.setState({
                questions: newQuestions
            }, async () => {
                let mandatoryQuestionsUnanswered = 0;
                const mandatoryQuestions = this.state.questions.filter(q => q.mandatory);
                for (var mandatory = 0; mandatory < mandatoryQuestions.length; mandatory++) {
                    const mandatoryQuestion = mandatoryQuestions[mandatory];
                    const userAnswer = this.state.answers.filter(a => a.key.toLowerCase() === mandatoryQuestion.key.toLowerCase());
                    if (userAnswer.length === 0 || !userAnswer[0].answer) {
                        mandatoryQuestionsUnanswered++;
                    }
                }

                if (mandatoryQuestionsUnanswered > 0) {
                    const validationErrors = [];
                    if (!this.state.email) {
                        validationErrors.push('E-mail address must be provided for Test & Trace reporting. Please enter this in field 1.2g.');
                    }
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: validationErrors
                    });
                }
                else if (dateOfBirth !== '' && dateOfBirth.length < 10) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["Date of birth must be in the format DD/MM/YYYY!"]
                    });
                }
                else if (new Date(dateOfBirthIsoFormat) > currentDate) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["Date of birth cannot be in the future!"]
                    });
                }
                else if (email && !this.validateEmail(email)) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["Email address is not in a valid format!"]
                    });
                }
                else if (willingToPartake === 'Yes' && positiveTest === 'No' && (selectedTestDays.length > 0 && selectedTestDays.length !== 2)) {
                    this.setState({
                        isInvalid: true,
                        submitting: false,
                        validationErrors: ["You must specify which two days you have been asked to take the tests on."]
                    });
                }
                else {
                    saveChanges(changes);
                }
            });
        }
    }

    render() {

        const { submission, returnCallback } = this.props;

        return <div className="questionnaire-body">
            <div><Button color="secondary" onClick={() => this.props.returnCallback()} disabled={this.state.submitting}>Return to list</Button></div>
            <div className="questionnaire-submission-summary">This submission was recorded on <strong><Moment format="DD MMM YYYY HH:mm:ss">{submission.submissionDate}</Moment></strong>. Submission identifier: <strong>{submission.submissionIdentifier}</strong>.</div>
            {!this.isEditing("willingtopartake") && <Question questionNumber="1.1a" questionText={"Are you willing to take part in the staff COVID home testing programme?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label>{this.renderAnswer(submission.questionAnswers, "willingtopartake")}</Label>
                    <Button color="link" onClick={() => this.editField("willingtopartake")}>change</Button>
                </FormGroup>
            </Question>}
            {this.isEditing("willingtopartake") && <Question questionNumber="1.1a" questionText={"Are you willing to take part in the staff COVID home testing programme?"} isMandatory>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="willingtopartake" value="Yes" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="willingtopartake" value="No" onChange={(e) => this.radioChangeHandler(e, "willingtopartake")} />No
                    </Label>
                </FormGroup>
            </Question>}
            {!this.isEditing("positivetest") && <Question questionNumber="1.1b" questionText={"Have you had a positive COVID-19 PCR test (nose and throat swab taken in the staff testing centre or a national testing centre) in the last 90 days?"} isMandatory={this.isMandatory('positivetest')}>
                <FormGroup className="questionnaire-answers">
                    <Label>{this.renderAnswer(submission.questionAnswers, "positivetest")}</Label>
                    {this.showIf(true, "Yes") ? <Button color="link" onClick={() => this.editField("positivetest")}>change</Button> : null}
                </FormGroup>
            </Question>}
            {this.isEditing("positivetest") && <Question questionNumber="1.1b" questionText={"Have you had a positive COVID-19 PCR test (nose and throat swab taken in the staff testing centre or a national testing centre) in the last 90 days?"} isMandatory={this.isMandatory('positivetest')}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="positivetest" value="Yes" onChange={(e) => this.radioChangeHandler(e, "positivetest")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="positivetest" value="No" onChange={(e) => this.radioChangeHandler(e, "positivetest")} />No
                    </Label>
                </FormGroup>
            </Question>}
            <Question questionNumber="1.2a" questionText={"Forename"} isMandatory={this.isMandatory('forename')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("forename") ? <Label>{this.renderAnswer(submission.questionAnswers, "forename")}</Label> : null}
                    {!this.isEditing("forename") ? <Button color="link" onClick={() => this.editField("forename")}>change</Button> : null }
                    {this.isEditing("forename") ? <Label check><Input type="text" name="forename" value={this.state.forename} autoFocus onChange={(e) => this.textChangeHandler(e, "forename", true)} maxLength={30} /></Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.2b" questionText={"Last name"} isMandatory={this.isMandatory('lastname')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("lastname") ? <Label>{this.renderAnswer(submission.questionAnswers, "lastname")}</Label> : null}
                    {!this.isEditing("lastname") ? <Button color="link" onClick={() => this.editField("lastname")}>change</Button> : null}
                    {this.isEditing("lastname") ? <Label check><Input type="text" name="lastname" value={this.state.lastname} autoFocus onChange={(e) => this.textChangeHandler(e, "lastname", true)} maxLength={30} /></Label> : null}
                </FormGroup>
            </Question>
        <Question questionNumber="1.2c" questionText={"Date of birth"} isMandatory={this.isMandatory('dateofbirth')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("dateofbirth") ? <Label>{this.renderAnswer(submission.questionAnswers, "dateofbirth")}</Label> : null}
                    {!this.isEditing("dateofbirth") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("dateofbirth")}>change</Button> : null}
                    {this.isEditing("dateofbirth") ? <Label check><Cleave placeholder="DD/MM/YYYY" options={{ date: true, delimeter: "/", datePattern: ["d", "m", "Y"] }} className="form-control" value={this.state.dateofbirth} onChange={(e) => this.textChangeHandler(e, "dateofbirth", true)} /></Label> : null}
                </FormGroup>
            </Question>
        <Question questionNumber="1.2d" questionText={"Gender"} isMandatory={this.isMandatory('gender')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("gender") ? <Label>{this.renderAnswer(submission.questionAnswers, "gender")}</Label> : null}
                    {!this.isEditing("gender") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("gender")}>change</Button> : null}
                    {this.isEditing("gender") ? <Label check><Input type="select" name="gender" onChange={(e) => this.textChangeHandler(e, "gender")} value={this.state.gender}>
                        <option value="">Please select</option>
                        {this.state.genderList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                    </Input></Label> : null}
                </FormGroup>
            </Question>
        <Question questionNumber="1.2e" questionText={"Postcode of residence"} isMandatory={this.isMandatory('postcode')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("postcode") ? <Label>{this.renderAnswer(submission.questionAnswers, "postcode")}</Label> : null}
                    {!this.isEditing("postcode") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("postcode")}>change</Button> : null}
                    {this.isEditing("postcode") ? <Label check><Input type="text" name="postcode" autoFocus value={this.state.postcode} onChange={(e) => this.textChangeHandler(e, "postcode", true)} maxLength={10} /></Label> : null}
                </FormGroup>
            </Question>
        <Question questionNumber="1.2f" questionText={"Mobile number"} isMandatory={this.isMandatory('mobile')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("mobile") ? <Label>{this.renderAnswer(submission.questionAnswers, "mobile")}</Label> : null}
                    {!this.isEditing("mobile") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("mobile")}>change</Button> : null}
                    {this.isEditing("mobile") ? <Label check><Cleave name="mobile" placeholder="07911 123456" value={this.state.mobile} options={{ phone: true, phoneRegionCode: 'GB' }} className="form-control" onChange={(e) => this.textChangeRawHandler(e, "mobile")} maxLength={15} /></Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.2g" questionText={"Email address"} isMandatory={this.isMandatory('email')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("email") ? <Label>{this.renderAnswer(submission.questionAnswers, "email")}</Label> : null}
                    {!this.isEditing("email") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("email")}>change</Button> : null}
                    {this.isEditing("email") ? <Label check><Input type="email" name="email" value={this.state.email} onChange={(e) => this.textChangeHandler(e, "email", true)} maxLength={320} /></Label> : null}
                </FormGroup>
            </Question>
        <Question questionNumber="1.3a" questionText={"Directorate:"} isMandatory={this.isMandatory('directorate')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("directorate") ? <Label>{this.renderAnswer(submission.questionAnswers, "directorate")}</Label> : null}
                    {!this.isEditing("directorate") && (this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) ? <Button color="link" onClick={() => this.editField("directorate")}>change</Button> : null}
                    {this.isEditing("directorate") ? <Label check><Input type="select" name="directorate" onChange={(e) => this.textChangeHandler(e, "directorate", true)} value={this.state.directorate}>
                        <option value="">Please select</option>
                        {this.state.directorateList.map((di, idx) => <option key={idx} value={di}>{di}</option>)}
                    </Input></Label> : null}
                </FormGroup>
            </Question>
        <Question questionNumber="1.3b" questionText={"Job Role:"} isMandatory={this.isMandatory('jobrole')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("jobrole") ? <Label>{this.renderAnswer(submission.questionAnswers, "jobrole")}</Label> : null}
                    {!this.isEditing("jobrole") && (this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) ? <Button color="link" onClick={() => this.editField("jobrole")}>change</Button> : null}
                    {this.isEditing("jobrole") ? <Label check><Input type="select" name="jobrole" onChange={(e) => this.textChangeHandler(e, "jobrole", true)} value={this.state.jobrole} >
                        <option value="">Please select</option>
                        {this.state.jobRoleList.map((jr, idx) => <option key={idx} value={jr}>{jr}</option>)}
                    </Input></Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.3c" questionText={"Department or Ward:"} isMandatory={this.isMandatory('departmentward')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("departmentward") ? <Label>{this.renderAnswer(submission.questionAnswers, "departmentward")}</Label> : null}
                    {!this.isEditing("departmentward") && (this.showIf(true, "Yes", true, "") || this.showIf(true, "No")) ? <Button color="link" onClick={() => this.editField("departmentward")}>change</Button> : null}
                    {this.isEditing("departmentward") ? <Label check><Input type="text" name="departmentward" value={this.state.departmentward} onChange={(e) => this.textChangeHandler(e, "departmentward", true)} maxLength={50} /></Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.3d" questionText={"Please let us know the reason why you are not willing to take part in this programme."} isMandatory={this.isMandatory('reason')} unanswered={!this.state.reason}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("reason") ? <Label>{this.renderAnswer(submission.questionAnswers, "reason")}</Label> : null}
                    {!this.isEditing("reason") && this.showIf(true, "No") ? <Button color="link" onClick={() => this.editField("reason")}>change</Button> : null}
                    {this.isEditing("reason") ? <Label check><Input type="textarea" name="reason" value={this.state.reason} onChange={(e) => this.textChangeHandler(e, "reason", true)} maxLength={255} cols={80} rows={2} /></Label> : null}
                </FormGroup>
            </Question>
            {!this.isEditing("hadantibodytestbefore") && <Question questionNumber="1.4a" questionText={"Have you had an antibody test at Guy's & St. Thomas' before?"} isMandatory={this.isMandatory('hadantibodytestbefore')}>
                <FormGroup className="questionnaire-answers">
                    <Label>{this.renderAnswer(submission.questionAnswers, "hadantibodytestbefore")}</Label>
                    {this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("hadantibodytestbefore")}>change</Button> : null}
                </FormGroup>
            </Question>}
            {this.isEditing("hadantibodytestbefore") && <Question questionNumber="1.4a" questionText={"Have you had an antibody test at Guy's & St. Thomas' before?"}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="hadantibodytestbefore" value="Yes" onChange={(e) => this.radioChangeHandler(e, "hadantibodytestbefore")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="hadantibodytestbefore" value="No" onChange={(e) => this.radioChangeHandler(e, "hadantibodytestbefore")} />No
                    </Label>
                </FormGroup>
            </Question>}
            <Question questionNumber="1.4b" questionText={"Address"} isMandatory={this.isMandatory('address')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("address") ? <Label>{this.renderAnswer(submission.questionAnswers, "address")}</Label> : null}
                    {!this.isEditing("address") && this.showIf(true, "Yes", true, "No", true, "No") ? <Button color="link" onClick={() => this.editField("address")}>change</Button> : null}
                    {this.isEditing("address") ? <Label check><Input type="textarea" name="address" value={this.state.address} onChange={(e) => this.textChangeHandler(e, "address", true)} maxLength={100} cols={40} rows={4} /></Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.4c" questionText={"GP Name and Address"} isMandatory={this.isMandatory('gpaddress')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("gpaddress") ? <Label>{this.renderAnswer(submission.questionAnswers, "gpaddress")}</Label> : null}
                    {!this.isEditing("gpaddress") && this.showIf(true, "Yes", true, "No", true, "No") ? <Button color="link" onClick={() => this.editField("gpaddress")}>change</Button> : null}
                    {this.isEditing("gpaddress") ? <Label check><Input type="textarea" name="gpaddress" value={this.state.gpaddress} onChange={(e) => this.textChangeHandler(e, "gpaddress", true)} maxLength={120} cols={40} rows={5} /></Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.5" questionText={"If your line manager has asked you to take your test on specific days each week, then please indicate below"} isMandatory={this.isMandatory('testdays')}>
                {!this.isEditing("testdays") ? <FormGroup className="questionnaire-answers">
                    <Label>{this.renderAnswer(submission.questionAnswers, "testdays")}</Label>
                    {this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("testdays")}>change</Button> : null}
                </FormGroup> : (this.isEditing("testdays") ?
                    <FormGroup className="questionnaire-answers">
                        <Row>
                            <Col xs="4" className="text-nowrap">Monday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Monday")} onChange={(e) => this.dayOfWeekChanged(e, "Monday")} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4" className="text-nowrap">Tuesday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Tuesday")} onChange={(e) => this.dayOfWeekChanged(e, "Tuesday")} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4" className="text-nowrap">Wednesday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Wednesday")} onChange={(e) => this.dayOfWeekChanged(e, "Wednesday")} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4" className="text-nowrap">Thursday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Thursday")} onChange={(e) => this.dayOfWeekChanged(e, "Thursday")} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4" className="text-nowrap">Friday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Friday")} onChange={(e) => this.dayOfWeekChanged(e, "Friday")} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4" className="text-nowrap">Saturday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Saturday")} onChange={(e) => this.dayOfWeekChanged(e, "Saturday")} /></Col>
                        </Row>
                        <Row>
                            <Col xs="4" className="text-nowrap">Sunday: </Col>
                            <Col xs="1" className="text-nowrap"><Input type="checkbox" className="answer-availability-check" checked={this.getDayOfWeekValue("Sunday")} onChange={(e) => this.dayOfWeekChanged(e, "Sunday")} /></Col>
                        </Row>
                    </FormGroup> : null)}
            </Question>
            <Question questionNumber="1.6" questionText={"In preparation for undertaking self-administered testing, I can confirm that I have seen the training video, have read the full instructions 'A guide for healthcare staff self-testing for coronavirus using a Lateral Flow Device (LFD)' and have read the occupational health FAQ guide."} isMandatory={this.isMandatory('seenvideoinstructions')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("seenvideoinstructions") ? <Label>{this.renderAnswer(submission.questionAnswers, "seenvideoinstructions")}</Label> : null}
                    {!this.isEditing("seenvideoinstructions") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("seenvideoinstructions")}>change</Button> : null}
                    {this.isEditing("seenvideoinstructions") ? <Label check><Input type="checkbox" name="seenvideoinstructions" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "seenvideoinstructions")} />Yes</Label> : null}
                </FormGroup>
            </Question>
            <Question questionNumber="1.7" questionText={"I pledge to use the tests only for myself."} isMandatory={this.isMandatory('pledgetousetestsforself')}>
                <FormGroup className="questionnaire-answers">
                    {!this.isEditing("pledgetousetestsforself") ? <Label>{this.renderAnswer(submission.questionAnswers, "pledgetousetestsforself")}</Label> : null}
                    {!this.isEditing("pledgetousetestsforself") && this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("pledgetousetestsforself")}>change</Button> : null}
                    {this.isEditing("pledgetousetestsforself") ? <Label check><Input type="checkbox" name="pledgetousetestsforself" value="Yes" onChange={(e) => this.checkedChangeHandler(e, "pledgetousetestsforself")} />Yes</Label> : null}
                </FormGroup>
            </Question>
            {!this.isEditing("helpadministering") && <Question questionNumber="1.8" questionText={"Do you need any additional assistance to administer the test to yourself?"} isMandatory={this.isMandatory('helpadministering')}>
                <FormGroup className="questionnaire-answers">
                    <Label>{this.renderAnswer(submission.questionAnswers, "helpadministering")}</Label>
                    {this.showIf(true, "Yes", true, "No") ? <Button color="link" onClick={() => this.editField("helpadministering")}>change</Button> : null}
                </FormGroup>
            </Question>}
            {this.isEditing("helpadministering") && <Question questionNumber="1.8" questionText={"Do you need any additional assistance to administer the test to yourself?"} isMandatory={this.isMandatory('helpadministering')}>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="helpadministering" value="Yes" onChange={(e) => this.radioChangeHandler(e, "helpadministering")} />Yes
                    </Label>
                </FormGroup>
                <FormGroup className="questionnaire-answers">
                    <Label check>
                        <Input type="radio" name="helpadministering" value="No" onChange={(e) => this.radioChangeHandler(e, "helpadministering")} />No
                    </Label>
                </FormGroup>
            </Question>}
            <Question questionNumber="2" questionText={"I confirm that the above information is accurate to the best of my knowledge."}>
                <FormGroup className="questionnaire-answers">
                    <Label>{this.renderAnswer(submission.questionAnswers, "confirm")}</Label>
                </FormGroup>
            </Question>
            <div>
                <h2>Administrative Fields</h2>
                <Question questionNumber="1.9" questionText={"Kit Code"} isMandatory>
                    <div>Take one testing kit box and locate the LOT number on the box (see image below)</div>
                    <div>
                        <ul>
                            <li>Please enter the LOT number found on the outside of the box in to this field and then click the button entitled 'Save data'. This will then take you back to the results screen and you should see the updated entry in the grid.
                                </li>
                        </ul>
                    </div>
                    <img className="questionnnaire-answer-image" src="/images/lfd-lot-number.jpg" alt="Example of LOT number" />
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="text" name="kitcode" value={this.state.kitcode} onChange={(e) => this.textChangeHandler(e, "kitcode")} maxLength={20} />
                        </Label>
                    </FormGroup>
                </Question>
                <h3>Additional Kits Issued</h3>
                <div className="questionnaire-error"><strong>When issuing a replacement kit you do not need to update the kit number record. Kit numbers are entered only when issuing a kit the first time.</strong></div>
                <Question questionNumber="1.10" questionText={"Staff has been issued a 2nd kit?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="checkbox" name="kit2issued" value="Yes" checked={this.state.kit2issued === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "kit2issued")} />Yes
                        </Label>
                    </FormGroup>
                </Question>
                {this.state.kit2issuedwarning && <div className="questionnaire-error"><strong>Alert:</strong> The previous kit was issued on <Moment format="DD MMM YYYY">{this.state.originalState.kitissueddate}</Moment>. Please check there is a valid reason for re-issuing another kit, as these are expected to last 3 months.</div>}
                <Question questionNumber="1.11" questionText={"Staff has been issued a 3rd kit?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="checkbox" name="kit3issued" value="Yes" checked={this.state.kit3issued === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "kit3issued")} disabled={!this.state.originalState.kit2issueddate} />Yes
                        </Label>
                    </FormGroup>
                </Question>
                {this.state.kit3issuedwarning && <div className="questionnaire-error"><strong>Alert:</strong> The previous kit was issued on <Moment format="DD MMM YYYY">{this.state.kit2issueddate}</Moment>. Please check there is a valid reason for re-issuing another kit, as these are expected to last 3 months.</div>}
                <Question questionNumber="1.12" questionText={"Staff has been issued a 4th kit?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="checkbox" name="kit4issued" value="Yes" checked={this.state.kit4issued === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "kit4issued")} disabled={!this.state.originalState.kit3issueddate} />Yes
                        </Label>
                    </FormGroup>
                </Question>
                {this.state.kit4issuedwarning && <div className="questionnaire-error"><strong>Alert:</strong> The previous kit was issued on <Moment format="DD MMM YYYY">{this.state.kit3issueddate}</Moment>. Please check there is a valid reason for re-issuing another kit, as these are expected to last 3 months.</div>}
                <Question questionNumber="1.13" questionText={"Staff has been issued a 5th kit?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="checkbox" name="kit5issued" value="Yes" checked={this.state.kit5issued === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "kit5issued")} disabled={!this.state.originalState.kit4issueddate} />Yes
                        </Label>
                    </FormGroup>
                </Question>
                {this.state.kit5issuedwarning && <div className="questionnaire-error"><strong>Alert:</strong> The previous kit was issued on <Moment format="DD MMM YYYY">{this.state.kit4issueddate}</Moment>. Please check there is a valid reason for re-issuing another kit, as these are expected to last 3 months.</div>}
                <Question questionNumber="1.14" questionText={"Staff has been issued a 6th kit?"} isMandatory>
                    <FormGroup className="questionnaire-answers">
                        <Label check>
                            <Input type="checkbox" name="kit6issued" value="Yes" checked={this.state.kit6issued === "Yes"} onChange={(e) => this.checkedChangeHandler(e, "kit6issued")} disabled={!this.state.originalState.kit5issueddate} />Yes
                        </Label>
                    </FormGroup>
                </Question>
                {this.state.kit6issuedwarning && <div className="questionnaire-error"><strong>Alert:</strong> The previous kit was issued on <Moment format="DD MMM YYYY">{this.state.kit5issueddate}</Moment>. Please check there is a valid reason for re-issuing another kit, as these are expected to last 3 months.</div>}
                {!this.state.showDeleteConfirm && <div><Button color="danger" onClick={() => this.updateAdminFields()} disabled={this.state.submitting}>Save Data</Button>&nbsp;<Button color="warning" disabled={this.state.submitting} onClick={() => this.showDeleteSubmission()}>Remove Registration...</Button><br />&nbsp;</div>}
                {this.state.showDeleteConfirm && <div className="questionnaire-error">Removing a registration is irreversible and should only be done if a registration is a duplicate and is no longer needed.<br /><Button color="danger" disabled={this.state.submitting} onClick={() => this.deleteSubmission()}>Confirm Remove Registration</Button><br />&nbsp;</div>}
            </div>
            {this.state.errored && <div className="questionnaire-error"><div><strong>There was a problem saving! Please try again.</strong></div><ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul></div>}
            {this.state.isInvalid && <div className="questionnaire-error"><strong>Not all mandatory answers have been completed. Please review the answers and re-submit.</strong>{this.state.validationErrors.length > 0 ? <ul>{this.state.validationErrors.map((ve, idx) => <li key={idx}>{ve}</li>)}</ul> : null}</div>}
            <div><Button color="secondary" onClick={() => returnCallback()} disabled={this.state.submitting}>Return to list</Button></div>
        </div>;
    }

};

export default ViewQuestionnaireSubmission;