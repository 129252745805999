import React from 'react';
import { Row } from 'reactstrap';

const Banner = () => (
    <div className="questionnaire-banner">
        <Row>
            <div className="questionnaire-logo col-3"><img src="/guys-cancer-logo.png" alt="Guy's Cancer Logo" /></div>
            <div className="questionnaire-bannertext col-9">COVID-19 Questionnaire</div>
        </Row>
    </div>
);

export default Banner;