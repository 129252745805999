const questionManager = {
    getQuestions(resultOfFirstTest, confirmRepeatTest, resultOfRepeatTest) {
        let newQuestions = [];
        let firstTestInvalid = false;
        let firstTestPositive = false;
        let repeatTestPositive = false;
        let hadRepeatTest = false;
        if (resultOfFirstTest === 'Invalid') {
            firstTestInvalid = true;
        }
        if (resultOfFirstTest === 'Positive') {
            firstTestPositive = true;
        }
        if (confirmRepeatTest === 'Yes') {
            hadRepeatTest = true;
        }
        if (resultOfRepeatTest === 'Positive') {
            repeatTestPositive = true;
        }
        if (!firstTestInvalid) {
            if (firstTestPositive) {
                newQuestions = [
                    { key: "mobile", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "gender", mandatory: true },
                    { key: "ethnicity", mandatory: true },
                    { key: "postcode", mandatory: true },
                    { key: "directorate", mandatory: true },
                    { key: "jobrole", mandatory: true },
                    { key: "assignmentnumber", mandatory: false },
                    { key: "dateoftest", mandatory: true },
                    { key: "lotnumberoftest1", mandatory: true },
                    { key: "resultoftest1", mandatory: true },
                    { key: "confirmrepeattest", mandatory: false },
                    { key: "lotnumberoftest2", mandatory: false },
                    { key: "resultoftest2", mandatory: false },
                    { key: "beentowork", mandatory: true },
                    { key: "dualreported", mandatory: true },
                    { key: "comments", mandatory: false }];
            } else {
                newQuestions = [
                    { key: "mobile", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "gender", mandatory: true },
                    { key: "ethnicity", mandatory: true },
                    { key: "postcode", mandatory: true },
                    { key: "directorate", mandatory: true },
                    { key: "jobrole", mandatory: true },
                    { key: "assignmentnumber", mandatory: false },
                    { key: "dateoftest", mandatory: true },
                    { key: "lotnumberoftest1", mandatory: true },
                    { key: "resultoftest1", mandatory: true },
                    { key: "confirmrepeattest", mandatory: false },
                    { key: "lotnumberoftest2", mandatory: false },
                    { key: "resultoftest2", mandatory: false },
                    { key: "beentowork", mandatory: false },
                    { key: "dualreported", mandatory: true },
                    { key: "comments", mandatory: false }];
            }
            
        } else {
            if (hadRepeatTest && repeatTestPositive) {
                newQuestions = [
                    { key: "mobile", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "gender", mandatory: true },
                    { key: "ethnicity", mandatory: true },
                    { key: "postcode", mandatory: true },
                    { key: "directorate", mandatory: true },
                    { key: "jobrole", mandatory: true },
                    { key: "assignmentnumber", mandatory: false },
                    { key: "dateoftest", mandatory: true },
                    { key: "lotnumberoftest1", mandatory: true },
                    { key: "resultoftest1", mandatory: true },
                    { key: "confirmrepeattest", mandatory: true },
                    { key: "lotnumberoftest2", mandatory: true },
                    { key: "resultoftest2", mandatory: true },
                    { key: "beentowork", mandatory: true },
                    { key: "dualreported", mandatory: true },
                    { key: "comments", mandatory: false }];
            } else {
                newQuestions = [
                    { key: "mobile", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "gender", mandatory: true },
                    { key: "ethnicity", mandatory: true },
                    { key: "postcode", mandatory: true },
                    { key: "directorate", mandatory: true },
                    { key: "jobrole", mandatory: true },
                    { key: "assignmentnumber", mandatory: false },
                    { key: "dateoftest", mandatory: true },
                    { key: "lotnumberoftest1", mandatory: true },
                    { key: "resultoftest1", mandatory: true },
                    { key: "confirmrepeattest", mandatory: true },
                    { key: "lotnumberoftest2", mandatory: hadRepeatTest },
                    { key: "resultoftest2", mandatory: hadRepeatTest },
                    { key: "beentowork", mandatory: false },
                    { key: "dualreported", mandatory: true },
                    { key: "comments", mandatory: false }];
            }            
        }
        return newQuestions;
    },
    getDirectorates() {
        return ["Cancer Services - Haematology",
            "Cancer Services - Oncology",
            "Cancer Services - Surgical Oncology",
            "Cardiovascular Services",
            "Chief Executive's Office",
            "Chief Nurse's Office",
            "Chief Operating Officer",
            "Clinical Imaging & Medical Physics",
            "Commercial",
            "Communications",
            "Data, Technology & Information",
            "Dental Services",
            "Essentia",
            "Evelina London - Community",
            "Evelina London - Medicine & Neonatology",
            "Evelina London - Surgery & Intensive Care",
            "Evelina London - Women's Services",
            "Finance",
            "Gastrointestinal Medicine & Surgery",
            "GSTT Contractors",
            "Hurley UTC",
            "Integrated Care - Acute & General Medicine",
            "Integrated Care - Integrated Local Services",
            "Integrated Care - Therapies & Rehabilitation",
            "KCL",
            "Medical Director's Office",
            "Medical Specialties",
            "Other Corporate Departments",
            "Other Directorate not otherwise listed",
            "PACCS - Pulmonary, Adult Critical Care & Sleep",
            "Pharmacy & Medicines Optimisation",
            "R&D / BRC",
            "Retail Partners",
            "Specialist Ambulatory Services",
            "Strategy",
            "Surgery",
            "Theatres, Anaesthesia & Perioperative",
            "Transplant, Renal & Urology",
            "Vaccination Service",
            "Viapath",
            "Workforce"];
    },
    getJobRoles() {
        return ["Additional Clinical Services",
            "Admin and Clerical Band 2",
            "Admin and Clerical Band 3",
            "Admin and Clerical Band 4",
            "Admin and Clerical Band 5",
            "Admin and Clerical Band 6",
            "Admin and Clerical Band 7",
            "Admin and Clerical Band 8",
            "Admin and Clerical Band 9",
            "Allied Health Professional ",
            "Allied Health Professional Band 4",
            "Allied Health Professional Band 5",
            "Allied Health Professional Band 6",
            "Allied Health Professional Band 7",
            "Allied Health Professional Band 8",
            "Allied Health Professional Band 9",
            "Apprentice Nursing Associate",
            "Clinical Scientist / Additional Professional Scientific & Technical",
            "Director/VSM",
            "Estates & Ancillary - Essentia Accommodation Services",
            "Estates & Ancillary - Essentia Catering Services",
            "Estates & Ancillary - Essentia Housekeeping Services",
            "Estates & Ancillary - Essentia Maintenance",
            "Estates & Ancillary - Essentia Other",
            "Estates & Ancillary - Essentia Portering Services",
            "Estates & Ancillary - Essentia Rapid Response Team",
            "Estates & Ancillary - Essentia Transport Services",
            "Estates & Ancillary - GSTT Engineering",
            "Estates & Ancillary - Other",
            "Estates & Ancillary - Security & Reception",
            "Executive Director",
            "KCL Other (if clinical, please chose from other categories if possible)",
            "Medical & Dental - Clinical Fellow/SAS Grade",
            "Medical & Dental - Consultant",
            "Medical & Dental - Foundation Doctor FY1/FY2/FY3",
            "Medical & Dental - Specialty Registrar (ST1/2)",
            "Medical & Dental - Specialty Registrar (ST3+)",
            "Non-Executive Director",
            "Nurse Practitioner",
            "Nursing & Midwifery Band 2",
            "Nursing & Midwifery Band 3",
            "Nursing & Midwifery Band 4",
            "Nursing & Midwifery Band 5",
            "Nursing & Midwifery Band 6",
            "Nursing & Midwifery Band 7",
            "Nursing & Midwifery Band 8",
            "Nursing & Midwifery Band 9",
            "Other",
            "Retail ",
            "Student - Medical & Dental",
            "Student - Nursing & Midwifery",
            "Student - Other ",
            "Viapath"];
    },
    getGenders() {
        return ["Male",
            "Female",
            "Other",
            "Prefer not to say"];
    },
    getEthnicities() {
        return ["White - British",
            "White - Irish",
            "White - Any other White background",
            "Mixed - White and Black Caribbean",
            "Mixed - White and Black African",
            "Mixed - White and Asian",
            "Mixed - Any other mixed background",
            "Asian or Asian British - Indian",
            "Asian or Asian British - Pakistani",
            "Asian or Asian British - Bangladeshi",
            "Asian or Asian British - Any other Asian background",
            "Black or Black British - Caribbean",
            "Black or Black British - African",
            "Black or Black British - Any other Black background",
            "Other Ethnic Groups - Chinese",
            "Other Ethnic Groups - Any other ethnic group",
            "Not stated"];
    }
};

export default questionManager;