import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Col, Row } from 'reactstrap';
import Spinner from '../../common/Spinner';
import Moment from 'react-moment';
import dateUtil from '../../../utils/dateUtil';
import tokenService from '../../../services/tokenService';
import ViewQuestionnaireSubmission from './ViewQuestionnaireSubmission';
import SubmissionFilter from './SubmissionFilter';

const currentDate = new Date(new Date().toDateString());
let startOfWeek = new Date(currentDate);
while (startOfWeek.getDay() !== 1) {
    startOfWeek.setDate(startOfWeek.getDate() - 1);
}
startOfWeek.setDate(startOfWeek.getDate() - 7);
let tomorrow = new Date(new Date().toDateString());
tomorrow.setDate(tomorrow.getDate() + 1);

const QuestionnaireResults = ({ hasReportRole, hasDateOverrideRole, hasVipAccessRole }) => {

    const questionnaireKey = 'COVID Vaccination';
    const [questionnaireResults, setQuestionnaireResults] = useState([]);
    const [submissionIdentifier, setSubmissionIdentifier] = useState(null);
    const [submissionResults, setSubmissionResults] = useState(null);
    const [showSubmissionResult, setShowSubmissionResult] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [errorRetrieving, setErrorRetrieving] = useState(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState(false);
    const [errorAccessing, setErrorAccessing] = useState(false);
    const [site, setSite] = useState('');
    const [location, setLocation] = useState('');
    const [appointmentDate, setAppointmentDate] = useState(currentDate);
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [nhsNumber, setNhsNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [patientQueue, setPatientQueue] = useState([]);
    const downloadAllLink = useRef();

    const buildFilterUrl = useCallback(() => {
        let url = "appointmentDate=";
        
        if (appointmentDate) {
            const newValue = `${appointmentDate.getFullYear()}-${pad(appointmentDate.getMonth()+1,2)}-${pad(appointmentDate.getDate(),2)}`;
            url += encodeURIComponent(newValue);
        }
        url += "&forename=";
        if (forename) {
            url += encodeURIComponent(forename);
        }
        url += "&lastname=";
        if (lastname) {
            url += encodeURIComponent(lastname);
        }
        url += "&nhsNumber=";
        if (nhsNumber) {
            url += encodeURIComponent(nhsNumber);
        }
        url += "&site=";
        if (site) {
            url += encodeURIComponent(site);
        }
        url += "&location=";
        if (location) {
            url += encodeURIComponent(location);
        }

        return url;
    }, [appointmentDate, appointmentDate, forename, lastname, nhsNumber, site, location]);

    const getQuestionnaireSubmission = useCallback(async () => {
        if (submissionIdentifier) {
            setLoading(true);
            setErrorAccessing(false);
            try {
                const token = tokenService.getToken();
                const response = await fetch('/QuestionnaireAnswers/' + questionnaireKey + '/' + submissionIdentifier, {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setSubmissionResults(data);
                    setShowSubmissionResult(true);
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                    setSubmissionIdentifier(null);
                } else if (response.status === 403) {
                    setErrorAccessing(true);
                    setSubmissionIdentifier(null);
                } else {
                    setErrorRetrieving(true);
                    setSubmissionIdentifier(null);
                }
                setSaved(false);
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }
    }, [submissionIdentifier]);

    const getQuestionnaireAnswers = useCallback(async () => {
        if (!submissionIdentifier && ((appointmentDate && site && location) || lastname || nhsNumber)) {
            setLoading(true);
            try {
                const token = tokenService.getToken();
                const url = '/CovidVaccinationResults?questionnaireKey=' + questionnaireKey + "&" + buildFilterUrl();
                const response = await fetch(url, {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setQuestionnaireResults(data);
                } else if (response.status === 401) {
                    setErrorAuthenticating(true);
                } else {
                    setErrorRetrieving(true);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }

    }, [buildFilterUrl, submissionIdentifier]);

    useEffect(() => {
        if (!errorRetrieving && !errorAuthenticating) {
            getQuestionnaireAnswers();
        }        
    }, [questionnaireKey, getQuestionnaireAnswers, appointmentDate, forename, lastname, nhsNumber, site, location, submissionIdentifier, errorRetrieving, errorAuthenticating])

    useEffect(() => {
        if (submissionIdentifier !== null) {
            getQuestionnaireSubmission();
        }
    }, [submissionIdentifier, getQuestionnaireSubmission])

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }


    const backToListHandler = () => {
        setSubmissionIdentifier(null);
        setShowSubmissionResult(false);
        setErrorSaving(false);
        setErrorRetrieving(false);
        setErrorAuthenticating(false);
    }

    const saveChangesHandler = async (changes) => {
        setLoading(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers';

            const data = {
                questionnaireName: questionnaireKey,
                submissionIdentifier: submissionIdentifier,
                questionAnswers: []
            };
            const method = changes.deleted === true ? 'DELETE' : 'PUT';
            delete changes.deleted;
            const changeKeys = Object.keys(changes);

            if (changeKeys.length > 0) {
                changeKeys.map(a => data.questionAnswers.push({ questionKey: a, answers: [changes[a]] }));

                const response = await fetch(url, {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {
                    setErrorSaving(false);
                    setSaved(true);
                    //backToListHandler();
                } else {
                    setErrorSaving(true);
                    setSaved(false);
                }
            } else {
                setSaved(true);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    const getForename = (questionAnswers) => {
        const forenameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'forename');
        if (forenameQuestion.length > 0) {
            const answers = forenameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getLastname = (questionAnswers) => {
        const lastnameQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'lastname');
        if (lastnameQuestion.length > 0) {
            const answers = lastnameQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getNhsNumber = (questionAnswers) => {
        const nhsNumberQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'nhsnumber');
        if (nhsNumberQuestion.length > 0) {
            const answers = nhsNumberQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFirstAppointmentSite = (questionAnswers) => {
        const firstAppointmentSiteQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'firstappointmentsite');
        if (firstAppointmentSiteQuestion.length > 0) {
            const answers = firstAppointmentSiteQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFirstAppointmentLocation = (questionAnswers) => {
        const firstAppointmentLocationQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'firstappointmentlocation');
        if (firstAppointmentLocationQuestion.length > 0) {
            const answers = firstAppointmentLocationQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFirstAppointmentDate = (questionAnswers) => {
        const firstAppointmentDateQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'firstappointmentdate');
        if (firstAppointmentDateQuestion.length > 0) {
            const answers = firstAppointmentDateQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFirstAppointmentTimeSlot = (questionAnswers) => {
        const firstAppointmentTimeSlotQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'firstappointmenttimeslot');
        if (firstAppointmentTimeSlotQuestion.length > 0) {
            const answers = firstAppointmentTimeSlotQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getSecondAppointmentSite = (questionAnswers) => {
        const secondAppointmentSiteQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'secondappointmentsite');
        if (secondAppointmentSiteQuestion.length > 0) {
            const answers = secondAppointmentSiteQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getSecondAppointmentLocation = (questionAnswers) => {
        const secondAppointmentLocationQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'secondappointmentlocation');
        if (secondAppointmentLocationQuestion.length > 0) {
            const answers = secondAppointmentLocationQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getSecondAppointmentDate = (questionAnswers) => {
        const secondAppointmentDateQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'secondappointmentdate');
        if (secondAppointmentDateQuestion.length > 0) {
            const answers = secondAppointmentDateQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getSecondAppointmentTimeSlot = (questionAnswers) => {
        const secondAppointmentTimeSlotQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'secondappointmenttimeslot');
        if (secondAppointmentTimeSlotQuestion.length > 0) {
            const answers = secondAppointmentTimeSlotQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getThirdAppointmentSite = (questionAnswers) => {
        const thirdAppointmentSiteQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'thirdappointmentsite');
        if (thirdAppointmentSiteQuestion.length > 0) {
            const answers = thirdAppointmentSiteQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getThirdAppointmentLocation = (questionAnswers) => {
        const thirdAppointmentLocationQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'thirdappointmentlocation');
        if (thirdAppointmentLocationQuestion.length > 0) {
            const answers = thirdAppointmentLocationQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getThirdAppointmentDate = (questionAnswers) => {
        const thirdAppointmentDateQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'thirdappointmentdate');
        if (thirdAppointmentDateQuestion.length > 0) {
            const answers = thirdAppointmentDateQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getThirdAppointmentTimeSlot = (questionAnswers) => {
        const thirdAppointmentTimeSlotQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'thirdappointmenttimeslot');
        if (thirdAppointmentTimeSlotQuestion.length > 0) {
            const answers = thirdAppointmentTimeSlotQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getBoost1AppointmentSite = (questionAnswers) => {
        const boost1AppointmentSiteQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'boost1appointmentsite');
        if (boost1AppointmentSiteQuestion.length > 0) {
            const answers = boost1AppointmentSiteQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getBoost1AppointmentLocation = (questionAnswers) => {
        const boost1AppointmentLocationQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'boost1appointmentlocation');
        if (boost1AppointmentLocationQuestion.length > 0) {
            const answers = boost1AppointmentLocationQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getBoost1AppointmentDate = (questionAnswers) => {
        const boost1AppointmentDateQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'boost1appointmentdate');
        if (boost1AppointmentDateQuestion.length > 0) {
            const answers = boost1AppointmentDateQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getBoost1AppointmentTimeSlot = (questionAnswers) => {
        const boost1AppointmentTimeSlotQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'boost1appointmenttimeslot');
        if (boost1AppointmentTimeSlotQuestion.length > 0) {
            const answers = boost1AppointmentTimeSlotQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFlu1AppointmentSite = (questionAnswers) => {
        const flu1AppointmentSiteQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'flu1appointmentsite');
        if (flu1AppointmentSiteQuestion.length > 0) {
            const answers = flu1AppointmentSiteQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFlu1AppointmentLocation = (questionAnswers) => {
        const flu1AppointmentLocationQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'flu1appointmentlocation');
        if (flu1AppointmentLocationQuestion.length > 0) {
            const answers = flu1AppointmentLocationQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFlu1AppointmentDate = (questionAnswers) => {
        const flu1AppointmentDateQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'flu1appointmentdate');
        if (flu1AppointmentDateQuestion.length > 0) {
            const answers = flu1AppointmentDateQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFlu1AppointmentTimeSlot = (questionAnswers) => {
        const flu1AppointmentTimeSlotQuestion = questionAnswers.filter(qa => qa.questionKey.toLowerCase() === 'flu1appointmenttimeslot');
        if (flu1AppointmentTimeSlotQuestion.length > 0) {
            const answers = flu1AppointmentTimeSlotQuestion[0].answers;
            if (answers.length > 0 && answers[0] && answers[0].length > 0) {
                return answers[0];
            }
        }
        return "n/a";
    }

    const getFirstAppointmentDetails = (questionAnswers) => {
        const output = [];
        const site = getFirstAppointmentSite(questionAnswers);
        const location = getFirstAppointmentLocation(questionAnswers);
        const date = getFirstAppointmentDate(questionAnswers);
        const time = getFirstAppointmentTimeSlot(questionAnswers);
        if (date !== 'n/a') {
            const dateParts = date.split('/');
            const theDate = new Date(dateParts[2], parseInt(dateParts[1]) - 1, dateParts[0]);
            if (dateMatch(theDate, currentDate)) {
                output.push(<span key="firstappdate"><strong><Moment format="DD MMM YYYY">{theDate}</Moment></strong></span>);
                output.push(<span key="firstapptime">@<strong>{time}</strong></span>)
            } else {
                output.push(<span key="firstappdate"><Moment format="DD MMM YYYY">{theDate}</Moment></span>);
                output.push(<span key="firstapptime">@{time}</span>)
            }
        }
        let siteAndLocation = '';
        if (site !== 'n/a' && location !== 'n/a') {
            output.push(<br key="firstappname" />);
            if (site !== 'n/a') {
                siteAndLocation += site;
            }
            if (location !== 'n/a') {
                siteAndLocation += siteAndLocation === '' ? location : ' ' + location;
            }
            output.push(<span key="firstappsite" className="questionnaire-results-smalltext">({siteAndLocation})</span>);
        }
        if (output.length === 0) {
            output.push(<span key="firstappna">n/a</span>);
        }

        return output;
    }

    const getSecondAppointmentDetails = (questionAnswers) => {
        const output = [];
        const site = getSecondAppointmentSite(questionAnswers);
        const location = getSecondAppointmentLocation(questionAnswers);
        const date = getSecondAppointmentDate(questionAnswers);
        const time = getSecondAppointmentTimeSlot(questionAnswers);
        if (date !== 'n/a') {
            const dateParts = date.split('/');
            const theDate = new Date(dateParts[2], parseInt(dateParts[1]) - 1, dateParts[0]);
            if (dateMatch(theDate, currentDate)) {
                output.push(<span key="secondappdate"><strong><Moment format="DD MMM YYYY">{theDate}</Moment></strong></span>);
                output.push(<span key="secondapptime">@<strong>{time}</strong></span>)
            } else {
                output.push(<span key="secondappdate"><Moment format="DD MMM YYYY">{theDate}</Moment></span>);
                output.push(<span key="secondapptime">@{time}</span>)
            }            
        }
        let siteAndLocation = '';
        if (site !== 'n/a' && location !== 'n/a') {
            output.push(<br key="secondappbr" />);
            if (site !== 'n/a') {
                siteAndLocation += site;
            }
            if (location !== 'n/a') {
                siteAndLocation += siteAndLocation === '' ? location : ' > ' + location;
            }
            output.push(<span key="secondappsite" className="questionnaire-results-smalltext">({siteAndLocation})</span>);
        }
        if (output.length === 0) {
            output.push(<span key="secondappna">n/a</span>);
        }

        return output;
    }

    const getThirdAppointmentDetails = (questionAnswers) => {
        const output = [];
        const site = getThirdAppointmentSite(questionAnswers);
        const location = getThirdAppointmentLocation(questionAnswers);
        const date = getThirdAppointmentDate(questionAnswers);
        const time = getThirdAppointmentTimeSlot(questionAnswers);
        if (date !== 'n/a') {
            const dateParts = date.split('/');
            const theDate = new Date(dateParts[2], parseInt(dateParts[1]) - 1, dateParts[0]);
            if (dateMatch(theDate, currentDate)) {
                output.push(<span key="thirdappdate"><strong><Moment format="DD MMM YYYY">{theDate}</Moment></strong></span>);
                output.push(<span key="thirdapptime">@<strong>{time}</strong></span>)
            } else {
                output.push(<span key="thirdappdate"><Moment format="DD MMM YYYY">{theDate}</Moment></span>);
                output.push(<span key="thirdapptime">@{time}</span>)
            }
        }
        let siteAndLocation = '';
        if (site !== 'n/a' && location !== 'n/a') {
            output.push(<br key="thirdappbr" />);
            if (site !== 'n/a') {
                siteAndLocation += site;
            }
            if (location !== 'n/a') {
                siteAndLocation += siteAndLocation === '' ? location : ' > ' + location;
            }
            output.push(<span key="thirdappsite" className="questionnaire-results-smalltext">({siteAndLocation})</span>);
        }
        if (output.length === 0) {
            output.push(<span key="thirdappna">n/a</span>);
        }

        return output;
    }

    const getBoost1AppointmentDetails = (questionAnswers) => {
        const output = [];
        const site = getBoost1AppointmentSite(questionAnswers);
        const location = getBoost1AppointmentLocation(questionAnswers);
        const date = getBoost1AppointmentDate(questionAnswers);
        const time = getBoost1AppointmentTimeSlot(questionAnswers);
        if (date !== 'n/a') {
            const dateParts = date.split('/');
            const theDate = new Date(dateParts[2], parseInt(dateParts[1]) - 1, dateParts[0]);
            if (dateMatch(theDate, currentDate)) {
                output.push(<span key="boost1appdate"><strong><Moment format="DD MMM YYYY">{theDate}</Moment></strong></span>);
                output.push(<span key="boost1apptime">@<strong>{time}</strong></span>)
            } else {
                output.push(<span key="boost1appdate"><Moment format="DD MMM YYYY">{theDate}</Moment></span>);
                output.push(<span key="boost1apptime">@{time}</span>)
            }
        }
        let siteAndLocation = '';
        if (site !== 'n/a' && location !== 'n/a') {
            output.push(<br key="boost1appbr" />);
            if (site !== 'n/a') {
                siteAndLocation += site;
            }
            if (location !== 'n/a') {
                siteAndLocation += siteAndLocation === '' ? location : ' > ' + location;
            }
            output.push(<span key="boost1appsite" className="questionnaire-results-smalltext">({siteAndLocation})</span>);
        }
        if (output.length === 0) {
            output.push(<span key="boost1appna">n/a</span>);
        }

        return output;
    }

    const getFlu1AppointmentDetails = (questionAnswers) => {
        const output = [];
        const site = getFlu1AppointmentSite(questionAnswers);
        const location = getFlu1AppointmentLocation(questionAnswers);
        const date = getFlu1AppointmentDate(questionAnswers);
        const time = getFlu1AppointmentTimeSlot(questionAnswers);
        if (date !== 'n/a') {
            const dateParts = date.split('/');
            const theDate = new Date(dateParts[2], parseInt(dateParts[1]) - 1, dateParts[0]);
            if (dateMatch(theDate, currentDate)) {
                output.push(<span key="flu1appdate"><strong><Moment format="DD MMM YYYY">{theDate}</Moment></strong></span>);
                output.push(<span key="flu1apptime">@<strong>{time}</strong></span>)
            } else {
                output.push(<span key="flu1appdate"><Moment format="DD MMM YYYY">{theDate}</Moment></span>);
                output.push(<span key="flu1apptime">@{time}</span>)
            }
        }
        let siteAndLocation = '';
        if (site !== 'n/a' && location !== 'n/a') {
            output.push(<br key="flu1appbr" />);
            if (site !== 'n/a') {
                siteAndLocation += site;
            }
            if (location !== 'n/a') {
                siteAndLocation += siteAndLocation === '' ? location : ' > ' + location;
            }
            output.push(<span key="flu1appsite" className="questionnaire-results-smalltext">({siteAndLocation})</span>);
        }
        if (output.length === 0) {
            output.push(<span key="flu1appna">n/a</span>);
        }

        return output;
    }

    const setFilter = (site, location, appointmentDate, forename, lastname, nhsNumber) => {
        setSite(site);
        setLocation(location);
        setAppointmentDate(appointmentDate);
        setForename(forename);
        setLastname(lastname);
        setNhsNumber(nhsNumber);
        setErrorRetrieving(false);
        setErrorAuthenticating(false);
    };

    const dateMatch = (firstDate, secondDate) => {
        if (firstDate.getDate() !== secondDate.getDate()) {
            return false;
        }
        if (firstDate.getMonth() !== secondDate.getMonth()) {
            return false;
        }
        if (firstDate.getFullYear() !== secondDate.getFullYear()) {
            return false;
        }
        return true;
    };

    const downloadAllCsv = async () => {
        setLoadingCsv(true);
        try {
            const token = tokenService.getToken();
            const url = '/QuestionnaireAnswers/' + questionnaireKey + '/DownloadAll?dateFrom=&dateTo=&questionFilterKey=&questionFilterValue=';
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (response.ok) {
                const data = await response.blob();
                const filename = questionnaireKey + " - ResultsAll - " + new Date().toISOString().substring(0, 10) + ".csv";

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(data, filename);
                } else {
                    let objectUrl = window.URL.createObjectURL(data);

                    downloadAllLink.current.href = objectUrl;
                    downloadAllLink.current.download = filename;
                    downloadAllLink.current.click();

                    window.URL.revokeObjectURL(objectUrl);
                }

            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingCsv(false);
        }
    }

    if (showSubmissionResult) {
        return <ViewQuestionnaireSubmission submission={submissionResults} returnCallback={() => backToListHandler()} saveChanges={saveChangesHandler} errorSaving={errorSaving} saved={saved} allowDateOverride={hasDateOverrideRole} hasVipAccess={hasVipAccessRole} />
    } else {
        return <div className="questionnaire-body">
            <h3>Results for COVID-19 NHS Staff Vaccination</h3>
            <SubmissionFilter setFilter={setFilter} originalSite={site} originalLocation={location} originalAppointmentDate={appointmentDate} originalForename={forename} originalLastname={lastname} originalNhsNumber={nhsNumber} />
            {loading && <Spinner />}
            {errorAccessing && <div className="questionnaire-error">You do not have the appropriate security rights to access this record!</div>}
            {errorAuthenticating && <div className="questionnaire-error">Authentication error detected. Your session may have expired. Please refresh the browser using F5 or the browser refresh button.</div>}
            {errorRetrieving && <div className="questionnaire-error">Unexpected error detected whilst retrieving results. This may be an intermittent problem. Please refresh the browser using F5 or the browser refresh button. If the problem keeps occuring please log a call with the service desk under the COVID-19 Vaccination Admin service.</div>}
            {questionnaireResults.length === 0 && !loading && <div>No Submissions Found - minimum criteria required is <strong>Site, Location and Appointment Date</strong>, or <strong>Last name</strong> or <strong>NHS number</strong>. Ensure this criteria is met if you are expecting a match.</div>}
            {questionnaireResults.length > 0 && <Table className="questionnaire-results-list" striped>
                <thead>
                    <tr>
                        <th>Submission Date</th>
                        <th>Forename</th>
                        <th>Last name</th>
                        <th>NHS #</th>
                        <th>1st App.</th>
                        <th>2nd App.</th>
                        <th>3rd App.</th>
                        <th>Booster App.</th>
                        <th>Flu App.</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {questionnaireResults.map(qr => <tr key={qr.submissionIdentifier}>
                        <td><Moment format="DD MMM YYYY HH:mm">{dateUtil.ConvertDateFromJSON(qr.submissionDate)}</Moment></td>
                        <td>{getForename(qr.questionAnswers)}</td>
                        <td>{getLastname(qr.questionAnswers)}</td>
                        <td>{getNhsNumber(qr.questionAnswers)}</td>
                        <td>{getFirstAppointmentDetails(qr.questionAnswers)}</td>
                        <td>{getSecondAppointmentDetails(qr.questionAnswers)}</td>
                        <td>{getThirdAppointmentDetails(qr.questionAnswers)}</td>
                        <td>{getBoost1AppointmentDetails(qr.questionAnswers)}</td>
                        <td>{getFlu1AppointmentDetails(qr.questionAnswers)}</td>
                        <td><Button onClick={() => setSubmissionIdentifier(qr.submissionIdentifier)}>View Results</Button></td>
                    </tr>)}
                </tbody>
            </Table>}
            {loadingCsv && <Spinner />}
            <br/>&nbsp;<br/>
            <a ref={downloadAllLink} href="/">&nbsp;</a>
        </div>;
    }
};

export default QuestionnaireResults;