import React, { Component } from 'react';
import Banner from '../Banner';
import QuestionnaireResults from './QuestionnaireResults';
import { Card, CardHeader, CardBody } from 'reactstrap';
import isInRole from '../../../utils/isInRole';

export class Covid19StaffAntibodyRegistrationResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkingAccess: true,
            hasRequiredRoles: false
        };
    }

    async componentDidMount() {
        const { requiredRoles } = this.props;
        if (isInRole(requiredRoles)) {
            this.setState({
                checkingAccess: false,
                hasRequiredRoles: true
            });
        } else {
            this.setState({
                checkingAccess: false
            });
        }
    }

    render() {

        if (this.state.checkingAccess) {
            return null;
        }

        if (this.state.hasRequiredRoles) {
            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} >
                        <Banner />
                    </CardHeader>
                    <CardBody>
                        <QuestionnaireResults />
                    </CardBody>
                </Card>
                <footer>
                    &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust
                </footer>
            </>
            );
        } else {
            return (<>
                <Card>
                    <CardHeader style={{ backgroundColor: '#004B8B' }} >
                        <Banner />
                    </CardHeader>
                    <CardBody>
                        <div>You are not authorized to view this page.</div>
                    </CardBody>
                </Card>
                <footer>
                    &copy; 2020 - Guy's and St Thomas' NHS Foundation Trust
                </footer>
            </>)
        }
    }
}
