import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

const PSDScreenerActions = ({
    assessorAdAccount, showingAsAdditionalPsd,
    confirmCovidVaxCheck, confirmFluVaxCheck, confirmStaffCheck, confirmVaxMatch,
    moreInformationNeededBeforeConsent, isFluPsd,
    approveActionDisabled,
    approveActionDelegate, approveAndContinueActionDelegate,
    approveForFluActionDelegate,
    declineActionDisabled,
    declineActionDelegate, declineAndContinueActionDelegate,
    declineForFluActionDelegate,
    revertActionDelegate
}) => {

    if (confirmCovidVaxCheck !== "Yes" && (confirmFluVaxCheck !== "Yes")) {
        return <>
            <ButtonGroup>
                {(moreInformationNeededBeforeConsent === "No" || (isFluPsd && confirmStaffCheck === "No"))
                    ? <Button color="danger" onClick={declineActionDelegate}>Finish with patient unvaccinated</Button> : <Button color="success" onClick={approveActionDelegate} disabled={approveActionDisabled}>
                        {!assessorAdAccount && <>Sign and </>}Submit for Prescribing</Button>}
                {showingAsAdditionalPsd && <Button color="danger" onClick={revertActionDelegate}>Revert to previous ePSD</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmCovidVaxCheck === "Yes") {
        return <>
            <ButtonGroup>
                {(moreInformationNeededBeforeConsent === "No" || (isFluPsd && confirmStaffCheck === "No"))
                    ? <Button color="danger" onClick={declineAndContinueActionDelegate} disabled={declineActionDisabled}>Finish with patient unvaccinated and Continue to next ePSD</Button> : <Button color="success" onClick={approveAndContinueActionDelegate} disabled={approveActionDisabled}>
                        {!assessorAdAccount && <>Sign and </>}Continue to next ePSD</Button>}
            </ButtonGroup>
        </>;
    }

    if (confirmFluVaxCheck === "Yes" && (confirmStaffCheck === "Yes" || confirmStaffCheck === "No")) {
        return <>
            <ButtonGroup>
                {(moreInformationNeededBeforeConsent === "No" || (!isFluPsd && confirmVaxMatch === "No"))
                    ? <Button color="danger" onClick={declineForFluActionDelegate}>Finish with patient unvaccinated and Continue to next ePSD</Button> : <Button color="success" onClick={approveForFluActionDelegate} disabled={approveActionDisabled}>
                        {!assessorAdAccount && <>Sign and </>}Continue to next ePSD</Button>}
            </ButtonGroup>
        </>;
    }

};

export default PSDScreenerActions;