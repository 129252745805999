const questionManager = {
    getQuestions(healthcareWorker, carehomeWorker, onBehalfOf, willingToPartake, infectionOrFeverish, covidPositive4Weeks, otherVaccinePast14Days, fluJabPlanned, seriousreaction, seriousreactionConfirmed, allergic, allergicConfirmed, bleedingDisorder, bleedingDisorderConfirmed, warfarin, warfarinThresholdOk, breastfeeding, pregnant, covidTrial, covidTrialApproved, organisation, childBearingAge, isAdmin, isAdminFollowUp) {
        let newQuestions = [];
        let isScreening = isAdmin || false;
        let isScreeningFollowUp = isAdminFollowUp || false;
        let isHealthcareWorker = false;
        let isCarehomeWorker = false;
        let isOnBehalfOf = false;
        let isWillingToPartake = false;
        // first app
        let hasInfectionOrFeverish = false;
        let hadSeriousReaction = false;
        let isAllergic = false;
        let isAllergicConfirmed = false;
        let hasBleedingDisorder = false;
        let hasBleedingDisorderConfirmed = false;
        let takesWarfarin = false;
        let takesWarfarinThresholdOk = false;
        let onCovidTrial = false;
        let onCovidTrialApproved = false;
        // follow up app
        let hasInfectionOrFeverishFollowUp = false;
        let hadSeriousReactionFollowUp = false;
        let isAllergicFollowUp = false;
        let isAllergicConfirmedFollowUp = false;
        let hasBleedingDisorderFollowUp = false;
        let hasBleedingDisorderConfirmedFollowUp = false;
        let takesWarfarinFollowUp = false;
        let takesWarfarinThresholdOkFollowUp = false;
        let onCovidTrialFollowUp = false;
        let onCovidTrialApprovedFollowUp = false;

        let isDirectorateRequired = false;
        if (healthcareWorker === 'Yes') {
            isHealthcareWorker = true;
        }
        if (carehomeWorker === 'Yes') {
            isCarehomeWorker = true;
        }
        if (onBehalfOf === 'Yes') {
            isOnBehalfOf = true;
        }
        if (willingToPartake === 'Yes') {
            isWillingToPartake = true;
        }
        // first appointment
        if (infectionOrFeverish === 'Yes') {
            hasInfectionOrFeverish = true;
        }
        if (seriousreaction === 'Yes') {
            hadSeriousReaction = true;
        }
        if (allergic === 'Yes') {
            isAllergic = true;
        }
        if (allergicConfirmed === 'Yes') {
            isAllergicConfirmed = true;
        }
        if (bleedingDisorder === 'Yes') {
            hasBleedingDisorder = true;
        }
        if (bleedingDisorderConfirmed === 'Yes') {
            hasBleedingDisorderConfirmed = true;
        }
        if (warfarin === 'Yes') {
            takesWarfarin = true;
        }
        if (warfarinThresholdOk) {
            takesWarfarinThresholdOk = true;
        }      
        if (covidTrial === 'Yes') {
            onCovidTrial = true;
        }
        if (covidTrialApproved === 'Yes') {
            onCovidTrialApproved = true;
        }
        // follow up appointment
        if (infectionOrFeverish === 'Yes' && isScreeningFollowUp) {
            hasInfectionOrFeverishFollowUp = true;
        }
        if (seriousreaction === 'Yes' && isScreeningFollowUp) {
            hadSeriousReactionFollowUp = true;
        }
        if (allergic === 'Yes' && isScreeningFollowUp) {
            isAllergicFollowUp = true;
        }
        if (allergicConfirmed === 'Yes' && isScreeningFollowUp) {
            isAllergicConfirmedFollowUp = true;
        }
        if (bleedingDisorder === 'Yes' && isScreeningFollowUp) {
            hasBleedingDisorderFollowUp = true;
        }
        if (bleedingDisorderConfirmed === 'Yes' && isScreeningFollowUp) {
            hasBleedingDisorderConfirmedFollowUp = true;
        }
        if (warfarin === 'Yes' && isScreeningFollowUp) {
            takesWarfarinFollowUp = true;
        }
        if (warfarinThresholdOk) {
            takesWarfarinThresholdOkFollowUp = true;
        }
        if (covidTrial === 'Yes' && isScreeningFollowUp) {
            onCovidTrialFollowUp = true;
        }
        if (covidTrialApproved === 'Yes' && isScreeningFollowUp) {
            onCovidTrialApprovedFollowUp = true;
        }

        if (organisation === 'Guy\'s and St Thomas\' NHS Foundation Trust') {
            isDirectorateRequired = true;
        }
        let eligibleForVaccine = isWillingToPartake &&
            !hasInfectionOrFeverish &&
            (!hadSeriousReaction) &&
            (!isAllergic || (isScreening ? (isAllergic && isAllergicConfirmed) : true)) &&
            (!hasBleedingDisorder || (isScreening ? (hasBleedingDisorder && hasBleedingDisorderConfirmed) : true)) &&
            (!takesWarfarin || (isScreening ? (takesWarfarin && takesWarfarinThresholdOk) : true)) &&
            (!onCovidTrial || (isScreening ? (onCovidTrial && onCovidTrialApproved) : true));
        if (isScreeningFollowUp) {
            eligibleForVaccine = isWillingToPartake &&
                !hasInfectionOrFeverishFollowUp &&
                (!hadSeriousReactionFollowUp) &&
                (!isAllergicFollowUp || (isAllergicFollowUp && isAllergicConfirmedFollowUp)) &&
                (!hasBleedingDisorderFollowUp || (hasBleedingDisorderFollowUp && hasBleedingDisorderConfirmedFollowUp)) &&
                (!takesWarfarinFollowUp || (takesWarfarinFollowUp && takesWarfarinThresholdOkFollowUp)) &&
                (!onCovidTrialFollowUp || (onCovidTrialFollowUp && onCovidTrialApprovedFollowUp));
        }
        if (!isWillingToPartake) {
            newQuestions = [
                { key: "vip", mandatory: false },
                { key: "eligible", mandatory: true },
                { key: "healthcareworker", mandatory: true },
                { key: "carehomeworker", mandatory: isHealthcareWorker },
                { key: "carehomeorganisation", mandatory: isCarehomeWorker },
                { key: "onbehalfof", mandatory: true },
                { key: "onbehalfofrelationship", mandatory: isOnBehalfOf },
                { key: "onbehalfofname", mandatory: true && !isScreening && !isScreeningFollowUp },
                { key: "willingtopartake", mandatory: true },
                { key: "infectionorfeverish", mandatory: false },
                { key: "covidpositive4weeks", mandatory: false },
                { key: "othervaccinepast14days", mandatory: false },
                { key: "othervaccinedate", mandatory: false },
                { key: "confirmflujabrestrictions", mandatory: false },
                { key: "seriousreaction", mandatory: false },
                { key: "seriousreactionconfirmed", mandatory: false },
                { key: "allergic", mandatory: false },
                { key: "allergicconfirmed", mandatory: false },
                { key: "bleedingdisorder", mandatory: false },
                { key: "bleedingdisorderconfirmed", mandatory: false },
                { key: "warfarin", mandatory: false },
                { key: "warfarinthresholdok", mandatory: false },
                { key: "breastfeeding", mandatory: false },
                { key: "pregnant", mandatory: false },
                { key: "covidtrial", mandatory: false },
                { key: "covidtrialapproved", mandatory: false },
                { key: "restrictedmobility", mandatory: false },
                { key: "vaccinetype", mandatory: false },
                { key: "nhsnumber", mandatory: true },
                { key: "forename", mandatory: true },
                { key: "lastname", mandatory: true },
                { key: "dateofbirth", mandatory: true },
                { key: "gender", mandatory: true },
                { key: "postcode", mandatory: !isHealthcareWorker },
                { key: "ethnicity", mandatory: true },
                { key: "mobile", mandatory: false },
                { key: "email", mandatory: false },
                { key: "organisation", mandatory: isHealthcareWorker && !isCarehomeWorker },
                { key: "directorate", mandatory: isHealthcareWorker && !isCarehomeWorker && isDirectorateRequired },
                { key: "jobrole", mandatory: isHealthcareWorker },
                { key: "departmentward", mandatory: isHealthcareWorker },
                { key: "assignmentnumber", mandatory: false },
                { key: "address", mandatory: false },
                { key: "firstappointmentsite", mandatory: false },
                { key: "firstappointmentlocation", mandatory: false },
                { key: "firstappointmentdate", mandatory: false },
                { key: "firstappointmenttimeslot", mandatory: false },
                { key: "secondappointmentsite", mandatory: false },
                { key: "secondappointmentlocation", mandatory: false },
                { key: "secondappointmentdate", mandatory: false },
                { key: "secondappointmenttimeslot", mandatory: false },
                { key: "consentshare", mandatory: true },
                { key: "confirmbringmedlist", mandatory: false },
                { key: "childbearingage", mandatory: false },
                { key: "confirmreadpregnancyleaflet", mandatory: false },
                { key: "confirmpregnancyandconsent", mandatory: false },
                { key: "reason", mandatory: true },
                { key: "confirm", mandatory: true }];
        } else {
            if (eligibleForVaccine) {
                newQuestions = [
                    { key: "vip", mandatory: false },
                    { key: "eligible", mandatory: true },
                    { key: "healthcareworker", mandatory: true },
                    { key: "carehomeworker", mandatory: isHealthcareWorker },
                    { key: "carehomeorganisation", mandatory: isCarehomeWorker },
                    { key: "onbehalfof", mandatory: true },
                    { key: "onbehalfofrelationship", mandatory: isOnBehalfOf },
                    { key: "onbehalfofname", mandatory: true && !isScreening && !isScreeningFollowUp },
                    { key: "willingtopartake", mandatory: true },

                    { key: "infectionorfeverish", mandatory: false },
                    { key: "covidpositive4weeks", mandatory: false },
                    { key: "othervaccinepast14days", mandatory: false },
                    { key: "othervaccinedate", mandatory: false },
                    { key: "flujabpast7days", mandatory: false },
                    { key: "confirmflujabrestrictions", mandatory: false },
                    { key: "seriousreaction", mandatory: false },
                    { key: "seriousreactionconfirmed", mandatory: false },
                    { key: "allergic", mandatory: false },
                    { key: "allergicconfirmed", mandatory: false },
                    { key: "bleedingdisorder", mandatory: false },
                    { key: "bleedingdisorderconfirmed", mandatory: false },
                    { key: "warfarin", mandatory: false },
                    { key: "warfarinthresholdok", mandatory: false },
                    { key: "breastfeeding", mandatory: false },
                    { key: "pregnant", mandatory: false },
                    { key: "covidtrial", mandatory: false },
                    { key: "covidtrialapproved", mandatory: false },
                    { key: "vaccinetype", mandatory: false },

                    { key: "infectionorfeverish2", mandatory: false },
                    { key: "covidpositive4weeks2", mandatory: false },
                    { key: "othervaccinepast14days2", mandatory: false },
                    { key: "othervaccinedate2", mandatory: false },
                    { key: "flujabpast7days2", mandatory: false },
                    { key: "confirmflujabrestrictions2", mandatory: false },
                    { key: "seriousreaction2", mandatory: false },
                    { key: "seriousreactionconfirmed2", mandatory: false },
                    { key: "allergic2", mandatory: false },
                    { key: "allergicconfirmed2", mandatory: false },
                    { key: "bleedingdisorder2", mandatory: false },
                    { key: "bleedingdisorderconfirmed2", mandatory: false },
                    { key: "breastfeeding2", mandatory: false },
                    { key: "pregnant2", mandatory: false },
                    { key: "covidtrial2", mandatory: false },
                    { key: "covidtrialapproved2", mandatory: false },
                    { key: "vaccinetype2", mandatory: false },

                    { key: "restrictedmobility", mandatory: true },
                    { key: "systemicallergicreactiontofirst", mandatory: false },

                    { key: "firstappointmentsite", mandatory: true },
                    { key: "firstappointmentlocation", mandatory: true },
                    { key: "firstappointmentdate", mandatory: true },
                    { key: "firstappointmenttimeslot", mandatory: true },
                    { key: "secondappointmentsite", mandatory: true },
                    { key: "secondappointmentlocation", mandatory: true },
                    { key: "secondappointmentdate", mandatory: true },
                    { key: "secondappointmenttimeslot", mandatory: true },
                    { key: "nhsnumber", mandatory: true },
                    { key: "forename", mandatory: true },
                    { key: "lastname", mandatory: true },
                    { key: "dateofbirth", mandatory: true },
                    { key: "gender", mandatory: true },
                    { key: "postcode", mandatory: true },
                    { key: "ethnicity", mandatory: true },
                    { key: "mobile", mandatory: false },
                    { key: "email", mandatory: false },
                    { key: "address", mandatory: false },
                    { key: "organisation", mandatory: isHealthcareWorker && !isCarehomeWorker && !isCarehomeWorker },
                    { key: "directorate", mandatory: isHealthcareWorker && isDirectorateRequired },
                    { key: "jobrole", mandatory: isHealthcareWorker },
                    { key: "departmentward", mandatory: isHealthcareWorker },
                    { key: "assignmentnumber", mandatory: false },
                    { key: "consentshare", mandatory: true },
                    { key: "confirmbringmedlist", mandatory: false },
                    { key: "childbearingage", mandatory: false },
                    { key: "confirmreadpregnancyleaflet", mandatory: false },
                    { key: "confirmpregnancyandconsent", mandatory: false },
                    { key: "reason", mandatory: false },
                    { key: "confirm", mandatory: true }];
            } else {
                newQuestions = [
                    { key: "vip", mandatory: false },
                    { key: "eligible", mandatory: true },
                    { key: "healthcareworker", mandatory: true },
                    { key: "carehomeworker", mandatory: isHealthcareWorker },
                    { key: "carehomeorganisation", mandatory: isCarehomeWorker },
                    { key: "onbehalfof", mandatory: true },
                    { key: "onbehalfofrelationship", mandatory: isOnBehalfOf },
                    { key: "onbehalfofname", mandatory: true && !isScreening && !isScreeningFollowUp },
                    { key: "willingtopartake", mandatory: true },

                    { key: "infectionorfeverish", mandatory: false },
                    { key: "covidpositive4weeks", mandatory: false },
                    { key: "othervaccinepast14days", mandatory: false },
                    { key: "othervaccinedate", mandatory: false },
                    { key: "flujabpast7days", mandatory: false },
                    { key: "confirmflujabrestrictions", mandatory: false },
                    { key: "seriousreaction", mandatory: false },
                    { key: "seriousreactionconfirmed", mandatory: false },
                    { key: "allergic", mandatory: false },
                    { key: "allergicconfirmed", mandatory: false },
                    { key: "bleedingdisorder", mandatory: false },
                    { key: "bleedingdisorderconfirmed", mandatory: false },
                    { key: "warfarin", mandatory: false },
                    { key: "warfarinthresholdok", mandatory: false },
                    { key: "breastfeeding", mandatory: false },
                    { key: "pregnant", mandatory: false },
                    { key: "covidtrial", mandatory: false },
                    { key: "covidtrialapproved", mandatory: false },

                    { key: "infectionorfeverish2", mandatory: false },
                    { key: "covidpositive4weeks2", mandatory: false },
                    { key: "othervaccinepast14days2", mandatory: false },
                    { key: "othervaccinedate2", mandatory: false },
                    { key: "flujabpast7days2", mandatory: false },
                    { key: "confirmflujabrestrictions2", mandatory: false },
                    { key: "seriousreaction2", mandatory: false },
                    { key: "seriousreactionconfirmed2", mandatory: false },
                    { key: "allergic2", mandatory: false },
                    { key: "allergicconfirmed2", mandatory: false },
                    { key: "bleedingdisorder2", mandatory: false },
                    { key: "bleedingdisorderconfirmed2", mandatory: false },
                    { key: "breastfeeding2", mandatory: false },
                    { key: "pregnant2", mandatory: false },
                    { key: "covidtrial2", mandatory: false },
                    { key: "covidtrialapproved2", mandatory: false },

                    { key: "restrictedmobility", mandatory: isScreening || isScreeningFollowUp },
                    { key: "systemicallergicreactiontofirst", mandatory: false },

                    { key: "firstappointmentsite", mandatory: false },
                    { key: "firstappointmentlocation", mandatory: false },
                    { key: "firstappointmentdate", mandatory: false },
                    { key: "firstappointmenttimeslot", mandatory: false },
                    { key: "secondappointmentsite", mandatory: false },
                    { key: "secondappointmentlocation", mandatory: false },
                    { key: "secondappointmentdate", mandatory: false },
                    { key: "secondappointmenttimeslot", mandatory: false },
                    { key: "nhsnumber", mandatory: isScreening },
                    { key: "forename", mandatory: isScreening },
                    { key: "lastname", mandatory: isScreening },
                    { key: "dateofbirth", mandatory: isScreening },
                    { key: "gender", mandatory: isScreening },
                    { key: "postcode", mandatory: isScreening },
                    { key: "ethnicity", mandatory: true },
                    { key: "mobile", mandatory: false },
                    { key: "email", mandatory: false },
                    { key: "address", mandatory: false },
                    { key: "organisation", mandatory: isHealthcareWorker && !isCarehomeWorker },
                    { key: "directorate", mandatory: isHealthcareWorker && !isCarehomeWorker && isDirectorateRequired },
                    { key: "jobrole", mandatory: isHealthcareWorker },
                    { key: "departmentward", mandatory: isHealthcareWorker },
                    { key: "assignmentnumber", mandatory: false },
                    { key: "consentshare", mandatory: true },
                    { key: "confirmbringmedlist", mandatory: false },
                    { key: "childbearingage", mandatory: false },
                    { key: "confirmreadpregnancyleaflet", mandatory: false },
                    { key: "confirmpregnancyandconsent", mandatory: false },
                    { key: "reason", mandatory: false },
                    { key: "confirm", mandatory: true }];
            }
        }
        return newQuestions;
    },
    getDirectorates() {
        return ["Cancer Services - Haematology",
            "Cancer Services - Oncology",
            "Cancer Services - Surgical Oncology",
            "Cardiovascular Services",
            "Chief Executive's Office",
            "Chief Nurse's Office",
            "Chief Operating Officer",
            "Clinical Imaging & Medical Physics",
            "Commercial",
            "Communications",
            "Data, Technology & Information",
            "Dental Services",
            "Essentia",
            "Evelina London - Community",
            "Evelina London - Medicine & Neonatology",
            "Evelina London - Surgery & Intensive Care",
            "Evelina London - Women's Services",
            "Finance",
            "Gastrointestinal Medicine & Surgery",
            "GSTT Contractors",
            "Integrated Care - Acute & General Medicine",
            "Integrated Care - Integrated Local Services",
            "Integrated Care - Therapies & Rehabilitation",
            "KCL",
            "Medical Director's Office",
            "Medical Specialties",
            "Other Corporate Departments",
            "Other Directorate not otherwise listed",
            "PACCS - Pulmonary, Adult Critical Care & Sleep",
            "Pharmacy & Medicines Optimisation",
            "R&D / BRC",
            "Retail Partners",
            "Specialist Ambulatory Services",
            "Strategy",
            "Surgery",
            "Theatres, Anaesthesia & Perioperative",
            "Transplant, Renal & Urology",
            "Viapath",
            "Workforce"];
    },
    getJobRoles() {
        return ["Additional Clinical Services",
            "Admin and Clerical Band 2",
            "Admin and Clerical Band 3",
            "Admin and Clerical Band 4",
            "Admin and Clerical Band 5",
            "Admin and Clerical Band 6",
            "Admin and Clerical Band 7",
            "Admin and Clerical Band 8",
            "Admin and Clerical Band 9",
            "Allied Health Professional ",
            "Allied Health Professional Band 4",
            "Allied Health Professional Band 5",
            "Allied Health Professional Band 6",
            "Allied Health Professional Band 7",
            "Allied Health Professional Band 8",
            "Allied Health Professional Band 9",
            "Apprentice Nursing Associate",
            "Clinical Scientist / Additional Professional Scientific & Technical",
            "Director/VSM",
            "Estates & Ancillary - Essentia Accommodation Services",
            "Estates & Ancillary - Essentia Catering Services",
            "Estates & Ancillary - Essentia Housekeeping Services",
            "Estates & Ancillary - Essentia Maintenance",
            "Estates & Ancillary - Essentia Other",
            "Estates & Ancillary - Essentia Portering Services",
            "Estates & Ancillary - Essentia Rapid Response Team",
            "Estates & Ancillary - Essentia Transport Services",
            "Estates & Ancillary - GSTT Engineering",
            "Estates & Ancillary - Other",
            "Estates & Ancillary - Security & Reception",
            "Executive Director",
            "KCL Other (if clinical, please chose from other categories if possible)",
            "Medical & Dental - Clinical Fellow/SAS Grade",
            "Medical & Dental - Consultant",
            "Medical & Dental - Foundation Doctor FY1/FY2/FY3",
            "Medical & Dental - Specialty Registrar (ST1/2)",
            "Medical & Dental - Specialty Registrar (ST3+)",
            "Non-Executive Director",
            "Nurse Practitioner",
            "Nursing & Midwifery Band 2",
            "Nursing & Midwifery Band 3",
            "Nursing & Midwifery Band 4",
            "Nursing & Midwifery Band 5",
            "Nursing & Midwifery Band 6",
            "Nursing & Midwifery Band 7",
            "Nursing & Midwifery Band 8",
            "Nursing & Midwifery Band 9",
            "Other",
            "Retail ",
            "Student - Medical & Dental",
            "Student - Nursing & Midwifery",
            "Student - Other ",
            "Viapath"];
    },
    getGenders() {
        return ["Male",
            "Female",
            "Other"];
    },
    getEthnicities() {
        return ["White - British",
            "White - Irish",
            "White - Any other White background",
            "Mixed - White and Black Caribbean",
            "Mixed - White and Black African",
            "Mixed - White and Asian",
            "Mixed - Any other mixed background",
            "Asian or Asian British - Indian",
            "Asian or Asian British - Pakistani",
            "Asian or Asian British - Bangladeshi",
            "Asian or Asian British - Any other Asian background",
            "Black or Black British - Caribbean",
            "Black or Black British - African",
            "Black or Black British - Any other Black background",
            "Other Ethnic Groups - Chinese",
            "Other Ethnic Groups - Any other ethnic group",
            "Not stated"];
    },
    getOrganisations() {
        return ["Guy's and St Thomas' NHS Foundation Trust",
            "Alliance",
            "Bexley CCG",
            "BMI",
            "Bromley CCG",
            "Bromley Healthcare",
            "Community Pharmacy",
            "GP Federation",
            "Greenbrooks",
            "Greenwich CCG",
            "Healthcare America",
            "Hurley Group",
            "Kings College Hospital NHS Foundation Trust",
            "Lambeth CCG",
            "Lewisham & Greenwich NHS Trust",
            "Lewisham CCG",
            "London Ambulance Service",
            "Marie Stopes",
            "Medinet",
            "NHS England and Improvement",
            "Nursing Home",
            "Other",
            "Oxleas NHS Foundation Trust",
            "Ramsay Group",
            "Residential Home",
            "Royal Brompton & Harefield NHS Foundation Trust",
            "SELDOC",
            "Social Care Provider",
            "South London and the Maudsley NHS Foundation Trust",
            "Southwark CCG",
            "Spa Medica",
            "St Elizabeth and St John",
            "Viapath",
            "Voluntary Sector"];
    },
    getCarehomeOrganisations() {
        return [{ id: "1-136143611", name: "Abbey Care Complex" },
            { id: "1-107269593", name: "Acorn Lodge Care Centre" },
            { id: "1-110213739", name: "Albany Nursing Home" },
            { id: "1-3977761030", name: "Alexander Court Care Centre" },
            { id: "1-135959879", name: "Ashgreen House Residential and Nursing Home" },
            { id: "1-319143132", name: "Aspen Court Nursing Home" },
            { id: "1-106916349", name: "Aspray House" },
            { id: "1-3152160938", name: "Bakers Court Care Home" },
            { id: "1-8049065442", name: "Barleycroft Care Home" },
            { id: "1-117294310", name: "Bennetts Castle Care Centre" },
            { id: "1-126240260", name: "Bluegrove House Care Home" },
            { id: "1-142750730", name: "Bridgeside Lodge Care Centre" },
            { id: "1-4122336991", name: "Brook House Care Centre" },
            { id: "1-135960150", name: "Chadwell House Residential Care Home" },
            { id: "1-140359380", name: "Charlton Park Care Home" },
            { id: "1-2891456486", name: "Chestnut Court Care Home" },
            { id: "1-128056159", name: "Forest Place Nursing Home" },
            { id: "1-6124552954", name: "Gable Court Care Home" },
            { id: "1-128272532", name: "Harts House" },
            { id: "1-8053357015", name: "Lambwood Heights Care home" },
            { id: "1-128976529", name: "Love Walk Care Home" },
            { id: "1-142386261", name: "Manor Farm Care Home" },
            { id: "1-304037140", name: "Marlborough Court" },
            { id: "1-3121814350", name: "Mornington Hall Care Home" },
            { id: "1-139859068", name: "Muriel Street Resource Centre" },
            { id: "1-125861732", name: "Park View" },
            { id: "1-135968375", name: "Pinewood Residential Care Home" },
            { id: "1-1058388579", name: "Puddingstone Grange" },
            { id: "1-118135581", name: "Queens Court Care Home" },
            { id: "1-126242019", name: "Rose Court" },
            { id: "1-3154511716", name: "Seabrooke Manor Care Home" },
            { id: "1-129916719", name: "Sherrell House Care Home" },
            { id: "1-126242064", name: "Silk Court Care Home" },
            { id: "1-3979621910", name: "Springfield Care Centre" },
            { id: "1-471918405", name: "Summerdale Court Care Home" },
            { id: "1-4078417903", name: "The Lodge Care Home" },
            { id: "1-135959962", name: "Time Court Residential and Nursing Home" },
            { id: "1-320755780", name: "Tower Bridge Care Centre" },
            { id: "1-123781199", name: "Vi and John Rubens House" },
            { id: "1-125863016", name: "Westgate House" },
            { id: "1-1555693520", name: "Willows Care Home" },
            { id: "1-4000551766", name: "Woodland Grove" }];
    },
    getFirstAppointmentOutcomeReasons() {
        return [{ id: "Cancelled - Allergy", name: "Cancelled - Allergy" },
            { id: "Cancelled - Patient Declined", name: "Cancelled - Patient Declined" },
            { id: "Cancelled", name: "Cancelled - Other" },
            { id: "DNA", name: "DNA" }];
    },
    getSecondAppointmentOutcomeReasons() {
        return [{ id: "Cancelled - Allergy", name: "Cancelled - Allergy" },
            { id: "Cancelled - Patient Declined", name: "Cancelled - Patient Declined" },
            { id: "Cancelled", name: "Cancelled - Other" },
            { id: "DNA", name: "DNA" }];
    },
    getThirdAppointmentOutcomeReasons() {
        return [{ id: "Cancelled - Allergy", name: "Cancelled - Allergy" },
        { id: "Cancelled - Patient Declined", name: "Cancelled - Patient Declined" },
        { id: "Cancelled", name: "Cancelled - Other" },
        { id: "DNA", name: "DNA" }];
    },
    getBoosterAppointmentOutcomeReasons() {
        return [{ id: "Cancelled - Allergy", name: "Cancelled - Allergy" },
        { id: "Cancelled - Patient Declined", name: "Cancelled - Patient Declined" },
        { id: "Cancelled", name: "Cancelled - Other" },
        { id: "DNA", name: "DNA" }];
    },
    getFluAppointmentOutcomeReasons() {
        return [{ id: "Cancelled - Allergy", name: "Cancelled - Allergy" },
        { id: "Cancelled - Patient Declined", name: "Cancelled - Patient Declined" },
        { id: "Cancelled", name: "Cancelled - Other" },
        { id: "DNA", name: "DNA" }];
    }
};

export default questionManager;