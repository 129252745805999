import React from 'react';
import RequiredSymb from '../common/RequiredSymb';

const QuestionnaireBlurb = () => (
    <div>
        <div>
            <p>Responses to this questionnaire will help the Trust to accurately measure vaccine uptake amongst staff across the Trust. To understand more about how data from this questionnaire will be used please <a href="/fluvaccinationnotice" target="_blank">click here</a>.</p>
            <p className="questionnaire-blurb-important">We strongly recommend that you have your vaccine to protect yourself, your patients, your colleagues and your family as much as possible.</p>
            <p className="questionnaire-blurb-emphasis">Trust Staff, if relevant, please ensure that you complete your name using your formal name (e.g. Catherine), rather than the name by which you are commonly known (e.g. Katy).</p>
        </div>
        <div>
            <RequiredSymb /> Required;
        </div>
    </div>);

export default QuestionnaireBlurb;