import * as signalR from "@microsoft/signalr";
import tokenService from "../services/tokenService";

export const signalRUtil = {
    getHubConnection: async function (site, location, role, desk) {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/queuehub",{ accessTokenFactory: () => tokenService.getToken()})
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        await hubConnection.start();

        hubConnection.invoke("joinSite", site, location, role, desk);

        hubConnection.onreconnected(async () => {
            hubConnection.invoke("joinSite", site, location, role, desk);
        });
        
        return hubConnection;
    },
    getHubConnectionUpload: async function () {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/uploadhub",{ accessTokenFactory: () => tokenService.getToken()})
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        await hubConnection.start();
              
        return hubConnection;
    },
    getHubConnectionAppointmentMove: async function () {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/appointmentmovehub", { accessTokenFactory: () => tokenService.getToken() })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        await hubConnection.start();

        return hubConnection;
    }
}