import React from 'react';
import RequiredSymb from '../common/RequiredSymb';

const QuestionnaireBlurb = () => (
    <div>
        <div>
            <p><strong>Report a COVID-19 rapid lateral flow test</strong></p>
            <p>Use this service to report the results of all your lateral flow tests, whether they are sourced from King's College Hospital, another NHS trust or through GOV.UK. You must report all results, even those that are negative or invalid.</p>
            <p>The information you submit will only be accessed by the King's College Hospital Vaccination and Asymptomatic Testing teams.</p>
            <p>A rapid lateral flow test is a coronavirus test you do yourself. It shows you the result on a testing tray that comes with the test. Replacement testing kits should now be ordered online - visit <a href="http://www.gov.uk/order-coronavirus-rapid-lateral-flow-tests" target="_blank" rel="noopener noreferrer">www.gov.uk/order-coronavirus-rapid-lateral-flow-tests</a> and follow instructions. Select 'yes' to the question 'Do you work for the NHS in England and take part in their staff testing programme?'. It's best to create an account if you don't already have one, to make ordering future kits simpler.</p>
            <p><strong>Before you start</strong></p>
            <div>To use this service, you will need:
                <ul>
                    <li>the ID number printed on the test strip (the part of the kit that shows your result)</li>
                    <li>a mobile phone number so we can text you to confirm we've got your result. Invalid test results will not be confirmed by text.</li>
                </ul>
            </div>
            <p><strong>Why you should report your results</strong></p>
            <div>Reporting results helps to:
                <ul>
                    <li>reduce infection rates in the Trust and your community</li>
                    <li>protect people at higher risk of infection</li>
                    <li>prevent and reduce the spread of the virus</li>
                    <li>find out if you're infectious (you may not have symptoms)</li>
                </ul>
            </div>
            <p><strong>TIP</strong></p>
            <p>Bookmark this page on your device so you can access it easily when reporting future test results.</p>
            <p>Save time by saving your details for yhour next report. Select the box 'allow cookies' and you won't have to re-enter them every time you report a test result.</p>
        </div>
        <div>
            <RequiredSymb /> Required;
        </div>
    </div>);

export default QuestionnaireBlurb;